var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import TranslateItem from './TranslateItem';
import styles from './styles.module.scss';
var TranslateList = function (_a) {
    var list = _a.list, prefix = _a.prefix;
    if (!list.length)
        return _jsx("span", { children: "No Data" }, void 0);
    if (list.length === 1 && !list[0])
        return _jsx("span", { children: "No Data" }, void 0);
    return (_jsx("ul", __assign({ className: styles.list }, { children: list
            .filter(function (key) { return key; })
            .map(function (key, i) { return (_jsx("li", { children: _jsx(TranslateItem, { keyword: key, prefix: prefix }, void 0) }, i)); }) }), void 0));
};
export default TranslateList;
