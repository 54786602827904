var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Game = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", fill: "currentColor", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M8.6 6a4 4 0 00-3.923 3.215l-1.634 8.173a1.3 1.3 0 002.217 1.15l4.015-4.227A1 1 0 0110 14h5.5a4 4 0 100-8H8.6zm0-2a6 6 0 00-5.885 4.823l-1.634 8.173a3.3 3.3 0 005.629 2.92L10.43 16h5.07a6 6 0 100-12H8.6z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M20.204 8.047a1 1 0 011.173.791l1.6 8.232a3.3 3.3 0 01-5.632 2.903l-4.07-4.284a1 1 0 011.45-1.378l4.07 4.284a1.3 1.3 0 002.218-1.143l-1.6-8.232a1 1 0 01.791-1.173zM8 8a1 1 0 011 1v2a1 1 0 11-2 0V9a1 1 0 011-1z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M6 10a1 1 0 011-1h2a1 1 0 010 2H7a1 1 0 01-1-1zM13 10a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z", clipRule: "evenodd" }))); };
export default Game;
