var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useCallback, useMemo, useRef, useState, useEffect, } from 'react';
import cn from 'classnames';
import { AngleDown, AngleUp, Loading } from '@core/icons';
import Icon from '../Icon';
import Input from '../Input';
import InputAdornment from '../InputAdornment';
import DropdownListContext from './select-context';
import Menu from '../Menu';
import DropdownListInput from './SelectInput';
import { isFunction } from '../../utils/common';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
var defaultProps = {
    component: Input,
    // type: 'filter',
};
function calculatePopoverStyle(ref) {
    if (!ref) {
        return {};
    }
    var width = Math.max(ref.getBoundingClientRect().width, 80);
    return {
        minWidth: width + "px",
        maxWidth: '300px',
        maxHeight: '300px',
        overflowY: 'auto',
    };
}
function checkIsSelected(value) {
    return value !== undefined && value !== null;
}
export var DropdownList = forwardRef(function (props, ref) {
    var _a;
    var _b = __assign(__assign({}, defaultProps), props), Component = _b.component, children = _b.children, onChange = _b.onChange, onClick = _b.onClick, onBlur = _b.onBlur, onFocus = _b.onFocus, menuClassName = _b.menuClassName, className = _b.className, placeholder = _b.placeholder, variant = _b.variant, type = _b.type, setOpenDropDown = _b.setOpenDropDown, displayProps = _b.display, loading = _b.loading, rest = __rest(_b, ["component", "children", "onChange", "onClick", "onBlur", "onFocus", "menuClassName", "className", "placeholder", "variant", "type", "setOpenDropDown", "display", "loading"]);
    var wrapperRef = useRef();
    var popoverStyle = useRef(null);
    var t = useTranslation().t;
    var isSelected = checkIsSelected(rest.value);
    // @ts-ignore
    var _c = __read(useState(false), 2), open = _c[0], setOpen = _c[1];
    var handleOpen = useCallback(function (e) {
        if (loading)
            return;
        e === null || e === void 0 ? void 0 : e.preventDefault();
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        if (!open && !rest.disabled) {
            popoverStyle.current = calculatePopoverStyle(wrapperRef.current);
            setOpen(true);
        }
        if (onFocus) {
            onFocus(e);
        }
        if (onClick) {
            return onClick(e);
        }
    }, [rest.disabled, onClick, onFocus, open, loading]);
    var handleClose = useCallback(function (e) {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        setOpen(false);
        if (onBlur) {
            return onBlur(e);
        }
    }, [onBlur]);
    useEffect(function () {
        setOpenDropDown === null || setOpenDropDown === void 0 ? void 0 : setOpenDropDown(open);
    }, [open, setOpenDropDown]);
    var dropdownListContext = useMemo(function () { return ({
        onChange: function (value) {
            if (!onChange || !isFunction(onChange)) {
                console.warn("onChange isn't implement yet");
                return;
            }
            return onChange(value);
        },
        value: rest.value,
    }); }, [onChange, rest.value]);
    var afterInputIcon = useMemo(function () {
        if (loading)
            return Loading;
        return open ? AngleUp : AngleDown;
    }, [open, loading]);
    var afterInput = useMemo(function () { return (_jsx(InputAdornment, __assign({ size: rest.size, onClick: handleOpen, className: styles["addon-size-" + rest.size] }, { children: _jsx(Icon, { className: styles.icon, component: afterInputIcon }, void 0) }), void 0)); }, [open, handleOpen, rest.size]);
    var getDisplayWithValue = useCallback(function () {
        var tmp = React.Children.toArray(children)
            .filter(function (child) { var _a; return ((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.value) === rest.value; })
            .map(function (child, idx) {
            if (displayProps && isFunction(displayProps)) {
                return displayProps(idx);
            }
            if (typeof child === 'string') {
                return child;
            }
            var innerChild = child.props.children;
            if (typeof innerChild === 'string') {
                return innerChild;
            }
            // is component
            if (React.isValidElement(child.props.children)) {
                return child.props.children;
            }
            return t('TEXT.SELECT');
        });
        if (!tmp.length)
            return placeholder;
        return tmp;
    }, [children, displayProps, placeholder, rest.value, t]);
    var display = useMemo(function () {
        if (loading)
            return t('TEXT.LOADING');
        return !isSelected ? placeholder : getDisplayWithValue();
    }, [loading, t, isSelected, placeholder, getDisplayWithValue]);
    return (_jsxs(_Fragment, { children: [_jsx(Input, __assign({}, rest, { className: cn(className, styles.input, (_a = {},
                    _a[styles['is-selected']] = type === 'filter' && isSelected,
                    _a[styles['disabled']] = rest.disabled,
                    _a[styles['is-open']] = open,
                    _a)), onClick: handleOpen, component: DropdownListInput, afterInput: afterInput, display: display, ref: ref, readOnly: true, 
                // @ts-ignore
                wrapperRef: wrapperRef }), void 0), open && (_jsx(Menu, __assign({ anchorRef: wrapperRef, onClose: handleClose, menuClassName: menuClassName, style: popoverStyle.current }, { children: _jsx(DropdownListContext.Provider, __assign({ value: dropdownListContext }, { children: children }), void 0) }), void 0))] }, void 0));
});
DropdownList.displayName = 'DropdownList';
export default DropdownList;
