import useQuery from '@core/uikit/build/src/hooks/useQuery';
const REDIRECT_PARAMS = {
    moduleId: 'module',
    path: 'path',
};
const DEFAULT_MODULE_ID = -1;
const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
export function useRedirectParams() {
    const query = useQuery();
    const moduleId = query.get(REDIRECT_PARAMS.moduleId) || DEFAULT_MODULE_ID;
    const pathParam = query.get(REDIRECT_PARAMS.path) || '';
    /* Query param with be passed from
      Alfred's Home -> Login -> Verify
      => Other apps' SSO
      The browser will treat all & symbol as the same, and the query params of the destination url will me missing.
      => Convert & to ⅋ then in other apps' SSO page, convert back to get escaped destination path
     */
    const pathParamWithFakeAnd = pathParam.replaceAll('&', '⅋');
    const path = encodeURIComponent(pathParamWithFakeAnd);
    const decodedPathWithFakeAnd = decodeURIComponent(decodeURIComponent(path));
    let decodedPath = decodedPathWithFakeAnd.replaceAll('⅋', '&');
    // If the path doesn't start with a slash, add it
    if (decodedPath.length && decodedPath[0] != '/') {
        decodedPath += '/';
    }
    const queryString = encodeURI(query.toString())
        .replaceAll('%2F', '/')
        .replaceAll('%3D', '=');
    return {
        DEFAULT_MODULE_ID,
        REDIRECT_PARAMS,
        moduleId,
        path,
        pathDecoded: decodedPath,
        queryString,
        portalUrlWithQueryString: `${PORTAL_URL}/?${queryString}`,
    };
}
