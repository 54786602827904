var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "#7462E1", d: "M7 16h1c0 .283.096.52.288.712A.965.965 0 009 17a.968.968 0 00.713-.288A.967.967 0 0010 16h1a.968.968 0 00.713-.288A.967.967 0 0012 15v-3a.97.97 0 00-.287-.713A.97.97 0 0011 11H8v-1h3.025a.926.926 0 00.7-.288A.99.99 0 0012 9a.97.97 0 00-.287-.713A.97.97 0 0011 8h-1a.97.97 0 00-.287-.713A.97.97 0 009 7a.967.967 0 00-.712.287A.968.968 0 008 8H7a.97.97 0 00-.713.287A.97.97 0 006 9v3c0 .283.096.52.287.712.192.192.43.288.713.288h3v1H6.975a.928.928 0 00-.7.287A.993.993 0 006 15c0 .283.096.52.287.712.192.192.43.288.713.288zm8.65-.1a.48.48 0 00.7 0L18 14.25h-4l1.65 1.65zM14 10h4l-1.65-1.65a.48.48 0 00-.7 0L14 10zM4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 014 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 20H4z" })));
}
export default SvgComponent;
