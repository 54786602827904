var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var TableGame = function (props) { return (React.createElement("svg", __assign({ id: "TableGameIcon", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { id: "Essential Icon" },
        React.createElement("path", { id: "Table", d: "M15 4H9C8.44772 4 8 4.44772 8 5V19C8 19.5523 8.44772 20 9 20H15C15.5523 20 16 19.5523 16 19V5C16 4.44772 15.5523 4 15 4Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("g", { id: "User" },
            React.createElement("path", { id: "Vector", d: "M20 4V4.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { id: "Vector_2", d: "M20 20V20.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { id: "Vector_3", d: "M20 16V16.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { id: "Vector_4", d: "M20 12V12.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { id: "Vector_5", d: "M20 8V8.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { id: "Vector_6", d: "M4 4V4.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { id: "Vector_7", d: "M4 20V20.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { id: "Vector_8", d: "M4 16V16.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { id: "Vector_9", d: "M4 12V12.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { id: "Vector_10", d: "M4 8V8.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))))); };
export default TableGame;
