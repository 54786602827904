var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import i18n from 'i18n';
import LiveOpsDialog from '../Dialog';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../Typography';
import CommonButton from '../CommonButton';
import DefaultStepInput from './DefaultStepInput';
import useErrorMessage from '../../hooks/useErrorMessage';
export var STEPS;
(function (STEPS) {
    STEPS[STEPS["STEP_1"] = 1] = "STEP_1";
    STEPS[STEPS["STEP_2"] = 2] = "STEP_2";
})(STEPS || (STEPS = {}));
var DialogTwoSteps = function (_a) {
    var form = _a.form, _b = _a.fields, fields = _b === void 0 ? [] : _b, subtitle = _a.subtitle, _c = _a.title, title = _c === void 0 ? '' : _c, _d = _a.steps, steps = _d === void 0 ? [] : _d, open = _a.open, bodyClassNames = _a.bodyClassNames, actionsClassNames = _a.actionsClassNames, _e = _a.loading, loading = _e === void 0 ? false : _e, _f = _a.autoResetForm, autoResetForm = _f === void 0 ? false : _f, _g = _a.maxWidth, maxWidth = _g === void 0 ? '512px' : _g, _h = _a.hideCancel, hideCancel = _h === void 0 ? false : _h, _j = _a.hideStepsLabel, hideStepsLabel = _j === void 0 ? false : _j, ComponentStep1 = _a.componentStep1, ComponentStep2 = _a.componentStep2, _k = _a.textSubmit, textSubmit = _k === void 0 ? i18n.t('Common::TEXT.SUBMIT') : _k, _l = _a.textNextToPreview, textNextToPreview = _l === void 0 ? i18n.t('Common::TEXT.NEXT_TO_PREVIEW') : _l, _m = _a.textCancel, textCancel = _m === void 0 ? i18n.t('Common::COMMON.TEXT.CANCEL') : _m, _o = _a.textPrevious, textPrevious = _o === void 0 ? i18n.t('Common::TEXT.PREVIOUS_STEP') : _o, actionsStep1 = _a.actionsStep1, actionsStep2 = _a.actionsStep2, handleSubmit = _a.handleSubmit, handleToggleDialog = _a.handleToggleDialog, unmounted = _a.unmounted;
    var useStyles = useMemo(function () {
        return makeStyles(function () { return ({
            MuiDialogPaperWidthSm: {
                maxWidth: maxWidth,
            },
            container: {
                background: 'rgba(39, 47, 61, 0.16)',
                backdropFilter: 'blur(8px)',
            },
        }); });
    }, [maxWidth]);
    var classes = useStyles();
    var errorMessage = useErrorMessage().errorMessage;
    var _p = __read(useState(STEPS.STEP_1), 2), activeStep = _p[0], setActiveStep = _p[1];
    var isFirstStep = activeStep === STEPS.STEP_1;
    useEffect(function () {
        if (open) {
            setActiveStep(STEPS.STEP_1);
            form === null || form === void 0 ? void 0 : form.clearErrors();
            autoResetForm && (form === null || form === void 0 ? void 0 : form.reset());
        }
        return function () {
            unmounted && unmounted();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    var nextAction = function () {
        activeStep >= steps.length ? handleSubmit() : setActiveStep(activeStep + 1);
    };
    var handleNext = function () {
        (form === null || form === void 0 ? void 0 : form.handleSubmit) ? form.handleSubmit(nextAction)() : nextAction();
    };
    var handleBack = function () {
        isFirstStep ? handleToggleDialog() : setActiveStep(activeStep - 1);
    };
    var actions = [
        actionsStep1 && actionsStep1(handleNext, handleBack),
        actionsStep2 && actionsStep2(handleNext, handleBack),
    ];
    var defaultActions = useMemo(function () { return (_jsxs("div", __assign({ className: "flex justify-end" }, { children: [((isFirstStep && !hideCancel) || !isFirstStep) && (_jsx(CommonButton, { className: "mr-8", type: "secondary", text: isFirstStep ? textCancel : textPrevious, onClick: handleBack }, void 0)), _jsx(CommonButton, { text: isFirstStep ? textNextToPreview : textSubmit, onClick: handleNext, loading: loading, disabled: loading }, void 0)] }), void 0)); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleNext, handleBack]);
    return (open && (_jsx(LiveOpsDialog, __assign({ fullWidth: true, open: true, steps: hideStepsLabel ? [] : steps, activeStep: activeStep - 1, title: hideStepsLabel ? steps[activeStep - 1] : title, subtitle: subtitle, onClose: handleToggleDialog, "aria-labelledby": "form-dialog-title", bodyClassNames: bodyClassNames, actionsClassNames: actionsClassNames, classes: {
            paperWidthSm: classes.MuiDialogPaperWidthSm,
            container: classes.container,
        }, errorMessage: errorMessage, actions: actions[activeStep - 1] || defaultActions }, { children: isFirstStep ? (ComponentStep1 ? (_jsx(ComponentStep1, { form: form, fields: fields }, void 0)) : (_jsx(DefaultStepInput, { form: form, fields: fields }, void 0))) : ComponentStep2 ? (_jsx(ComponentStep2, { form: form, fields: fields }, void 0)) : (_jsx(DefaultStepInput, { form: form, fields: fields }, void 0)) }), void 0)));
};
export default DialogTwoSteps;
export var FormControl = function (_a) {
    var title = _a.title, text = _a.text;
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: "mb-8" }, { children: _jsx(Typography, __assign({ variant: TypoVariants.subtitle2, weight: TypoWeights.bold, type: TypoTypes.secondary }, { children: title }), void 0) }), void 0), _jsx(Typography, __assign({ variant: TypoVariants.subtitle1, weight: TypoWeights.bold }, { children: text }), void 0)] }, void 0));
};
