var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { InfoCircle } from '@core/icons';
import Typography from '../../Typography';
import { getCellStyle, getStickyCellClassName } from '../helper';
import { HeaderTooltip } from './HeaderTooltip';
import SortCell from './HeaderCellBySort';
import styles from '../styles.module.scss';
var HeaderCell = function (_a) {
    var label = _a.label, stickyColumn = _a.stickyColumn, header = _a.header, width = _a.width, sticky = _a.sticky, columnIdx = _a.columnIdx, hasCheckboxes = _a.hasCheckboxes, classOfHeaderCell = _a.classOfHeaderCell, className = _a.className, headerTooltip = _a.headerTooltip, isAllRowsExpanded = _a.isAllRowsExpanded, handleToggleExpandAll = _a.handleToggleExpandAll, sort = _a.sort, colSpan = _a.colSpan, rowSpan = _a.rowSpan, align = _a.align;
    var cellStyle = getCellStyle(stickyColumn, header, width, sticky, columnIdx, hasCheckboxes);
    var classOfCurrentHeaderCell = getStickyCellClassName([classOfHeaderCell, className], { stickyColumn: stickyColumn, sticky: sticky, width: width });
    var cellLabel = (_jsx("span", __assign({ className: styles.headerText }, { children: label }), void 0));
    if (headerTooltip) {
        cellLabel = (_jsx(HeaderTooltip, __assign({ title: headerTooltip }, { children: _jsxs("span", __assign({ className: styles.headerText }, { children: [_jsx(InfoCircle, {}, void 0), " \u00A0 ", _jsx("span", { children: label }, void 0)] }), void 0) }), void 0));
    }
    var headerRender = header[columnIdx].headerRender;
    if (headerRender) {
        cellLabel = headerRender({
            isAllRowsExpanded: isAllRowsExpanded,
            handleToggleExpandAll: handleToggleExpandAll,
        });
    }
    var attrs = {
        className: classOfCurrentHeaderCell,
        style: cellStyle,
    };
    if (rowSpan)
        attrs.rowSpan = rowSpan;
    if (colSpan)
        attrs.colSpan = colSpan;
    var cell = (_jsx(Typography, __assign({ variant: "body1", type: "text", weight: "bold", component: "th" }, attrs, { children: _jsx(SortCell, __assign({ align: align, sortName: sort }, { children: cellLabel }), void 0) }), columnIdx));
    return cell;
};
export default React.memo(HeaderCell);
