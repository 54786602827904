import { KPISpaceIcon, KPISpaceIconSelected, GamingSpaceIcon, GamingSpaceIconSelected, CMSSpaceIcon, CMSSpaceIconSelected, UserSpaceIcon, UserSpaceIconSelected, PlayerSpaceIcon, PlayerSpaceIconSelected, MoneySpaceIcon, MoneySpaceIconSelected, WithdrawApprovalSpaceIcon, WithdrawApprovalSpaceIconSelected, GameInsightSpaceIcon, GameInsightSpaceIconSelected, DashboardSpaceIcon, DashboardSpaceIconSelected, } from '@core/icons';
export var KPI_URL = "" + process.env.REACT_APP_KPI_URL;
export var FORGE_URL = "" + process.env.REACT_APP_FORGE_URL;
export var CMS_URL = "" + process.env.REACT_APP_CMS_URL;
export var USER_MANAGEMENT_URL = "" + process.env.REACT_APP_USER_MANAGEMENT_URL;
export var PLAYER_MANAGEMENT_URL = "" + process.env.REACT_APP_PLAYER_MANAGEMENT_URL;
export var MONEY_MANAGEMENT_URL = "" + process.env.REACT_APP_MONEY_MANAGEMENT_URL;
export var WITHDRAW_APPROVAL_MANAGEMENT_URL = "" + process.env.REACT_APP_WITHDRAW_APPROVAL_URL;
export var GAME_INSIGHT_URL = "" + process.env.REACT_APP_GAME_INSIGHT_URL;
export var DASHBOARD_URL = "" + process.env.REACT_APP_DASHBOARD_URL;
export var spacesConfig = [
    {
        icon: DashboardSpaceIcon,
        iconSelected: DashboardSpaceIconSelected,
        title: 'MODULE.DASHBOARD',
        url: DASHBOARD_URL,
        id: Number(process.env.REACT_APP_DASHBOARD),
        redirectTo: MONEY_MANAGEMENT_URL,
    },
    {
        icon: KPISpaceIcon,
        iconSelected: KPISpaceIconSelected,
        title: 'MODULE.KPI',
        url: KPI_URL,
        id: Number(process.env.REACT_APP_MODULE_KPI),
    },
    {
        icon: PlayerSpaceIcon,
        iconSelected: PlayerSpaceIconSelected,
        title: 'MODULE.PLAYER_MANAGEMENT',
        url: PLAYER_MANAGEMENT_URL,
        id: Number(process.env.REACT_APP_MODULE_PLAYER_MANAGEMENT),
    },
    {
        icon: GameInsightSpaceIcon,
        iconSelected: GameInsightSpaceIconSelected,
        title: 'MODULE.GAME_INSIGHT',
        url: GAME_INSIGHT_URL,
        id: Number(process.env.REACT_APP_GAME_INSIGHT),
    },
    {
        icon: WithdrawApprovalSpaceIcon,
        iconSelected: WithdrawApprovalSpaceIconSelected,
        title: 'MODULE.WITHDRAW_APPROVAL',
        url: WITHDRAW_APPROVAL_MANAGEMENT_URL,
        id: Number(process.env.REACT_APP_MODULE_WITHDRAW_APPROVAL),
    },
    {
        icon: CMSSpaceIcon,
        iconSelected: CMSSpaceIconSelected,
        title: 'MODULE.CMS',
        url: CMS_URL,
        id: Number(process.env.REACT_APP_MODULE_CMS),
    },
    {
        icon: GamingSpaceIcon,
        iconSelected: GamingSpaceIconSelected,
        title: 'MODULE.CRADLE',
        url: FORGE_URL,
        id: Number(process.env.REACT_APP_MODULE_FORGE),
    },
    {
        icon: MoneySpaceIcon,
        iconSelected: MoneySpaceIconSelected,
        title: 'MODULE.MONEY_MANAGEMENT',
        url: MONEY_MANAGEMENT_URL,
        redirectTo: MONEY_MANAGEMENT_URL,
        id: Number(process.env.REACT_APP_MODULE_MONEY_MANAGEMENT),
    },
    {
        icon: UserSpaceIcon,
        iconSelected: UserSpaceIconSelected,
        title: 'MODULE.USER_MANAGEMENT',
        url: USER_MANAGEMENT_URL,
        redirectTo: MONEY_MANAGEMENT_URL,
        id: Number(process.env.REACT_APP_MODULE_USER_MANAGEMENT),
    },
];
