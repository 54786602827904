var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import MultipleSelect from '../../MultipleSelect';
import { MultipleSelectOption } from '../../MultipleSelectOption';
import useCheckbox from '../../../hooks/useCheckbox';
import useFilter from '../../../hooks/useFilter';
var FilterMultiSelect = function (_a) {
    var _b;
    var name = _a.name, placeholder = _a.placeholder, options = _a.options, _c = _a.pageKey, pageKey = _c === void 0 ? 'p' : _c;
    var _d = useFilter(), query = _d.query, pushQuery = _d.pushQuery, popQuery = _d.popQuery;
    var selectedFromUrl = ((_b = query.get(name)) === null || _b === void 0 ? void 0 : _b.split(',')) || [];
    var _e = useCheckbox(selectedFromUrl, options.map(function (o) { return o.value; }), function (data) {
        if (data === void 0) { data = []; }
        data.length ? pushQuery(name, data.join(',')) : popQuery(name);
        popQuery(pageKey); // return page 1 after change filter
    }), selected = _e.selected, onChange = _e.onChange, unselectAll = _e.unselectAll;
    useEffect(function () {
        !selectedFromUrl.length && unselectAll();
    }, [selectedFromUrl.length]);
    return (_jsx(MultipleSelect, __assign({ placeholder: placeholder, selected: selected, onChange: onChange, onClear: unselectAll }, { children: options.map(function (o) { return (_jsx(MultipleSelectOption, __assign({ value: "" + o.value }, { children: o.name }), o.value)); }) }), void 0));
};
export default FilterMultiSelect;
