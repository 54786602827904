import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import LiveOpsDialog from '@core/uikit/build/src/components/Dialog';

import BasicInfoForm from './step/BasicInfo';

const useStyles = makeStyles(() => ({
  MuiDialogContainer: {
    background: 'rgba(39, 47, 61, 0.16)',
    backdropFilter: 'blur(16px)',
  },
  MuiDialogPaperWidthSm: {
    maxWidth: '528px',
  },
}));

type ChangePasswordProps = {
  openChangePassword: boolean;
  setOpenChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangePassword: React.FunctionComponent<ChangePasswordProps> = ({
  openChangePassword,
  setOpenChangePassword,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<any>({
    role: 'TELLER',
  });

  const handleClose = () => {
    setOpenChangePassword(false);
    handleFirst();
  };

  const handleFirst = () => {
    setFormFields({});
  };

  const saveFormData = (formData: {}) => {
    setFormFields((prev: any) => {
      let newFormData = {
        ...prev,
        ...formData,
      };
      return newFormData;
    });
  };

  return (
    <div>
      <LiveOpsDialog
        title={t('Common::PORTAL.PASSWORD.CHANGE_PASSWORD')}
        fullWidth
        open={openChangePassword}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paperWidthSm: classes.MuiDialogPaperWidthSm,
          container: classes.MuiDialogContainer,
        }}
        handleBack={handleClose}
      >
        <BasicInfoForm
          handleClose={handleClose}
          formFields={formFields}
          setFormFields={saveFormData}
        />
      </LiveOpsDialog>
    </div>
  );
};

export default ChangePassword;
