var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState } from 'react';
import { getErrorMessageFromCode } from '../utils/constant/message';
function useErrorMessage() {
    var _a = __read(useState({
        message: '',
        code: undefined,
        metadata: undefined,
        name: '',
    }), 2), error = _a[0], setError = _a[1];
    var handleError = function (error) {
        if (error) {
            setError(error);
        }
        else {
            setError({
                message: '',
                code: undefined,
                metadata: undefined,
                name: '',
            });
        }
    };
    return {
        error: error,
        setError: handleError,
        errorMessage: error.code ? getErrorMessageFromCode(error.code) : undefined,
    };
}
export default useErrorMessage;
