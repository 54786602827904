var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { Popover } from '@material-ui/core';
import cn from 'classnames';
import { formatDateTime, formatDate } from '@core/okit';
import { Calendar, Close } from '@core/icons';
import PopoverCalendar from './PopoverCalendar';
import { TextField } from '../../../../TextField';
import { InputAdornment } from '../../../../InputAdornment';
import Icon from '../../../../Icon';
import styles from './styles.module.scss';
var CalendarPicker = function (_a) {
    var _b;
    var value = _a.value, onChange = _a.onChange, showTime = _a.showTime, field = __rest(_a, ["value", "onChange", "showTime"]);
    var _c = __read(useState(false), 2), isActive = _c[0], setIsActive = _c[1];
    var _d = __read(useState(null), 2), anchorEl = _d[0], setAnchorEl = _d[1];
    // @ts-ignore
    var placeholder = field.placeholder, name = field.name, disabled = field.disabled;
    var methods = useFormContext();
    var formState = methods.formState;
    var errors = formState.errors;
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
        setIsActive(true);
    };
    var handleClose = function () {
        setAnchorEl(null);
        setIsActive(false);
    };
    var open = Boolean(anchorEl);
    var id = open ? 'simple-popover' : undefined;
    var onChangeDate = function (value) {
        onChange(value);
    };
    var handleChangeDate = function (e) {
        onChange(e.currentTarget.value || 0);
    };
    var onClickBtnClear = function () {
        onChangeDate(null);
    };
    var clsName = cn(disabled ? styles.input_date_disabled : styles.input_date, (_b = {}, _b[styles.inputDateActive] = isActive, _b));
    var dateValue = useMemo(function () {
        if (!value)
            return '';
        if (showTime)
            return formatDateTime(+value);
        return formatDate(+value);
    }, [value]);
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { status: errors[name] ? 'error' : 'primary', className: clsName, name: "date", placeholder: placeholder, disabled: disabled, value: dateValue, onChange: handleChangeDate, onClick: function () { return setIsActive(true); }, onBlur: function () { return setIsActive(false); }, afterInput: [
                    _jsx(InputAdornment, __assign({ onClick: onClickBtnClear, className: cn(styles.reset_icon, value && styles.reset_icon_active) }, { children: _jsx(Icon, { className: "pointer", component: Close }, void 0) }), void 0),
                    _jsxs(InputAdornment, __assign({ onClick: handleClick, className: styles.calendar_icon }, { children: [_jsx(Calendar, { "aria-describedby": id, className: isActive ? styles.icon_date_active : styles.icon_date }, void 0), _jsx("div", { className: styles.overlap }, void 0)] }), void 0),
                ] }, void 0), _jsx(Popover, __assign({ id: id, className: styles['popover_daily'], open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                } }, { children: _jsx(PopoverCalendar, __assign({ value: value, onChangeDate: onChangeDate, handleClose: handleClose, showTime: showTime }, field), void 0) }), void 0)] }, void 0));
};
export default CalendarPicker;
