import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@material-ui/core';
import { ArrowLeft, Loading, Search } from '@core/icons';
import { SSO_PATH } from '@core/okit/build/const/routes';
import Icon from '@core/uikit/build/src/components/Icon';
import { debounce } from 'lodash-es';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../Typography';
import { useRedirectPath } from '../../hooks/useRedirectPath';
import { useSearchData } from './hook';
import styles from './styles.module.scss';
var DEFAULT_INDEX = -1;
var SearchEngine = function (_a) {
    var open = _a.open, onClose = _a.onClose;
    var t = useTranslation().t;
    var inputRef = React.useRef(null);
    var _b = useState(''), keyWord = _b[0], setKeyWord = _b[1];
    var _c = useState([]), resultData = _c[0], setResultData = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(false), openDialog = _e[0], setOpenDialog = _e[1];
    var _f = useState(DEFAULT_INDEX), indexSelected = _f[0], setIndexSelected = _f[1];
    useEffect(function () {
        setOpenDialog(open || false);
    }, [open]);
    var dataSearch = useSearchData().dataSearch;
    var handleKeyDown = useCallback(function (event) {
        event.stopPropagation();
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            event.preventDefault();
            setOpenDialog(function (currentValue) {
                return !currentValue;
            });
            onClose && onClose();
            setKeyWord('');
            return;
        }
        if (event.key === 'ArrowDown') {
            console.log('indexSelected', indexSelected, resultData.length);
            setIndexSelected(function (i) {
                if (i !== DEFAULT_INDEX && !resultData.length)
                    return DEFAULT_INDEX;
                if (i !== DEFAULT_INDEX && i === resultData.length - 1)
                    return i;
                return i + 1;
            });
            return;
        }
        if (event.key === 'ArrowUp') {
            setIndexSelected(function (i) {
                if (i - 1 <= 0)
                    return 0;
                return i - 1;
            });
            return;
        }
        if (event.key === 'Enter') {
            var rs = resultData[indexSelected];
            if (!rs)
                return;
            window.open(getLink(rs.url, rs.path), '_blank');
            return;
        }
    }, [indexSelected]);
    useEffect(function () {
        window.addEventListener('keydown', handleKeyDown);
        return function () {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
    var getRedirectPath = useRedirectPath().getRedirectPath;
    var getLink = useCallback(function (url, path) {
        return "" + url + SSO_PATH + "?" + getRedirectPath(path);
    }, []);
    var handleCloseDialog = function () {
        setOpenDialog(function (currentValue) {
            return !currentValue;
        });
        onClose && onClose();
        setKeyWord('');
        setIndexSelected(-1);
    };
    useEffect(function () {
        if (openDialog === true) {
            setTimeout(function () {
                inputRef.current && inputRef.current.focus();
            }, 0);
            setResultData(dataSearch.filter(function (item) { return item.default; }));
        }
    }, [openDialog]);
    var debouncedSearch = useCallback(debounce(function (value) {
        if (value) {
            var valueTrue_1 = value.toLowerCase();
            var result = dataSearch.filter(function (o) {
                var searchKey = o.title
                    .concat(o.description, (o === null || o === void 0 ? void 0 : o.extendSearch) || '')
                    .toLowerCase();
                return searchKey.includes(valueTrue_1);
            });
            setResultData(result);
        }
        else
            setResultData(dataSearch.filter(function (item) { return item.default; }));
        setTimeout(function () {
            setLoading(false);
        }, 100);
    }, 500), []);
    var onInputChange = function (event) {
        var _a;
        var value = (_a = event.target) === null || _a === void 0 ? void 0 : _a.value;
        if (value !== undefined) {
            setKeyWord(value);
            setIndexSelected(DEFAULT_INDEX);
            if (!value.length || value.length >= 3) {
                setLoading(true);
                debouncedSearch(value);
            }
        }
    };
    return (React.createElement(Dialog, { open: openDialog, onClose: handleCloseDialog, className: styles.container, classes: {
            paper: styles.customDialog,
        } },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.searchBox },
                React.createElement("input", { ref: inputRef, type: "text", placeholder: t('TEXT.SEARCH'), value: keyWord, onChange: onInputChange }),
                React.createElement("div", { className: styles.icon }, loading ? React.createElement(Loading, { className: styles.emphasize }) : React.createElement(Search, null))),
            React.createElement("div", { className: styles.resultBox },
                React.createElement("div", { className: styles.resultText },
                    "Results (",
                    (resultData === null || resultData === void 0 ? void 0 : resultData.length) || 0,
                    ")"),
                React.createElement("ul", null, resultData.map(function (r, i) { return (React.createElement("li", null,
                    React.createElement("a", { href: getLink(r.url, r.path), target: "_blank", rel: "noreferrer", "data-selected": indexSelected === i, onMouseEnter: function () { return setIndexSelected(i); } },
                        React.createElement(Icon, { component: r.icon }),
                        React.createElement("div", { className: "flex flex-col" },
                            React.createElement(Typography, { variant: TypoVariants.buttonHighLevel, weight: TypoWeights.medium }, r.title),
                            React.createElement(Typography, { type: TypoTypes.secondary }, r.description)),
                        React.createElement("div", { className: styles.enterIcon },
                            React.createElement(ArrowLeft, null))))); })))),
        React.createElement("div", { className: styles.guide },
            React.createElement("div", null,
                React.createElement("div", { className: styles.icon },
                    React.createElement(ArrowLeft, null)),
                ' ',
                React.createElement("span", null, "to select")),
            React.createElement("div", null,
                React.createElement("div", { className: styles.icon + " " + styles.upAndDown },
                    React.createElement(ArrowLeft, { className: styles.iconDown }),
                    React.createElement(ArrowLeft, { className: styles.iconUp })),
                React.createElement("span", null, "to navigate")),
            React.createElement("div", null,
                React.createElement("div", { className: styles.icon + " " + styles.esc }, "ESC"),
                " ",
                React.createElement("span", null, "to close")))));
};
export default SearchEngine;
