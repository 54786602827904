var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SidebarList from './Sidebar';
var drawerWidth = 256;
var toolBar = 72;
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        root: {
            display: 'flex',
        },
        containerDrawer: {
            margin: '0 12px',
        },
        drawer: (_a = {},
            _a[theme.breakpoints.up('sm')] = {
                width: drawerWidth,
                flexShrink: 0,
            },
            _a),
        // necessary for content to be below app bar
        toolbar: {
            height: toolBar,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
        },
        drawerPaper: {
            width: drawerWidth,
            borderRight: '1px solid #D6DEFF',
            backgroundColor: '#272F3D',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        sidebarList: {
            marginTop: theme.spacing(2),
        },
    });
});
var DrawerSidebar = function (_a) {
    var Logo = _a.logo, sidebar = _a.sidebar;
    var classes = useStyles();
    var theme = useTheme();
    var _b = __read(React.useState(false), 2), mobileOpen = _b[0], setMobileOpen = _b[1];
    var handleDrawerToggle = function () {
        setMobileOpen(!mobileOpen);
    };
    var drawer = (_jsxs("div", __assign({ className: classes.containerDrawer }, { children: [_jsx("div", __assign({ className: classes.toolbar }, { children: _jsx(Logo, {}, void 0) }), void 0), _jsx("div", __assign({ className: classes.sidebarList }, { children: _jsx(SidebarList, { sidebar: sidebar }, void 0) }), void 0)] }), void 0));
    var container = window !== undefined ? function () { return window.document.body; } : undefined;
    return (_jsxs("nav", __assign({ className: classes.drawer, "aria-label": "mailbox folders" }, { children: [_jsx(Hidden, __assign({ smUp: true, implementation: "css" }, { children: _jsx(Drawer, __assign({ container: container, variant: "temporary", anchor: theme.direction === 'rtl' ? 'right' : 'left', open: mobileOpen, onClose: handleDrawerToggle, classes: {
                        paper: classes.drawerPaper,
                    }, ModalProps: {
                        keepMounted: true,
                    } }, { children: drawer }), void 0) }), void 0), _jsx(Hidden, __assign({ xsDown: true, implementation: "css" }, { children: _jsx(Drawer, __assign({ classes: {
                        paper: classes.drawerPaper,
                    }, variant: "permanent", open: true }, { children: drawer }), void 0) }), void 0)] }), void 0));
};
export default DrawerSidebar;
