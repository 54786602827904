import { Card, Frame, Lottery, Maintenance, RiskManagement, Settings, SlotGame, TableGame, TaiXiuMini, UserX, } from '@core/icons';
import { GAME_DATA } from './route';
// * GAME DATA
export var foundationGameData = [
    {
        title: 'SIDEBAR.FOUNDATION.TINDER.RESOURCE',
        path: GAME_DATA.foundation.tinder.resource.path,
        extendSearch: ['SIDEBAR.FOUNDATION.TINDER'],
    },
    {
        title: 'SIDEBAR.FOUNDATION.FEATURE_FLAG',
        path: GAME_DATA.foundation.featureFlag.path,
    },
    {
        title: 'SIDEBAR.FOUNDATION.POTTER',
        path: GAME_DATA.foundation.potter.path,
    },
    {
        title: 'SIDEBAR.FOUNDATION.TINDER.SETTING',
        path: GAME_DATA.foundation.tinder.setting.path,
        extendSearch: ['SIDEBAR.FOUNDATION.TINDER'],
    },
    {
        title: 'SIDEBAR.FOUNDATION.TINDER.SCORE',
        path: GAME_DATA.foundation.tinder.score.path,
        extendSearch: ['SIDEBAR.FOUNDATION.TINDER'],
    },
];
export var tableGameGameData = [
    {
        path: GAME_DATA.tableGame.xocdia.setting.path,
        title: 'SIDEBAR.TABLE_GAME.XOC_DIA.SETTING',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.XOC_DIA'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.xocdia.stageTime.path,
        title: 'SIDEBAR.TABLE_GAME.XOC_DIA.STAGE_TIME',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.XOC_DIA'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.xocdia.betLimit.path,
        title: 'SIDEBAR.TABLE_GAME.XOC_DIA.BET_LIMIT',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.XOC_DIA'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.xocdia.ratio.path,
        title: 'SIDEBAR.TABLE_GAME.XOC_DIA.RATIO',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.XOC_DIA'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.baucuaFull.setting.path,
        title: 'SIDEBAR.TABLE_GAME.BAU_CUA_FULL.SETTING',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.BAU_CUA_FULL'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.baucuaFull.winRate.path,
        title: 'SIDEBAR.TABLE_GAME.BAU_CUA_FULL.WIN_RATE',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.BAU_CUA_FULL'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.baucuaFull.stageTime.path,
        title: 'SIDEBAR.TABLE_GAME.BAU_CUA_FULL.STAGE_TIME',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.BAU_CUA_FULL'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.baucuaFull.betLimit.path,
        title: 'SIDEBAR.TABLE_GAME.BAU_CUA_FULL.BET_LIMIT',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.BAU_CUA_FULL'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.sicbo.setting.path,
        title: 'SIDEBAR.TABLE_GAME.SIC_BO.SETTING',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.SIC_BO'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.sicbo.ratio.path,
        title: 'SIDEBAR.TABLE_GAME.SIC_BO.RATIO',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.SIC_BO'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.sicbo.stageTime.path,
        title: 'SIDEBAR.TABLE_GAME.SIC_BO.STAGE_TIME',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.SIC_BO'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
    {
        path: GAME_DATA.tableGame.sicbo.betLimit.path,
        title: 'SIDEBAR.TABLE_GAME.SIC_BO.BET_LIMIT',
        extendSearch: ['SIDEBAR.TABLE_GAME', 'SIDEBAR.TABLE_GAME.SIC_BO'],
        parentTitle: 'SIDEBAR.TABLE_GAME',
    },
];
export var slotGameGameData = [
    {
        path: GAME_DATA.slotGame.thantai.normalDistribution.path,
        title: 'SIDEBAR.SLOT_GAME.THAN_TAI.NORMAL_DISTRIBUTION',
        extendSearch: ['SIDEBAR.SLOT_GAME', 'SIDEBAR.SLOT_GAME.THAN_TAI'],
        parentTitle: 'SIDEBAR.SLOT_GAME',
    },
    {
        path: GAME_DATA.slotGame.thantai.freeDistribution.path,
        title: 'SIDEBAR.SLOT_GAME.THAN_TAI.FREE_DISTRIBUTION',
        extendSearch: ['SIDEBAR.SLOT_GAME', 'SIDEBAR.SLOT_GAME.THAN_TAI'],
        parentTitle: 'SIDEBAR.SLOT_GAME',
    },
    {
        path: GAME_DATA.slotGame.thantai.bonusGameBox.path,
        title: 'SIDEBAR.SLOT_GAME.THAN_TAI.BONUS_GAME_BOX',
        extendSearch: ['SIDEBAR.SLOT_GAME', 'SIDEBAR.SLOT_GAME.THAN_TAI'],
        parentTitle: 'SIDEBAR.SLOT_GAME',
    },
    {
        path: GAME_DATA.slotGame.thantai.bonusGameMultiply.path,
        title: 'SIDEBAR.SLOT_GAME.THAN_TAI.BONUS_GAME_MULTIPLY',
        extendSearch: ['SIDEBAR.SLOT_GAME', 'SIDEBAR.SLOT_GAME.THAN_TAI'],
        parentTitle: 'SIDEBAR.SLOT_GAME',
    },
    {
        path: GAME_DATA.slotGame.chienthan.normalDistribution.path,
        title: 'SIDEBAR.SLOT_GAME.CHIEN_THAN.NORMAL_DISTRIBUTION',
        extendSearch: ['SIDEBAR.SLOT_GAME', 'SIDEBAR.SLOT_GAME.CHIEN_THAN'],
        parentTitle: 'SIDEBAR.SLOT_GAME',
    },
    {
        path: GAME_DATA.slotGame.chienthan.freeDistribution.path,
        title: 'SIDEBAR.SLOT_GAME.CHIEN_THAN.FREE_DISTRIBUTION',
        extendSearch: ['SIDEBAR.SLOT_GAME', 'SIDEBAR.SLOT_GAME.CHIEN_THAN'],
        parentTitle: 'SIDEBAR.SLOT_GAME',
    },
    {
        path: GAME_DATA.slotGame.chienthan.bonusDistribution.path,
        title: 'SIDEBAR.SLOT_GAME.CHIEN_THAN.BONUS_DISTRIBUTION',
        extendSearch: ['SIDEBAR.SLOT_GAME', 'SIDEBAR.SLOT_GAME.CHIEN_THAN'],
        parentTitle: 'SIDEBAR.SLOT_GAME',
    },
];
export var lotteryGameGameData = [
    {
        path: GAME_DATA.lotteryGame.lode.region.path,
        title: 'SIDEBAR.LOTTERY_GAME.LODE.REGION',
        extendSearch: ['SIDEBAR.LOTTERY_GAME', 'SIDEBAR.LOTTERY_GAME.LODE'],
        parentTitle: 'SIDEBAR.LOTTERY_GAME',
    },
    {
        path: GAME_DATA.lotteryGame.lode.setting.path,
        title: 'SIDEBAR.LOTTERY_GAME.LODE.SETTING',
        extendSearch: ['SIDEBAR.LOTTERY_GAME', 'SIDEBAR.LOTTERY_GAME.LODE'],
        parentTitle: 'SIDEBAR.LOTTERY_GAME',
    },
    {
        path: GAME_DATA.lotteryGame.lode.headline.path,
        title: 'SIDEBAR.LOTTERY_GAME.LODE.HEADLINE',
        extendSearch: ['SIDEBAR.LOTTERY_GAME', 'SIDEBAR.LOTTERY_GAME.LODE'],
        parentTitle: 'SIDEBAR.LOTTERY_GAME',
    },
    {
        path: GAME_DATA.lotteryGame.lode.categoryMapping.path,
        title: 'SIDEBAR.LOTTERY_GAME.LODE.CATEGORY_MAPPING',
        extendSearch: ['SIDEBAR.LOTTERY_GAME', 'SIDEBAR.LOTTERY_GAME.LODE'],
        parentTitle: 'SIDEBAR.LOTTERY_GAME',
    },
    {
        path: GAME_DATA.lotteryGame.lode.city.path,
        title: 'SIDEBAR.LOTTERY_GAME.LODE.CITY',
        extendSearch: ['SIDEBAR.LOTTERY_GAME', 'SIDEBAR.LOTTERY_GAME.LODE'],
        parentTitle: 'SIDEBAR.LOTTERY_GAME',
    },
    {
        path: GAME_DATA.lotteryGame.lode.guideline.path,
        title: 'SIDEBAR.LOTTERY_GAME.LODE.GUIDELINE',
        extendSearch: ['SIDEBAR.LOTTERY_GAME', 'SIDEBAR.LOTTERY_GAME.LODE'],
        parentTitle: 'SIDEBAR.LOTTERY_GAME',
    },
];
export var miniGameGameData = [
    {
        path: GAME_DATA.miniGame.txmn.stageTime.path,
        title: 'SIDEBAR.MINI_GAME.TXMN.STAGE_TIME',
        extendSearch: ['SIDEBAR.MINI_GAME', 'SIDEBAR.MINI_GAME.TXMN'],
        parentTitle: 'SIDEBAR.MINI_GAME',
    },
    {
        path: GAME_DATA.miniGame.txmn.setting.path,
        title: 'SIDEBAR.MINI_GAME.TXMN.SETTING',
        extendSearch: ['SIDEBAR.MINI_GAME', 'SIDEBAR.MINI_GAME.TXMN'],
        parentTitle: 'SIDEBAR.MINI_GAME',
    },
    {
        path: GAME_DATA.miniGame.bcmn.stageTime.path,
        title: 'SIDEBAR.MINI_GAME.BCMN.STAGE_TIME',
        extendSearch: ['SIDEBAR.MINI_GAME', 'SIDEBAR.MINI_GAME.BCMN'],
        parentTitle: 'SIDEBAR.MINI_GAME',
    },
    {
        path: GAME_DATA.miniGame.bcmn.setting.path,
        title: 'SIDEBAR.MINI_GAME.BCMN.SETTING',
        extendSearch: ['SIDEBAR.MINI_GAME', 'SIDEBAR.MINI_GAME.BCMN'],
        parentTitle: 'SIDEBAR.MINI_GAME',
    },
    {
        path: GAME_DATA.miniGame.pkmn.comboReward.path,
        title: 'SIDEBAR.MINI_GAME.PKMN.COMBO_REWARD',
        extendSearch: ['SIDEBAR.MINI_GAME', 'SIDEBAR.MINI_GAME.PKMN'],
        parentTitle: 'SIDEBAR.MINI_GAME',
    },
    {
        path: GAME_DATA.miniGame.updown.setting.path,
        title: 'SIDEBAR.MINI_GAME.UPDOWN.SETTING',
        extendSearch: ['SIDEBAR.MINI_GAME', 'SIDEBAR.MINI_GAME.UPDOWN'],
        parentTitle: 'SIDEBAR.MINI_GAME',
    },
];
export var cardGameGameData = [
    {
        title: 'SIDEBAR.CARD_GAME.MAU_BINH.SETTING',
        path: GAME_DATA.cardGame.maubinh.setting.path,
        extendSearch: ['SIDEBAR.CARD_GAME.MAU_BINH'],
    },
    {
        title: 'SIDEBAR.CARD_GAME.MAU_BINH.SCORE_EXTEND',
        path: GAME_DATA.cardGame.maubinh.scoreExtend.path,
        extendSearch: ['SIDEBAR.CARD_GAME.MAU_BINH'],
    },
    {
        title: 'SIDEBAR.CARD_GAME.MAU_BINH.NATURAL_RATE',
        path: GAME_DATA.cardGame.maubinh.naturalRate.path,
        extendSearch: ['SIDEBAR.CARD_GAME.MAU_BINH'],
    },
    {
        title: 'SIDEBAR.CARD_GAME.MAU_BINH.COMBINE_RATE',
        path: GAME_DATA.cardGame.maubinh.combineRate.path,
        extendSearch: ['SIDEBAR.CARD_GAME.MAU_BINH'],
    },
];
export var maintenanceGameData = [
    {
        path: GAME_DATA.maintenance.list.path,
        title: 'SIDEBAR.MAINTENANCE',
    },
    {
        path: GAME_DATA.maintenance.appUpdate.path,
        title: 'SIDEBAR.APP_UPDATE',
    },
];
export var gamesInGameData = [
    {
        icon: Frame,
        data: foundationGameData,
        searchDefault: ['SIDEBAR.FOUNDATION'],
    },
    {
        icon: Card,
        data: cardGameGameData,
        searchDefault: ['SIDEBAR.CARD_GAME'],
    },
    {
        icon: TaiXiuMini,
        data: miniGameGameData,
        searchDefault: ['SIDEBAR.MINI_GAME'],
    },
    {
        icon: Lottery,
        data: lotteryGameGameData,
        searchDefault: ['SIDEBAR.LOTTERY_GAME'],
    },
    {
        icon: SlotGame,
        data: slotGameGameData,
        searchDefault: ['SIDEBAR.SLOT_GAME'],
    },
    {
        icon: TableGame,
        data: tableGameGameData,
        searchDefault: ['SIDEBAR.TABLE_GAME'],
    },
    {
        icon: Maintenance,
        data: maintenanceGameData,
        searchDefault: ['SIDEBAR.MAINTENANCE'],
    },
    {
        icon: Settings,
        data: [
            {
                path: GAME_DATA.gameFake.config.path,
                title: 'SIDEBAR.GAME_FAKE_CONFIG',
            },
        ],
        searchDefault: ['SIDEBAR.GAME_FAKE_CONFIG'],
    },
    {
        icon: RiskManagement,
        data: [
            {
                path: GAME_DATA.riskManagement.path,
                title: 'SIDEBAR.RISK_MANAGEMENT',
            },
        ],
        searchDefault: ['SIDEBAR.RISK_MANAGEMENT'],
    },
    {
        icon: UserX,
        data: [
            {
                path: GAME_DATA.avoidPlayer.path,
                title: 'SIDEBAR.AVOID_PLAYER',
            },
        ],
        searchDefault: ['SIDEBAR.AVOID_PLAYER'],
    },
];
