import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Loading } from '@core/icons';
import Icon from '../../Icon';
import styles from '../styles.module.scss';
function TableLoading(_a) {
    var loading = _a.loading;
    if (!loading)
        return null;
    return _jsx(Icon, { className: styles.loading, component: Loading }, void 0);
}
export default React.memo(TableLoading);
