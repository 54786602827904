var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Baccarat = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_1544_3316)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.338 2.2505C11.7168 1.91596 12.2856 1.91657 12.6636 2.25192L16.0455 5.25192C16.0517 5.25749 16.0579 5.26314 16.0641 5.26887C16.5018 5.67735 16.9105 6.08958 17.2911 6.50633L17.2932 6.50861C18.0267 7.31682 18.4542 8.35069 18.4965 9.4367C18.5389 10.5228 18.1931 11.5861 17.5236 12.4472L17.5226 12.4484C16.8288 13.3379 15.7708 13.8165 14.7053 13.7936L15.0082 14.6749C15.1132 14.9805 15.0642 15.3179 14.8765 15.5809C14.6888 15.8439 14.3856 16 14.0625 16H9.93749C9.61439 16 9.31121 15.8439 9.12352 15.5809C8.93583 15.3179 8.88677 14.9805 8.9918 14.6749L9.3077 13.756C8.24536 13.7839 7.1759 13.3441 6.47729 12.4483L6.47642 12.4472L6.47642 12.4472C5.80688 11.5861 5.46111 10.5228 5.50348 9.4367C5.54584 8.35069 5.97333 7.31682 6.70685 6.50861L6.70978 6.50538L6.70978 6.50539C7.10172 6.0773 7.51101 5.66457 7.93666 5.26819L7.95294 5.25336C9.07906 4.25004 10.2074 3.24908 11.338 2.2505ZM9.29171 6.73923C8.90825 7.09672 8.53956 7.46868 8.18648 7.85422C7.76502 8.31931 7.5257 8.90606 7.50196 9.51466C7.4782 10.1236 7.67137 10.7255 8.05486 11.219C8.50633 11.7971 9.42016 11.9393 10.0662 11.504C10.3728 11.2974 10.7683 11.2769 11.0947 11.4505C11.4211 11.6241 11.625 11.9637 11.625 12.3333V13C11.625 13.1106 11.6066 13.2205 11.5707 13.3251L11.3387 14H12.6613L12.4293 13.3251C12.3934 13.2205 12.375 13.1106 12.375 13V12.3333C12.375 11.9565 12.5868 11.6117 12.9229 11.4414C13.2589 11.271 13.6622 11.304 13.9662 11.5268C14.5738 11.9721 15.4506 11.8523 15.9452 11.2189C16.3286 10.7255 16.5218 10.1236 16.498 9.51466C16.4743 8.90588 16.2348 8.31896 15.8131 7.85382C15.4734 7.4818 15.1058 7.11058 14.7087 6.73961L11.9988 4.33574C11.095 5.13538 10.1927 5.93655 9.29171 6.73923Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.5 14C18.7968 14 19.0782 14.1318 19.2682 14.3598L21.7682 17.3598C22.0773 17.7307 22.0773 18.2693 21.7682 18.6402L19.2682 21.6402C19.0782 21.8682 18.7968 22 18.5 22C18.2032 22 17.9218 21.8682 17.7318 21.6402L15.2318 18.6402C14.9227 18.2693 14.9227 17.7307 15.2318 17.3598L17.7318 14.3598C17.9218 14.1318 18.2032 14 18.5 14ZM17.3017 18L18.5 19.438L19.6983 18L18.5 16.562L17.3017 18Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.5 14C5.79678 14 6.07823 14.1318 6.26822 14.3598L8.76822 17.3598C9.07726 17.7307 9.07726 18.2693 8.76822 18.6402L6.26822 21.6402C6.07823 21.8682 5.79678 22 5.5 22C5.20322 22 4.92177 21.8682 4.73178 21.6402L2.23178 18.6402C1.92274 18.2693 1.92274 17.7307 2.23178 17.3598L4.73178 14.3598C4.92177 14.1318 5.20322 14 5.5 14ZM4.30171 18L5.5 19.438L6.69829 18L5.5 16.562L4.30171 18Z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1544_3316" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" }))))); };
export default Baccarat;
