import { Stats } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';

export interface CardFooter {
  rtp: number;
  bet: number;
  fee: number;
  normalWin: number;
  playerGain: number;
  botGain: number;
  houseGain: number;
}

export function calculateSumCard(data: Stats.AsObject[]): CardFooter {
  const sum = data.reduce(
    (acc, item) => {
      // acc.rtp += item.rtp;
      acc.bet += item.bet;
      acc.fee += item.fee;
      acc.normalWin += item.normalWin;
      acc.playerGain += item.playerGain;
      acc.botGain += item.botGain;
      acc.houseGain += item.houseGain;
      return acc;
    },
    {
      rtp: 0,
      bet: 0,
      fee: 0,
      normalWin: 0,
      playerGain: 0,
      botGain: 0,
      houseGain: 0,
    },
  );

  sum.rtp = sum.normalWin / sum.bet;

  return sum;
}

export enum CardSortName {
  SESSIONS = 'sessions',
  PLAYERS = 'players',
  TAX = 'tax',
  RTP_TARGET = 'rtpTarget',
  RTP = 'rtp',
  BET = 'bet',
  FEE = 'fee',
  NORMAL_WIN = 'normalWin',
  PLAYER_GAIN = 'playerGain',
  BOT_GAIN = 'botGain',
  HOUSE_GAIN = 'houseGain',
}

export const compareSortOfCard = {
  [`${CardSortName.SESSIONS}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.sessions - a.sessions,
  [`${CardSortName.SESSIONS}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.sessions - b.sessions,

  [`${CardSortName.PLAYERS}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.players - a.players,
  [`${CardSortName.PLAYERS}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.players - b.players,

  [`${CardSortName.TAX}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.tax - a.tax,
  [`${CardSortName.TAX}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.tax - b.tax,

  [`${CardSortName.RTP}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.rtp - a.rtp,
  [`${CardSortName.RTP}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.rtp - b.rtp,

  [`${CardSortName.BET}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.bet - a.bet,
  [`${CardSortName.BET}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.bet - b.bet,

  [`${CardSortName.NORMAL_WIN}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.normalWin - a.normalWin,
  [`${CardSortName.NORMAL_WIN}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.normalWin - b.normalWin,

  [`${CardSortName.FEE}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.fee - a.fee,
  [`${CardSortName.FEE}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.fee - b.fee,

  [`${CardSortName.PLAYER_GAIN}_desc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => b.playerGain - a.playerGain,
  [`${CardSortName.PLAYER_GAIN}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.playerGain - b.playerGain,

  [`${CardSortName.HOUSE_GAIN}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.houseGain - a.houseGain,
  [`${CardSortName.HOUSE_GAIN}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.houseGain - b.houseGain,
};
