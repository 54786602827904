import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Typography, { TypoVariants, TypoWeights, } from '@core/uikit/build/src/components/Typography';
import { StatusLogType } from '@greyhole/myid/myid_pb';
import { enumRevert } from '@core/uikit/build/src/utils/common/enumConvert';
import styles from './styles.module.scss';
var mapRestrictActionName = function () { return enumRevert(StatusLogType, 'CMS::ACTION.'); };
var PlayerStatusWithDot = function (_a) {
    var type = _a.type;
    var t = useTranslation().t;
    var dotStyles = useMemo(function () {
        var _a;
        var dotStyle = (_a = {},
            _a[StatusLogType.STATUS_LOG_TYPE_LOCK] = styles.lockType,
            _a[StatusLogType.STATUS_LOG_TYPE_BAN] = styles.banType,
            _a[StatusLogType.STATUS_LOG_TYPE_UNLOCK] = styles.unlockType,
            _a[StatusLogType.STATUS_LOG_TYPE_UNBAN] = styles.unbanType,
            _a);
        return dotStyle[type] || '';
    }, [type]);
    return (React.createElement("div", { className: styles.root },
        React.createElement("span", { className: cn(styles['dot'], dotStyles) }, "\u25CF"),
        React.createElement(Typography, { component: "span", variant: TypoVariants.body1, weight: TypoWeights.bold }, t("" + mapRestrictActionName()[type]))));
};
export default memo(PlayerStatusWithDot);
