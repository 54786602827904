var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "40", height: "40", fill: "none", viewBox: "0 0 40 40" }, props),
        React.createElement("path", { fill: "currentColor", d: "M13 29c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0111 27V13c0-.55.196-1.021.587-1.413A1.928 1.928 0 0113 11h11l5 5v11c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0127 29H13zm0-2h14V17h-3a.965.965 0 01-.712-.288A.965.965 0 0123 16v-3H13v14zm3-2h8c.283 0 .52-.096.712-.288A.965.965 0 0025 24a.968.968 0 00-.288-.713A.967.967 0 0024 23h-8a.97.97 0 00-.713.287A.97.97 0 0015 24c0 .283.096.52.287.712.192.192.43.288.713.288zm0-8h3a.968.968 0 00.713-.288A.967.967 0 0020 16a.97.97 0 00-.287-.713A.97.97 0 0019 15h-3a.97.97 0 00-.713.287A.97.97 0 0015 16c0 .283.096.52.287.712.192.192.43.288.713.288zm0 4h8c.283 0 .52-.096.712-.288A.965.965 0 0025 20a.968.968 0 00-.288-.713A.967.967 0 0024 19h-8a.97.97 0 00-.713.287A.97.97 0 0015 20c0 .283.096.52.287.712.192.192.43.288.713.288zm-3-8v4-4 14-14z" })));
}
export default SvgComponent;
