import { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withToast } from '@greyhole/ui-toast';
import { useTokenQuery } from '@core/uikit/build/src/hooks/useTokenQuery';
import GlobalStyles from '@core/uikit/build/src/components/GlobalStyles';
import LiveOpsLoading from '@core/uikit/build/src/components/LiveOpsLoading';
import '@core/uikit/build/src/styles/common.scss';
import { checkPermissionToAccessSpace } from '@core/uikit/build/src/components/SpaceList/checkPermissionToAccessSpace';
import { OpsRoutes } from '@core/oui/build/src/components/OpsRoutes';
import { PageViewLogger, SPACES } from '@greyhole/ui-tracking';

import { useRouteObjects } from 'configs/routes/useRouteObjects';
import { useAppDispatch } from 'redux/store';
import useAuth from 'hooks/useAuth';
import { getAuthThunk } from 'redux/features/auth/thunks';
import { useUserInfo } from 'hooks/useUserInfo';
import './App.css';

function App() {
  useTokenQuery();

  const dispatch = useAppDispatch();
  const { isFetchingAuth, isAuthenticating, accessToken } = useAuth();

  const getAuth = async () => {
    const { response } = await dispatch(getAuthThunk()).unwrap();
    if (response) {
      const moduleId = Number(process.env.REACT_APP_MODULE_KPI);
      checkPermissionToAccessSpace({
        moduleIdsList: response.moduleIdsList,
        moduleId,
      });
    }
  };

  useEffect(() => {
    if (isFetchingAuth) getAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFetchingAuth, accessToken]);

  const { routes } = useRouteObjects();
  const { userInfo } = useUserInfo();

  if (isAuthenticating) {
    return <LiveOpsLoading />;
  }

  return (
    <>
      <GlobalStyles />
      <OpsRoutes routes={routes}>
        <PageViewLogger spaceName={SPACES.KATANA} userID={userInfo?.safeId} />
      </OpsRoutes>
      <CssBaseline />
    </>
  );
}

export default withToast(App);
