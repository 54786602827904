var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import DropdownList from '../../DropdownList';
import Option from '../../Option';
import Pagination from '../../Pagination';
import { PAGE_SIZE_OPTIONS } from '../const';
import styles from '../styles.module.scss';
function TablePagination(_a) {
    var isEmpty = _a.isEmpty, pagination = _a.pagination, setCheckboxes = _a.setCheckboxes;
    var t = useTranslation().t;
    var _b = __read(React.useState(PAGE_SIZE_OPTIONS[0]), 2), pageSize = _b[0], setPageSize = _b[1];
    React.useEffect(function () {
        if (pagination && pagination.pageSize) {
            setPageSize(pagination.pageSize);
        }
    }, [pagination]);
    var handleChangePageSize = React.useCallback(function (value) {
        setPageSize(value);
        if (pagination.onChangePageSize) {
            pagination.onChangePageSize(value);
        }
    }, [pagination]);
    if (isEmpty ||
        !pagination ||
        !pagination.pageSize ||
        !pagination.totalRecord) {
        return null;
    }
    return (_jsx("div", __assign({ className: styles.footer }, { children: _jsxs(Grid, __assign({ container: true, alignItems: "center", justifyContent: "space-between", className: styles.pagination }, { children: [_jsx("div", __assign({ className: "flex items-center" }, { children: _jsxs(Grid, __assign({ container: true, item: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true }, { children: pagination.pageSize && pagination.onChangePageSize && (_jsx(DropdownList, __assign({ value: pageSize.toString(), onChange: handleChangePageSize, display: function () { return t("Common::ROW") + ": " + pageSize.toString(); } }, { children: PAGE_SIZE_OPTIONS.map(function (x) { return x.toString(); }).map(function (size) { return (_jsx(Option, __assign({ value: size }, { children: size }), size)); }) }), void 0)) }), void 0), pagination.paginationActions && _jsx(Grid, __assign({ item: true }, { children: pagination.paginationActions }), void 0)] }), void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: "auto" }, { children: pagination.totalRecord && pagination.pageSize && (_jsx(Pagination, __assign({}, pagination, { onChangePage: function (val) {
                            setCheckboxes({});
                            pagination.onChangePage(val);
                        } }), void 0)) }), void 0)] }), void 0) }), void 0));
}
export default React.memo(TablePagination);
