var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MinorTooltip } from '../../../../components/Tooltip';
import Typography, { TypoWeights, TypoTypes } from './../../../Typography';
import CalendarPicker from './CalendarPicker';
var CalendarBody = function (_a) {
    var label = _a.label, value = _a.value, description = _a.description, onChange = _a.onChange, field = __rest(_a, ["label", "value", "description", "onChange"]);
    var handleChange = function (timestamp) {
        onChange(timestamp);
    };
    return (_jsxs("div", { children: [_jsx(Typography, __assign({ weight: TypoWeights.medium, type: TypoTypes.secondary }, { children: _jsx(MinorTooltip, __assign({ description: description }, { children: label }), void 0) }), void 0), _jsx("div", __assign({ className: "mt-8" }, { children: _jsx(CalendarPicker, __assign({ value: value, onChange: handleChange }, field), void 0) }), void 0)] }, void 0));
};
export default CalendarBody;
