import React from 'react';
import { useRoutes, BrowserRouter } from 'react-router-dom';
function Routes(_a) {
    var routes = _a.routes, children = _a.children;
    var element = useRoutes(routes);
    return (React.createElement(React.Fragment, null,
        element,
        children));
}
export function OpsRoutes(_a) {
    var routes = _a.routes, children = _a.children;
    return (React.createElement(BrowserRouter, null,
        React.createElement(Routes, { routes: routes, children: children })));
}
