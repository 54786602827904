var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Management = function (props) {
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M9 5a2 2 0 100 4 2 2 0 000-4zM5 7a4 4 0 118 0 4 4 0 01-8 0zM7.333 14c-.49 0-1.06.271-1.543.948-.486.68-.79 1.657-.79 2.719V20a1 1 0 11-2 0v-2.333c0-1.414.399-2.812 1.163-3.881C4.93 12.712 6.055 12 7.333 12h3.334c1.278 0 2.403.712 3.17 1.786.764 1.07 1.163 2.467 1.163 3.88V20a1 1 0 11-2 0v-2.333c0-1.062-.304-2.038-.79-2.719-.483-.677-1.054-.948-1.543-.948H7.333zM14.042 3.714a1 1 0 011.244-.672c.817.243 1.509.787 1.987 1.504.478.717.727 1.58.727 2.454 0 .874-.249 1.737-.727 2.454-.478.717-1.17 1.26-1.987 1.504a1 1 0 11-.572-1.916c.327-.098.65-.33.895-.698.247-.37.391-.843.391-1.344 0-.501-.144-.974-.39-1.344-.246-.369-.569-.6-.896-.698a1 1 0 01-.672-1.244zM16.045 12.705a1 1 0 011.25-.66c1.103.34 2.043 1.096 2.698 2.102.654 1.004 1.001 2.22 1.007 3.455V20a1 1 0 11-2 0v-2.39c-.005-.877-.252-1.71-.683-2.372-.43-.66-1.006-1.095-1.612-1.282a1 1 0 01-.66-1.251z", clipRule: "evenodd" })));
};
export default Management;
