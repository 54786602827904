var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ width: "40", height: "40", fill: "none", viewBox: "0 0 40 40", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fill: "currentColor", d: "M28 11H13c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V13c0-1.1-.9-2-2-2zm0 2v3H13v-3h15zm-5 14h-5v-9h5v9zm-10-9h3v9h-3v-9zm12 9v-9h3v9h-3z" })));
}
export default SvgComponent;
