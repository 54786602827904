var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var LiveOpsLogo = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "126", height: "48", fill: "none", viewBox: "0 0 126 48" }, props),
        React.createElement("g", { clipPath: "url(#clip0_1077_6751)" },
            React.createElement("path", { fill: "#7462E1", d: "M18.476 47.068L3.524 38.546A6.94 6.94 0 010 32.522V15.479a6.94 6.94 0 013.524-6.025L18.476.932a7.126 7.126 0 017.048 0l14.952 8.522A6.94 6.94 0 0144 15.478v17.044a6.94 6.94 0 01-3.524 6.024l-14.952 8.522a7.126 7.126 0 01-7.048 0z" }),
            React.createElement("path", { fill: "#fff", fillRule: "evenodd", d: "M19.037 43.223L6.463 36.122C4.63 35.087 3.5 33.172 3.5 31.102V16.899c0-2.071 1.13-3.985 2.963-5.02l12.574-7.102a6.04 6.04 0 015.926 0l12.574 7.101c1.833 1.036 2.963 2.95 2.963 5.02v14.203c0 2.071-1.13 3.985-2.963 5.02l-12.574 7.102a6.04 6.04 0 01-5.926 0zM32.863 26.4v3.293c0 .474-.278.906-.715 1.11l-3.475 1.616a.508.508 0 01-.726-.451V10.453a1.08 1.08 0 00-.554-.939L25.037 8.18C24.133 7.668 23 8.308 23 9.332v29.335c0 1.025 1.131 1.665 2.037 1.152l11.151-6.312a2.561 2.561 0 001.312-2.225V26.4a.416.416 0 00-.42-.411h-3.797a.416.416 0 00-.42.411zM10.568 12.932l-2.757 1.56A2.561 2.561 0 006.5 16.718v14.564c0 .918.5 1.767 1.311 2.225l11.152 6.312c.906.513 2.037-.127 2.037-1.152V9.332c0-1.024-1.133-1.664-2.037-1.152L16.76 9.427a1.383 1.383 0 00-.707 1.201V31.89a.557.557 0 01-.797.494l-3.453-1.604a1.146 1.146 0 01-.667-1.036v-16.49a.38.38 0 00-.57-.322z", clipRule: "evenodd" }),
            React.createElement("path", { fill: "#090212", d: "M63.277 28.892V18.876h4.17v17.286h-4.044V33.09h-.184c-.4.968-1.056 1.76-1.97 2.375-.906.615-2.023.922-3.351.922-1.16 0-2.185-.25-3.076-.754-.883-.51-1.574-1.249-2.073-2.217-.5-.975-.749-2.153-.749-3.533V18.876h4.17v10.376c0 1.096.307 1.966.921 2.611.615.646 1.42.968 2.42.968.613 0 1.209-.146 1.785-.439a3.598 3.598 0 001.416-1.305c.377-.585.565-1.317.565-2.195z" }),
            React.createElement("path", { fill: "#7462E1", d: "M71.58 36.162V13.114h4.274v19.548H82v3.5H71.58zM84.073 36.162V18.876h4.17v17.286h-4.17zm2.096-19.74c-.66 0-1.229-.213-1.705-.64-.476-.436-.714-.957-.714-1.565 0-.615.238-1.137.714-1.564A2.44 2.44 0 0186.17 12c.668 0 1.236.218 1.705.653.476.427.714.949.714 1.564 0 .608-.238 1.13-.714 1.564-.469.428-1.037.642-1.705.642zM107.996 18.876l-6.301 17.286h-4.607l-6.301-17.286h4.446L99.3 31.717h.185l4.077-12.84h4.435zM117.774 36.5c-1.774 0-3.306-.36-4.596-1.08a7.39 7.39 0 01-2.96-3.084c-.691-1.335-1.037-2.907-1.037-4.715 0-1.779.346-3.34 1.037-4.682.699-1.35 1.674-2.401 2.926-3.151 1.251-.758 2.722-1.137 4.411-1.137 1.091 0 2.12.173 3.087.518a7 7 0 012.581 1.575c.752.713 1.343 1.621 1.774 2.724.43 1.095.645 2.4.645 3.916v1.25H111.14v-2.747h10.505c-.008-.78-.181-1.474-.519-2.082a3.72 3.72 0 00-1.417-1.451c-.599-.353-1.297-.53-2.096-.53-.852 0-1.601.203-2.246.608a4.23 4.23 0 00-1.509 1.576 4.446 4.446 0 00-.541 2.127v2.397c0 1.006.188 1.868.564 2.589.376.712.902 1.26 1.578 1.643.676.375 1.467.562 2.373.562a5.15 5.15 0 001.647-.247 3.67 3.67 0 001.279-.754c.361-.33.633-.74.817-1.227l3.894.428a5.889 5.889 0 01-1.405 2.633c-.684.743-1.559 1.32-2.627 1.733-1.067.405-2.288.608-3.663.608z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_1077_6751" },
                React.createElement("path", { fill: "#fff", d: "M0 0H126V48H0z" })))));
};
export default LiveOpsLogo;
