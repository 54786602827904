var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var PlayerOverview = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9 4C7.34315 4 6 5.34315 6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4ZM4 7C4 4.23858 6.23858 2 9 2C11.7614 2 14 4.23858 14 7C14 9.76142 11.7614 12 9 12C6.23858 12 4 9.76142 4 7Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 16C6.20435 16 5.44129 16.3161 4.87868 16.8787C4.31607 17.4413 4 18.2044 4 19V21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21V19C2 17.6739 2.52678 16.4021 3.46447 15.4645C4.40215 14.5268 5.67392 14 7 14H11C12.3261 14 13.5979 14.5268 14.5355 15.4645C15.4732 16.4021 16 17.6739 16 19V21C16 21.5523 15.5523 22 15 22C14.4477 22 14 21.5523 14 21V19C14 18.2044 13.6839 17.4413 13.1213 16.8787C12.5587 16.3161 11.7956 16 11 16H7Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.0312 2.88196C15.1682 2.34694 15.713 2.02426 16.248 2.16125C17.3236 2.43663 18.2768 3.06213 18.9576 3.93914C19.6383 4.81615 20.0078 5.89479 20.0078 7.005C20.0078 8.11521 19.6383 9.19385 18.9576 10.0709C18.2768 10.9479 17.3236 11.5734 16.248 11.8488C15.713 11.9857 15.1682 11.6631 15.0312 11.128C14.8943 10.593 15.2169 10.0482 15.752 9.91125C16.3973 9.74603 16.9692 9.37073 17.3777 8.84452C17.7861 8.31831 18.0078 7.67113 18.0078 7.005C18.0078 6.33887 17.7861 5.69169 17.3777 5.16548C16.9692 4.63928 16.3973 4.26398 15.752 4.09875C15.2169 3.96176 14.8943 3.41699 15.0312 2.88196Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.0317 14.9C17.1698 14.3653 17.7152 14.0437 18.25 14.1817C19.3185 14.4576 20.2658 15.0792 20.9441 15.9497C21.6224 16.8202 21.9936 17.8907 22 18.9942L22 19H22V21C22 21.5523 21.5523 22 21 22C20.4477 22 20 21.5523 20 21V19.0031C19.9956 18.3419 19.7729 17.7006 19.3665 17.179C18.9595 16.6567 18.3911 16.2838 17.75 16.1182C17.2153 15.9802 16.8937 15.4348 17.0317 14.9Z", fill: "currentColor" }))); };
export default PlayerOverview;
