import { Topic } from '@gameloot/topic/topic_pb';
import { TableSort } from '@core/uikit/src/hooks/useTableSort';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/reducers';

type SortWithTopic = { topic: Topic } & TableSort;

interface CommonState {
  mounted: boolean;
  curTab: string;
  sortWithTopic?: SortWithTopic;
}

const initialState: CommonState = {
  mounted: false,
  curTab: '',
};

export const getSortByName = (a: any, b: any) => ({
  rtp: {
    asc: (a.rtp || 0) - (b.rtp || 0),
    desc: (b.rtp || 0) - (a.rtp || 0),
  },
  houseEdge: {
    asc: a.gameEdge - b.gameEdge,
    desc: b.gameEdge - a.gameEdge,
  },
});

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setMounted: (state: CommonState) => {
      state.mounted = true;
    },
    setTab: (state, action) => {
      state.curTab = action.payload;
    },
    setTableSort: (state, action: PayloadAction<SortWithTopic>) => {
      state.sortWithTopic = action.payload;
    },
  },
});

const { reducer, actions } = slice;

export const { setMounted, setTab, setTableSort } = actions;

export const selectCommonSlice = (state: RootState) => state.common;

export default reducer;
