var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Website = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 4a5 5 0 100 10 5 5 0 000-10zM5 9a7 7 0 1114 0A7 7 0 015 9z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M11.03 2.758a1 1 0 011.212-.728c1.15.287 1.826 1.262 2.206 2.403C14.832 5.584 15 7.126 15 9s-.168 3.416-.552 4.566c-.38 1.142-1.056 2.117-2.206 2.404a1 1 0 01-.484-1.94c.183-.046.507-.237.793-1.096.283-.85.449-2.14.449-3.934 0-1.793-.166-3.084-.449-3.934-.286-.859-.61-1.05-.793-1.096a1 1 0 01-.728-1.212z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12.97 2.758a1 1 0 01-.728 1.212c-.183.046-.507.237-.793 1.096C11.166 5.916 11 7.207 11 9c0 1.793.166 3.085.449 3.934.286.859.61 1.05.793 1.096a1 1 0 01-.484 1.94c-1.15-.287-1.826-1.262-2.207-2.404C9.168 12.416 9 10.874 9 9c0-1.874.168-3.416.551-4.567.38-1.14 1.056-2.116 2.207-2.403a1 1 0 011.212.728z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M5 9a1 1 0 011-1h12a1 1 0 110 2H6a1 1 0 01-1-1zM2 19a1 1 0 011-1h7a1 1 0 110 2H3a1 1 0 01-1-1zM13 19a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 18a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 14a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1z", clipRule: "evenodd" })));
};
export default Website;
