import { useTranslation } from 'react-i18next';
import {
  FormFieldData,
  FormTypes,
} from '@core/uikit/build/src/components/Form/types';
import {
  PASSWORD_RULES,
  REGEX_PASSWORD,
  getErrorMessage,
} from '@core/uikit/build/src/utils/common/password';

export const useField = () => {
  const { t } = useTranslation();

  const fields: FormFieldData[] = [
    {
      name: 'currentPassword',
      label: t('Common::PORTAL.PASSWORD.CURRENT_PASSWORD'),
      type: FormTypes.INPUT_PASSWORD,
      placeholder: t('Common::PORTAL.PASSWORD.PH_CURRENT_PASSWORD'),
      rules: { required: t('Common::COMMON.REQUIRED') },
      width: { xs: 12 },
    },
    {
      name: 'newPassword',
      label: t('Common::PORTAL.PASSWORD.NEW_PASSWORD'),
      type: FormTypes.INPUT_PASSWORD,
      placeholder: t('Common::PORTAL.PASSWORD.PH_NEW_PASSWORD'),
      rules: {
        required: t('Common::COMMON.REQUIRED'),
        pattern: {
          value: REGEX_PASSWORD,
          message: t('Common::FIELD.INVALID'),
        },
      },
      validation: true,
      errorPattern: PASSWORD_RULES,
      errorMessage: getErrorMessage(t),
      width: { xs: 12 },
    },
    {
      name: 'confirmNewPassword',
      label: t('Common::PORTAL.PASSWORD.CONFIRM_PASSWORD'),
      type: FormTypes.INPUT_PASSWORD,
      placeholder: t('Common::PORTAL.PASSWORD.PH_CONFIRM_PASSWORD'),
      rules: { required: t('Common::COMMON.REQUIRED') },
      width: { xs: 12 },
    },
  ];

  return { fields };
};
