var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { AngleDown } from '@core/icons';
import classnames from 'classnames';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../Typography';
import Grid from '../../Grid';
import { minutes, hours } from '../../../utils/date';
import { MinorTooltip } from '../../Tooltip';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        root: {
            width: '100%',
            minWidth: '100px',
            height: '58px',
            marginTop: '0px !important',
        },
        errorNotDisable: {
            backgroundColor: '#ffd6d6',
            color: '#f53131',
            border: '1px solid #f53131',
            '&:hover': {
                border: '2px solid #f53131',
                padding: '0px !important',
            },
        },
        formControl: {
            width: '100%',
        },
        hours: {
            display: 'flex',
            alignItems: 'center',
        },
        colon: {
            marginLeft: '8px',
            marginRight: '-8px',
            marginBottom: '12px',
        },
    });
});
var IconAngleDown = function (props) {
    return (_jsx(AngleDown, { className: props.className, style: {
            color: 'grey',
            userSelect: 'none',
            pointerEvents: 'none',
            right: 8,
            marginLeft: '8px',
        } }, void 0));
};
var IconLoading = function (props) {
    return (_jsx(CircularProgress, { size: 16, style: {
            position: 'absolute',
            color: 'grey',
            userSelect: 'none',
            pointerEvents: 'none',
            right: 10,
            marginLeft: '8px',
        } }, void 0));
};
var TimePickerField = function (_a) {
    var _b;
    var label = _a.label, name = _a.name, readOnly = _a.readOnly, disabled = _a.disabled, value = _a.value, placeholder = _a.placeholder, loading = _a.loading, onChange = _a.onChange, type = _a.type, description = _a.description, restProps = __rest(_a, ["label", "name", "readOnly", "disabled", "value", "placeholder", "loading", "onChange", "type", "description"]);
    var classes = useStyles();
    var methods = useFormContext();
    var formState = methods.formState;
    var errors = formState.errors;
    var error = !!errors[name];
    var handleChange = function (event, child) {
        var _a, _b;
        var option = event.target;
        var e = {
            target: {
                value: {
                    hours: option.name === 'hours' ? option === null || option === void 0 ? void 0 : option.value : (_a = value === null || value === void 0 ? void 0 : value.hours) !== null && _a !== void 0 ? _a : 0,
                    minutes: option.name === 'minutes' ? option === null || option === void 0 ? void 0 : option.value : (_b = value === null || value === void 0 ? void 0 : value.minutes) !== null && _b !== void 0 ? _b : 0,
                },
            },
        };
        onChange(e);
    };
    var selectRootClass = classnames(classes.root, (_b = {},
        _b[classes.errorNotDisable] = error && !disabled,
        _b));
    return (_jsxs(FormControl, __assign({ component: "fieldset", style: { width: '100%' } }, { children: [label && (_jsx(FormLabel, { children: _jsx(Typography, __assign({ variant: TypoVariants.body2, type: TypoTypes.sub, weight: TypoWeights.bold }, { children: _jsx(MinorTooltip, __assign({ description: description }, { children: label }), void 0) }), void 0) }, void 0)), _jsx(Grid, { children: _jsx(Grid, __assign({ item: true, xs: true }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, __assign({ className: classes.hours, item: true, xs: 6 }, { children: [_jsx(Select, __assign({ name: "hours", defaultValue: "none", onChange: handleChange, disabled: disabled, IconComponent: loading
                                            ? IconLoading
                                            : !disabled
                                                ? IconAngleDown
                                                : function () { return _jsx(_Fragment, {}, void 0); }, className: selectRootClass, style: __assign({}, (disabled
                                            ? { background: '#F5F5F5', borderColor: 'transparent' }
                                            : {})), renderValue: Boolean((value === null || value === void 0 ? void 0 : value.hours) === 0 ? String(value === null || value === void 0 ? void 0 : value.hours) : value === null || value === void 0 ? void 0 : value.hours)
                                            ? undefined
                                            : function () { return _jsx("div", { children: placeholder }, void 0); }, value: +(value === null || value === void 0 ? void 0 : value.hours) }, restProps, { children: hours().map(function (i) { return (_jsx(MenuItem, __assign({ value: i.value }, { children: _jsx(Typography, __assign({ type: TypoTypes.text, variant: TypoVariants.body1, weight: TypoWeights.regular }, { children: i.name }), void 0) }), void 0)); }) }), void 0), _jsx(Typography, __assign({ className: classes.colon, variant: TypoVariants.subtitle1, weight: TypoWeights.light, type: TypoTypes.text }, { children: ":" }), void 0)] }), void 0), _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(Select, __assign({ name: "minutes", defaultValue: "none", onChange: handleChange, disabled: disabled, IconComponent: loading
                                        ? IconLoading
                                        : !disabled
                                            ? IconAngleDown
                                            : function () { return _jsx(_Fragment, {}, void 0); }, className: selectRootClass, style: __assign({}, (disabled
                                        ? { background: '#F5F5F5', borderColor: 'transparent' }
                                        : {})), renderValue: Boolean((value === null || value === void 0 ? void 0 : value.minutes) === 0
                                        ? String(value === null || value === void 0 ? void 0 : value.minutes)
                                        : value === null || value === void 0 ? void 0 : value.minutes)
                                        ? undefined
                                        : function () { return _jsx("div", { children: placeholder }, void 0); }, value: +(value === null || value === void 0 ? void 0 : value.minutes) }, restProps, { children: minutes().map(function (item, index) {
                                        return (_jsx(MenuItem, __assign({ value: item.value }, { children: _jsx(Typography, __assign({ type: TypoTypes.text, variant: TypoVariants.body1, weight: TypoWeights.regular }, { children: item.name }), void 0) }), index));
                                    }) }), void 0) }), void 0)] }), void 0) }), void 0) }, void 0)] }), void 0));
};
export default TimePickerField;
