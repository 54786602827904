import { useCallback, useRef } from 'react';
export default function useClickOutside(callback) {
    var refEvent = useRef({ shouldPreventEvent: false });
    var onParentClick = useCallback(function (event) {
        if (refEvent.current.shouldPreventEvent) {
            refEvent.current.shouldPreventEvent = false;
            return;
        }
        callback(event);
    }, [refEvent, callback]);
    var onChildClick = useCallback(function () {
        refEvent.current.shouldPreventEvent = true;
    }, [refEvent]);
    return [onParentClick, onChildClick];
}
