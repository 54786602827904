var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var TienLen = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_1544_3276)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.9925 5.08688C13.7262 4.91574 14.5126 4.99807 15.1891 5.32868C15.5263 5.49347 15.8324 5.71837 16.0848 5.99712C16.3376 6.27628 16.5319 6.60463 16.6473 6.96642C16.7628 7.32888 16.7946 7.71105 16.7386 8.08821C16.6826 8.46498 16.5414 8.82038 16.3315 9.13459C16.293 9.19223 16.2485 9.24571 16.199 9.29417L14.4537 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H12C11.5934 13 11.2274 12.7539 11.0739 12.3774C10.9205 12.0009 11.0103 11.569 11.301 11.2849L14.7091 7.95371C14.7365 7.89884 14.7528 7.84508 14.7603 7.79416C14.771 7.72219 14.7655 7.64838 14.7418 7.57405C14.7179 7.49905 14.6736 7.41835 14.6023 7.33955C14.5306 7.26035 14.4333 7.1854 14.3109 7.12557C14.0591 7.00253 13.7445 6.96516 13.4468 7.0346C13.149 7.10406 12.9398 7.26203 12.8315 7.42411C12.5246 7.88332 11.9036 8.00686 11.4444 7.70003C10.9852 7.39321 10.8617 6.77221 11.1685 6.313C11.5906 5.6812 12.2589 5.258 12.9925 5.08688Z", fill: "currentColor" }),
        React.createElement("path", { d: "M7.99374 20.9995L10.5117 18.4195C10.8242 18.0959 10.9992 17.6637 11 17.2138C11.0002 17.0495 10.9773 16.8876 10.9328 16.7323C10.8556 16.4623 10.7134 16.2127 10.5157 16.0065C10.3643 15.8483 10.1824 15.7222 9.981 15.6359C9.77962 15.5497 9.56289 15.505 9.34381 15.5045C9.12473 15.504 8.90781 15.5478 8.70606 15.6332C8.50432 15.7186 8.3219 15.8439 8.16974 16.0015L8.00175 16.1735L7.83374 16.0015C7.6822 15.8433 7.50026 15.7174 7.29884 15.6312C7.09742 15.545 6.88068 15.5004 6.6616 15.5C6.44252 15.4996 6.22562 15.5435 6.02391 15.629C5.92169 15.6723 5.82445 15.7259 5.73359 15.7887C5.64515 15.8498 5.56276 15.9197 5.48775 15.9975C5.33052 16.1605 5.20814 16.3509 5.12529 16.5573C5.04363 16.7607 5.00037 16.9795 5 17.2027C4.99926 17.6524 5.17262 18.0849 5.48374 18.4095L7.99374 20.9995Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V19C20 19.7957 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7957 22 17 22H12C11.4477 22 11 21.5523 11 21C11 20.4477 11.4477 20 12 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H7Z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1544_3276" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" }))))); };
export default TienLen;
