var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function ArrowSortDesc(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "none", viewBox: "0 0 20 20" }, props),
        React.createElement("path", { fill: "#BABEC3", fillRule: "evenodd", d: "M5 6.179v9.655a.833.833 0 001.667 0V6.179l1.91 1.91a.833.833 0 101.18-1.178L6.422 3.578a.83.83 0 00-1.179 0L1.911 6.91A.833.833 0 103.09 8.089L5 6.18z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "#7462E1", fillRule: "evenodd", d: "M14.167 3.333c.46 0 .833.374.833.834v9.655l1.91-1.911a.834.834 0 011.18 1.178l-3.334 3.334a.833.833 0 01-1.179 0l-3.333-3.334a.833.833 0 111.179-1.178l1.91 1.91V4.168c0-.46.373-.833.834-.833z", clipRule: "evenodd" })));
}
export default ArrowSortDesc;
