export var checkPermissionToAccessSpace = function (_a) {
    var moduleIdsList = _a.moduleIdsList, moduleId = _a.moduleId;
    var isPermission = moduleIdsList.length > 0 ? moduleIdsList.includes(moduleId) : false;
    var checkPermission = function () {
        if (!isPermission)
            return (window.location.href = process.env.REACT_APP_PORTAL_URL + "/dont-have-permission");
        return;
    };
    return checkPermission();
};
