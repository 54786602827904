var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { WendyClient } from '@dceu/wendy/WendyServiceClientPb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { BooleanFieldValue, CreateRecordRequest, DateFieldValue, DecimalFieldValue, DeleteListRecordRequest, FieldType, FieldValue, FileFieldValue, GetListRecordRequest, GetTableRequest, IntegerFieldValue, LongFieldValue, ReferenceFieldValue, ResourceFieldValue, Sort, StringFieldValue, UpdateRecordRequest, } from '@greyhole/forge/forge_pb';
import { ExportCSVBadwordChatRequest, ExportCSVBadwordNamingRequest, GetListMissionRequest, ImportCSVBadwordChatRequest, ImportCSVBadwordNamingRequest, } from '@dceu/wendy/wendy_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcClient = /** @class */ (function (_super) {
    __extends(rpcClient, _super);
    function rpcClient(config) {
        var _this = this;
        config.serviceName = 'WENDY';
        _this = _super.call(this, WendyClient, config) || this;
        return _this;
    }
    // News
    rpcClient.prototype.getListNews = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListNews', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.createNews = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initCreateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('createNews', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateNews = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updateNews', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.activeNews = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('activeNews', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.inactiveNews = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('inactiveNews', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Bulletins
    rpcClient.prototype.getListBulletin = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListBulletin', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.createBulletin = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initCreateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('createBulletin', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateBulletin = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updateBulletin', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.activeBulletin = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('activeBulletin', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.inactiveBulletin = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('inactiveBulletin', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Badwords naming
    rpcClient.prototype.getListBadwordNaming = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListBadwordNaming', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.createBadwordNaming = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initCreateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('createBadwordNaming', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateBadwordNaming = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updateBadwordNaming', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.activeBadwordNaming = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('activeBadwordNaming', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.inactiveBadwordNaming = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('inactiveBadwordNaming', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.importCSVBadwordNaming = function () {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ImportCSVBadwordNamingRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('importCSVBadwordNaming', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.exportCSVBadwordNaming = function () {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ExportCSVBadwordNamingRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('exportCSVBadwordNaming', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Badwords chat
    rpcClient.prototype.getListBadwordChat = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListBadwordChat', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.createBadwordChat = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initCreateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('createBadwordChat', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateBadwordChat = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updateBadwordChat', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.activeBadwordChat = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('activeBadwordChat', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.inactiveBadwordChat = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('inactiveBadwordChat', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.importCSVBadwordChat = function () {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ImportCSVBadwordChatRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('importCSVBadwordChat', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.exportCSVBadwordChat = function () {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ExportCSVBadwordChatRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('exportCSVBadwordChat', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Banner order
    rpcClient.prototype.getListBannerOrder = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListBannerOrder', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateBannerOrder = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updateBannerOrder', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Banner
    rpcClient.prototype.getListBanner = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListBanner', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.createBanner = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initCreateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('createBanner', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateBanner = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updateBanner', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.getTableBanner = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetTableRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getTableBanner', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.activeBanner = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('activeBanner', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.inactiveBanner = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('inactiveBanner', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.changeBannerStatus = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('changeBannerStatus', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // CS Account config
    rpcClient.prototype.getListCSAccountConfig = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListCSAccountConfig', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateCSAccountConfig = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updateCSAccountConfig', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.activeCSAccountConfig = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('activeCSAccountConfig', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.inactiveCSAccountConfig = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('inactiveCSAccountConfig', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Payment exchange
    rpcClient.prototype.getListPaymentExchange = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListPaymentExchange', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.createPaymentExchange = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initCreateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('createPaymentExchange', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updatePaymentExchange = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updatePaymentExchange', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Payment attention
    rpcClient.prototype.getListPaymentAttention = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListPaymentAttention', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.getTablePaymentAttention = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initCreateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getTablePaymentAttention', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updatePaymentAttention = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updatePaymentAttention', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Feature Flag
    rpcClient.prototype.getListFeatureFlag = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListFeatureFlag', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.activeFeatureFlag = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('activeFeatureFlag', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.inactiveFeatureFlag = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('inactiveFeatureFlag', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    //mission
    rpcClient.prototype.getListMission = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, listRecord;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetListMissionRequest();
                        if (p.request) {
                            listRecord = this.initGetListRecordRequest(p.request);
                            req.setRequest(listRecord);
                        }
                        req.setMissionTableActiveFieldId(p.missionTableActiveFieldId);
                        return [4 /*yield*/, this.gRPCClientRequest('getListMission', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.activeMission = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('activeMission', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.inactiveMission = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('inactiveMission', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.getListRecord = function (name, p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest(name, req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.createRecord = function (name, p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initCreateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest(name, req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateRecord = function (name, p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest(name, req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.deleteRecord = function (name, p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initDeleteListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest(name, req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Landing page config
    rpcClient.prototype.getListLandingPage = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListLandingPage', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateLandingPage = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updateLandingPage', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Payment Image
    rpcClient.prototype.getListPaymentGuideImage = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListPaymentGuideImage', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updatePaymentGuideImage = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updatePaymentGuideImage', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Risk Config
    rpcClient.prototype.getListRTP = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initGetListRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('getListRTP', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateListRTP = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = this.initUpdateRecordRequest(p);
                        return [4 /*yield*/, this.gRPCClientRequest('updateListRTP', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // Common
    rpcClient.prototype.initGetListRecordRequest = function (p) {
        var req = new GetListRecordRequest();
        req.setTableId(p.tableId);
        req.setViewId(p.viewId);
        req.setOffset(p.offset);
        req.setLimit(p.limit);
        if (p.search) {
            var s = new GetListRecordRequest.Search();
            s.setFieldIdsList(p.search.fieldIdsList);
            s.setValue(p.search.value);
            req.setSearch(s);
        }
        if (p.sort && p.sort.fieldId) {
            var reqSort = new Sort();
            reqSort.setFieldId(p.sort.fieldId);
            reqSort.setOrder(p.sort.order || Sort.Order.ORDER_DESC);
            req.setSort(reqSort);
        }
        if (p.filterList.length) {
            var f = [];
            var fs = p.filterList.map(function (item) {
                var f = new GetListRecordRequest.Filter();
                f.setFieldId(item.fieldId);
                f.setType(item.type);
                if (item.integerField) {
                    var i = new GetListRecordRequest.IntegerField();
                    i.setValueList(item.integerField.valueList);
                    f.setIntegerField(i);
                }
                if (item.stringField) {
                    var i = new GetListRecordRequest.StringField();
                    i.setValueList(item.stringField.valueList);
                    f.setStringField(i);
                }
                if (item.booleanField) {
                    var i = new GetListRecordRequest.BooleanField();
                    i.setValueList(item.booleanField.valueList);
                    f.setBooleanField(i);
                }
                return f;
            });
            req.setFilterList(fs);
        }
        return req;
    };
    rpcClient.prototype.initGetTableRequest = function (p) {
        var req = new GetTableRequest();
        req.setId(p.id);
        return req;
    };
    rpcClient.prototype.initCreateRecordRequest = function (p) {
        var _this = this;
        var req = new CreateRecordRequest();
        var fList = [];
        p.fieldsList.map(function (field) {
            fList.push(_this.setFieldValue(field));
        });
        req.setTableId(p.tableId);
        req.setFieldsList(fList);
        return req;
    };
    rpcClient.prototype.initUpdateRecordRequest = function (p) {
        var _this = this;
        var req = new UpdateRecordRequest();
        var fList = [];
        p.fieldsList.map(function (field) {
            fList.push(_this.setFieldValue(field));
        });
        req.setId(p.id);
        req.setTableId(p.tableId);
        req.setFieldsList(fList);
        return req;
    };
    rpcClient.prototype.initDeleteListRecordRequest = function (p) {
        var req = new DeleteListRecordRequest();
        req.setIdsList(p.idsList);
        req.setTableId(p.tableId);
        return req;
    };
    rpcClient.prototype.setFieldValue = function (field) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var fieldId = field.fieldId, fieldName = field.fieldName, type = field.type;
        var fieldValue = new FieldValue();
        fieldValue.setFieldId(fieldId);
        fieldValue.setType(type);
        if (!!fieldName)
            fieldValue.setFieldName(fieldName);
        try {
            switch (field.type) {
                case FieldType.BOOLEAN:
                    var booleanFieldValue = new BooleanFieldValue();
                    booleanFieldValue.setValue(((_a = field.booleanField) === null || _a === void 0 ? void 0 : _a.value) || false);
                    fieldValue.setBooleanField(booleanFieldValue);
                    break;
                case FieldType.TEXT:
                case FieldType.LONG_TEXT:
                    var stringFieldValue = new StringFieldValue();
                    stringFieldValue.setValue(((_b = field.stringField) === null || _b === void 0 ? void 0 : _b.value) || '');
                    fieldValue.setStringField(stringFieldValue);
                    break;
                case FieldType.INTEGER:
                    var integerFieldValue = new IntegerFieldValue();
                    integerFieldValue.setValue(((_c = field.integerField) === null || _c === void 0 ? void 0 : _c.value) || 0);
                    fieldValue.setIntegerField(integerFieldValue);
                    break;
                case FieldType.LONG:
                    var longFieldValue = new LongFieldValue();
                    longFieldValue.setValue(((_d = field.longField) === null || _d === void 0 ? void 0 : _d.value) || 0);
                    fieldValue.setLongField(longFieldValue);
                    break;
                case FieldType.DECIMAL:
                    var decimalFieldValue = new DecimalFieldValue();
                    decimalFieldValue.setValue(((_e = field.decimalField) === null || _e === void 0 ? void 0 : _e.value) || 0);
                    fieldValue.setDecimalField(decimalFieldValue);
                    break;
                case FieldType.DATE: {
                    var dateFieldValue = new DateFieldValue();
                    var timestamp = new Timestamp();
                    timestamp.setSeconds(((_g = (_f = field.dateField) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.seconds) || 0);
                    dateFieldValue.setValue(timestamp);
                    fieldValue.setDateField(dateFieldValue);
                    break;
                }
                case FieldType.RESOURCE:
                    var resourceFieldValue = this.setResourceValue(__assign({}, field.resourceField));
                    fieldValue.setResourceField(resourceFieldValue);
                    break;
                case FieldType.LINK_TO_RECORD: {
                    var referenceFieldValue = new ReferenceFieldValue();
                    var value = (_h = field.referenceField) === null || _h === void 0 ? void 0 : _h.recordIdsList;
                    if (value === null || value === void 0 ? void 0 : value.length) {
                        referenceFieldValue.setRecordIdsList(value);
                    }
                    fieldValue.setReferenceField(referenceFieldValue);
                    break;
                }
                default:
                    break;
            }
        }
        catch (e) {
            console.log(e);
        }
        return fieldValue;
    };
    rpcClient.prototype.setResourceValue = function (resource) {
        var resourceFieldValue = new ResourceFieldValue();
        if (!!resource) {
            var filesUploaded = (resource === null || resource === void 0 ? void 0 : resource.uploaded) || (resource === null || resource === void 0 ? void 0 : resource.filesList) || [];
            resourceFieldValue.setValue(resource.value || 0);
            resourceFieldValue.setType(resource.type || 0);
            resourceFieldValue.setBaseDirectoryName(resource.baseDirectoryName || '');
            var filesList = filesUploaded.map(function (file) {
                var fileFieldValue = new FileFieldValue();
                fileFieldValue.setName(file.fileName || file.name);
                fileFieldValue.setSize(file.size);
                fileFieldValue.setPath(file.path);
                fileFieldValue.setType(file.type);
                return fileFieldValue;
            });
            resourceFieldValue.setFilesList(filesList);
        }
        return resourceFieldValue;
    };
    return rpcClient;
}(gRPCClientAbstract));
export default rpcClient;
