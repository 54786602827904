import { useTranslation } from 'react-i18next';
import { Stats } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';
import { Column } from '@core/uikit/build/src/components/LiveOpsTable';
import FormatNumber from '@core/uikit/build/src/components/FormatNumber';
import Typography, {
  TypoVariants,
  TypoWeights,
} from '@core/oui/build/src/components/Typography';

import { CommissionFooter, CommissionSortName } from './const';

export const useColumn = () => {
  const { t } = useTranslation();

  const columns: Column<Stats.AsObject>[] = [
    {
      Header: t('TEXT.GAME'),
      accessor: (row) => t(`TOPIC.${row.topic}`),
      Footer: () => (
        <Typography
          variant={TypoVariants.buttonLowLevel}
          weight={TypoWeights.bold}
        >
          {t('TEXT.OVERVIEW')}
        </Typography>
      ),
      sticky: 'left',
      width: 220,
    },
    {
      Header: t('KPI::GAME_TRACKING.TOTAL_SESSION'),
      accessor: (row) => <FormatNumber value={row.sessions || 0} />,
      align: 'right',
      sort: CommissionSortName.SESSIONS,
    },
    {
      Header: t('KPI::GAME_TRACKING.UNIQUE_USER'),
      accessor: (row) => <FormatNumber value={row.players || 0} />,
      align: 'right',
      sort: CommissionSortName.PLAYERS,
    },
    {
      Header: `% ${t('TEXT.FEE')}`,
      accessor: (row) => <FormatNumber value={row.tax || 0} afterNumber="%" />,
      align: 'right',
      sort: CommissionSortName.TAX,
    },
    {
      Header: t('KPI::RNG.RTP_TARGET'),
      accessor: (row) => <>{t(`KPI::RTP_TARGET.${row.topic}`)}</>,
      align: 'right',
      // sort: CommissionSortName.RTP_TARGET,
    },
    {
      Header: t('KPI::RNG.ACTUAL_RTP'),
      accessor: (row) => (
        <FormatNumber value={row.rtp || 0} percent afterNumber="%" />
      ),
      Footer: (data: CommissionFooter) => {
        return <FormatNumber value={data.rtp || 0} percent afterNumber="%" />;
      },
      align: 'right',
      sort: CommissionSortName.RTP,
    },
    {
      Header: t('TEXT.TOTAL_BET'),
      accessor: (row) => <FormatNumber value={row.bet || 0} unit />,
      Footer: (data: CommissionFooter) => {
        return <FormatNumber value={data.bet || 0} unit />;
      },
      align: 'right',
      sort: CommissionSortName.BET,
    },
    {
      Header: t('KPI::RNG.WIN_FROM_COMPETITOR'),
      accessor: (row) => <FormatNumber value={row.normalWin || 0} unit />,
      Footer: (data: CommissionFooter) => {
        return <FormatNumber value={data.normalWin || 0} unit />;
      },
      align: 'right',
      sort: CommissionSortName.NORMAL_WIN,
    },
    {
      Header: t('KPI::GAME_TRACKING.JACKPOT_WIN'),
      accessor: (row) => <FormatNumber value={row.jackpotWin || 0} unit />,
      Footer: (data: CommissionFooter) => {
        return <FormatNumber value={data.jackpotWin || 0} unit />;
      },
      align: 'right',
      sort: CommissionSortName.JACKPOT_WIN,
    },
    {
      Header: t('KPI::GAME_TRACKING.USER_TOTAL_WIN'),
      accessor: (row) => <FormatNumber value={row.totalWin || 0} unit />,
      Footer: (data: CommissionFooter) => {
        return <FormatNumber value={data.totalWin || 0} unit />;
      },
      align: 'right',
      sort: CommissionSortName.TOTAL_WIN,
    },
    {
      Header: t('KPI::RNG.PROGRESSIVE_AMOUNT'),
      accessor: (row) => <FormatNumber value={row.progressive || 0} unit />,
      Footer: (data: CommissionFooter) => {
        return <FormatNumber value={data.progressive || 0} unit />;
      },
      align: 'right',
      sort: CommissionSortName.PROGRESSIVE,
    },
    {
      Header: t('KPI::RNG.TOTAL_FEE'),
      accessor: (row) => <FormatNumber value={row.fee || 0} unit />,
      Footer: (data: CommissionFooter) => {
        return <FormatNumber value={data.fee || 0} unit />;
      },
      align: 'right',
      sort: CommissionSortName.FEE,
    },
    {
      Header: t('KPI::RNG.HOUSE_GAIN'),
      accessor: (row) => <FormatNumber value={row.houseGain || 0} unit />,
      Footer: (data: CommissionFooter) => {
        return <FormatNumber value={data.houseGain || 0} unit />;
      },
      align: 'right',
      sort: CommissionSortName.HOUSE_GAIN,
    },
  ];

  return { columns };
};
