var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { withStyles, createStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
export default withStyles(function (theme) {
    return createStyles({
        root: {
            textTransform: 'none',
            minWidth: 40,
            marginRight: theme.spacing(4),
            padding: 0,
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: 14,
            color: '#666666',
            opacity: 1,
            '&:hover': {
                color: '#7462E1',
            },
            '&$selected': {
                color: '#7462E1',
                fontWeight: theme.typography.fontWeightBold,
            },
            '&:focus': {
                color: '#7462E1',
            },
        },
        selected: {},
    });
})(function (props) { return _jsx(Tab, __assign({ disableRipple: true }, props), void 0); });
