var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cn from 'classnames';
import styles from './styles.module.scss';
var defaultProps = { component: 'ul', direction: 'column' };
export var List = function (_props) {
    var _a = __assign(__assign({}, defaultProps), _props), Component = _a.component, className = _a.className, direction = _a.direction, rest = __rest(_a, ["component", "className", "direction"]);
    var classOfComponent = cn(styles.list, className, styles["direction-" + direction]);
    return _jsx(Component, __assign({}, rest, { className: classOfComponent }), void 0);
};
List.displayName = 'List';
export default List;
