var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import cn from 'classnames';
import { InputSizes, InputStatuses } from '../Input';
import { getArrayOf } from '../../utils/common/getArrayOf';
import styles from './styles.module.scss';
import { DECIMAL_SEPARATOR, SUFFIX_NUMBER, THOUSAND_SEPARATOR, } from '../../utils/common/numberFormat';
var defaultProps = {
    component: NumberFormat,
    thousandSeparator: THOUSAND_SEPARATOR,
    decimalSeparator: DECIMAL_SEPARATOR,
    suffix: SUFFIX_NUMBER,
    size: InputSizes.md,
    status: InputStatuses.primary,
};
export var InputMoneyFormat = forwardRef(function (props, ref) {
    var _a = __assign(__assign({}, defaultProps), props), Component = _a.component, wrapperRef = _a.wrapperRef, className = _a.className, status = _a.status, beforeInput = _a.beforeInput, afterInput = _a.afterInput, onChange = _a.onChange, name = _a.name, rest = __rest(_a, ["component", "wrapperRef", "className", "status", "beforeInput", "afterInput", "onChange", "name"]);
    var classOfRoot = cn(styles.root, styles["status-" + status], className, rest.disabled && styles.disabled);
    var beforeInputs = getArrayOf(beforeInput);
    var afterInputs = getArrayOf(afterInput);
    var classOfInputBase = cn(styles.input, styles["size-" + rest.size], beforeInputs.length > 0 && styles['has-before'], afterInputs.length > 0 && styles['has-after'], rest.disabled && styles.disabled);
    var handleValueChange = function (values) {
        if (typeof onChange === 'function') {
            var reg = new RegExp("\\" + THOUSAND_SEPARATOR, 'g');
            onChange({
                // @ts-ignore
                target: {
                    name: name,
                    value: values.formattedValue.replace(reg, ''),
                },
            });
        }
    };
    return (_jsxs("div", __assign({ className: classOfRoot, role: "presentation", ref: wrapperRef }, { children: [beforeInputs.map(function (component, index) {
                // @ts-ignore
                return React.cloneElement(component, {
                    key: index.toString(),
                    disabled: rest.disabled,
                    size: rest.size,
                    className: styles['adornment'],
                });
            }), _jsx(Component, __assign({}, rest, { ref: ref, className: classOfInputBase, onChange: function () { return console.log('run'); }, onValueChange: handleValueChange }), void 0), afterInputs.map(function (component, index) {
                // @ts-ignore
                return React.cloneElement(component, {
                    key: index.toString(),
                    disabled: rest.disabled,
                    size: rest.size,
                    className: styles['adornment'],
                });
            })] }), void 0));
});
InputMoneyFormat.displayName = 'InputMoneyFormat';
export default InputMoneyFormat;
