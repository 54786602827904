import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import FormTabs from '@core/uikit/build/src/components/Tabs';
import LayoutContainer from '@core/uikit/build/src/components/Layout/LayoutContainer';
import { useNavigateTab } from '@core/okit/build/hooks/useNavigateTab';

import RNG from './RNG';
import Card from './Card';
import Commission from './Commission';

const tabs = {
  RNG: 'rng',
  COMMISSION: 'commission',
  CARD: 'card',
} as const;

const SaleReportGameType = () => {
  const { t } = useTranslation();
  const { tab = tabs.RNG } = useParams<{ tab: string }>();
  const { navigateTab } = useNavigateTab();

  const tabList = [
    {
      label: t('KPI::GAME_TYPE.RNG'),
      value: tabs.RNG,
      panel: <RNG />,
    },
    {
      label: t('KPI::GAME_TYPE.COMMISSION'),
      value: tabs.COMMISSION,
      panel: <Commission />,
    },
    {
      label: t('KPI::GAME_TYPE.CARD'),
      value: tabs.CARD,
      panel: <Card />,
    },
  ];

  const handleChange = (_, newTab: string) => {
    navigateTab(newTab);
  };

  return (
    <>
      <Helmet>
        <title>
          {`${t('SIDEBAR.SALE_REPORT.GAME_REPORT')} - ${t(
            `KPI::GAME_TYPE.${tab.toUpperCase()}`,
          )} | ${t('SPACE.KPI')}`}
        </title>
      </Helmet>
      <LayoutContainer
        header={t('SIDEBAR.SALE_REPORT.GAME_REPORT')}
        maxWidth={false}
      >
        <FormTabs currentTab={tab} tabList={tabList} onChange={handleChange} />
      </LayoutContainer>
    </>
  );
};

export default SaleReportGameType;
