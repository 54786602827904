var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function AngleLeft(props) {
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M8.32193 12.42L13.9819 18.07C14.0749 18.1637 14.1855 18.2381 14.3074 18.2889C14.4292 18.3397 14.5599 18.3658 14.6919 18.3658C14.8239 18.3658 14.9546 18.3397 15.0765 18.2889C15.1984 18.2381 15.309 18.1637 15.4019 18.07C15.5882 17.8826 15.6927 17.6292 15.6927 17.365C15.6927 17.1008 15.5882 16.8474 15.4019 16.66L10.4519 11.66L15.4019 6.71C15.5882 6.52264 15.6927 6.26919 15.6927 6.005C15.6927 5.74081 15.5882 5.48736 15.4019 5.3C15.3093 5.20551 15.1989 5.13034 15.077 5.07884C14.9551 5.02735 14.8242 5.00055 14.6919 5C14.5596 5.00055 14.4287 5.02735 14.3069 5.07884C14.185 5.13034 14.0745 5.20551 13.9819 5.3L8.32193 10.95C8.22043 11.0436 8.13942 11.1573 8.08401 11.2838C8.0286 11.4103 8 11.5469 8 11.685C8 11.8231 8.0286 11.9597 8.08401 12.0862C8.13942 12.2127 8.22043 12.3264 8.32193 12.42Z", fill: "currentColor" })));
}
export default AngleLeft;
