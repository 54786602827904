import { Stats } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';

export interface CommissionFooter {
  rtp: number;
  bet: number;
  normalWin: number;
  jackpotWin: number;
  totalWin: number;
  progressive: number;
  fee: number;
  houseGain: number;
}

export function calculateSumCommission(
  data: Stats.AsObject[],
): CommissionFooter {
  const sum = data.reduce(
    (acc, item) => {
      // acc.rtp += item.rtp;
      acc.bet += item.bet;
      acc.normalWin += item.normalWin;
      acc.jackpotWin += item.jackpotWin;
      acc.totalWin += item.totalWin;
      acc.progressive += item.progressive;
      acc.fee += item.fee;
      acc.houseGain += item.houseGain;
      return acc;
    },
    {
      rtp: 0,
      bet: 0,
      normalWin: 0,
      jackpotWin: 0,
      totalWin: 0,
      progressive: 0,
      fee: 0,
      houseGain: 0,
    },
  );

  sum.rtp = sum.totalWin / sum.bet;

  return sum;
}

export enum CommissionSortName {
  SESSIONS = 'sessions',
  PLAYERS = 'players',
  TAX = 'tax',
  RTP_TARGET = 'rtpTarget',
  RTP = 'rtp',
  BET = 'bet',
  NORMAL_WIN = 'normalWin',
  JACKPOT_WIN = 'jackpotWin',
  TOTAL_WIN = 'totalWin',
  PROGRESSIVE = 'progressive',
  FEE = 'fee',
  HOUSE_GAIN = 'houseGain',
}

export const compareSortOfCommission = {
  [`${CommissionSortName.SESSIONS}_desc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => b.sessions - a.sessions,
  [`${CommissionSortName.SESSIONS}_asc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => a.sessions - b.sessions,

  [`${CommissionSortName.PLAYERS}_desc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => b.players - a.players,
  [`${CommissionSortName.PLAYERS}_asc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => a.players - b.players,

  [`${CommissionSortName.TAX}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.tax - a.tax,
  [`${CommissionSortName.TAX}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.tax - b.tax,

  [`${CommissionSortName.RTP}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.rtp - a.rtp,
  [`${CommissionSortName.RTP}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.rtp - b.rtp,

  [`${CommissionSortName.BET}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.bet - a.bet,
  [`${CommissionSortName.BET}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.bet - b.bet,

  [`${CommissionSortName.NORMAL_WIN}_desc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => b.normalWin - a.normalWin,
  [`${CommissionSortName.NORMAL_WIN}_asc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => a.normalWin - b.normalWin,

  [`${CommissionSortName.JACKPOT_WIN}_desc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => b.jackpotWin - a.jackpotWin,
  [`${CommissionSortName.JACKPOT_WIN}_asc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => a.jackpotWin - b.jackpotWin,

  [`${CommissionSortName.TOTAL_WIN}_desc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => b.totalWin - a.totalWin,
  [`${CommissionSortName.TOTAL_WIN}_asc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => a.totalWin - b.totalWin,

  [`${CommissionSortName.PROGRESSIVE}_desc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => b.progressive - a.progressive,
  [`${CommissionSortName.PROGRESSIVE}_asc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => a.progressive - b.progressive,

  [`${CommissionSortName.FEE}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.fee - a.fee,
  [`${CommissionSortName.FEE}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.fee - b.fee,

  [`${CommissionSortName.HOUSE_GAIN}_desc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => b.houseGain - a.houseGain,
  [`${CommissionSortName.HOUSE_GAIN}_asc`]: (
    a: Stats.AsObject,
    b: Stats.AsObject,
  ) => a.houseGain - b.houseGain,
};
