var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@material-ui/core';
import { formatDate, formatDateTime } from '@core/okit';
import { Button, ButtonVariants } from '@core/oui/build/src/components/Button';
import Typography, { TypoVariants, TypoWeights, } from '@core/oui/build/src/components/Typography';
import { TimeSheet } from '@core/icons';
import PopoverCalendar from './PopoverCalendar';
import QuickSelectOfDate from './QuickSelectOfDate';
import styles from './styles.module.scss';
import { useDateRangeContext } from '../context';
var CalendarPicker = function (_a) {
    var value = _a.value, onChange = _a.onChange, showTime = _a.showTime, periodOptions = _a.periodOptions, field = __rest(_a, ["value", "onChange", "showTime", "periodOptions"]);
    var t = useTranslation().t;
    var dayRangeTmp = useDateRangeContext().dayRangeTmp;
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var id = open ? 'date-range-popover' : undefined;
    var applyDate = function () {
        onChange(dayRangeTmp);
        handleClose();
    };
    var getValue = useCallback(function () {
        if (!value[0] || !value[1])
            return t('Common::DATE_RANGE');
        var timeShown = [];
        if (value[0]) {
            timeShown.push(showTime ? formatDateTime(value[0]) : formatDate(value[0]));
        }
        if (value[1]) {
            timeShown.push(showTime ? formatDateTime(value[1]) : formatDate(value[1]));
        }
        return t('Common::DATE_RANGE') + ": " + timeShown.join(' - ');
    }, [value[0], value[1]]); //eslint-disable-line
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: ButtonVariants.secondary, className: styles['button_date_range'], selected: !!value[0] || !!value[1], startIcon: React.createElement(TimeSheet, null), onClick: handleClick },
            React.createElement(Typography, { variant: TypoVariants.buttonHighLevel, weight: TypoWeights.regular }, getValue())),
        React.createElement(Popover, { id: id, className: styles['popover_daily'], open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            } },
            React.createElement("div", { className: styles.mainBox },
                React.createElement(QuickSelectOfDate, { periodOptions: periodOptions }),
                React.createElement(PopoverCalendar, __assign({}, field))),
            React.createElement("div", { className: styles.applyBtn },
                React.createElement(Button, { onClick: applyDate, disabled: !dayRangeTmp[1] }, t('TEXT.APPLY'))))));
};
export default CalendarPicker;
