var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var UpDown = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_1544_3652)" },
        React.createElement("path", { d: "M7.99374 20.9995L10.5117 18.4195C10.8242 18.0959 10.9992 17.6637 11 17.2138C11.0002 17.0495 10.9773 16.8876 10.9328 16.7323C10.8556 16.4623 10.7134 16.2127 10.5157 16.0065C10.3643 15.8483 10.1824 15.7222 9.981 15.6359C9.77962 15.5497 9.56289 15.505 9.34381 15.5045C9.12473 15.504 8.90781 15.5478 8.70606 15.6332C8.50432 15.7186 8.3219 15.8439 8.16974 16.0015L8.00175 16.1735L7.83374 16.0015C7.6822 15.8433 7.50026 15.7174 7.29884 15.6312C7.09742 15.545 6.88068 15.5004 6.6616 15.5C6.44252 15.4996 6.22562 15.5435 6.02391 15.629C5.92169 15.6723 5.82445 15.7259 5.73359 15.7887C5.64515 15.8498 5.56276 15.9197 5.48775 15.9975C5.33052 16.1605 5.20814 16.3509 5.12529 16.5573C5.04363 16.7607 5.00037 16.9795 5 17.2027C4.99926 17.6524 5.17262 18.0849 5.48374 18.4095L7.99374 20.9995Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V19C20 19.7957 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7957 22 17 22H12C11.4477 22 11 21.5523 11 21C11 20.4477 11.4477 20 12 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H7Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.2929 5.29289C13.6834 4.90237 14.3166 4.90237 14.7071 5.29289L16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711C16.3166 9.09763 15.6834 9.09763 15.2929 8.70711L14 7.41421L12.7071 8.70711C12.3166 9.09763 11.6834 9.09763 11.2929 8.70711C10.9024 8.31658 10.9024 7.68342 11.2929 7.29289L13.2929 5.29289Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L14 12.5858L15.2929 11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L11.2929 12.7071C10.9024 12.3166 10.9024 11.6834 11.2929 11.2929Z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1544_3652" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" }))))); };
export default UpDown;
