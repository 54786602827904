var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, FormLabel } from '@material-ui/core';
import Option from '../../Option';
import DropdownList from '../../DropdownList';
import DropdownAutoList from '../../DropdownAutoList';
import Typography, { TypoTypes, TypoWeights, TypoVariants, } from '../../Typography';
import { InputSizes } from '../../Input';
import { forwardRef } from 'react';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        formControl: {
            width: '100%',
            // '& > div': {
            //   height: '56px !important',
            // },
        },
    });
});
var SelectField = forwardRef(function (props, ref) {
    var _a = props.options, options = _a === void 0 ? [] : _a, label = props.label, autoComplete = props.autoComplete, autoCompleteProps = props.autoCompleteProps, name = props.name, loading = props.loading, rest = __rest(props, ["options", "label", "autoComplete", "autoCompleteProps", "name", "loading"]);
    var classes = useStyles();
    var methods = useFormContext();
    var formState = methods.formState;
    var errors = formState.errors;
    var status = errors[name] ? 'error' : 'primary';
    return (_jsxs("fieldset", __assign({ className: classes.formControl }, { children: [label && (_jsx(FormLabel, { children: _jsx(Typography, __assign({ variant: TypoVariants.body2, type: TypoTypes.sub, weight: TypoWeights.bold }, { children: label }), void 0) }, void 0)), autoComplete ? (_jsx(DropdownAutoList, __assign({ ref: ref, status: status, size: InputSizes.xl, options: options }, autoCompleteProps, rest), void 0)) : (_jsx(DropdownList, __assign({ status: status, size: InputSizes.xl }, rest, { children: options.length > 0 ? (options.map(function (option) { return (_jsx(Option, __assign({ value: option.value }, { children: option.name }), option.value)); })) : (_jsx(Box, __assign({ p: 2, display: "flex", justifyContent: "center" }, { children: loading ? 'Loading...' : 'No data' }), void 0)) }), void 0))] }), void 0));
});
export default SelectField;
