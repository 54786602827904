var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var WithdrawApproval = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fill: "currentColor", d: "M14 13a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 0111 10c0-.833.292-1.542.875-2.125A2.893 2.893 0 0114 7c.833 0 1.542.292 2.125.875S17 9.167 17 10s-.292 1.542-.875 2.125A2.893 2.893 0 0114 13zm-7 3c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 015 14V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 017 4h14c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v8c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0121 16H7zm2-2h10c0-.55.196-1.021.587-1.413A1.928 1.928 0 0121 12V8c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0119 6H9c0 .55-.196 1.02-.588 1.412A1.923 1.923 0 017 8v4c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413zm-6 6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 011 18V8c0-.283.096-.521.288-.713A.967.967 0 012 7a.97.97 0 01.713.287A.97.97 0 013 8v10h16c.283 0 .52.096.712.288A.965.965 0 0120 19c0 .283-.096.52-.288.712A.965.965 0 0119 20H3zm4-6V6v8z" }))); };
export default WithdrawApproval;
