var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { GothambankClient } from '@dceu/gothambank/GothambankServiceClientPb';
import { GetHouseWalletRequest, ListGamesWalletsRequest, GameWallet, ListBotsWalletsRequest, BotsWallet, DepositGameRequest, DepositBotsRequest, DepositBotsPreviewRequest, ListWalletsConfigsRequest, WalletConfig, GetWalletConfigRequest, UpdateWalletConfigRequest, ListCashFlowsRequest, CashFlow, ListHouseDespositsRequest, DepositHouseWalletRequest, GetAllUserWalletRequest, GetTotalAmountToTotalHouseDepositRequest, GetTotalHouseDepositRequest, GetGiftCodeWalletRequest, GetReferralWalletRequest, } from '@dceu/gothambank/gothambank_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcGoThamBankClient = /** @class */ (function (_super) {
    __extends(rpcGoThamBankClient, _super);
    function rpcGoThamBankClient(config) {
        var _this = this;
        config.serviceName = 'GOTHAMBANK';
        _this = _super.call(this, GothambankClient, config) || this;
        return _this;
    }
    rpcGoThamBankClient.prototype.getHouseWallet = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetHouseWalletRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('getHouseWallet', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.listGamesWallets = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListGamesWalletsRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listGamesWallets', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.gameWallet = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GameWallet();
                        req.setId(p.id);
                        req.setAmount(p.amount);
                        req.setName(p.name);
                        req.setGenre(p.genre);
                        req.setMin(p.min);
                        req.setMax(p.max);
                        return [4 /*yield*/, this.gRPCClientRequest('gameWallet', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.listBotsWallets = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListBotsWalletsRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listBotsWallets', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.botsWallet = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new BotsWallet();
                        req.setTopic(p.topic);
                        req.setGroupBot(p.groupBot);
                        req.setTotalBot(p.totalBot);
                        req.setActiveBot(p.activeBot);
                        req.setNotGoodCount(p.notGoodCount);
                        req.setTotalActiveAmount(p.totalActiveAmount);
                        req.setAverageActiveAmount(p.averageActiveAmount);
                        return [4 /*yield*/, this.gRPCClientRequest('botsWallet', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.depositGame = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new DepositGameRequest();
                        req.setServiceTopic(p.serviceTopic);
                        req.setAmount(p.amount);
                        return [4 /*yield*/, this.gRPCClientRequest('depositGame', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.depositBots = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new DepositBotsRequest();
                        req.setTopic(p.topic);
                        req.setGroupBot(p.groupBot);
                        req.setAmount(p.amount);
                        return [4 /*yield*/, this.gRPCClientRequest('depositBots', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.depositBotsPreview = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new DepositBotsPreviewRequest();
                        req.setTopic(p.topic);
                        req.setGroupBot(p.groupBot);
                        req.setAmount(p.amount);
                        req.setCursor(p.cursor);
                        req.setLimit(p.limit);
                        return [4 /*yield*/, this.gRPCClientRequest('depositBotsPreview', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.listWalletsConfigs = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListWalletsConfigsRequest();
                        req.setWalletIdsList(p.walletIdsList);
                        req.setTypeList(p.typeList);
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        return [4 /*yield*/, this.gRPCClientRequest('listWalletsConfigs', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.walletConfig = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new WalletConfig();
                        req.setId(p.id);
                        req.setWalletId(p.walletId);
                        req.setType(p.type);
                        req.setMin(p.min);
                        req.setMax(p.max);
                        return [4 /*yield*/, this.gRPCClientRequest('walletConfig', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.getWalletConfig = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetWalletConfigRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getWalletConfig', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.updateWalletConfig = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateWalletConfigRequest();
                        req.setId(p.id);
                        req.setMin(p.min);
                        req.setMax(p.max);
                        return [4 /*yield*/, this.gRPCClientRequest('updateWalletConfig', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.listCashFlows = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, timestampFromDate, timestampToDate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListCashFlowsRequest();
                        if (p.fromDate) {
                            timestampFromDate = new Timestamp();
                            timestampFromDate.setSeconds(p.fromDate.seconds);
                            timestampFromDate.setNanos(p.fromDate.nanos);
                            req.setFromDate(timestampFromDate);
                        }
                        if (p.toDate) {
                            timestampToDate = new Timestamp();
                            timestampToDate.setSeconds(p.toDate.seconds);
                            timestampToDate.setNanos(p.toDate.nanos);
                            req.setToDate(timestampToDate);
                        }
                        req.setSearch(p.search);
                        req.setFromList(p.fromList);
                        req.setToList(p.toList);
                        req.setTypeList(p.typeList);
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        return [4 /*yield*/, this.gRPCClientRequest('listCashFlows', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.cashFlow = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, timestamp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CashFlow();
                        if (p.createdAt) {
                            timestamp = new Timestamp();
                            timestamp.setSeconds(p.createdAt.seconds);
                            timestamp.setNanos(p.createdAt.nanos);
                            req.setCreatedAt(timestamp);
                        }
                        req.setId(p.id);
                        req.setFrom(p.from);
                        req.setTo(p.to);
                        req.setAmount(p.amount);
                        req.setTransferType(p.transferType);
                        req.setHandledBy(p.handledBy);
                        return [4 /*yield*/, this.gRPCClientRequest('cashFlow', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.listHouseDesposits = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListHouseDespositsRequest();
                        req.setSearch(p.search);
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        return [4 /*yield*/, this.gRPCClientRequest('listHouseDesposits', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.depositHouseWallet = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new DepositHouseWalletRequest();
                        req.setAmount(p.amount);
                        req.setNote(p.note);
                        return [4 /*yield*/, this.gRPCClientRequest('depositHouseWallet', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.getAllUserWallet = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetAllUserWalletRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('getAllUserWallet', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.getTotalAmountToTotalHouseDeposit = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetTotalAmountToTotalHouseDepositRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('getTotalAmountToTotalHouseDeposit', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.getTotalHouseDeposit = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetTotalHouseDepositRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('getTotalHouseDeposit', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.getGiftCodeWallet = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetGiftCodeWalletRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('getGiftCodeWallet', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGoThamBankClient.prototype.getReferralWallet = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetReferralWalletRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('getReferralWallet', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcGoThamBankClient;
}(gRPCClientAbstract));
export default rpcGoThamBankClient;
