var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { AngleDown, AngleUp } from '@core/icons';
import MenuItem from '@material-ui/core/MenuItem';
import { LANGUAGE_OPTIONS, LANGUAGE_INCLUDE_DEBUG_OPTIONS, KEY_I18N, LANGUAGES_SUPPORT, NAMESPACES_SUPPORT, PREFIX_LANGUAGE_CACHE, } from '../../widgets';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../Typography';
import useQuery from '../../hooks/useQuery';
import styles from './styles.module.scss';
import Divider from '../Divider';
var LANGUAGES = process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_DEBUG === 'false'
    ? LANGUAGE_OPTIONS
    : LANGUAGE_INCLUDE_DEBUG_OPTIONS;
var useLangQuery = function () {
    var query = useQuery();
    return {
        langQuery: query.get(KEY_I18N),
    };
};
var LanguageSelectorQuery = function () {
    var langQuery = useLangQuery().langQuery;
    return _jsx(LanguageSelector, { langQuery: langQuery }, void 0);
};
var LanguageSelector = React.memo(function (_a) {
    var langQuery = _a.langQuery;
    var _b = useTranslation(), i18n = _b.i18n, t = _b.t;
    var _c = __read(useState(false), 2), expanded = _c[0], setExpanded = _c[1];
    var _d = __read(useState(null), 2), anchorEl = _d[0], setAnchorEl = _d[1];
    var langNode = LANGUAGES.find(function (lang) { return lang.value === i18n.language; });
    useEffect(function () {
        langQuery && i18n.changeLanguage(langQuery);
    }, [langQuery]);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
        setExpanded(true);
    };
    var handleClose = function () {
        setAnchorEl(null);
        setExpanded(false);
    };
    var switchLanguage = function (lang) {
        i18n.changeLanguage(lang);
        handleClose();
        var uri = window.location.href;
        if (uri.includes(KEY_I18N + "=")) {
            var reg = new RegExp(KEY_I18N + "=[a-z]*", 'g');
            uri = uri.replace(reg, KEY_I18N + "=" + lang);
        }
        window.location.assign(uri);
    };
    var reloadLanguage = function () {
        if (!LANGUAGES_SUPPORT.length || !NAMESPACES_SUPPORT.length)
            return;
        LANGUAGES_SUPPORT.forEach(function (lng) {
            NAMESPACES_SUPPORT.forEach(function (namespace) {
                var key = "" + PREFIX_LANGUAGE_CACHE + lng + "-" + namespace;
                localStorage.removeItem(key);
            });
        });
        window.location.reload();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "small", "aria-controls": "simple-menu", "aria-haspopup": "true", onClick: handleClick, startIcon: langNode === null || langNode === void 0 ? void 0 : langNode.flag, endIcon: expanded ? _jsx(AngleUp, {}, void 0) : _jsx(AngleDown, {}, void 0), className: styles.menuShow }, { children: _jsx("div", __assign({ className: styles.languageName }, { children: _jsx(Typography, __assign({ type: TypoTypes.text, variant: TypoVariants.button1, weight: TypoWeights.medium }, { children: langNode === null || langNode === void 0 ? void 0 : langNode.name }), void 0) }), void 0) }), void 0), _jsxs(Menu, __assign({ id: "simple-menu", className: styles.formDropdown, anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose }, { children: [LANGUAGES.map(function (lang) { return (_jsxs(MenuItem, __assign({ onClick: function () { return switchLanguage(lang.value); } }, { children: [_jsx(Box, __assign({ mr: 1 }, { children: lang.flag }), void 0), _jsx(Typography, __assign({ type: TypoTypes.text, variant: TypoVariants.button1, weight: TypoWeights.medium }, { children: lang.name }), void 0)] }), lang.value)); }), _jsx(Divider, { className: styles.divider }, void 0), _jsx(MenuItem, __assign({ onClick: reloadLanguage }, { children: _jsx(Typography, __assign({ type: TypoTypes.text, variant: TypoVariants.button1, weight: TypoWeights.medium }, { children: t('TEXT.CLEAN_CACHE') }), void 0) }), void 0)] }), void 0)] }, void 0));
});
export default LanguageSelectorQuery;
