var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ClarkClient } from '@dceu/dailyplanet/Dailyplanet_gamesServiceClientPb';
import { ListBauCuaFullRequest, ListBauCuaFullDailyCustomRequest, ListPokerMiniRequest, ListGOFRequest, ListSlotRequest, ListGOWRequest, ListGOFGroupByDailyCustomRequest, ListSlotGroupByDailyCustomRequest, ListGOWGroupByDailyCustomRequest, ListLodeRequest, ListLodeGroupByDailyCustomRequest, ListTLMNRequest, ListCardRequest, ListTLMNGroupByDailyCustomRequest, ListCardGroupByDailyCustomRequest, ListPokerMiniGroupByDailyCustomRequest, ListTableGroupByDailyCustomRequest, ListTableRequest, ListFishGroupByDailyCustomRequest, ListFishUserBetsRequest, ListBaccaratDailyCustomRequest, ListBaccaratRequest, ListPokerRequest, ListPokerGroupByDailyCustomRequest, ListMauBinhRequest, ListMauBinhGroupByDailyCustomRequest, ListSamGroupByDailyCustomRequest, ListSamRequest, ListLiengRequest, ListLiengGroupByDailyCustomRequest, ListPhomRequest, ListPhomGroupByDailyCustomRequest, ListUpdownRequest, ListUpdownGroupByDailyCustomRequest, ListAllGamesRequest, } from '@dceu/dailyplanet/dailyplanet_games_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcClarkClient = /** @class */ (function (_super) {
    __extends(rpcClarkClient, _super);
    function rpcClarkClient(config) {
        var _this = this;
        config.serviceName = 'CLARK';
        _this = _super.call(this, ClarkClient, config) || this;
        return _this;
    }
    rpcClarkClient.prototype.listBauCuaFull = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        subReq = new ListTableRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setTimezone(((_j = p.request) === null || _j === void 0 ? void 0 : _j.timezone) || 0);
                        req = new ListBauCuaFullRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listBauCuaFull', req)];
                    case 1: return [2 /*return*/, _k.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listBauCuaFullDailyCustom = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        subReq = new ListTableGroupByDailyCustomRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setGroupBy(((_j = p.request) === null || _j === void 0 ? void 0 : _j.groupBy) || 0);
                        subReq.setTimezone(((_k = p.request) === null || _k === void 0 ? void 0 : _k.timezone) || 0);
                        req = new ListBauCuaFullDailyCustomRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listBauCuaFullDailyCustom', req)];
                    case 1: return [2 /*return*/, _l.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listXocDia = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        subReq = new ListTableRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setTimezone(((_j = p.request) === null || _j === void 0 ? void 0 : _j.timezone) || 0);
                        req = new ListBauCuaFullRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listXocDia', req)];
                    case 1: return [2 /*return*/, _k.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listXocDiaDailyCustom = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        subReq = new ListTableGroupByDailyCustomRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setGroupBy(((_j = p.request) === null || _j === void 0 ? void 0 : _j.groupBy) || 0);
                        subReq.setTimezone(((_k = p.request) === null || _k === void 0 ? void 0 : _k.timezone) || 0);
                        req = new ListBauCuaFullDailyCustomRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listXocDiaDailyCustom', req)];
                    case 1: return [2 /*return*/, _l.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listSicbo = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        subReq = new ListTableRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setTimezone(((_j = p.request) === null || _j === void 0 ? void 0 : _j.timezone) || 0);
                        req = new ListBauCuaFullRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listSicbo', req)];
                    case 1: return [2 /*return*/, _k.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listSicboDailyCustom = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        subReq = new ListTableGroupByDailyCustomRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setGroupBy(((_j = p.request) === null || _j === void 0 ? void 0 : _j.groupBy) || 0);
                        subReq.setTimezone(((_k = p.request) === null || _k === void 0 ? void 0 : _k.timezone) || 0);
                        req = new ListBauCuaFullDailyCustomRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listSicboDailyCustom', req)];
                    case 1: return [2 /*return*/, _l.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listBCMN = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        subReq = new ListTableRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setTimezone(((_j = p.request) === null || _j === void 0 ? void 0 : _j.timezone) || 0);
                        req = new ListBauCuaFullRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listBCMN', req)];
                    case 1: return [2 /*return*/, _k.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listBCMNDailyCustom = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        subReq = new ListTableGroupByDailyCustomRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setGroupBy(((_j = p.request) === null || _j === void 0 ? void 0 : _j.groupBy) || 0);
                        subReq.setTimezone(((_k = p.request) === null || _k === void 0 ? void 0 : _k.timezone) || 0);
                        req = new ListBauCuaFullDailyCustomRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listBCMNDailyCustom', req)];
                    case 1: return [2 /*return*/, _l.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listTXMN = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        subReq = new ListTableRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setTimezone(((_j = p.request) === null || _j === void 0 ? void 0 : _j.timezone) || 0);
                        req = new ListBauCuaFullRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listTXMN', req)];
                    case 1: return [2 /*return*/, _k.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listTXMNDailyCustom = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var subReq, ts, ts, req;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        subReq = new ListTableGroupByDailyCustomRequest();
                        if ((_a = p.request) === null || _a === void 0 ? void 0 : _a.from) {
                            ts = new Timestamp();
                            ts.setNanos((_b = p.request) === null || _b === void 0 ? void 0 : _b.from.nanos);
                            ts.setSeconds((_c = p.request) === null || _c === void 0 ? void 0 : _c.from.seconds);
                            subReq.setFrom(ts);
                        }
                        if ((_d = p.request) === null || _d === void 0 ? void 0 : _d.to) {
                            ts = new Timestamp();
                            ts.setNanos((_e = p.request) === null || _e === void 0 ? void 0 : _e.to.nanos);
                            ts.setSeconds((_f = p.request) === null || _f === void 0 ? void 0 : _f.to.seconds);
                            subReq.setTo(ts);
                        }
                        subReq.setLimit(((_g = p.request) === null || _g === void 0 ? void 0 : _g.limit) || 0);
                        subReq.setOffset(((_h = p.request) === null || _h === void 0 ? void 0 : _h.offset) || 0);
                        subReq.setGroupBy(((_j = p.request) === null || _j === void 0 ? void 0 : _j.groupBy) || 0);
                        subReq.setTimezone(((_k = p.request) === null || _k === void 0 ? void 0 : _k.timezone) || 0);
                        req = new ListBauCuaFullDailyCustomRequest();
                        req.setRequest(subReq);
                        return [4 /*yield*/, this.gRPCClientRequest('listTXMNDailyCustom', req)];
                    case 1: return [2 /*return*/, _l.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listPokerMini = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListPokerMiniRequest();
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setLimit(p.limit);
                        req.setTimezone(p.timezone || 0);
                        req.setOffset(p.offset);
                        return [4 /*yield*/, this.gRPCClientRequest('listPokerMini', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listPokerMiniGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListPokerMiniGroupByDailyCustomRequest();
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setLimit(p.limit);
                        req.setOffset(p.offset);
                        req.setTimezone(p.timezone || 0);
                        req.setGroupBy(p.groupBy);
                        return [4 /*yield*/, this.gRPCClientRequest('listPokerMiniGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listGOF = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, timezone, listSlot, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListGOFRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, timezone = _a.timezone;
                        listSlot = new ListSlotRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            listSlot.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            listSlot.setTo(ts);
                        }
                        limit && listSlot.setLimit(limit);
                        offset && listSlot.setOffset(offset);
                        timezone && listSlot.setTimezone(timezone);
                        req.setRequest(listSlot);
                        return [4 /*yield*/, this.gRPCClientRequest('listGOF', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listGOFGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, groupBy, timezone, listSlotGroupByDailyCustom, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListGOFGroupByDailyCustomRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, groupBy = _a.groupBy, timezone = _a.timezone;
                        listSlotGroupByDailyCustom = new ListSlotGroupByDailyCustomRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            listSlotGroupByDailyCustom.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            listSlotGroupByDailyCustom.setTo(ts);
                        }
                        limit && listSlotGroupByDailyCustom.setLimit(limit);
                        offset && listSlotGroupByDailyCustom.setOffset(offset);
                        groupBy && listSlotGroupByDailyCustom.setGroupBy(groupBy);
                        timezone && listSlotGroupByDailyCustom.setTimezone(timezone);
                        req.setRequest(listSlotGroupByDailyCustom);
                        return [4 /*yield*/, this.gRPCClientRequest('listGOFGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listGOW = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, timezone, listSlot, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListGOWRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, timezone = _a.timezone;
                        listSlot = new ListSlotRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            listSlot.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            listSlot.setTo(ts);
                        }
                        limit && listSlot.setLimit(limit);
                        offset && listSlot.setOffset(offset);
                        timezone && listSlot.setTimezone(timezone);
                        req.setRequest(listSlot);
                        return [4 /*yield*/, this.gRPCClientRequest('listGOW', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listGOWGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, groupBy, timezone, listSlotGroupByDailyCustom, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListGOWGroupByDailyCustomRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, groupBy = _a.groupBy, timezone = _a.timezone;
                        listSlotGroupByDailyCustom = new ListSlotGroupByDailyCustomRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            listSlotGroupByDailyCustom.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            listSlotGroupByDailyCustom.setTo(ts);
                        }
                        limit && listSlotGroupByDailyCustom.setLimit(limit);
                        offset && listSlotGroupByDailyCustom.setOffset(offset);
                        groupBy && listSlotGroupByDailyCustom.setGroupBy(groupBy);
                        timezone && listSlotGroupByDailyCustom.setTimezone(timezone);
                        req.setRequest(listSlotGroupByDailyCustom);
                        return [4 /*yield*/, this.gRPCClientRequest('listGOWGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listLode = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListLodeRequest();
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setLimit(p.limit);
                        req.setOffset(p.offset);
                        req.setTimezone(p.timezone);
                        return [4 /*yield*/, this.gRPCClientRequest('listLode', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listLodeGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListLodeGroupByDailyCustomRequest();
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setLimit(p.limit);
                        req.setOffset(p.offset);
                        req.setGroupBy(p.groupBy);
                        req.setTimezone(p.timezone);
                        return [4 /*yield*/, this.gRPCClientRequest('listLodeGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listLodeUserBets = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListLodeRequest();
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setLimit(p.limit);
                        req.setOffset(p.offset);
                        req.setTimezone(p.timezone);
                        return [4 /*yield*/, this.gRPCClientRequest('listLodeUserBets', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listTLMN = function (p) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var req, _b, from, to, limit, offset, requestList, ts, ts;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        req = new ListTLMNRequest();
                        _b = p.request || {}, from = _b.from, to = _b.to, limit = _b.limit, offset = _b.offset;
                        requestList = new ListCardRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        requestList.setTimezone(((_a = p.request) === null || _a === void 0 ? void 0 : _a.timezone) || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listTLMN', req)];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listTLMNGroupByDailyCustom = function (p) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var req, _b, from, to, limit, offset, groupBy, requestList, ts, ts;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        req = new ListTLMNGroupByDailyCustomRequest();
                        _b = p.request || {}, from = _b.from, to = _b.to, limit = _b.limit, offset = _b.offset, groupBy = _b.groupBy;
                        requestList = new ListCardGroupByDailyCustomRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        groupBy && requestList.setGroupBy(groupBy);
                        requestList.setTimezone(((_a = p.request) === null || _a === void 0 ? void 0 : _a.timezone) || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listTLMNGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listFishGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListFishGroupByDailyCustomRequest();
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setLimit(p.limit);
                        req.setOffset(p.offset);
                        req.setGroupBy(p.groupBy);
                        req.setTimezone(p.timezone);
                        return [4 /*yield*/, this.gRPCClientRequest('listFishGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listFishUserBets = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListFishUserBetsRequest();
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setLimit(p.limit);
                        req.setOffset(p.offset);
                        req.setGroupBy(p.groupBy);
                        req.setTimezone(p.timezone);
                        return [4 /*yield*/, this.gRPCClientRequest('listFishUserBets', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listBaccarat = function (p) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var req, _b, from, to, limit, offset, requestList, ts, ts;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        req = new ListBaccaratRequest();
                        _b = p.request || {}, from = _b.from, to = _b.to, limit = _b.limit, offset = _b.offset;
                        requestList = new ListCardRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        requestList.setTimezone(((_a = p.request) === null || _a === void 0 ? void 0 : _a.timezone) || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listBaccarat', req)];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listBaccaratDailyCustom = function (p) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var req, _b, from, to, limit, offset, groupBy, requestList, ts, ts;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        req = new ListBaccaratDailyCustomRequest();
                        _b = p.request || {}, from = _b.from, to = _b.to, limit = _b.limit, offset = _b.offset, groupBy = _b.groupBy;
                        requestList = new ListCardGroupByDailyCustomRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        groupBy && requestList.setGroupBy(groupBy);
                        requestList.setTimezone(((_a = p.request) === null || _a === void 0 ? void 0 : _a.timezone) || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listBaccaratDailyCustom', req)];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listMauBinh = function (p) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var req, _b, from, to, limit, offset, requestList, ts, ts;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        req = new ListMauBinhRequest();
                        _b = p.request || {}, from = _b.from, to = _b.to, limit = _b.limit, offset = _b.offset;
                        requestList = new ListCardRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        requestList.setTimezone(((_a = p.request) === null || _a === void 0 ? void 0 : _a.timezone) || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listMauBinh', req)];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listMauBinhGroupByDailyCustom = function (p) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var req, _b, from, to, limit, offset, groupBy, requestList, ts, ts;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        req = new ListMauBinhGroupByDailyCustomRequest();
                        _b = p.request || {}, from = _b.from, to = _b.to, limit = _b.limit, offset = _b.offset, groupBy = _b.groupBy;
                        requestList = new ListCardGroupByDailyCustomRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        groupBy && requestList.setGroupBy(groupBy);
                        requestList.setTimezone(((_a = p.request) === null || _a === void 0 ? void 0 : _a.timezone) || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listMauBinhGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listPoker = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, timezone, requestList, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListPokerRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, timezone = _a.timezone;
                        requestList = new ListCardRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        timezone && requestList.setTimezone(timezone || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listPoker', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listPokerGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, groupBy, timezone, requestList, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListPokerGroupByDailyCustomRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, groupBy = _a.groupBy, timezone = _a.timezone;
                        requestList = new ListCardGroupByDailyCustomRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        groupBy && requestList.setGroupBy(groupBy);
                        timezone && requestList.setTimezone(timezone || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listPokerGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listSam = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, timezone, requestList, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListSamRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, timezone = _a.timezone;
                        requestList = new ListCardRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        timezone && requestList.setTimezone(timezone || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listSam', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listSamGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, groupBy, timezone, requestList, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListSamGroupByDailyCustomRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, groupBy = _a.groupBy, timezone = _a.timezone;
                        requestList = new ListCardGroupByDailyCustomRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        groupBy && requestList.setGroupBy(groupBy);
                        timezone && requestList.setTimezone(timezone || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listSamGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listLieng = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, timezone, requestList, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListLiengRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, timezone = _a.timezone;
                        requestList = new ListCardRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        timezone && requestList.setTimezone(timezone || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listLieng', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listLiengGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, groupBy, timezone, requestList, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListLiengGroupByDailyCustomRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, groupBy = _a.groupBy, timezone = _a.timezone;
                        requestList = new ListCardGroupByDailyCustomRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        groupBy && requestList.setGroupBy(groupBy);
                        timezone && requestList.setTimezone(timezone || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listLiengGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listPhom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, timezone, requestList, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListPhomRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, timezone = _a.timezone;
                        requestList = new ListCardRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        timezone && requestList.setTimezone(timezone || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listPhom', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listPhomGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, from, to, limit, offset, groupBy, timezone, requestList, ts, ts;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new ListPhomGroupByDailyCustomRequest();
                        _a = p.request || {}, from = _a.from, to = _a.to, limit = _a.limit, offset = _a.offset, groupBy = _a.groupBy, timezone = _a.timezone;
                        requestList = new ListCardGroupByDailyCustomRequest();
                        if (from) {
                            ts = new Timestamp();
                            ts.setNanos(from.nanos);
                            ts.setSeconds(from.seconds);
                            requestList.setFrom(ts);
                        }
                        if (to) {
                            ts = new Timestamp();
                            ts.setNanos(to.nanos);
                            ts.setSeconds(to.seconds);
                            requestList.setTo(ts);
                        }
                        limit && requestList.setLimit(limit);
                        offset && requestList.setOffset(offset);
                        groupBy && requestList.setGroupBy(groupBy);
                        timezone && requestList.setTimezone(timezone || 0);
                        req.setRequest(requestList);
                        return [4 /*yield*/, this.gRPCClientRequest('listPhomGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listUpdown = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUpdownRequest();
                        if (p === null || p === void 0 ? void 0 : p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p === null || p === void 0 ? void 0 : p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p === null || p === void 0 ? void 0 : p.to.nanos);
                            ts.setSeconds(p === null || p === void 0 ? void 0 : p.to.seconds);
                            req.setTo(ts);
                        }
                        (p === null || p === void 0 ? void 0 : p.limit) && req.setLimit(p === null || p === void 0 ? void 0 : p.limit);
                        (p === null || p === void 0 ? void 0 : p.offset) && req.setOffset(p === null || p === void 0 ? void 0 : p.offset);
                        (p === null || p === void 0 ? void 0 : p.timezone) && req.setTimezone(p.timezone || 0);
                        return [4 /*yield*/, this.gRPCClientRequest('listUpdown', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listUpdownGroupByDailyCustom = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUpdownGroupByDailyCustomRequest();
                        if (p === null || p === void 0 ? void 0 : p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p === null || p === void 0 ? void 0 : p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        (p === null || p === void 0 ? void 0 : p.groupBy) && req.setGroupBy(p.groupBy);
                        (p === null || p === void 0 ? void 0 : p.limit) && req.setLimit(p === null || p === void 0 ? void 0 : p.limit);
                        (p === null || p === void 0 ? void 0 : p.offset) && req.setOffset(p === null || p === void 0 ? void 0 : p.offset);
                        (p === null || p === void 0 ? void 0 : p.timezone) && req.setTimezone(p.timezone || 0);
                        return [4 /*yield*/, this.gRPCClientRequest('listUpdownGroupByDailyCustom', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClarkClient.prototype.listAllGames = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListAllGamesRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listAllGames', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcClarkClient;
}(gRPCClientAbstract));
export default rpcClarkClient;
