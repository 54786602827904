var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core';
import Typography, { TypoVariants } from '../../components/Typography';
import { parseJson } from '../../utils/common';
export var ProfileAvatar = function (_a) {
    var _b;
    var _c = _a.userInfo, userInfo = _c === void 0 ? {} : _c;
    var classes = useStyles();
    var metadata = parseJson(userInfo.metadata);
    var displayName = metadata.fullName || userInfo.username || '...';
    return (_jsxs("div", __assign({ className: classes.root }, { children: [_jsx(Avatar, __assign({ alt: userInfo.username, src: metadata.picture, classes: {
                    root: classes.MuiAvatarRoot,
                } }, { children: (_b = displayName[0]) !== null && _b !== void 0 ? _b : null }), void 0), _jsx(Typography, __assign({ component: "span", variant: TypoVariants.button1, style: { marginLeft: 8 } }, { children: displayName }), void 0)] }), void 0));
};
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
        },
        MuiAvatarRoot: {
            height: '20px',
            width: '20px',
            // styleName: 'Title (Semi Bold-600)/Title 4',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '0.25px',
            textAlign: 'center',
        },
    });
});
