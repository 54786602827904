var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var RiskManagement = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", color: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_1800_3255)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 8C12.5523 8 13 8.44772 13 9V11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11V9C11 8.44772 11.4477 8 12 8ZM12 14C12.5523 14 13 14.4477 13 15V15.01C13 15.5623 12.5523 16.01 12 16.01C11.4477 16.01 11 15.5623 11 15.01V15C11 14.4477 11.4477 14 12 14Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.4663 2.38396C10.9279 2.11179 11.4541 1.96825 11.9899 1.96825C12.5258 1.96825 13.0519 2.11179 13.5136 2.38396C13.9721 2.65426 14.3503 3.04164 14.6096 3.50627L17.2676 8.0923C17.5446 8.57013 17.3817 9.18199 16.9039 9.45894C16.4261 9.73588 15.8142 9.57303 15.5373 9.0952L12.8748 4.50145L12.8649 4.48412C12.7785 4.32782 12.6517 4.19754 12.4978 4.10682C12.3439 4.01609 12.1686 3.96825 11.9899 3.96825C11.8113 3.96825 11.6359 4.01609 11.4821 4.10682C11.3282 4.19754 11.2014 4.32782 11.1149 4.48412L11.1051 4.50145L4.0462 16.6806C4.00119 16.8129 3.98435 16.9534 3.99701 17.093C4.01142 17.2518 4.06362 17.405 4.14926 17.5395C4.2349 17.6741 4.35149 17.7862 4.4893 17.8666C4.6271 17.9469 4.78213 17.9931 4.94143 18.0013C4.94252 18.0014 4.94361 18.0014 4.9447 18.0015C4.96299 18.0005 4.98141 18 4.99995 18H11.9999C12.5522 18 12.9999 18.4477 12.9999 19C12.9999 19.5523 12.5522 20 11.9999 20H4.99995C4.98167 20 4.9635 19.9995 4.94547 19.9985C4.91021 20.0005 4.87451 20.0005 4.83846 19.9987C4.36055 19.974 3.89548 19.8354 3.48205 19.5944C3.06863 19.3534 2.71885 19.017 2.46194 18.6133C2.20502 18.2096 2.04841 17.7502 2.00518 17.2736C1.96196 16.797 2.03337 16.317 2.21346 15.8737C2.23096 15.8306 2.25144 15.7888 2.27476 15.7485L9.37027 3.5063C9.62957 3.04165 10.0078 2.65427 10.4663 2.38396Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.9996 14.5C17.4686 14.5 17.0204 14.9379 17.0204 15.5C17.0204 16.0621 17.4686 16.5 17.9996 16.5C18.5306 16.5 18.9789 16.0621 18.9789 15.5C18.9789 14.9379 18.5306 14.5 17.9996 14.5ZM15.0204 15.5C15.0204 13.853 16.3444 12.5 17.9996 12.5C19.6548 12.5 20.9789 13.853 20.9789 15.5C20.9789 17.147 19.6548 18.5 17.9996 18.5C16.3444 18.5 15.0204 17.147 15.0204 15.5Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.9996 11C18.5519 11 18.9996 11.4477 18.9996 12V13.5C18.9996 14.0523 18.5519 14.5 17.9996 14.5C17.4473 14.5 16.9996 14.0523 16.9996 13.5V12C16.9996 11.4477 17.4473 11 17.9996 11Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.9996 16.5C18.5519 16.5 18.9996 16.9477 18.9996 17.5V19C18.9996 19.5523 18.5519 20 17.9996 20C17.4473 20 16.9996 19.5523 16.9996 19V17.5C16.9996 16.9477 17.4473 16.5 17.9996 16.5Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21.8628 13.2461C22.1411 13.7231 21.98 14.3354 21.503 14.6137L20.2175 15.3637C19.7404 15.6421 19.1281 15.481 18.8498 15.0039C18.5715 14.5269 18.7326 13.9146 19.2096 13.6362L20.4951 12.8862C20.9722 12.6079 21.5845 12.769 21.8628 13.2461Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.1505 15.9964C17.4286 16.4735 17.2673 17.0858 16.7902 17.3639L15.5037 18.1139C15.0266 18.3921 14.4143 18.2308 14.1361 17.7536C13.858 17.2765 14.0193 16.6642 14.4964 16.3861L15.7829 15.6361C16.26 15.3579 16.8723 15.5192 17.1505 15.9964Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.1361 13.2464C14.4143 12.7692 15.0266 12.6079 15.5037 12.8861L16.7902 13.6361C17.2673 13.9142 17.4286 14.5265 17.1505 15.0036C16.8723 15.4808 16.26 15.6421 15.7829 15.3639L14.4964 14.6139C14.0193 14.3358 13.858 13.7235 14.1361 13.2464Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.8496 15.9964C19.1278 15.5192 19.7401 15.3579 20.2172 15.6361L21.5037 16.3861C21.9808 16.6642 22.1421 17.2765 21.864 17.7536C21.5858 18.2308 20.9735 18.3921 20.4964 18.1139L19.2099 17.3639C18.7328 17.0858 18.5715 16.4735 18.8496 15.9964Z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1800_3255" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" }))))); };
export default RiskManagement;
