var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useState } from 'react';
export function useExpandRow(data) {
    var _a = __read(useState({}), 2), expandedRows = _a[0], setExpandedRows = _a[1];
    var expandedRowsValues = Object.values(expandedRows);
    // const expandedRowsCount = expandedRowsValues.length;
    var isAllRowsExpanded = expandedRowsValues.every(function (item) { return item === true; });
    var handleToggleExpandRow = useCallback(function (rowIdx) {
        setExpandedRows(function (prevExpandedRows) {
            var _a;
            var newExpandedRows = __assign(__assign({}, prevExpandedRows), (_a = {}, _a[rowIdx] = !prevExpandedRows[rowIdx], _a));
            return newExpandedRows;
        });
    }, [data]);
    var handleToggleExpandAll = useCallback(function () {
        setExpandedRows(function (prevExpandedRows) {
            var checkboxValues = Object.values(prevExpandedRows);
            var shouldExpandAll = checkboxValues.some(function (item) { return item === false; }) ||
                checkboxValues.length === 0;
            var newExpandedRows = data.reduce(function (acc, _, index) {
                var _a;
                return Object.assign(acc, (_a = {}, _a[index] = shouldExpandAll, _a));
            }, {});
            return newExpandedRows;
        });
    }, [data]);
    return {
        expandedRows: expandedRows,
        // setExpandedRows,
        // expandedRowsCount,
        isAllRowsExpanded: isAllRowsExpanded,
        handleToggleExpandRow: handleToggleExpandRow,
        handleToggleExpandAll: handleToggleExpandAll,
    };
}
