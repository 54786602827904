var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import cn from 'classnames';
import { Loading } from '@core/icons';
import ButtonBase from '../ButtonBase';
import Icon from '../Icon';
import { ButtonSizes, ButtonVariants } from './types';
import styles from './styles.module.scss';
export * from './types';
var defaultProps = {
    component: 'button',
    disabled: false,
    fullWidth: true,
    loading: false,
    variant: ButtonVariants.primary,
    size: ButtonSizes.md,
};
export var Button = forwardRef(function (props, ref) {
    var _a;
    var _b = __assign(__assign({}, defaultProps), props), Component = _b.component, variant = _b.variant, size = _b.size, className = _b.className, children = _b.children, disabled = _b.disabled, activated = _b.activated, selected = _b.selected, fullWidth = _b.fullWidth, loading = _b.loading, icon = _b.icon, startIcon = _b.startIcon, onClick = _b.onClick, rest = __rest(_b, ["component", "variant", "size", "className", "children", "disabled", "activated", "selected", "fullWidth", "loading", "icon", "startIcon", "onClick"]);
    var shouldUseIcon = !!icon || !!loading;
    var handleOnClick = function (event) {
        if (!loading && !disabled) {
            if (typeof onClick === 'function') {
                onClick(event);
            }
        }
    };
    var classOfComponent = cn(styles.btn, styles["variant-" + variant], styles["size-" + size], className, (_a = {},
        _a[styles.loading] = loading,
        _a[styles.disabled] = disabled,
        _a[styles.activated] = activated,
        _a[styles.selected] = selected,
        _a[styles.loading] = loading,
        _a[styles['full-width']] = fullWidth,
        _a[styles['use-icon']] = shouldUseIcon,
        _a));
    var contentOfButton = shouldUseIcon ? (_jsx(Icon, { className: styles.icon, component: loading ? Loading : icon }, void 0)) : (children);
    var contentStartIcon = !shouldUseIcon && startIcon ? (_jsx(Icon, { className: cn(styles.icon, styles['start-icon']), component: startIcon }, void 0)) : null;
    return (_jsxs(ButtonBase, __assign({}, rest, { ref: ref, component: Component, disabled: disabled, onClick: handleOnClick, className: classOfComponent }, { children: [contentStartIcon, contentOfButton] }), void 0));
});
Button.displayName = 'Button';
export default Button;
