var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Eye = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 11a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M3.16 12c2.498 4.13 5.453 6 8.84 6 3.387 0 6.342-1.87 8.84-6-2.498-4.13-5.453-6-8.84-6-3.387 0-6.342 1.87-8.84 6zm-2.028-.496C3.907 6.647 7.52 4 12 4s8.093 2.647 10.868 7.504a1 1 0 010 .992C20.093 17.353 16.48 20 12 20s-8.093-2.647-10.868-7.504a1 1 0 010-.992z", clipRule: "evenodd" })));
};
export default Eye;
