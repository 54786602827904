export const ALFRED = 'Portal';
export const CMS = 'CMS';
export const MERA = 'User Management';
export const RICK = 'Money Management';
export const LOIS = 'Game Data';
export const KATANA = 'KPI';
export const JOKER = 'Content Management';
export const QUINN = 'Player Management';
export const ZOD = 'Withdraw Approval';
export const AQUAMAN = 'Game Insight';
