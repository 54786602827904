var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "#7462E1", d: "M10.11 12a3.931 3.931 0 01-2.864-1.175C6.452 10.042 6.055 9.1 6.055 8s.397-2.042 1.19-2.825A3.931 3.931 0 0110.11 4c1.115 0 2.07.392 2.863 1.175.794.783 1.191 1.725 1.191 2.825s-.397 2.042-1.19 2.825A3.931 3.931 0 0110.11 12zm-7.096 8a.985.985 0 01-.722-.288A.958.958 0 012 19v-1.8c0-.55.144-1.067.43-1.55.288-.483.685-.85 1.192-1.1.862-.433 1.833-.8 2.914-1.1 1.082-.3 2.273-.45 3.574-.45h.354a.97.97 0 01.304.05c-.135.3-.249.613-.342.938A7.74 7.74 0 0010.21 15c-.085.6-.11 1.104-.076 1.512.034.409.127.905.279 1.488.101.35.236.696.405 1.038.169.341.355.662.558.962H3.014zm14.191-2c.558 0 1.035-.196 1.433-.587.396-.392.595-.863.595-1.413s-.199-1.021-.595-1.413A1.968 1.968 0 0017.205 14c-.557 0-1.034.196-1.431.587A1.914 1.914 0 0015.178 16c0 .55.199 1.021.596 1.413.397.391.874.587 1.431.587zm-1.317 1.5a7.408 7.408 0 01-.571-.262 4.496 4.496 0 01-.545-.338l-1.09.325a.491.491 0 01-.329-.013A.558.558 0 0113.1 19l-.608-1a.404.404 0 01-.063-.325.525.525 0 01.19-.3l.836-.725a4.552 4.552 0 01-.051-.65c0-.2.017-.417.05-.65l-.836-.725a.525.525 0 01-.19-.3.404.404 0 01.064-.325l.608-1a.564.564 0 01.253-.213.495.495 0 01.33-.012l1.09.325c.185-.133.367-.246.544-.338.178-.091.368-.179.57-.262l.229-1.1a.594.594 0 01.177-.287.441.441 0 01.304-.113h1.216c.119 0 .22.038.305.113.084.075.143.17.177.287l.228 1.1c.203.083.393.175.57.275.178.1.36.225.545.375l1.064-.375a.41.41 0 01.343 0c.11.05.198.125.266.225l.608 1.05c.067.1.093.208.076.325a.468.468 0 01-.178.3l-.861.725c.034.2.05.408.05.625 0 .217-.016.425-.05.625l.836.725a.525.525 0 01.19.3.404.404 0 01-.063.325l-.608 1a.558.558 0 01-.254.212.491.491 0 01-.33.013l-1.089-.325a4.495 4.495 0 01-.544.338 7.443 7.443 0 01-.57.262l-.229 1.1a.594.594 0 01-.177.287.442.442 0 01-.304.113h-1.217a.441.441 0 01-.304-.113.594.594 0 01-.177-.287l-.229-1.1z" })));
}
export default SvgComponent;
