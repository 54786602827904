import checkFieldType from '../utils/common/checkFieldType';
import getWindow from '../utils/common/getWindow';
import useEnhancedEffect from './useEnhancedEffect';
import useEventCallback from './useEventCallback';
export default function useEventListener(element, event, fn) {
    var win = getWindow();
    var el = element || win;
    var handler = useEventCallback(fn);
    var destroy = function () {
        return checkFieldType(el, 'removeEventListener', 'function') &&
            el.removeEventListener(event, handler);
    };
    useEnhancedEffect(function () {
        if (checkFieldType(el, 'addEventListener', 'function')) {
            el.addEventListener(event, handler);
        }
        return destroy;
    }, []);
    return destroy;
}
