var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function AngleUp(props) {
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M10.9458 8.62918L5.2958 14.2892C5.20207 14.3821 5.12768 14.4927 5.07691 14.6146C5.02614 14.7365 5 14.8672 5 14.9992C5 15.1312 5.02614 15.2619 5.07691 15.3838C5.12768 15.5056 5.20207 15.6162 5.2958 15.7092C5.48316 15.8954 5.73661 16 6.0008 16C6.26498 16 6.51844 15.8954 6.7058 15.7092L11.7058 10.7592L16.6558 15.7092C16.8432 15.8954 17.0966 16 17.3608 16C17.625 16 17.8784 15.8954 18.0658 15.7092C18.1603 15.6166 18.2355 15.5061 18.287 15.3842C18.3384 15.2624 18.3652 15.1315 18.3658 14.9992C18.3652 14.8669 18.3384 14.736 18.287 14.6141C18.2355 14.4922 18.1603 14.3818 18.0658 14.2892L12.4158 8.62918C12.3222 8.52768 12.2085 8.44667 12.082 8.39126C11.9555 8.33586 11.8189 8.30725 11.6808 8.30725C11.5427 8.30725 11.4061 8.33586 11.2796 8.39126C11.1531 8.44667 11.0394 8.52768 10.9458 8.62918Z", fill: "currentColor" })));
}
export default AngleUp;
