import React from 'react';
import { getMonth, getYear } from 'date-fns';
import { Button } from '@core/oui/build/src/components/Button';
import { AngleLeft } from '@core/icons';
import { MIN_YEAR } from '../const';
import styles from '../styles.module.scss';
var PrevMonthButton = function (_a) {
    var curMonth = _a.curMonth, onPrevMonth = _a.onPrevMonth;
    return (React.createElement(Button, { className: "mr-16 " + (getYear(curMonth) - MIN_YEAR < 0 ||
            (getMonth(curMonth) === 0 && getYear(curMonth) - MIN_YEAR <= 0)
            ? styles['icon_arrow_disabled']
            : ''), disabled: getYear(curMonth) - MIN_YEAR < 0 ||
            (getMonth(curMonth) === 0 && getYear(curMonth) - MIN_YEAR <= 0), onClick: onPrevMonth },
        React.createElement(AngleLeft, null)));
};
export default PrevMonthButton;
