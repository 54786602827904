var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useCallback } from 'react';
import { isFunction } from '../../../utils/common';
import TableCell from '../../TableCell';
import TableRow from '../../TableRow';
import styles from '../styles.module.scss';
import BodyRowCells from './BodyRowCells';
import BodyRowCheckboxCell from './BodyRowCheckboxCell';
var noop = function () { };
var BodyRow = function (_a) {
    var stickyColumn = _a.stickyColumn, rowIdx = _a.rowIdx, 
    //
    classOfCurrentRow = _a.classOfCurrentRow, classOfCell = _a.classOfCell, classOfCheckboxCell = _a.classOfCheckboxCell, 
    //
    header = _a.header, rowData = _a.rowData, dataOfItem = _a.dataOfItem, otherProps = _a.otherProps, 
    //
    tableRowProps = _a.tableRowProps, 
    //
    isRowSelected = _a.isRowSelected, handleToggleCheckboxRow = _a.handleToggleCheckboxRow, hasCheckboxes = _a.hasCheckboxes, 
    //
    isRowExpanded = _a.isRowExpanded, handleToggleExpandRow = _a.handleToggleExpandRow, tableRowSubComponent = _a.tableRowSubComponent, rest = __rest(_a, ["stickyColumn", "rowIdx", "classOfCurrentRow", "classOfCell", "classOfCheckboxCell", "header", "rowData", "dataOfItem", "otherProps", "tableRowProps", "isRowSelected", "handleToggleCheckboxRow", "hasCheckboxes", "isRowExpanded", "handleToggleExpandRow", "tableRowSubComponent"]);
    var columnCount = Object.keys(header).length;
    var handleClickRow = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        isFunction(tableRowProps === null || tableRowProps === void 0 ? void 0 : tableRowProps.onClick) && (tableRowProps === null || tableRowProps === void 0 ? void 0 : tableRowProps.onClick(rowData));
    }, [rowData]);
    var shouldShowSubComponent = !!tableRowSubComponent && isRowExpanded;
    return (_jsxs(_Fragment, { children: [_createElement(TableRow, __assign({}, otherProps, { key: rowIdx, className: classOfCurrentRow }, tableRowProps, { onClick: (tableRowProps === null || tableRowProps === void 0 ? void 0 : tableRowProps.onClick) ? handleClickRow : noop }),
                hasCheckboxes && (_jsx(BodyRowCheckboxCell, { classOfCheckboxCell: classOfCheckboxCell, isRowSelected: isRowSelected, rowIdx: rowIdx, handleToggleCheckboxRow: handleToggleCheckboxRow }, void 0)),
                _jsx(BodyRowCells, __assign({ rowIdx: rowIdx, stickyColumn: stickyColumn, hasCheckboxes: hasCheckboxes, classOfCell: classOfCell, header: header, dataOfItem: dataOfItem, isRowExpanded: isRowExpanded, handleToggleExpandRow: handleToggleExpandRow }, rest), void 0)), shouldShowSubComponent && (_jsx(TableRow, __assign({ className: styles['row-sub-component'] }, { children: _jsx(TableCell, __assign({ colSpan: columnCount }, { children: tableRowSubComponent === null || tableRowSubComponent === void 0 ? void 0 : tableRowSubComponent(rowData) }), void 0) }), void 0))] }, void 0));
};
export default BodyRow;
