import warning from 'warning';
var emptyFunc = function () { };
export default function createChainedFunction() {
    var funcs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        funcs[_i] = arguments[_i];
    }
    return funcs.filter(Boolean).reduce(function (acc, func) {
        warning(typeof func === 'function', 'invalid Argument Type, must only provide functions, undefined, or null.');
        return function chainedFunction() {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            acc.apply(this, args);
            func.apply(this, args);
        };
    }, emptyFunc);
}
