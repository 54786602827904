var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Close } from '@core/icons';
import { InputSizes } from '../../Input';
import TextField from '../../TextField';
import InputAdornment from '../../InputAdornment';
import Icon from '../../Icon';
var TextInputField = function (_a) {
    var onBlur = _a.onBlur, restProps = __rest(_a, ["onBlur"]);
    var name = restProps.name;
    var methods = useFormContext();
    var setValue = methods.setValue, formState = methods.formState, getValues = methods.getValues;
    var errors = formState.errors;
    var status = errors[restProps.name] ? 'error' : 'primary';
    var value = getValues()[restProps.name];
    var handleOnBlur = function (e) {
        if (typeof onBlur === 'function') {
            onBlur(e);
        }
        setValue(name, e.target.value.trim());
    };
    var onClickBtnClear = function () {
        setValue(name, '', {
            // If the field already has value, clear the value should make the field dirty
            shouldDirty: Boolean(value),
        });
    };
    return (_jsx(TextField, __assign({ status: status, size: InputSizes.lg, onBlur: handleOnBlur, afterInput: [
            restProps.value && !restProps.disabled ? (_jsx(InputAdornment, __assign({ onClick: onClickBtnClear }, { children: _jsx(Icon, { style: { cursor: 'pointer' }, component: Close }, void 0) }), void 0)) : (_jsx(_Fragment, {}, void 0)),
        ] }, restProps), void 0));
};
export default TextInputField;
