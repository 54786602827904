import { GAMELOOT_TENANT_ID } from '@core/okit';
import { enumToArrayOptions } from '@core/uikit/build/src/utils/common/enumConvert';

export enum FilterConfig {
  VIEW_BY = 'viewBy',
}

export enum ViewBy {
  ALL_PLAYER_TYPE = 1,
  PLAYER_ONLY = 2,
  BOT_ONLY = 3,
}

export const VIEW_BY_OPTION = () => enumToArrayOptions(ViewBy, 'KPI::RNG.');

export const BOT_TENANT_ID = ['LUNA', 'QAT'];

export const getTenantId = (viewById: number) => {
  if (viewById === ViewBy.PLAYER_ONLY) return [GAMELOOT_TENANT_ID];
  if (viewById === ViewBy.BOT_ONLY) return BOT_TENANT_ID;
  return undefined;
};
