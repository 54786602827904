var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import cn from 'classnames';
import Typography, { TypoTypes } from '../Typography';
import styles from './styles.module.scss';
var defaultProps = {
    component: 'input',
};
var SelectInput = React.forwardRef(function (_props, ref) {
    var _a;
    var _b = __assign(__assign({}, defaultProps), _props), Component = _b.component, className = _b.className, onClick = _b.onClick, display = _b.display, placeholder = _b.placeholder, rest = __rest(_b, ["component", "className", "onClick", "display", "placeholder"]);
    var isDisplayPlaceholder = Array.isArray(display) && display.length === 0;
    return (_jsxs(Fragment, { children: [_jsx("div", __assign({ className: cn(styles.select, styles["select-size-" + rest.size], className, (_a = {},
                    _a[styles.disabled] = rest.disabled,
                    _a[styles.placeholder] = isDisplayPlaceholder,
                    _a)), ref: ref, onClick: onClick }, { children: isDisplayPlaceholder ? (_jsx(Typography, __assign({ truncate: 1, type: TypoTypes.inherit, className: cn(styles['label-text'], styles["label-size-" + rest.size]) }, { children: placeholder }), void 0)) : (_jsx(Typography, __assign({ truncate: 1, type: TypoTypes.inherit, className: cn(styles['label-text'], styles["label-size-" + rest.size]) }, { children: display }), void 0)) }), void 0), _jsx(Component, __assign({}, rest, { type: "hidden" }), void 0)] }, void 0));
});
SelectInput.displayName = 'SelectInput';
export default SelectInput;
