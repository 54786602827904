var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback } from 'react';
import { AngleDown, AngleUp } from '@core/icons';
import cn from 'classnames';
import ListItemAction from '../ListItemAction';
import styles from './styles.module.scss';
var defaultProps = {
    component: 'div',
    disabled: false,
    activated: false,
    open: null,
    classes: {},
};
export var ListItem = forwardRef(function (props, ref) {
    var _a, _b, _c;
    var _d = __assign(__assign({}, defaultProps), props), Component = _d.component, className = _d.className, disabled = _d.disabled, children = _d.children, activated = _d.activated, open = _d.open, classes = _d.classes, rest = __rest(_d, ["component", "className", "disabled", "children", "activated", "open", "classes"]);
    var classOfWrapper = cn(styles.item, className, (_a = {},
        _a[styles.disabled] = !!disabled,
        _a[styles.activated] = !!activated,
        _a[classes.wrapper] = !!classes.wrapper,
        _a));
    var classOfContent = cn(styles.content, (_b = {},
        _b[styles["content-size-" + rest.size]] = !!rest.size,
        _b), (_c = {},
        _c[classes.content] = !!classes.content,
        _c));
    var openIsBool = open === undefined || typeof open === 'boolean';
    var contentOfToggle = openIsBool && (_jsx(ListItemAction, { icon: !open ? AngleDown : AngleUp }, void 0));
    var handleOnClick = useCallback(function (event) {
        if (disabled)
            return;
        if (rest.onClick) {
            rest.onClick(event);
        }
    }, [disabled, rest]);
    return (_jsx("li", __assign({ ref: ref, className: classOfWrapper }, { children: _jsxs(Component, __assign({}, rest, { className: classOfContent, onClick: handleOnClick }, { children: [children, contentOfToggle] }), void 0) }), void 0));
});
ListItem.displayName = 'ListItem';
export default ListItem;
