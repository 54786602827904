var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ButlerClient } from '@marketplace/ecs/ButlerServiceClientPb';
import { CreateSeasonRequest, DailyBudget, DepositReward, Game, GetSeasonRequest, ListSeasonsRequest, Ticket, TicketPackage, UpdateSeasonRequest, UserClassificationConditions, } from '@marketplace/ecs/butler_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
import { getTimestampMsg } from '../helper';
var rpcECSClient = /** @class */ (function (_super) {
    __extends(rpcECSClient, _super);
    function rpcECSClient(config) {
        var _this = this;
        config.serviceName = 'ECS';
        _this = _super.call(this, ButlerClient, config) || this;
        return _this;
    }
    rpcECSClient.prototype.listSeasons = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, rTime, rTime;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListSeasonsRequest();
                        req.setCursor(p.cursor).setLimit(p.limit).setName(p.name);
                        if (p.startsAt) {
                            rTime = new ListSeasonsRequest.TimeRange();
                            if (p.startsAt.from)
                                rTime.setFrom(getTimestampMsg(p.startsAt.from));
                            if (p.startsAt.to)
                                rTime.setTo(getTimestampMsg(p.startsAt.to));
                            req.setStartsAt(rTime);
                        }
                        if (p.endsAt) {
                            rTime = new ListSeasonsRequest.TimeRange();
                            if (p.endsAt.from)
                                rTime.setFrom(getTimestampMsg(p.endsAt.from));
                            if (p.endsAt.to)
                                rTime.setTo(getTimestampMsg(p.endsAt.to));
                            req.setEndsAt(rTime);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listSeasons', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcECSClient.prototype.createSeason = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateSeasonRequest();
                        req.setName(p.name);
                        if (p.startsAt) {
                            req.setStartsAt(getTimestampMsg(p.startsAt));
                        }
                        if (p.endsAt) {
                            req.setEndsAt(getTimestampMsg(p.endsAt));
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('createSeason', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcECSClient.prototype.updateSeason = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, changesList, itemChange, change, change, change, change, change, tickets, change, userClassificationConditions, change, dailyBudget, change, newUserRewards, change, verifiedPhoneRewards, change, games, change, depositRewards;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateSeasonRequest();
                        req.setId(p.id);
                        changesList = [];
                        itemChange = p.changesList[0];
                        if (itemChange.name) {
                            change = new UpdateSeasonRequest.Change();
                            change.setName(itemChange.name);
                            changesList.push(change);
                        }
                        if (itemChange.startsAt) {
                            change = new UpdateSeasonRequest.Change();
                            change.setStartsAt(getTimestampMsg(itemChange.startsAt));
                            changesList.push(change);
                        }
                        if (itemChange.endsAt) {
                            change = new UpdateSeasonRequest.Change();
                            change.setEndsAt(getTimestampMsg(itemChange.endsAt));
                            changesList.push(change);
                        }
                        if (itemChange.jackpotAmount) {
                            change = new UpdateSeasonRequest.Change();
                            change.setJackpotAmount(itemChange.jackpotAmount);
                            changesList.push(change);
                        }
                        if (itemChange.tickets) {
                            change = new UpdateSeasonRequest.Change();
                            tickets = new UpdateSeasonRequest.Tickets();
                            tickets.setTicketsList(itemChange.tickets.ticketsList.map(function (t) {
                                var ticket = new Ticket();
                                ticket.setTicketType(t.ticketType);
                                ticket.setProbability(t.probability);
                                ticket.setValidityPeriod(t.validityPeriod);
                                return ticket;
                            }));
                            change.setTickets(tickets);
                            changesList.push(change);
                        }
                        if (itemChange.userClassificationConditions) {
                            change = new UpdateSeasonRequest.Change();
                            userClassificationConditions = new UserClassificationConditions();
                            userClassificationConditions.setLiveDurationSeconds(itemChange.userClassificationConditions.liveDurationSeconds);
                            userClassificationConditions.setWinningThreshold(itemChange.userClassificationConditions.winningThreshold);
                            userClassificationConditions.setLosingThreshold(itemChange.userClassificationConditions.losingThreshold);
                            change.setUserClassificationConditions(userClassificationConditions);
                            changesList.push(change);
                        }
                        if (itemChange.dailyBudget) {
                            change = new UpdateSeasonRequest.Change();
                            dailyBudget = new DailyBudget();
                            dailyBudget.setCashLimit(itemChange.dailyBudget.cashLimit);
                            dailyBudget.setGoldTicketLimit(itemChange.dailyBudget.goldTicketLimit);
                            dailyBudget.setSilverTicketLimit(itemChange.dailyBudget.silverTicketLimit);
                            change.setDailyBudget(dailyBudget);
                            changesList.push(change);
                        }
                        if (itemChange.newUserRewards) {
                            change = new UpdateSeasonRequest.Change();
                            newUserRewards = new UpdateSeasonRequest.TicketPackages();
                            newUserRewards.setPackagesList(itemChange.newUserRewards.packagesList.map(function (t) {
                                var ticket = new TicketPackage();
                                ticket.setTicketType(t.ticketType);
                                ticket.setQuantity(t.quantity);
                                ticket.setValidityPeriod(t.validityPeriod);
                                return ticket;
                            }));
                            change.setNewUserRewards(newUserRewards);
                            changesList.push(change);
                        }
                        if (itemChange.verifiedPhoneRewards) {
                            change = new UpdateSeasonRequest.Change();
                            verifiedPhoneRewards = new UpdateSeasonRequest.TicketPackages();
                            verifiedPhoneRewards.setPackagesList(itemChange.verifiedPhoneRewards.packagesList.map(function (t) {
                                var ticket = new TicketPackage();
                                ticket.setTicketType(t.ticketType);
                                ticket.setQuantity(t.quantity);
                                ticket.setValidityPeriod(t.validityPeriod);
                                return ticket;
                            }));
                            change.setVerifiedPhoneRewards(verifiedPhoneRewards);
                            changesList.push(change);
                        }
                        if (itemChange.games) {
                            change = new UpdateSeasonRequest.Change();
                            games = new UpdateSeasonRequest.Games();
                            games.setGamesList(itemChange.games.gamesList.map(function (g) {
                                var game = new Game();
                                game.setTopic(g.topic);
                                game.setEnabled(g.enabled);
                                game.setTicketPrice(g.ticketPrice);
                                return game;
                            }));
                            change.setGames(games);
                            changesList.push(change);
                        }
                        if (itemChange.depositRewards) {
                            change = new UpdateSeasonRequest.Change();
                            depositRewards = new UpdateSeasonRequest.DepositRewards();
                            depositRewards.setDepositRewardsList(itemChange.depositRewards.depositRewardsList.map(function (d) {
                                var deposit = new DepositReward();
                                deposit.setAmount(d.amount);
                                deposit.setLimit(d.limit);
                                deposit.setGoldTickets(d.goldTickets);
                                deposit.setSilverTickets(d.silverTickets);
                                return deposit;
                            }));
                            change.setDepositRewards(depositRewards);
                            changesList.push(change);
                        }
                        req.setChangesList(changesList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateSeason', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcECSClient.prototype.getSeason = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetSeasonRequest();
                        req.setSeasonId(p.seasonId);
                        return [4 /*yield*/, this.gRPCClientRequest('getSeason', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcECSClient;
}(gRPCClientAbstract));
export default rpcECSClient;
