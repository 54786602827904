import { GroupBy } from '@dceu/dailyplanet/dailyplanet_enum_pb';
import {
  Payment,
  PlayerReport,
} from '@dceu/dailyplanet/dailyplanet_overall_report_pb';

import {
  PaymentReportSortName,
  PlayerReportSortName,
  RangeType,
} from './types';

export const getRangeTypeByGroupBy = (groupBy: GroupBy) => {
  if (groupBy === GroupBy.GROUP_BY_MONTHLY) return RangeType.MONTHLY;
  return RangeType.WEEKLY;
};

export const getEmptyRowInTable = (numberEmpty: number) => {
  return Array.from(Array(numberEmpty), () => ['']);
};

export const compareSortOfPlayer = {
  [`${PlayerReportSortName.REGISTER_PLAYER}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => b.nru - a.nru,
  [`${PlayerReportSortName.REGISTER_PLAYER}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => a.nru - b.nru,

  [`${PlayerReportSortName.LOGIN_PLAYER}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => b.dlu - a.dlu,
  [`${PlayerReportSortName.LOGIN_PLAYER}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => a.dlu - b.dlu,

  [`${PlayerReportSortName.ACTIVE_PLAYER_QUANTITY}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => b.dau - a.dau,
  [`${PlayerReportSortName.ACTIVE_PLAYER_QUANTITY}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => a.dau - b.dau,

  [`${PlayerReportSortName.ACTIVE_PLAYER_PERCENT}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => {
    const aPercent = !a.dlu ? 0 : a.dau / a.dlu;
    const bPercent = !b.dlu ? 0 : b.dau / b.dlu;
    return bPercent - aPercent;
  },
  [`${PlayerReportSortName.ACTIVE_PLAYER_PERCENT}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => {
    const aPercent = !a.dlu ? 0 : a.dau / a.dlu;
    const bPercent = !b.dlu ? 0 : b.dau / b.dlu;
    return aPercent - bPercent;
  },

  [`${PlayerReportSortName.DEPOSIT_PLAYER_QUANTITY}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => b.dtu - a.dtu,
  [`${PlayerReportSortName.DEPOSIT_PLAYER_QUANTITY}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => a.dtu - b.dtu,

  [`${PlayerReportSortName.DEPOSIT_PLAYER_PERCENT}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => {
    const aPercent = !a.dlu ? 0 : a.dtu / a.dlu;
    const bPercent = !b.dlu ? 0 : b.dtu / b.dlu;
    return bPercent - aPercent;
  },
  [`${PlayerReportSortName.DEPOSIT_PLAYER_PERCENT}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => {
    const aPercent = !a.dlu ? 0 : a.dtu / a.dlu;
    const bPercent = !b.dlu ? 0 : b.dtu / b.dlu;
    return aPercent - bPercent;
  },

  [`${PlayerReportSortName.WITHDRAW_PLAYER}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => b.dwu - a.dwu,
  [`${PlayerReportSortName.WITHDRAW_PLAYER}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => a.dwu - b.dwu,

  [`${PlayerReportSortName.ARPP}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => b.arpu - a.arpu,
  [`${PlayerReportSortName.ARPP}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => a.arpu - b.arpu,

  [`${PlayerReportSortName.DEPOSIT_BY_PLAYER}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => b.topUpByUser - a.topUpByUser,
  [`${PlayerReportSortName.DEPOSIT_BY_PLAYER}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => a.topUpByUser - b.topUpByUser,

  [`${PlayerReportSortName.WITHDRAW_BY_PLAYER}_desc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => b.withdrawByUser - a.withdrawByUser,
  [`${PlayerReportSortName.WITHDRAW_BY_PLAYER}_asc`]: (
    a: PlayerReport.AsObject,
    b: PlayerReport.AsObject,
  ) => a.withdrawByUser - b.withdrawByUser,
};

export const compareSortOfPayment = {
  [`${PaymentReportSortName.DEPOSIT_BANK}_desc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => b.totalDepositBank - a.totalDepositBank,
  [`${PaymentReportSortName.DEPOSIT_BANK}_asc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => a.totalDepositBank - b.totalDepositBank,

  [`${PaymentReportSortName.DEPOSIT_TELCO}_desc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => b.totalDepositTelco - a.totalDepositTelco,
  [`${PaymentReportSortName.DEPOSIT_TELCO}_asc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => a.totalDepositTelco - b.totalDepositTelco,

  [`${PaymentReportSortName.DEPOSIT_TICKETS}_desc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => b.totalDepositTicket - a.totalDepositTicket,
  [`${PaymentReportSortName.DEPOSIT_TICKETS}_asc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => a.totalDepositTicket - b.totalDepositTicket,

  [`${PaymentReportSortName.DEPOSIT_TOTAL}_desc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => {
    const aTotal = a.totalDepositBank + a.totalDepositTelco;
    const bTotal = b.totalDepositBank + b.totalDepositTelco;
    return bTotal - aTotal;
  },
  [`${PaymentReportSortName.DEPOSIT_TOTAL}_asc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => {
    const aTotal = a.totalDepositBank + a.totalDepositTelco;
    const bTotal = b.totalDepositBank + b.totalDepositTelco;
    return aTotal - bTotal;
  },

  [`${PaymentReportSortName.WITHDRAW_BANK}_desc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => b.totalWithdrawBank - a.totalWithdrawBank,
  [`${PaymentReportSortName.WITHDRAW_BANK}_asc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => a.totalWithdrawBank - b.totalWithdrawBank,

  [`${PaymentReportSortName.WITHDRAW_TELCO}_desc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => b.totalWithdrawTelco - a.totalWithdrawTelco,
  [`${PaymentReportSortName.WITHDRAW_TELCO}_asc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => a.totalWithdrawTelco - b.totalWithdrawTelco,

  [`${PaymentReportSortName.WITHDRAW_TICKETS}_desc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => b.totalWithdrawTicket - a.totalWithdrawTicket,
  [`${PaymentReportSortName.WITHDRAW_TICKETS}_asc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => a.totalWithdrawTicket - b.totalWithdrawTicket,

  [`${PaymentReportSortName.WITHDRAW_TOTAL}_desc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => {
    const aTotal = a.totalWithdrawBank + a.totalWithdrawTelco;
    const bTotal = b.totalWithdrawBank + b.totalWithdrawTelco;
    return bTotal - aTotal;
  },
  [`${PaymentReportSortName.WITHDRAW_TOTAL}_asc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => {
    const aTotal = a.totalWithdrawBank + a.totalWithdrawTelco;
    const bTotal = b.totalWithdrawBank + b.totalWithdrawTelco;
    return aTotal - bTotal;
  },

  [`${PaymentReportSortName.CASH}_desc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => {
    const aTotal =
      a.totalDepositBank +
      a.totalDepositTelco +
      a.totalWithdrawBank +
      a.totalWithdrawTelco;
    const bTotal =
      b.totalDepositBank +
      b.totalDepositTelco +
      b.totalWithdrawBank +
      b.totalWithdrawTelco;
    return bTotal - aTotal;
  },
  [`${PaymentReportSortName.CASH}_asc`]: (
    a: Payment.AsObject,
    b: Payment.AsObject,
  ) => {
    const aTotal =
      a.totalDepositBank +
      a.totalDepositTelco +
      a.totalWithdrawBank +
      a.totalWithdrawTelco;
    const bTotal =
      b.totalDepositBank +
      b.totalDepositTelco +
      b.totalWithdrawBank +
      b.totalWithdrawTelco;
    return aTotal - bTotal;
  },
};
