import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBy } from '@dceu/dailyplanet/dailyplanet_enum_pb';
import { GetPaymentReportRequest } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';
import LiveOpsTable from '@core/uikit/build/src/components/LiveOpsTable/LiveOpsTable';
import Typography, {
  TypoVariants,
  TypoWeights,
} from '@core/oui/build/src/components/Typography';
import useFilter from '@core/uikit/build/src/hooks/useFilter';
import { getCurrentTzNumber, setTimestampPB } from '@core/okit';

import { useSaleReportOverviewAPI } from 'pages/SaleReportOverview/useSaleReportOverviewAPI';
import { useColumns } from './columns';
import { FilterConfig } from '../types';

interface IPlayerReportProps {}

const PaymentReportComp: React.FunctionComponent<IPlayerReportProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { columns } = useColumns();
  const { startDate, endDate, query } = useFilter();

  const groupBy = Number(query.get(FilterConfig.GROUP_BY)) || GroupBy.GROUP_BY_WEEKLY;

  const { paymentReportsWithSort, paymentReportsLoading, getPaymentReport } =
    useSaleReportOverviewAPI();

  useEffect(() => {
    if (!startDate || !endDate) return;
    const req = new GetPaymentReportRequest().toObject();
    req.overallFilter = {
      groupBy,
      tenantIdsList: ['GAMELOOT'],
      timezone: getCurrentTzNumber(),
      from: setTimestampPB(startDate),
      to: setTimestampPB(endDate),
    };
    getPaymentReport(req);
  }, [endDate, getPaymentReport, groupBy, startDate]);

  return (
    <>
      <div className="mb-16 layout-padding-x flex justify-between">
        <Typography variant={TypoVariants.title} weight={TypoWeights.medium}>
          {t('KPI::PAYMENT_REPORT')}
        </Typography>
      </div>
      <LiveOpsTable
        stickyColumn
        overflowX
        onlyScreen
        columns={columns}
        loading={paymentReportsLoading}
        data={paymentReportsWithSort}
      />
    </>
  );
};

export default PaymentReportComp;
