var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var SvgComponent = function (props) { return (React.createElement("svg", __assign({ width: 24, height: 24, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M9.681 5H2v14h20V5H9.681Z", fill: "#FF4322" }),
    React.createElement("path", { d: "m11.998 8.788.85 2.454h2.75l-2.225 1.516.85 2.454-2.225-1.517-2.225 1.517.85-2.454-2.225-1.516h2.75l.85-2.454Z", fill: "#FFB41F" }))); };
export default SvgComponent;
