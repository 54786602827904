var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Lieng = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_1544_3131)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.0987 5.02335L4.01274 8.10968L4.01247 8.11408L4.014 8.11773L8.92735 19.995L16.0123 16.9087L16.0138 16.9068L16.014 16.9048L16.0119 16.8993L11.0987 5.02335ZM3.24432 6.26289L10.348 3.16885C10.5885 3.06578 10.8471 3.01191 11.1088 3.01039C11.3704 3.00888 11.6297 3.05976 11.8713 3.16004C12.113 3.26031 12.3321 3.40796 12.5158 3.59427C12.6977 3.77875 12.8412 3.99744 12.938 4.23769L17.8581 16.1302L17.8593 16.133C18.0603 16.6143 18.0658 17.155 17.8745 17.6402C17.6828 18.1265 17.3088 18.5186 16.8321 18.733L16.8213 18.7378L9.68431 21.8468L9.67978 21.8487C9.4392 21.9521 9.18033 22.0063 8.91847 22.008C8.65661 22.0096 8.39707 21.9588 8.15518 21.8585C7.91329 21.7582 7.69395 21.6105 7.5101 21.424C7.32812 21.2394 7.18458 21.0205 7.08777 20.7801L2.16741 8.88591L2.1665 8.88375C1.96585 8.40252 1.96054 7.86201 2.15176 7.37689C2.34327 6.89104 2.71679 6.49909 3.19285 6.2844C3.20986 6.27673 3.22703 6.26956 3.24432 6.26289Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 4C14 3.44772 14.4477 3 15 3H16C16.5304 3 17.0391 3.21071 17.4142 3.58579C17.7893 3.96086 18 4.46957 18 5V8.5C18 9.05228 17.5523 9.5 17 9.5C16.4477 9.5 16 9.05228 16 8.5L16 5L15 5C14.4477 5 14 4.55228 14 4Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.0794 5.60945C19.2951 5.10103 19.8821 4.86372 20.3906 5.07942C20.6475 5.18842 20.8958 5.29025 21.1355 5.38498C21.1433 5.38804 21.151 5.3912 21.1586 5.39445C21.6469 5.60162 22.0328 5.99424 22.2316 6.48594C22.4304 6.97765 22.4257 7.52818 22.2186 8.01645L22.2175 8.01918L22.2175 8.01917L19.9195 13.3932C19.7023 13.901 19.1146 14.1366 18.6068 13.9195C18.099 13.7023 17.8634 13.1146 18.0805 12.6068L20.3773 7.23583C20.1283 7.13721 19.8724 7.03211 19.6095 6.92058C19.101 6.70489 18.8637 6.11787 19.0794 5.60945Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.33541 8.54045C8.64484 8.41377 8.9971 8.45011 9.27415 8.6373L12.2617 10.6559C12.5962 10.8819 12.762 11.2868 12.682 11.6826L11.9678 15.2167C11.9016 15.5444 11.6759 15.8173 11.3665 15.944C11.0571 16.0707 10.7048 16.0343 10.4278 15.8472L7.44022 13.8286C7.10571 13.6026 6.93991 13.1976 7.01988 12.8019L7.73411 9.2678C7.80035 8.94008 8.02599 8.66713 8.33541 8.54045ZM9.11231 12.5446L10.3046 13.3502L10.5896 11.9398L9.39734 11.1343L9.11231 12.5446Z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1544_3131" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" }))))); };
export default Lieng;
