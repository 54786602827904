import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subYears, } from 'date-fns';
import format from 'date-fns/format';
import { endOfDay, endOfYesterday, startOfDay, startOfYesterday, } from 'date-fns/esm';
import { PeriodType } from '../context/url_params_context/resolve_url_params';
import { localeMap } from './common/dateTimeFormat';
import { DEFAULT_LANG, KEY_I18N_LNG } from '../widgets';
export var getUTCStartOfDate = function (timestamp) {
    var date = new Date(timestamp);
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0);
};
export var getLocalStartOfDate = function (timestamp) {
    var date = new Date(timestamp);
    return date.setHours(0, 0, 0, 0);
};
// GET DISTANCE
export var getLastWeek = function () {
    var dateOfLastWeek = subDays(new Date(), 7);
    return {
        start: +startOfWeek(dateOfLastWeek, { weekStartsOn: 1 }),
        end: +endOfWeek(dateOfLastWeek, { weekStartsOn: 1 }),
    };
};
export var getThisWeek = function () {
    var date = new Date();
    return {
        start: +startOfWeek(date, { weekStartsOn: 1 }),
        end: +endOfWeek(date, { weekStartsOn: 1 }),
    };
};
export var getLastMonth = function () {
    var lastMonth = subMonths(new Date(), 1);
    return {
        start: +startOfMonth(lastMonth),
        end: +endOfMonth(lastMonth),
    };
};
export var getThisMonth = function () {
    var date = new Date();
    return {
        start: +startOfMonth(date),
        end: +endOfMonth(date),
    };
};
export var getFromThisTimeLastDay = function () {
    var dateOfLastDay = subDays(new Date(), 1);
    return {
        start: Math.floor(+startOfDay(dateOfLastDay)),
        end: Math.floor(+endOfDay(new Date())),
    };
};
export var getFromThisTimeYesterday = function () {
    var dateOfYesterday = subDays(new Date(), 1);
    return {
        start: Math.floor(+startOfDay(dateOfYesterday)),
        end: Math.floor(+endOfDay(dateOfYesterday)),
    };
};
export var getFromThisTimeLastWeek = function () {
    var dateOfLastWeek = subDays(new Date(), 7);
    return {
        start: Math.floor(+startOfDay(dateOfLastWeek)),
        end: Math.floor(+endOfDay(new Date())),
    };
};
export var getFromThisTimeLastMonth = function () {
    var dateOfLastMonth = subMonths(new Date(), 1);
    return {
        start: Math.floor(+startOfDay(dateOfLastMonth)),
        end: Math.floor(+endOfDay(new Date())),
    };
};
export var getLast7Days = function () {
    var now = new Date();
    var dateOfLast7Days = subDays(now, 7);
    return {
        start: Math.floor(+startOfDay(dateOfLast7Days)),
        end: Math.floor(+endOfDay(now)),
    };
};
export var getLast30Days = function () {
    var dateOfLast30Days = subDays(new Date(), 30);
    return {
        start: Math.floor(+startOfDay(dateOfLast30Days)),
        end: Math.floor(+endOfDay(new Date())),
    };
};
export var getLastYear = function () {
    var lastYear = subYears(new Date(), 1);
    return {
        start: +startOfYear(lastYear),
        end: +endOfYear(lastYear),
    };
};
export var getThisYear = function () {
    var date = new Date();
    return {
        start: +startOfYear(date),
        end: +endOfYear(date),
    };
};
export var getCurrentDay = function () {
    return {
        start: Math.floor(+startOfDay(new Date())),
        end: Math.floor(+endOfDay(new Date())),
    };
};
export var getYesterday = function () {
    return {
        start: Math.floor(+startOfYesterday()),
        end: Math.floor(+endOfYesterday()),
    };
};
export var getRangeByPeriod = function (period) {
    switch (period) {
        case PeriodType.Daily:
            return getCurrentDay();
        case PeriodType.Yesterday:
            return getYesterday();
        case PeriodType.Weekly:
            return getThisWeek();
        case PeriodType.Monthly:
            return getThisMonth();
        case PeriodType.ThisWeek:
            return getThisWeek();
        case PeriodType.LastWeek:
            return getLastWeek();
        case PeriodType.ThisMonth:
            return getThisMonth();
        case PeriodType.LastMonth:
            return getLastMonth();
        case PeriodType.Last7Days:
            return getLast7Days();
        case PeriodType.Last30Days:
            return getLast30Days();
        case PeriodType.LastYear:
            return getLastYear();
        case PeriodType.ThisYear:
            return getThisYear();
        default:
            throw new Error("missing get range function for period: " + period);
            return {
                start: 0,
                end: 0,
            };
    }
};
// FORMAT
export var formatTimeStampToSeconds = function (timestamp) {
    return Math.floor(timestamp / 1000);
};
export var formatTimeStampToObject = function (timestamp, nanos) {
    if (nanos === void 0) { nanos = 0; }
    return ({
        seconds: Math.floor(timestamp / 1000),
        nanos: nanos,
    });
};
var formatWithSchema = function (timestamp, schema) {
    return format(timestamp, schema, {
        locale: localeMap[localStorage.getItem(KEY_I18N_LNG) || DEFAULT_LANG],
    });
};
export var formatDate = function (timestamp) {
    return formatWithSchema(timestamp, 'dd/MM/yyyy');
};
export var formatTime = function (timestamp) {
    return formatWithSchema(timestamp, 'HH:mm');
};
export var formatDateTime = function (timestamp) {
    return formatWithSchema(timestamp, 'dd/MM/yyyy, HH:mm');
};
export var formatLongDateTime = function (timestamp) {
    return formatWithSchema(timestamp, 'dd MMM yyyy, HH:mm');
};
export var hours = function () {
    var arrTime = [];
    for (var i = 0; i < 24; i++) {
        var t = ("0" + i).slice(-2);
        arrTime.push({
            value: +t,
            name: t,
        });
    }
    return arrTime;
};
export var minutes = function () {
    var arrMinutes = [];
    for (var i = 0; i < 60; i++) {
        var t = ("0" + i).slice(-2);
        arrMinutes.push({
            value: +t,
            name: t,
        });
    }
    return arrMinutes;
};
export var timeRange = [
    {
        value: 1,
        name: 'AM',
    },
    {
        value: 2,
        name: 'PM',
    },
];
export var getTimeFromTimeRange = function (t, m) {
    var result = new Date();
    result.setHours(t, m, 0, 0);
    return result.valueOf();
};
export var getTimeRangeFromTime = function (t) {
    var _a, _b;
    var date = new Date(t);
    var result = {
        hours: 0,
        minutes: 0,
    };
    result.hours = (_a = hours().find(function (f) { return f.value === date.getHours(); })) === null || _a === void 0 ? void 0 : _a.value;
    result.minutes = (_b = minutes().find(function (f) { return f.value === date.getMinutes(); })) === null || _b === void 0 ? void 0 : _b.value;
    return result;
};
export var setHoursByTimePicker = function (d, time) {
    d.setHours(time.hours, time.minutes, 0, 0);
};
