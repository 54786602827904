var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LanguageSelector from '../LanguageSelector';
import { LiveOpsLogo } from '@core/icons';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return {
        root: {
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            height: 'calc(100% - 64px)',
            width: '100%',
            overflow: 'hidden',
        },
        wrapper: { display: 'flex', flex: '1 1 auto', overflow: 'hidden' },
        container: {
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            overflow: 'hidden',
        },
        content: {
            flex: '1 1 auto',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        footer: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        footerWrapper: (_a = {
                display: 'block',
                marginBottom: '30px',
                width: '100%',
                textAlign: 'left'
            },
            _a[theme.breakpoints.down('sm')] = {
                textAlign: 'center',
            },
            _a),
        menu: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        menuTitle: {
            flexGrow: 1,
        },
        logoWrapper: (_b = {
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
            },
            _b[theme.breakpoints.down('sm')] = {
                justifyContent: 'center',
            },
            _b),
        logo: {
            height: '40px',
            width: 'unset',
            cursor: 'pointer',
        },
        appBar: {
            backgroundColor: '#fff',
        },
    };
});
export var Footer = function () {
    var classes = useStyles();
    return (_jsx("footer", __assign({ className: classes.footerWrapper }, { children: _jsxs(Box, __assign({ className: classes.footer, whiteSpace: "nowrap" }, { children: [_jsx(Typography, __assign({ component: "div", color: "textSecondary" }, { children: "\u00A9 2021 - LiveOps" }), void 0), _jsxs(Typography, __assign({ component: "div", color: "textSecondary" }, { children: ['Copyright ', _jsx("strong", { children: "LiveOps" }, void 0), " ", ' | All rights reserved'] }), void 0)] }), void 0) }), void 0));
};
export var Navbar = function () {
    var navigate = useNavigate();
    var classes = useStyles();
    return (_jsx(AppBar, __assign({ className: classes.appBar, position: "static" }, { children: _jsxs(Toolbar, { children: [_jsx(Box, __assign({ className: classes.logoWrapper }, { children: _jsx(LiveOpsLogo, { onClick: function () { return navigate('/login'); }, className: classes.logo }, void 0) }), void 0), _jsx(LanguageSelector, {}, void 0)] }, void 0) }), void 0));
};
export var NoNavBarLayout = function (props) {
    var classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx(Navbar, {}, void 0), _jsx("div", __assign({ className: classes.root }, { children: _jsx("div", __assign({ className: classes.wrapper }, { children: _jsxs("div", __assign({ className: classes.container }, { children: [_jsx("div", __assign({ className: classes.content }, { children: props.children }), void 0), _jsx(Footer, {}, void 0)] }), void 0) }), void 0) }), void 0)] }, void 0));
};
