import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import _difference from 'lodash-es/difference';
import { isLegalPermission } from './utils';
import { PermissionContext } from '../../context/permission';
var AllowedTo = function (_a) {
    var _b = _a.perform, perform = _b === void 0 ? [] : _b, children = _a.children, _c = _a.renderYes, RenderYes = _c === void 0 ? function () { return _jsx(React.Fragment, { children: children }, void 0); } : _c, _d = _a.renderNo, RenderNo = _d === void 0 ? function () { return null; } : _d, _e = _a.logic, logic = _e === void 0 ? 'and' : _e;
    var userPermissions = useContext(PermissionContext).userPermissions;
    if (isLegalPermission(perform, userPermissions, logic)) {
        return _jsx(RenderYes, {}, void 0);
    }
    return _jsx(RenderNo, {}, void 0);
};
/*
  * @param perform
  ! In case we want it to render when changing @perform or any other PROPS change then use the function below
  =>
  const areEqual = (prevProps: AllowedToType, nextProps: AllowedToType) => {
    return prevProps.perform === nextProps.perform;
  };
*/
/*
  ! Because we only need it to render once
  * @pram: watch => Use in case when we need it to re-render when the value in watch changes
  *
*/
var areEqual = function (prevProps, nextProps) {
    return _difference(prevProps.watch, nextProps.watch).length === 0;
};
export default React.memo(AllowedTo, areEqual);
