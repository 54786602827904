//@ts-nocheck
import { createContext, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resolveUrlParams } from './resolve_url_params';
const UrlParamsContext = createContext({
    urlParams: {},
});
export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return React.createElement(Component, Object.assign({}, props, { router: { location, navigate, params } }));
    }
    return ComponentWithRouterProp;
}
const UrlParamsProvider = withRouter(({ location, children }) => {
    const refUrlParams = useRef(resolveUrlParams(location));
    const urlParams = useMemo(() => resolveUrlParams(location), [location]);
    refUrlParams.current = urlParams;
    const contextValue = useMemo(() => {
        return {
            urlParams,
        };
    }, [urlParams]);
    return React.createElement(UrlParamsContext.Provider, { children: children, value: contextValue });
});
export { UrlParamsProvider, UrlParamsContext };
