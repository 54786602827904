import { KEY_ACCESS_TOKEN } from '@core/myservice';

import { useAppSelector } from 'redux/store';
import {
  selectAuthStatus,
  selectUserModuleIdsList,
  selectUserPermission,
  selectUserPermissionList,
} from 'redux/features/auth/slice';
import { StatusEnum } from 'redux/constant';

export default function useAuth() {
  const accessToken = localStorage.getItem(KEY_ACCESS_TOKEN);

  const authStatus = useAppSelector(selectAuthStatus);
  const moduleIdsList = useAppSelector(selectUserModuleIdsList);
  const permissionList = useAppSelector(selectUserPermissionList);

  // * Use this when config ready
  const userPermissions = useAppSelector(selectUserPermission);

  const isFetchingAuth = Boolean(authStatus === StatusEnum.IDLE);
  const isAuthenticating = Boolean(
    [StatusEnum.LOADING, StatusEnum.IDLE].includes(authStatus),
  );
  const isAuthenticated = Boolean(authStatus === StatusEnum.SUCCEEDED);

  return {
    accessToken,
    moduleIdsList,
    permissionList,
    userPermissions,
    isFetchingAuth,
    isAuthenticating,
    isAuthenticated,
  };
}
