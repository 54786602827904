var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import i18next from 'i18next';
import * as grpcWeb from 'grpc-web';
import { iToast } from '@greyhole/ui-toast';
import ExportHandler from './restful/export';
import ImportHandler from './restful/import';
import UploadResource from './restful/upload';
import { rpcApokolipsClient, rpcForgeClient, rpcGothamBankClient, rpcLunaClient, rpcLodeClient, rpcMyMailClient, rpcMyIdClient, rpcMyRoleClient, rpcPepperClient, rpcTheCoffeeHouseClient, rpcDailyPlanetClient, rpcClarkClient, rpcWendyClient, rpcMissionClient, KEY_ACCESS_TOKEN, IGNORE_TOAST_CODES, rpcGiftcodeButlerClient, rpcEveClient, rpcFateBookClient, rpcTopPlayerClient, rpcNomnomClient, rpcMyKeyClient, rpcMyWalletClient, rpcEwcClient, rpcTetClient, rpcNotifierClient, rpcMylogClient, rpcSteveClient, rpcLatteClient, rpcZeusClient, rpcDeadshotClient, rpcDailyPlanetMissionClient, rpcDailyPlanetPlayerClient, rpcOverallReportClient, rpcEQSClient, rpcDailyPlanetOverall, rpcDailyPlanetRetention, rpcPotter, rpcBasClient, rpcBauCuaV2Client, rpcBcmnClient, rpcXocDiaClient, rpcSicboV2Client, rpcBaccaratClient, rpcPorungaClient, rpcECSClient, } from './grpc';
var onError = function (error, serviceName) {
    switch (error === null || error === void 0 ? void 0 : error.code) {
        case grpcWeb.StatusCode.UNAUTHENTICATED:
        case grpcWeb.StatusCode.ABORTED:
            onLogout(error.code);
            break;
    }
    if (IGNORE_TOAST_CODES.includes(error === null || error === void 0 ? void 0 : error.code))
        return;
    if (serviceName === 'CLARK' && (error === null || error === void 0 ? void 0 : error.code) === 1001)
        return iToast.info({
            title: i18next.t('Common::INFO_TITLE'),
            msg: i18next.t("Error::CLARK." + (error === null || error === void 0 ? void 0 : error.code)),
        });
    if (serviceName === 'LUNA' && (error === null || error === void 0 ? void 0 : error.code) === 14310005)
        return iToast.info({
            title: i18next.t('Common::INFO_TITLE'),
            msg: i18next.t("Error::LUNA." + (error === null || error === void 0 ? void 0 : error.code)),
        });
    var prefixError = !(error === null || error === void 0 ? void 0 : error.code) || error.code <= 16 ? 'COMMON' : serviceName;
    iToast.error({
        title: i18next.t('Common::ERROR_TITLE'),
        msg: i18next.t("Error::" + prefixError + "." + (error === null || error === void 0 ? void 0 : error.code)),
    });
};
var batmanConfig = {
    hostname: process.env.REACT_APP_BATMAN_URL || '',
    onError: onError,
};
var wayneConfig = {
    hostname: process.env.REACT_APP_WAYNE_URL || '',
    onError: onError,
};
var sanbayConfig = {
    hostname: process.env.REACT_APP_SANBAY_URL || '',
    onError: onError,
};
export var myIDClient = new rpcMyIdClient(batmanConfig);
export var myKeyClient = new rpcMyKeyClient(wayneConfig);
export var theCoffeeHouseClient = new rpcTheCoffeeHouseClient(batmanConfig);
export var myRoleClient = new rpcMyRoleClient(batmanConfig);
export var gothamBankClient = new rpcGothamBankClient(batmanConfig);
export var apokolipsClient = new rpcApokolipsClient(batmanConfig);
export var wendyClient = new rpcWendyClient(batmanConfig);
export var dailyPlanetClient = new rpcDailyPlanetClient(batmanConfig);
export var dailyPlanetMissionClient = new rpcDailyPlanetMissionClient(batmanConfig);
export var dailyPlanetPlayerClient = new rpcDailyPlanetPlayerClient(batmanConfig);
export var dailyPlanetOverall = new rpcDailyPlanetOverall(batmanConfig);
export var dailyPlanetRetention = new rpcDailyPlanetRetention(batmanConfig);
export var clarkClient = new rpcClarkClient(batmanConfig);
export var nomnomClient = new rpcNomnomClient(batmanConfig);
export var mylogClient = new rpcMylogClient(batmanConfig);
export var steveClient = new rpcSteveClient(batmanConfig);
export var zeusClient = new rpcZeusClient(batmanConfig);
export var deadshotClient = new rpcDeadshotClient(batmanConfig);
export var overallReportClient = new rpcOverallReportClient(batmanConfig);
export var missionClient = new rpcMissionClient(wayneConfig);
export var forgeClient = new rpcForgeClient(wayneConfig);
export var lunaClient = new rpcLunaClient(wayneConfig);
export var lodeClient = new rpcLodeClient(wayneConfig);
export var myMailClient = new rpcMyMailClient(wayneConfig);
export var pepperClient = new rpcPepperClient(wayneConfig);
export var latteClient = new rpcLatteClient(wayneConfig);
export var giftcodeButlerClient = new rpcGiftcodeButlerClient(wayneConfig);
export var eveClient = new rpcEveClient(wayneConfig);
export var fatebookClient = new rpcFateBookClient(wayneConfig);
export var topPlayerClient = new rpcTopPlayerClient(batmanConfig);
export var theCoffeeHouseClientByWayne = new rpcTheCoffeeHouseClient(wayneConfig);
export var myWalletClient = new rpcMyWalletClient(wayneConfig);
export var ewcClient = new rpcEwcClient(wayneConfig);
export var tetClient = new rpcTetClient(wayneConfig);
export var notifierClient = new rpcNotifierClient(wayneConfig);
export var eqsClient = new rpcEQSClient(wayneConfig);
export var ecsClient = new rpcECSClient(wayneConfig);
export var basClient = new rpcBasClient(wayneConfig);
export var baucuaV2Client = new rpcBauCuaV2Client(wayneConfig);
export var bcmnClient = new rpcBcmnClient(wayneConfig);
export var xocDiaClient = new rpcXocDiaClient(wayneConfig);
export var sicboV2Client = new rpcSicboV2Client(wayneConfig);
export var baccaratClient = new rpcBaccaratClient(wayneConfig);
export var porungaClient = new rpcPorungaClient(wayneConfig);
export var potterClient = new rpcPotter(sanbayConfig);
export var onLogout = function (code) { return __awaiter(void 0, void 0, void 0, function () {
    var href;
    return __generator(this, function (_a) {
        localStorage.removeItem(KEY_ACCESS_TOKEN);
        href = process.env.REACT_APP_PORTAL_URL + "/logout";
        code && (href += "?c=" + code);
        return [2 /*return*/, (window.location.href = href)];
    });
}); };
export var redirectToLogoutPortal = function (code) { return __awaiter(void 0, void 0, void 0, function () {
    var token, href;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = localStorage.getItem(KEY_ACCESS_TOKEN);
                if (!token) return [3 /*break*/, 2];
                return [4 /*yield*/, myIDClient.signOut()];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2:
                localStorage.removeItem(KEY_ACCESS_TOKEN);
                href = "/login";
                code && (href += "?c=" + code);
                window.location.href = href;
                return [2 /*return*/];
        }
    });
}); };
export var uploadClient = new UploadResource(process.env.REACT_APP_WAYNE_URL + "/" + process.env.REACT_APP_UPLOAD_PATH, process.env.REACT_APP_UPLOAD_SERVICE_NAME || '');
export var exportClient = new ExportHandler(process.env.REACT_APP_WAYNE_URL + "/" + process.env.REACT_APP_EXPORT_URL);
export var importClient = new ImportHandler(process.env.REACT_APP_WAYNE_URL + "/" + process.env.REACT_APP_IMPORT_URL);
