var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ButlerClient } from '@gameloot/giftcode/Giftcode_butlerServiceClientPb';
import { ListEventsRequest, CreateEventRequest, GetEventRequest, ListClaimsRequest, UpdateEventRequest, ListCodesRequest, ListTransactionsRequest, UpdateSettingsRequest, GetSettingsRequest, GeneratingRule, SpecifiedCode, ListSendingHistoriesRequest, GetStatisticRequest, SendCodesRequest, } from '@gameloot/giftcode/giftcode_butler_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcGiftcodeButlerClient = /** @class */ (function (_super) {
    __extends(rpcGiftcodeButlerClient, _super);
    function rpcGiftcodeButlerClient(config) {
        var _this = this;
        config.serviceName = 'GIFTCODE';
        _this = _super.call(this, ButlerClient, config) || this;
        return _this;
    }
    rpcGiftcodeButlerClient.prototype.listEvents = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListEventsRequest();
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        req.setStatus(p.status);
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setSeconds(p.from.seconds);
                            ts.setNanos(p.from.nanos);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setSeconds(p.to.seconds);
                            ts.setNanos(p.to.nanos);
                            req.setTo(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listEvents', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.createEvent = function (p) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var req, reqSpec, codesList, reqGeneralRule, codesList, fromTimeStamp, toTimeStamp;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        req = new CreateEventRequest();
                        req.setName(p.name);
                        p.description && req.setDescription(p.description);
                        req.setWalletType(p.walletType);
                        req.setClaimMessage(p.claimMessage);
                        if ((_a = p.specifiedCode) === null || _a === void 0 ? void 0 : _a.codesList.length) {
                            reqSpec = new SpecifiedCode();
                            codesList = ((_b = p.specifiedCode) === null || _b === void 0 ? void 0 : _b.codesList.map(function (c) {
                                var reqCode = new SpecifiedCode.Code();
                                reqCode
                                    .setValue(c.value)
                                    .setMaxClaims(c.maxClaims)
                                    .setCodesList(c.codesList);
                                return reqCode;
                            })) || [];
                            reqSpec.setCodesList(codesList);
                            req.setSpecifiedCode(reqSpec);
                        }
                        else {
                            reqGeneralRule = new GeneratingRule();
                            codesList = ((_c = p.generatingRule) === null || _c === void 0 ? void 0 : _c.codesList.map(function (c) {
                                var reqCode = new GeneratingRule.Code();
                                reqCode.setValue(c.value);
                                reqCode.setQuantity(c.quantity);
                                return reqCode;
                            })) || [];
                            reqGeneralRule.setCodesList(codesList);
                            req.setGeneratingRule(reqGeneralRule);
                        }
                        if (p.startsAt && p.endsAt) {
                            fromTimeStamp = new Timestamp();
                            fromTimeStamp.setSeconds(p.startsAt.seconds);
                            fromTimeStamp.setNanos(p.startsAt.nanos);
                            toTimeStamp = new Timestamp();
                            toTimeStamp.setSeconds(p.endsAt.seconds);
                            toTimeStamp.setNanos(p.endsAt.nanos);
                            req.setStartsAt(fromTimeStamp);
                            req.setEndsAt(toTimeStamp);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('createEvent', req)];
                    case 1: return [2 /*return*/, _d.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.listCodes = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListCodesRequest();
                        req.setCursor(p.cursor);
                        req.setLimit(p.limit);
                        req.setEventId(p.eventId);
                        return [4 /*yield*/, this.gRPCClientRequest('listCodes', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.updateEvent = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, changesList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateEventRequest();
                        req.setEventId(p.eventId);
                        changesList = [];
                        p.changesList.map(function (_a) {
                            var claimMessage = _a.claimMessage, status = _a.status, name = _a.name, description = _a.description, startsAt = _a.startsAt, endsAt = _a.endsAt;
                            if (status) {
                                var change_1 = new UpdateEventRequest.Change();
                                change_1.setStatus(status);
                                changesList.push(change_1);
                            }
                            if (claimMessage) {
                                var change_2 = new UpdateEventRequest.Change();
                                change_2.setClaimMessage(claimMessage);
                                changesList.push(change_2);
                            }
                            if (name) {
                                var change_3 = new UpdateEventRequest.Change();
                                change_3.setName(name);
                                changesList.push(change_3);
                            }
                            if (startsAt) {
                                var change_4 = new UpdateEventRequest.Change();
                                var ts = new Timestamp();
                                ts.setSeconds(startsAt.seconds);
                                ts.setNanos(startsAt.nanos);
                                change_4.setStartsAt(ts);
                                changesList.push(change_4);
                            }
                            if (endsAt) {
                                var change_5 = new UpdateEventRequest.Change();
                                var ts = new Timestamp();
                                ts.setSeconds(endsAt.seconds);
                                ts.setNanos(endsAt.nanos);
                                change_5.setEndsAt(ts);
                                changesList.push(change_5);
                            }
                            var change = new UpdateEventRequest.Change();
                            change.setDescription(description || '');
                            changesList.push(change);
                        });
                        req.setChangesList(changesList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateEvent', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.getEvent = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetEventRequest();
                        req.setEventId(p.eventId);
                        return [4 /*yield*/, this.gRPCClientRequest('getEvent', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.listClaims = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListClaimsRequest();
                        req
                            .setUserId(p.userId)
                            .setSize(p.size)
                            .setPage(p.page)
                            .setEventId(p.eventId)
                            .setCode(p.code)
                            .setSafeId(p.safeId)
                            .setUsername(p.username);
                        if (p.orderBy) {
                            req.setOrderBy(p.orderBy);
                            req.setOrder(p.order || ListClaimsRequest.Order.ORDER_UNSPECIFIED);
                        }
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setSeconds(p.from.seconds);
                            ts.setNanos(p.from.nanos);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setSeconds(p.to.seconds);
                            ts.setNanos(p.to.nanos);
                            req.setTo(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listClaims', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.listTransactions = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListTransactionsRequest();
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        req.setSessionId(p.sessionId);
                        req.setSource(p.source);
                        req.setDest(p.dest);
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setSeconds(p.from.seconds);
                            ts.setNanos(p.from.nanos);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setSeconds(p.to.seconds);
                            ts.setNanos(p.to.nanos);
                            req.setTo(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listTransactions', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.updateSettings = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, changesList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateSettingsRequest();
                        changesList = [];
                        p.changesList.map(function (_a) {
                            var allowedUnverifiedPhoneNumber = _a.allowedUnverifiedPhoneNumber;
                            if (allowedUnverifiedPhoneNumber !== null ||
                                allowedUnverifiedPhoneNumber !== undefined) {
                                var change = new UpdateSettingsRequest.Change();
                                change.setAllowedUnverifiedPhoneNumber(allowedUnverifiedPhoneNumber);
                                changesList.push(change);
                            }
                        });
                        req.setChangesList(changesList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateSettings', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.getSettings = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetSettingsRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('getSettings', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.listSendingHistories = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListSendingHistoriesRequest();
                        req
                            .setLimit(p.limit)
                            .setCursor(p.cursor)
                            .setEventId(p.eventId)
                            .setUserId(p.userId)
                            .setCode(p.code)
                            .setSendersList(p.sendersList)
                            .setSafeId(p.safeId)
                            .setUsername(p.username);
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setSeconds(p.from.seconds);
                            ts.setNanos(p.from.nanos);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setSeconds(p.to.seconds);
                            ts.setNanos(p.to.nanos);
                            req.setTo(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listSendingHistories', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.getStatistic = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetStatisticRequest();
                        req.setEventId(p.eventId);
                        return [4 /*yield*/, this.gRPCClientRequest('getStatistic', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcGiftcodeButlerClient.prototype.sendCodes = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, sendingsList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new SendCodesRequest();
                        req.setEventId(p.eventId);
                        sendingsList = [];
                        p.sendingsList.map(function (_a) {
                            var value = _a.value, userIdsList = _a.userIdsList, messageTitle = _a.messageTitle, messageBody = _a.messageBody;
                            var change = new SendCodesRequest.Sending();
                            value && change.setValue(value);
                            userIdsList && change.setUserIdsList(userIdsList);
                            messageTitle && change.setMessageTitle(messageTitle);
                            messageBody && change.setMessageBody(messageBody);
                            sendingsList.push(change);
                        });
                        req.setSendingsList(sendingsList);
                        return [4 /*yield*/, this.gRPCClientRequest('sendCodes', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcGiftcodeButlerClient;
}(gRPCClientAbstract));
export default rpcGiftcodeButlerClient;
