var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useMemo, useRef, useState, } from 'react';
import cn from 'classnames';
import Popper from '@material-ui/core/Popper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, InputBase } from '@material-ui/core';
import { AngleDown, AngleUp, Loading } from '@core/icons';
import Icon from '../Icon';
import Input from '../Input';
import InputAdornment from '../InputAdornment';
import { SelectVariants } from '../SelectInput/types';
import SelectInput from './SelectInput';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../Typography';
import styles from './styles.module.scss';
var defaultProps = {
    component: Input,
};
function calculatePopoverStyle(ref) {
    if (!ref) {
        return {};
    }
    var width = Math.max(ref.getBoundingClientRect().width, 180);
    return {
        width: width + "px",
    };
}
function checkIsSelected(value) {
    return value !== undefined && value !== null && value !== '';
}
export var DropdownAutoList = forwardRef(function (props, ref) {
    var _a;
    var _b = __assign(__assign({}, defaultProps), props), Component = _b.component, children = _b.children, onChange = _b.onChange, onClick = _b.onClick, onBlur = _b.onBlur, onFocus = _b.onFocus, onSearch = _b.onSearch, menuClassName = _b.menuClassName, className = _b.className, placeholder = _b.placeholder, placeholderSearch = _b.placeholderSearch, variant = _b.variant, loading = _b.loading, options = _b.options, displayProps = _b.display, renderOption = _b.renderOption, filterOptions = _b.filterOptions, rest = __rest(_b, ["component", "children", "onChange", "onClick", "onBlur", "onFocus", "onSearch", "menuClassName", "className", "placeholder", "placeholderSearch", "variant", "loading", "options", "display", "renderOption", "filterOptions"]);
    var wrapperRef = useRef();
    var popoverStyle = useRef(null);
    var isSelected = checkIsSelected(rest.value);
    var _c = __read(useState(null), 2), anchorEl = _c[0], setAnchorEl = _c[1];
    var _d = __read(useState(false), 2), open = _d[0], setOpen = _d[1];
    var handleOpen = useCallback(function (e) {
        if (loading || open)
            return;
        if (!open && !rest.disabled) {
            popoverStyle.current = calculatePopoverStyle(wrapperRef.current);
            setOpen(true);
        }
        if (onFocus) {
            onFocus(e);
        }
        if (onClick) {
            onClick(e);
        }
        setAnchorEl(wrapperRef.current);
    }, [rest.disabled, onClick, onFocus, open, loading]);
    var handleClose = useCallback(function (event, reason) {
        if (reason === 'toggleInput') {
            return;
        }
        if (anchorEl) {
            anchorEl.focus();
        }
        if (onBlur) {
            onBlur(event);
        }
        setAnchorEl(null);
        setOpen(false);
    }, [anchorEl, onBlur]);
    var afterInput = useMemo(function () { return (_jsx(InputAdornment, __assign({ size: rest.size, onClick: handleOpen, className: styles["addon-size-" + rest.size] }, { children: loading ? (_jsx(Icon, { className: styles.icon, component: Loading }, void 0)) : (_jsx(Icon, { className: styles.icon, component: open ? AngleUp : AngleDown }, void 0)) }), void 0)); }, [loading, open, rest.size, handleOpen]);
    var displayValue = displayProps ? displayProps(rest.value) : rest.value;
    var display = isSelected ? displayValue : placeholder;
    var classOfComponent = cn(className, styles.input, (_a = {},
        _a[styles['is-selected']] = variant === SelectVariants.selected && isSelected,
        _a[styles['is-open']] = open,
        _a));
    var optionsMemo = useMemo(function () {
        return __spreadArray([], __read(options)).sort(function (a, b) {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
    }, [options]);
    return (_jsxs(_Fragment, { children: [_jsx(Input, __assign({}, rest, { className: classOfComponent, onClick: handleOpen, component: SelectInput, afterInput: afterInput, display: display, selected: isSelected, ref: ref, readOnly: true, 
                // @ts-ignore
                wrapperRef: wrapperRef }), void 0), _jsx(Popper, __assign({ modifiers: {
                    offset: {
                        enabled: true,
                        offset: '0, 8',
                    },
                }, style: popoverStyle.current, id: "dropdown-auto-complete", open: open, anchorEl: anchorEl, placement: "bottom-start", className: styles.popper }, { children: _jsx(Autocomplete, { open: true, onClose: handleClose, classes: {
                        paper: styles.paper,
                        option: styles.option,
                        popperDisablePortal: styles.popperDisablePortal,
                    }, onChange: function (event, newValue) {
                        onChange(newValue.value);
                    }, filterOptions: filterOptions, disablePortal: true, renderTags: function () { return null; }, noOptionsText: _jsx(Box, __assign({ p: 1, display: "flex", justifyContent: "center" }, { children: _jsx(Typography, __assign({ variant: TypoVariants.body1, type: TypoTypes.sub, weight: TypoWeights.light }, { children: props.loading ? 'Loading...' : 'No data' }), void 0) }), void 0), renderOption: function (option) {
                        return renderOption ? (renderOption(option)) : (_jsx(Typography, __assign({ variant: TypoVariants.body1, type: TypoTypes.sub, weight: TypoWeights.light }, { children: option.name }), void 0));
                    }, options: optionsMemo, getOptionLabel: function (option) { return option.name; }, renderInput: function (params) { return (_jsx(InputBase, { autoFocus: true, placeholder: placeholderSearch, ref: params.InputProps.ref, inputProps: params.inputProps, className: styles.inputBase }, void 0)); } }, void 0) }), void 0)] }, void 0));
});
DropdownAutoList.displayName = 'DropdownAutoList';
export default DropdownAutoList;
