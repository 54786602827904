var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "40", height: "40", fill: "none", viewBox: "0 0 40 40" }, props),
        React.createElement("path", { fill: "currentColor", d: "M12 26c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0110 24v-8c0-.55.196-1.02.588-1.412A1.923 1.923 0 0112 14h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v8c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0128 26H12zm0-2h16v-8H12v8zm4-1a.968.968 0 00.713-.288A.967.967 0 0017 22v-1h1a.968.968 0 00.713-.288A.967.967 0 0019 20a.97.97 0 00-.287-.713A.97.97 0 0018 19h-1v-1a.97.97 0 00-.287-.713A.97.97 0 0016 17a.97.97 0 00-.713.287A.97.97 0 0015 18v1h-1a.97.97 0 00-.713.287A.97.97 0 0013 20c0 .283.096.52.287.712.192.192.43.288.713.288h1v1c0 .283.096.52.287.712.192.192.43.288.713.288zm6.5 0c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0022.5 20c-.417 0-.77.146-1.062.438A1.444 1.444 0 0021 21.5c0 .417.146.77.438 1.062.291.292.645.438 1.062.438zm3-3c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0025.5 17c-.417 0-.77.146-1.062.438A1.444 1.444 0 0024 18.5c0 .417.146.77.438 1.062.291.292.645.438 1.062.438zM12 24v-8 8z" })));
}
export default SvgComponent;
