import React from 'react';
export function useScrollToTopLeft(_a) {
    var _b = _a.current, current = _b === void 0 ? 0 : _b, _c = _a.pageSize, pageSize = _c === void 0 ? 0 : _c;
    var scrollContainerRef = React.useRef(null);
    React.useLayoutEffect(function () {
        if (!scrollContainerRef.current)
            return;
        scrollContainerRef.current.scrollTop = 0;
        scrollContainerRef.current.scrollLeft = 0;
    }, [current, pageSize]);
    return { scrollContainerRef: scrollContainerRef };
}
