var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ width: "40", height: "40", fill: "none", viewBox: "0 0 40 40", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { clipPath: "url(#clip0_1565_3812)" },
            React.createElement("circle", { cx: "20", cy: "20", r: "20", fill: "#F5F4FD" }),
            React.createElement("path", { fill: "#7462E1", d: "M18 18.02h5V29h-5V18.02zM25 29h3c1.1 0 2-.9 2-2v-9h-5v11zm3-18H13c-1.1 0-2 .9-2 2v3h19v-3c0-1.1-.9-2-2-2zM11 27c0 1.1.9 2 2 2h3V18h-5v9z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_1565_3812" },
                React.createElement("path", { fill: "#fff", d: "M0 0H40V40H0z" })))));
}
export default SvgComponent;
