import { useTranslation } from 'react-i18next';
import { GroupBy } from '@dceu/dailyplanet/dailyplanet_enum_pb';
import { Payment } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';
import { Column } from '@core/uikit';
import LiveOpsFormatNumber from '@core/uikit/build/src/components/FormatNumber';
import Typography, {
  TypoWeights,
} from '@core/oui/build/src/components/Typography';
import useFilter from '@core/uikit/build/src/hooks/useFilter';

import { useForColumns } from 'hooks/useForColumns';
import { getRangeTypeByGroupBy } from '../const';
import { FilterConfig, PaymentReportSortName } from '../types';

export const useColumns = () => {
  const { t } = useTranslation();
  const { query } = useFilter();
  const groupBy =
    Number(query.get(FilterConfig.GROUP_BY)) || GroupBy.GROUP_BY_WEEKLY;

  const { dateTimeColumnValue } = useForColumns(
    FilterConfig.VIEW_BY,
    getRangeTypeByGroupBy(groupBy),
    getRangeTypeByGroupBy(groupBy),
  );

  const columns: Column<Payment.AsObject>[] = [
    {
      Header: t('TEXT.DATE'),
      accessor: (row) => (
        <Typography weight={TypoWeights.bold}>
          {dateTimeColumnValue(row?.datetime?.seconds)}
        </Typography>
      ),
      width: 180,
      sticky: 'left',
    },
    {
      Header: t('TEXT.DEPOSIT'),
      columns: [
        {
          Header: t('TEXT.BANK'),
          accessor: (row) => {
            return <LiveOpsFormatNumber value={row.totalDepositBank} unit />;
          },
          align: 'right',
          sort: PaymentReportSortName.DEPOSIT_BANK,
        },
        {
          Header: t('TEXT.TELCO'),
          accessor: (row) => (
            <LiveOpsFormatNumber value={row.totalDepositTelco} unit />
          ),
          align: 'right',
          sort: PaymentReportSortName.DEPOSIT_TELCO,
        },
        {
          Header: t('TEXT.TICKETS'),
          accessor: (row) => (
            <LiveOpsFormatNumber value={row.totalDepositTicket} />
          ),
          align: 'right',
          sort: PaymentReportSortName.DEPOSIT_TICKETS,
        },
        {
          Header: t('TEXT.TOTAL'),
          accessor: (row) => (
            <LiveOpsFormatNumber
              value={row.totalDepositBank + row.totalDepositTelco}
              unit
            />
          ),
          align: 'right',
          sort: PaymentReportSortName.DEPOSIT_TOTAL,
        },
      ],
    },
    {
      Header: t('TEXT.WITHDRAW'),
      columns: [
        {
          Header: t('TEXT.BANK'),
          accessor: (row) => {
            return <LiveOpsFormatNumber value={row.totalWithdrawBank} unit />;
          },
          align: 'right',
          sort: PaymentReportSortName.WITHDRAW_BANK,
        },
        {
          Header: t('TEXT.TELCO'),
          accessor: (row) => (
            <LiveOpsFormatNumber value={row.totalWithdrawTelco} unit />
          ),
          align: 'right',
          sort: PaymentReportSortName.WITHDRAW_TELCO,
        },
        {
          Header: t('TEXT.TICKETS'),
          accessor: (row) => (
            <LiveOpsFormatNumber value={row.totalWithdrawTicket} />
          ),
          align: 'right',
          sort: PaymentReportSortName.WITHDRAW_TICKETS,
        },
        {
          Header: t('TEXT.TOTAL'),
          accessor: (row) => (
            <LiveOpsFormatNumber
              value={row.totalWithdrawBank + row.totalWithdrawTelco}
              unit
            />
          ),
          align: 'right',
          sort: PaymentReportSortName.WITHDRAW_TOTAL,
        },
      ],
    },
    {
      Header: t('TEXT.CASH'),
      headerTooltip: t('TEXT.CASH_TOOLTIP'),
      accessor: (row) => {
        const total =
          row.totalDepositBank +
          row.totalDepositTelco -
          (row.totalWithdrawBank + row.totalWithdrawTelco);

        return (
          <LiveOpsFormatNumber
            value={total}
            variantColor={total < 0 ? 'danger' : undefined}
            unit
          />
        );
      },
      align: 'right',
      sort: PaymentReportSortName.CASH,
    },
  ];

  return { columns };
};
