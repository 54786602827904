export var TypoTypes;
(function (TypoTypes) {
    TypoTypes["primary"] = "primary";
    TypoTypes["secondary"] = "secondary";
    TypoTypes["disabled"] = "disabled";
    TypoTypes["success"] = "success";
    TypoTypes["warning"] = "warning";
    TypoTypes["danger"] = "danger";
    TypoTypes["emphasize"] = "emphasize";
    TypoTypes["white"] = "white";
    TypoTypes["link"] = "link";
    TypoTypes["inherit"] = "inherit";
})(TypoTypes || (TypoTypes = {}));
export var TypoVariants;
(function (TypoVariants) {
    TypoVariants["display"] = "display";
    TypoVariants["headline"] = "headline";
    TypoVariants["title"] = "title";
    TypoVariants["headingAndLargeButton"] = "headingAndLargeButton";
    TypoVariants["buttonHighLevel"] = "buttonHighLevel";
    TypoVariants["buttonLowLevel"] = "buttonLowLevel";
    TypoVariants["bodyHighLevel"] = "bodyHighLevel";
    TypoVariants["bodyLowLevel"] = "bodyLowLevel";
    TypoVariants["supportTextEmphasize"] = "supportTextEmphasize";
    TypoVariants["supportTextNormal"] = "supportTextNormal";
})(TypoVariants || (TypoVariants = {}));
export var TypoWeights;
(function (TypoWeights) {
    TypoWeights["emphasize"] = "emphasize";
    TypoWeights["bold"] = "bold";
    TypoWeights["medium"] = "medium";
    TypoWeights["regular"] = "regular";
    TypoWeights["light"] = "light";
    TypoWeights["inherit"] = "inherit";
})(TypoWeights || (TypoWeights = {}));
