var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormLabel, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import classnames from 'classnames';
import _ from 'lodash';
import { Close, Search } from '@core/icons';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../../Typography';
import styles from './styles.module.scss';
var useStyles = makeStyles({
    root: {},
    inputRoot: {
        // paddingRight: '1px !important',
        // paddingBottom: '0px !important',
        minHeight: '60px !important',
        '& $input': {
            padding: '16px !important',
            minWidth: '100px !important',
        },
    },
    endAdornment: {
        top: 'revert',
    },
    clearIndicator: {
        padding: '11px',
        margin: '0px',
    },
    popupIndicator: {
        padding: '11px',
        margin: '0px',
    },
    popupIndicatorOpen: {
        transform: 'rotate(0deg)',
    },
});
var AutoCompleteMulti = React.forwardRef(function (props, ref) {
    var _a;
    var classes = useStyles();
    var _b = __read(useState(function () { return (props === null || props === void 0 ? void 0 : props.onInputChange) && _.debounce(props === null || props === void 0 ? void 0 : props.onInputChange, 1000); }), 1), debouncedCallApi = _b[0];
    var _c = useFormContext(), setValue = _c.setValue, errors = _c.formState.errors;
    var name = props.name, label = props.label, placeholder = props.placeholder, value = props.value, options = props.options, disabled = props.disabled, popupIcon = props.popupIcon, onBlur = props.onBlur, renderOption = props.renderOption, renderTags = props.renderTags, isLoading = props.isLoading, _d = props.searchNumber, searchNumber = _d === void 0 ? false : _d;
    var error = !!errors[name];
    var handleInputChange = function (event, value, reason) {
        (props === null || props === void 0 ? void 0 : props.onInputChange) && debouncedCallApi(event, value, reason);
    };
    return (_jsxs("div", __assign({ className: styles.autoComplete }, { children: [label && (_jsx(FormLabel, { children: _jsx(Typography, __assign({ variant: TypoVariants.body2, type: TypoTypes.sub, weight: TypoWeights.bold }, { children: label }), void 0) }, void 0)), _jsx(Autocomplete, { multiple: true, disableCloseOnSelect: true, fullWidth: true, autoComplete: true, autoHighlight: true, onInputChange: handleInputChange, value: value, classes: __assign(__assign({}, classes), { inputRoot: classnames(classes.inputRoot, styles.inputRoot, (_a = {},
                        _a[styles.errorNotDisable] = error && !disabled,
                        _a)) }), options: options || [], groupBy: function (_a) {
                    var category = _a.category;
                    return category || '';
                }, getOptionLabel: function (option) { return option.name; }, filterSelectedOptions: true, getOptionSelected: function (option, v) { return option.value === v.value; }, renderTags: renderTags, getOptionDisabled: function (_a) {
                    var disabled = _a.disabled;
                    return !!disabled;
                }, disabled: !!disabled, filterOptions: function (options) { return options; }, onChange: function (e, options) {
                    e.preventDefault();
                    setValue(name, options);
                    // onSync();
                }, onBlur: onBlur, popupIcon: popupIcon || _jsx(Search, {}, void 0), closeIcon: _jsx(Close, {}, void 0), loading: isLoading, renderOption: renderOption, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { 
                    // label={label}
                    type: searchNumber ? 'number' : 'string', placeholder: placeholder, inputRef: ref, InputLabelProps: {
                        shrink: true,
                    } }), void 0)); } }, void 0)] }), void 0));
});
export default AutoCompleteMulti;
