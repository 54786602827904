var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Skeleton from '@material-ui/lab/Skeleton';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import { AngleDown } from '@core/icons';
import Paper from '../../components/Paper';
import Icon from '../../components/Icon';
import Typography, { TypoVariants } from '../../components/Typography';
import { ProfileAvatar } from './ProfileAvatar';
var useStyles = makeStyles(function (theme) {
    return {
        languages: {
            border: 'none',
            width: '180px',
        },
        dropdownButton: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '150%',
            letterSpacing: 0.5,
            borderRadius: 8,
            border: '1px solid #fff',
            padding: '8px 10px 8px 16px',
            '&:hover , &.active': {
                backgroundColor: '#D6DEFF',
            },
        },
        icon: {
            width: 20,
            height: 20,
            color: '#031352',
        },
    };
});
var AvatarProfile = function (_a) {
    var userInfo = _a.userInfo, menuList = _a.menuList;
    var classes = useStyles();
    var _b = __read(useState(false), 2), open = _b[0], setOpen = _b[1];
    var anchorRef = useRef(null);
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var handleClosePopper = function (event) {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    if (!userInfo)
        return _jsx(Skeleton, {}, void 0);
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ ref: anchorRef, "aria-controls": open ? 'menu-list-grow' : undefined, "aria-haspopup": "menu", onClick: handleToggle, className: classes.dropdownButton + " " + (open ? 'active' : ''), endIcon: _jsx(AngleDown, {}, void 0) }, { children: _jsx(Box, __assign({ display: "flex", alignItems: "center" }, { children: _jsx(ProfileAvatar, { userInfo: userInfo }, void 0) }), void 0) }), void 0), _jsx(Popper, __assign({ id: open ? 'menu-list-grow' : undefined, open: open, anchorEl: anchorRef.current, transition: true, placement: "top-end", style: { zIndex: 99999 }, modifiers: {
                    offset: {
                        enabled: true,
                        offset: '0, 8',
                    },
                } }, { children: function (_a) {
                    var TransitionProps = _a.TransitionProps, placement = _a.placement;
                    return (_jsx(Grow, __assign({}, TransitionProps, { style: {
                            transformOrigin: placement === 'bottom' ? 'top-end' : 'bottom-end',
                        } }, { children: _jsx(Paper, { children: _jsx(ClickAwayListener, __assign({ onClickAway: handleClosePopper }, { children: _jsx(MenuList, __assign({ id: "menu-list-grow", onClick: handleToggle }, { children: menuList.map(function (item, index) { return (_jsx(MenuItem, __assign({ value: index, onClick: item.onClick }, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "center" }, { children: [_jsx(Box, __assign({ mr: 1 }, { children: _jsx(Icon, { className: classes.icon, component: item.icon }, void 0) }), void 0), _jsx(Typography, __assign({ variant: TypoVariants.button1 }, { children: item.name }), void 0)] }), void 0) }), index)); }) }), void 0) }), void 0) }, void 0) }), void 0));
                } }), void 0)] }, void 0));
};
export default AvatarProfile;
