import React, { memo } from 'react';
import { getDayTz, setTimezoneInTime } from '@core/okit';
import { startOfDay } from 'date-fns';
import Button from '@core/oui/build/src/components/Button';
import Typography from '@core/oui/build/src/components/Typography';
import styles from './styles.module.scss';
import { useDateRangeContext } from '../context';
var DayPicker = function (_a) {
    var idx = _a.idx, day = _a.day, dateHover = _a.dateHover, setDateHover = _a.setDateHover, minDate = _a.minDate, maxDate = _a.maxDate;
    var _b = useDateRangeContext(), dayRangeTmp = _b.dayRangeTmp, setDayRangeTmp = _b.setDayRangeTmp;
    var hasActive = function () {
        if (!day || !dayRangeTmp[0])
            return false;
        if (dayRangeTmp[1]) {
            return day >= dayRangeTmp[0] && day <= dayRangeTmp[1];
        }
        return day === dayRangeTmp[0];
    };
    var onMouseLeave = function () {
        setDateHover(0);
    };
    var onMouseEnter = function () {
        day && setDateHover(day);
    };
    var getClassName = function () {
        var classes = [];
        if (hasActive())
            return styles.dateActive;
        if (!dayRangeTmp[0] || !day || dayRangeTmp[1])
            return '';
        if (dayRangeTmp[0] > dateHover)
            return '';
        if (day <= dateHover && day >= dayRangeTmp[0])
            classes.push(styles.dateHover);
        if (day === dayRangeTmp[0])
            classes.push(styles.firstChosen);
        if (day === dateHover)
            classes.push(styles.lastChosen);
        return classes.join(' ');
    };
    var firstChosenClassName = function () {
        if (!day || !dayRangeTmp.length)
            return '';
        if (day === dayRangeTmp[0])
            return styles.firstChosen;
        return '';
    };
    var lastChosenClassName = function () {
        if (day && dayRangeTmp[1] && setEndDay(day) === dayRangeTmp[1])
            return styles.lastChosen;
        return '';
    };
    var setEndDay = function (day) {
        return day + (24 * 3600 - 1) * 1000 + 999;
    };
    var selectDate = function () {
        if (!day)
            return;
        if (!dayRangeTmp[0] || day < dayRangeTmp[0] || dayRangeTmp[1])
            return setDayRangeTmp([day, 0]);
        return setDayRangeTmp([dayRangeTmp[0], setEndDay(day)]);
    };
    return (React.createElement("span", { key: idx, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, className: getClassName() + " " + firstChosenClassName() + " " + lastChosenClassName(), onClick: selectDate },
        React.createElement(Button, { className: styles['item_date'] + " " + (!day
                ? styles['item_date_disable']
                : hasActive()
                    ? styles['item_date_active']
                    : styles['item_date_base']) + " \n        " },
            React.createElement(Typography, { className: (minDate &&
                    day < setTimezoneInTime(startOfDay(minDate).getTime())) ||
                    (maxDate && day > setTimezoneInTime(startOfDay(maxDate).getTime()))
                    ? styles['day_disable']
                    : '' }, day ? getDayTz(day) : ''))));
};
export default memo(DayPicker);
