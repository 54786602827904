//@ts-nocheck
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';
import { resolveUrlParams } from './resolve_url_params';
import { UrlParamsContext } from './url_params_context';
export function useUrlParams() {
    return useContext(UrlParamsContext);
}
export const useGetUrlParams = () => {
    const location = useLocation();
    return resolveUrlParams(location);
};
export const useUpdateUrlParams = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentParams = useGetUrlParams();
    const updateUrlParams = (updatedParams) => {
        if (!navigate || !location)
            return;
        const { pathname } = location;
        const mergedParams = Object.assign(Object.assign({}, currentParams), updatedParams);
        navigate({
            hash: location.hash,
            pathname,
            search: stringify(
            // drop any parameters that have no value
            Object.keys(mergedParams).reduce((params, key) => {
                const value = mergedParams[key];
                if (value === undefined || value === '') {
                    return params;
                }
                return Object.assign(Object.assign({}, params), { [key]: value });
            }, {}), { sort: false }),
        });
    };
    return [useGetUrlParams, updateUrlParams];
};
