import { useTranslation } from 'react-i18next';
import { Stats } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';
import { Column } from '@core/uikit/build/src/components/LiveOpsTable';
import FormatNumber from '@core/uikit/build/src/components/FormatNumber';
import Typography, {
  TypoVariants,
  TypoWeights,
} from '@core/oui/build/src/components/Typography';

import { CardFooter, CardSortName } from './const';
import { ViewBy } from '../const';

type UseColumnProps = {
  viewById: string | ViewBy;
};

export const useColumn = ({ viewById }: UseColumnProps) => {
  const { t } = useTranslation();

  const columns: Column<Stats.AsObject>[] = [
    {
      Header: t('TEXT.GAME'),
      accessor: (row) => t(`TOPIC.${row.topic}`),
      Footer: () => (
        <Typography
          variant={TypoVariants.buttonLowLevel}
          weight={TypoWeights.bold}
        >
          {t('TEXT.OVERVIEW')}
        </Typography>
      ),
      sticky: 'left',
      width: 220,
    },
    {
      Header: t('KPI::GAME_TRACKING.TOTAL_SESSION'),
      accessor: (row) => <FormatNumber value={row.sessions || 0} />,
      align: 'right',
      sort: CardSortName.SESSIONS,
    },
    {
      Header: t('KPI::GAME_TRACKING.UNIQUE_USER'),
      accessor: (row) => <FormatNumber value={row.players || 0} />,
      align: 'right',
      sort: CardSortName.PLAYERS,
    },
    {
      Header: `% ${t('TEXT.FEE')}`,
      accessor: (row) => <FormatNumber value={row.tax || 0} afterNumber="%" />,
      align: 'right',
      sort: CardSortName.TAX,
    },
    {
      Header: t('KPI::RNG.RTP_TARGET'),
      accessor: (row) => <>{t(`KPI::RTP_TARGET.${row.topic}`)}</>,
      align: 'right',
      // sort: CardSortName.RTP_TARGET,
    },
    {
      Header: t('KPI::RNG.ACTUAL_RTP'),
      accessor: (row) => (
        <FormatNumber value={row.rtp || 0} percent afterNumber="%" />
      ),
      Footer: (data: CardFooter) => {
        return <FormatNumber value={data.rtp || 0} percent afterNumber="%" />;
      },
      align: 'right',
      sort: CardSortName.RTP,
    },
    {
      Header: t('TEXT.TOTAL_BET'),
      accessor: (row) => <FormatNumber value={row.bet || 0} unit />,
      Footer: (data: CardFooter) => {
        return <FormatNumber value={data.bet || 0} unit />;
      },
      align: 'right',
      sort: CardSortName.BET,
    },
    {
      Header: t('KPI::RNG.FEE_AMOUNT'),
      accessor: (row) => <FormatNumber value={row.fee || 0} unit />,
      Footer: (data: CardFooter) => {
        return <FormatNumber value={data.fee || 0} unit />;
      },
      align: 'right',
      sort: CardSortName.FEE,
    },
    {
      Header: t('KPI::RNG.WIN_FROM_COMPETITOR'),
      accessor: (row) => <FormatNumber value={row.normalWin || 0} unit />,
      Footer: (data: CardFooter) => {
        return <FormatNumber value={data.normalWin || 0} unit />;
      },
      align: 'right',
      sort: CardSortName.NORMAL_WIN,
    },
    {
      Header: t('KPI::RNG.PLAYER_GAIN'),
      accessor: (row) => <FormatNumber value={row.playerGain || 0} unit />,
      Footer: (data: CardFooter) => {
        return <FormatNumber value={data.playerGain || 0} unit />;
      },
      align: 'right',
      sort: CardSortName.PLAYER_GAIN,
      hidden: Number(viewById) !== ViewBy.PLAYER_ONLY,
    },
    {
      Header: t('KPI::RNG.BOT_GAIN'),
      accessor: (row) => <FormatNumber value={row.botGain || 0} unit />,
      Footer: (data: CardFooter) => {
        return <FormatNumber value={data.botGain || 0} unit />;
      },
      align: 'right',
      sort: CardSortName.BOT_GAIN,
      hidden: Number(viewById) === ViewBy.PLAYER_ONLY,
    },
    {
      Header: t('KPI::RNG.HOUSE_GAIN'),
      accessor: (row) => <FormatNumber value={row.houseGain || 0} unit />,
      Footer: (data: CardFooter) => {
        return <FormatNumber value={data.houseGain || 0} unit />;
      },
      align: 'right',
      sort: CardSortName.HOUSE_GAIN,
      hidden: [ViewBy.BOT_ONLY, ViewBy.PLAYER_ONLY].includes(Number(viewById)),
    },
  ];

  return { columns };
};
