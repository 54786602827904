var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, FormProvider } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AlertCircle } from '@core/icons';
import get from 'lodash-es/get';
import Typography, { TypoTypes, TypoVariants } from '../Typography';
import { FormTypes } from './types';
// Fields
import InputTextField from './fields/TextInput';
import SelectField from './fields/Select';
import DatePickerField from './fields/DatePicker';
import TextareaField from './fields/Textarea';
import RadioField from './fields/Radio';
import CheckboxField from './fields/Checkbox';
import TypographyField from './fields/Typography';
import ComponentField from './fields/Component';
import InputNumberField from './fields/InputNumber';
import InputMoneyField from './fields/InputMoney';
import PhoneNumberField from './fields/PhoneNumber';
import PasswordField from './fields/Password';
import TimePickerField from './fields/TimePicker';
import DivideField from './fields/Divide';
import { FormAutoComplete } from './fields/AutoComplete';
import AutoCompleteMulti from './fields/AutoComplete/Multi';
import DatePickerFieldV2 from './fields/DatePickerV2';
var useStyles = makeStyles(function (theme) { return ({
    alert: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '8px',
        '& > svg > path': {
            fill: '#f53131',
        },
    },
    alertMessage: {
        marginLeft: '4px',
    },
    actions: {
        '& > button:not(:last-child)': {
            marginBottom: '16px',
        },
    },
    icon: {
        fontSize: 17,
        flexShrink: 0,
    },
}); });
var FormData = function (props) {
    var classes = useStyles();
    var actions = props.actions, fields = props.fields, onSubmit = props.onSubmit, methods = props.methods;
    var formState = methods.formState, handleSubmit = methods.handleSubmit, control = methods.control;
    var errors = formState.errors;
    var renderField = function (formFields, field) {
        switch (formFields.type) {
            case FormTypes.INPUT:
                return _jsx(InputTextField, __assign({}, field, formFields), void 0);
            case FormTypes.INPUT_NUMBER:
                return _jsx(InputNumberField, __assign({}, field, formFields), void 0);
            case FormTypes.INPUT_MONEY:
                return _jsx(InputMoneyField, __assign({}, field, formFields), void 0);
            case FormTypes.SELECT:
                return (_jsx(SelectField, __assign({}, field, formFields, { onChange: function (e) {
                        field.onChange(e);
                        if (typeof formFields.onChange === 'function') {
                            formFields.onChange(e);
                        }
                    } }), void 0));
            case FormTypes.DATE:
                return _jsx(DatePickerField, __assign({}, field, formFields), void 0);
            case FormTypes.TEXT_AREA:
                return _jsx(TextareaField, __assign({}, field, formFields), void 0);
            case FormTypes.RADIO:
                return _jsx(RadioField, __assign({}, field, formFields), void 0);
            case FormTypes.TYPOGRAPHY:
                return _jsx(TypographyField, __assign({}, field, formFields), void 0);
            case FormTypes.AUTOCOMPLETE:
                return _jsx(FormAutoComplete, __assign({}, field, formFields), void 0);
            case FormTypes.AUTOCOMPLETE_MULTI:
                return _jsx(AutoCompleteMulti, __assign({}, field, formFields), void 0);
            case FormTypes.COMPONENT:
                return _jsx(ComponentField, __assign({}, field, formFields), void 0);
            case FormTypes.CHECKBOX:
                return _jsx(CheckboxField, __assign({}, field, formFields), void 0);
            case FormTypes.PHONE_NUMBER:
                return _jsx(PhoneNumberField, __assign({}, field, formFields), void 0);
            case FormTypes.INPUT_PASSWORD:
                return _jsx(PasswordField, __assign({}, field, formFields), void 0);
            case FormTypes.TIME:
                return _jsx(TimePickerField, __assign({}, field, formFields), void 0);
            case FormTypes.DIVIDE:
                return _jsx(DivideField, __assign({}, field, formFields), void 0);
            case FormTypes.DATE_V2:
                return _jsx(DatePickerFieldV2, __assign({}, field, formFields), void 0);
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    return (_jsx(FormProvider, __assign({}, methods, { children: _jsx("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [fields
                        .filter(function (item) { return !item.hidden; })
                        .map(function (formField, index) {
                        var _a = formField.width, width = _a === void 0 ? { xs: 12 } : _a, helperText = formField.helperText;
                        var field1 = {};
                        return (_createElement(Grid, __assign({ item: true }, width, { key: index, className: formField === null || formField === void 0 ? void 0 : formField.className }), formField.type === FormTypes.COMPONENT &&
                            formField.isControllerCustom ? (_jsx(ComponentField, __assign({}, formField, { component: formField.component }, field1), void 0)) : (_jsx(Controller, { control: control, name: formField.name, defaultValue: formField.value || formField.defaultValue, rules: formField.rules, render: function (_a) {
                                var field = _a.field;
                                return (_jsxs(_Fragment, { children: [renderField(formField, field), helperText, _jsx(FieldErrorMessage, { message: get(errors, formField.name + ".message") }, void 0)] }, void 0));
                            } }, void 0))));
                    }), actions && (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx("div", __assign({ className: classes.actions }, { children: actions }), void 0) }), void 0))] }), void 0) }), void 0) }), void 0));
};
export var FieldErrorMessage = function (_a) {
    var message = _a.message;
    var classes = useStyles();
    if (message === undefined)
        return null;
    return (_jsxs("div", __assign({ className: classes.alert }, { children: [_jsx(AlertCircle, { color: "error", className: classes.icon }, void 0), _jsx("div", __assign({ className: classes.alertMessage }, { children: _jsx(Typography, __assign({ variant: TypoVariants.body2, type: TypoTypes.error }, { children: message }), void 0) }), void 0)] }), void 0));
};
export default FormData;
export * from './types';
