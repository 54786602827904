var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var AccessPoint = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd", clipPath: "url(#clip0_1739_42124)", clipRule: "evenodd" },
        React.createElement("path", { d: "M12 11a1 1 0 011 1v.01a1 1 0 11-2 0V12a1 1 0 011-1zM14.121 8.465a1 1 0 011.414 0 5 5 0 010 7.07 1 1 0 11-1.414-1.414 3 3 0 000-4.242 1 1 0 010-1.414z" }),
        React.createElement("path", { d: "M16.95 5.636a1 1 0 011.414 0 9 9 0 010 12.728 1 1 0 01-1.414-1.414 6.999 6.999 0 000-9.9 1 1 0 010-1.414zM9.875 8.465a1 1 0 010 1.414 3 3 0 000 4.242 1 1 0 11-1.414 1.414 5 5 0 010-7.07 1 1 0 011.414 0z" }),
        React.createElement("path", { d: "M7.045 5.636a1 1 0 010 1.414 7 7 0 000 9.9 1 1 0 01-1.415 1.414 9 9 0 010-12.728 1 1 0 011.415 0z" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1739_42124" },
            React.createElement("path", { fill: "#fff", d: "M0 0H24V24H0z" }))))); };
export default AccessPoint;
