import { createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { Stats } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';

import { stateLoading, stateError, stateSucceeded } from 'redux/common';
import { CommonState } from 'redux/common/types';
import { StatusEnum } from 'redux/constant';
import { RootState } from 'redux/reducers';
import {
  getRNGGamesReportThunk,
  getCommissionGamesReportThunk,
  getCardGamesReportThunk,
} from './thunks';

type ReferralState = {
  rngList: Array<Stats.AsObject>;
  rngLoading: boolean;
  commissionList: Array<Stats.AsObject>;
  commissionLoading: boolean;
  cardList: Array<Stats.AsObject>;
  cardLoading: boolean;
} & CommonState;

export const initialState: ReferralState = {
  status: StatusEnum.IDLE,
  rngList: [] as Array<Stats.AsObject>,
  rngLoading: false,
  commissionList: [] as Array<Stats.AsObject>,
  commissionLoading: false,
  cardList: [] as Array<Stats.AsObject>,
  cardLoading: false,
};

const slice = createSlice({
  name: 'saleReportOverview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isPending(
          getRNGGamesReportThunk,
          getCommissionGamesReportThunk,
          getCardGamesReportThunk,
        ),
        stateLoading,
      )
      .addMatcher(isPending(getRNGGamesReportThunk), (state) => {
        state.rngLoading = true;
      })
      .addMatcher(isPending(getCommissionGamesReportThunk), (state) => {
        state.commissionLoading = true;
      })
      .addMatcher(isPending(getCardGamesReportThunk), (state) => {
        state.cardLoading = true;
      })
      .addMatcher(
        isFulfilled(
          getRNGGamesReportThunk,
          getCommissionGamesReportThunk,
          getCardGamesReportThunk,
        ),
        (state, action) => {
          const { error } = action.payload;
          if (error) return stateError(state, error.message);
          stateSucceeded(state);
        },
      )
      .addMatcher(isFulfilled(getRNGGamesReportThunk), (state, action) => {
        const { error, response } = action.payload;
        state.rngLoading = false;
        if (error) return;

        state.rngList = response?.gameStatsList || [];
      })
      .addMatcher(
        isFulfilled(getCommissionGamesReportThunk),
        (state, action) => {
          const { error, response } = action.payload;
          state.commissionLoading = false;
          if (error) return;

          state.commissionList = response?.gameStatsList || [];
        },
      )
      .addMatcher(isFulfilled(getCardGamesReportThunk), (state, action) => {
        const { error, response } = action.payload;
        state.cardLoading = false;
        if (error) return;

        state.cardList = response?.gameStatsList || [];
      });
  },
});

export const selectStateSaleReportGameType = (state: RootState) =>
  state.saleReportGameType;
export const isStateSaleReportGameTypeLoading = (state: RootState) =>
  state.saleReportGameType.status === StatusEnum.LOADING;

export default slice.reducer;
