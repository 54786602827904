import { formatTime, getTzLocal } from '@core/okit';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DisplayGMT from './DisplayGMT';
import { Typography } from '../Typography';
import { TypoTypes, TypoVariants, TypoWeights } from '../Typography';
import styles from './styles.module.scss';
var getTimeNow = function () {
    return new Date().getTime();
};
var DisplayTimeSelected = function (_a) {
    var currentTz = _a.currentTz;
    var t = useTranslation().t;
    var _b = React.useState(getTimeNow()), time = _b[0], setTime = _b[1];
    var tzLocal = getTzLocal();
    React.useEffect(function () {
        var timeInterval = setInterval(function () {
            setTime(getTimeNow());
        }, 30000); // 30 seconds
        return function () {
            clearInterval(timeInterval);
        };
    }, []);
    return (React.createElement("div", { className: styles.displayTimeBox },
        tzLocal === currentTz && (React.createElement(Typography, { type: TypoTypes.emphasize, variant: TypoVariants.bodyLowLevel, className: styles.timeLocal }, t('TEXT.LOCAL'))),
        React.createElement(Typography, { type: TypoTypes.primary, variant: TypoVariants.bodyHighLevel, weight: TypoWeights.medium },
            formatTime(time),
            " (",
            React.createElement(DisplayGMT, { tz: currentTz }),
            ")")));
};
export default DisplayTimeSelected;
