import { useCallback, useMemo } from 'react';
import {
  GetCardGamesReportRequest,
  GetCommissionGamesReportRequest,
  GetRNGGamesReportRequest,
} from '@dceu/dailyplanet/dailyplanet_overall_report_pb';
import useFilter from '@core/uikit/build/src/hooks/useFilter';

import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getCardGamesReportThunk,
  getCommissionGamesReportThunk,
  getRNGGamesReportThunk,
} from 'redux/features/saleReportGameType/thunks';
import { selectStateSaleReportGameType } from 'redux/features/saleReportGameType/slice';
import { compareSortOfRNG } from './RNG/const';
import { compareSortOfCard } from './Card/const';
import { compareSortOfCommission } from './Commission/const';

export const useSaleReportGameTypeAPI = () => {
  const dispatch = useAppDispatch();
  const { orderBy, sortName } = useFilter();

  const {
    rngList,
    rngLoading,
    commissionList,
    commissionLoading,
    cardList,
    cardLoading,
  } = useAppSelector(selectStateSaleReportGameType);

  const getRNGGamesReport = useCallback(
    (req: GetRNGGamesReportRequest.AsObject) => {
      dispatch(getRNGGamesReportThunk(req));
    },
    [dispatch],
  );

  const getCommissionGamesReport = useCallback(
    (req: GetCommissionGamesReportRequest.AsObject) => {
      dispatch(getCommissionGamesReportThunk(req));
    },
    [dispatch],
  );

  const getCardGamesReport = useCallback(
    (req: GetCardGamesReportRequest.AsObject) => {
      dispatch(getCardGamesReportThunk(req));
    },
    [dispatch],
  );

  const rngListWithSort = useMemo(() => {
    if (!orderBy || !sortName) return rngList;
    return [...rngList].sort(compareSortOfRNG[`${sortName}_${orderBy}`]);
  }, [orderBy, rngList, sortName]);

  const commissionListWithSort = useMemo(() => {
    if (!orderBy || !sortName) return commissionList;
    return [...commissionList].sort(
      compareSortOfCommission[`${sortName}_${orderBy}`],
    );
  }, [orderBy, commissionList, sortName]);

  const cardListWithSort = useMemo(() => {
    if (!orderBy || !sortName) return cardList;
    return [...cardList].sort(compareSortOfCard[`${sortName}_${orderBy}`]);
  }, [orderBy, cardList, sortName]);

  return {
    rngList,
    rngLoading,
    rngListWithSort,
    getRNGGamesReport,
    commissionList,
    commissionLoading,
    commissionListWithSort,
    getCommissionGamesReport,
    cardList,
    cardLoading,
    cardListWithSort,
    getCardGamesReport,
  };
};
