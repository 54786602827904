import React from 'react';
import RedirectTo from './RedirectTo';
var MODULE_WITHDRAW_APPROVAL = Number(process.env.REACT_APP_MODULE_WITHDRAW_APPROVAL);
var WITHDRAW_APPROVAL_URL = process.env.REACT_APP_WITHDRAW_APPROVAL_URL;
var WITHDRAW_APPROVAL_PATH = '/zod/payment-withdraw';
var RedirectToWithdrawApproval = function (_a) {
    var moduleIdsList = _a.moduleIdsList, idSearch = _a.idSearch, searchType = _a.searchType, children = _a.children;
    return (React.createElement(RedirectTo, { moduleIdsList: moduleIdsList, currentModule: MODULE_WITHDRAW_APPROVAL, origin: WITHDRAW_APPROVAL_URL, pathname: WITHDRAW_APPROVAL_PATH + "?searchType=" + searchType + "&s=" + idSearch }, children));
};
export default RedirectToWithdrawApproval;
