var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Typography, { TypoVariants, TypoWeights } from '../../Typography';
import { Checkbox } from '../../Checkbox';
import CheckboxGroup from '../../CheckboxGroup';
var CheckboxField = function (_a) {
    var onChange = _a.onChange, options = _a.options, value = _a.value, restProps = __rest(_a, ["onChange", "options", "value"]);
    var handleCheck = function (checkedId) {
        if (typeof checkedId === 'number') {
            console.warn('Value of checkbox should be string');
            return;
        }
        var values = value;
        var newValues = (values === null || values === void 0 ? void 0 : values.includes(checkedId))
            ? values === null || values === void 0 ? void 0 : values.filter(function (id) { return id !== checkedId; })
            : __spreadArray(__spreadArray([], __read((values !== null && values !== void 0 ? values : []))), [checkedId]);
        return newValues;
    };
    var handleChange = function (e) {
        onChange(handleCheck(e.target.value));
    };
    return (_jsx(CheckboxGroup
    // @ts-ignore
    , __assign({ 
        // @ts-ignore
        onChange: handleChange, selected: value }, restProps, { children: options.map(function (option) { return (_jsx(Checkbox, __assign({ value: option.value.toString() }, { children: _jsx(Typography, __assign({ variant: TypoVariants.body1, weight: TypoWeights.bold }, { children: option.name }), void 0) }), void 0)); }) }), void 0));
};
export default CheckboxField;
