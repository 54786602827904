var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormLabel, Grid } from '@material-ui/core';
import { Radio } from '../../Radio';
import { MinorTooltip } from '../../Tooltip';
import RadioGroup from '../../RadioGroup';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../Typography';
var RadioField = function (_a) {
    var options = _a.options, value = _a.value, label = _a.label, onChange = _a.onChange, description = _a.description, grid = _a.grid, restProps = __rest(_a, ["options", "value", "label", "onChange", "description", "grid"]);
    return (_jsxs(Box, __assign({ my: 1 }, { children: [label && (_jsx(FormLabel, { children: _jsx(Typography, __assign({ variant: TypoVariants.body2, type: TypoTypes.sub, weight: TypoWeights.bold }, { children: _jsx(MinorTooltip, __assign({ description: description }, { children: label }), void 0) }), void 0) }, void 0)), _jsx(RadioGroup, __assign({ onChange: onChange, selected: value }, restProps, { children: _jsx(Grid, __assign({ container: true, columnSpacing: { xs: 1, sm: 2, md: 3 }, spacing: 2 }, grid === null || grid === void 0 ? void 0 : grid.containerProps, { children: options.map(function (item) { return (_jsx(Grid, __assign({ item: true, xs: 12 }, grid === null || grid === void 0 ? void 0 : grid.itemProps, { children: _jsx(Radio, __assign({ value: item.value }, { children: _jsx(Typography, __assign({ variant: TypoVariants.body1, weight: TypoWeights.bold }, { children: item.name }), void 0) }), void 0) }), item.name)); }) }), void 0) }), void 0)] }), void 0));
};
export default RadioField;
