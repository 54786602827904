var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { GetListRecordRequest, GetTableRequest, CreateRecordRequest, UpdateRecordRequest, FieldValue, FieldType, BooleanFieldValue, StringFieldValue, IntegerFieldValue, LongFieldValue, DecimalFieldValue, DateFieldValue, ReferenceFieldValue, ResourceFieldValue, FileFieldValue, GetMappingRequest, Sort, } from '@greyhole/forge/forge_pb';
import { ButlerClient } from '@greyhole/forge/ForgeServiceClientPb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcForgeClient = /** @class */ (function (_super) {
    __extends(rpcForgeClient, _super);
    function rpcForgeClient(config) {
        var _this = this;
        config.serviceName = 'FORGE';
        _this = _super.call(this, ButlerClient, config) || this;
        return _this;
    }
    rpcForgeClient.prototype.setResourceValue = function (resource) {
        var resourceFieldValue = new ResourceFieldValue();
        if (!!resource) {
            var filesUploaded = (resource === null || resource === void 0 ? void 0 : resource.uploaded) || (resource === null || resource === void 0 ? void 0 : resource.filesList) || [];
            resourceFieldValue.setValue(resource.value || 0);
            resourceFieldValue.setType(resource.type || 0);
            resourceFieldValue.setBaseDirectoryName(resource.baseDirectoryName || '');
            var filesList = filesUploaded.map(function (file) {
                var fileFieldValue = new FileFieldValue();
                fileFieldValue.setName(file.fileName || file.name);
                fileFieldValue.setSize(file.size);
                fileFieldValue.setPath(file.path);
                fileFieldValue.setType(file.type);
                return fileFieldValue;
            });
            resourceFieldValue.setFilesList(filesList);
        }
        return resourceFieldValue;
    };
    rpcForgeClient.prototype.setFieldValue = function (field) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var fieldId = field.fieldId, fieldName = field.fieldName, type = field.type;
        var fieldValue = new FieldValue();
        fieldValue.setFieldId(fieldId);
        fieldValue.setType(type);
        if (!!fieldName)
            fieldValue.setFieldName(fieldName);
        try {
            switch (field.type) {
                case FieldType.BOOLEAN:
                    var booleanFieldValue = new BooleanFieldValue();
                    booleanFieldValue.setValue(((_a = field.booleanField) === null || _a === void 0 ? void 0 : _a.value) || false);
                    fieldValue.setBooleanField(booleanFieldValue);
                    break;
                case FieldType.TEXT:
                case FieldType.LONG_TEXT:
                    var stringFieldValue = new StringFieldValue();
                    stringFieldValue.setValue(((_b = field.stringField) === null || _b === void 0 ? void 0 : _b.value) || '');
                    fieldValue.setStringField(stringFieldValue);
                    break;
                case FieldType.INTEGER:
                    var integerFieldValue = new IntegerFieldValue();
                    integerFieldValue.setValue(((_c = field.integerField) === null || _c === void 0 ? void 0 : _c.value) || 0);
                    fieldValue.setIntegerField(integerFieldValue);
                    break;
                case FieldType.LONG:
                    var longFieldValue = new LongFieldValue();
                    longFieldValue.setValue(((_d = field.longField) === null || _d === void 0 ? void 0 : _d.value) || 0);
                    fieldValue.setLongField(longFieldValue);
                    break;
                case FieldType.DECIMAL:
                    var decimalFieldValue = new DecimalFieldValue();
                    decimalFieldValue.setValue(((_e = field.decimalField) === null || _e === void 0 ? void 0 : _e.value) || 0);
                    fieldValue.setDecimalField(decimalFieldValue);
                    break;
                case FieldType.DATE: {
                    var dateFieldValue = new DateFieldValue();
                    var timestamp = new Timestamp();
                    timestamp.setSeconds(((_g = (_f = field.dateField) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.seconds) || 0);
                    dateFieldValue.setValue(timestamp);
                    fieldValue.setDateField(dateFieldValue);
                    break;
                }
                case FieldType.RESOURCE:
                    var resourceFieldValue = this.setResourceValue(__assign({}, field.resourceField));
                    fieldValue.setResourceField(resourceFieldValue);
                    break;
                case FieldType.LINK_TO_RECORD: {
                    var referenceFieldValue = new ReferenceFieldValue();
                    var value = (_h = field.referenceField) === null || _h === void 0 ? void 0 : _h.recordIdsList;
                    if (value === null || value === void 0 ? void 0 : value.length) {
                        referenceFieldValue.setRecordIdsList(value);
                    }
                    fieldValue.setReferenceField(referenceFieldValue);
                    break;
                }
                default:
                    break;
            }
        }
        catch (e) {
            console.log(e);
        }
        return fieldValue;
    };
    rpcForgeClient.prototype.getTable = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetTableRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getTable', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcForgeClient.prototype.getMapping = function () {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetMappingRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('getMapping', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcForgeClient.prototype.createRecord = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, fList;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateRecordRequest();
                        fList = [];
                        p.fieldsList.map(function (field) {
                            fList.push(_this.setFieldValue(field));
                        });
                        // req.setId(p.id);
                        req.setTableId(p.tableId);
                        req.setFieldsList(fList);
                        return [4 /*yield*/, this.gRPCClientRequest('createRecord', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcForgeClient.prototype.updateRecord = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, fList;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateRecordRequest();
                        fList = [];
                        p.fieldsList.map(function (field) {
                            fList.push(_this.setFieldValue(field));
                        });
                        req.setId(p.id);
                        req.setTableId(p.tableId);
                        req.setFieldsList(fList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateRecord', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcForgeClient.prototype.getListRecord = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, s, s, fs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetListRecordRequest();
                        req.setTableId(p.tableId);
                        req.setViewId(p.viewId);
                        req.setOffset(p.offset);
                        req.setLimit(p.limit);
                        if (p.sort) {
                            s = new Sort();
                            s.setFieldId(p.sort.fieldId);
                            s.setOrder(p.sort.order);
                            req.setSort(s);
                        }
                        if (p.search) {
                            s = new GetListRecordRequest.Search();
                            s.setFieldIdsList(p.search.fieldIdsList);
                            s.setValue(p.search.value);
                            req.setSearch(s);
                        }
                        fs = [];
                        if (p.filterList.length) {
                            fs = p.filterList.map(function (item) {
                                var f = new GetListRecordRequest.Filter();
                                f.setFieldId(item.fieldId);
                                f.setType(item.type);
                                if (item.integerField) {
                                    var i = new GetListRecordRequest.IntegerField();
                                    i.setValueList(item.integerField.valueList);
                                    f.setIntegerField(i);
                                }
                                if (item.stringField) {
                                    var i = new GetListRecordRequest.StringField();
                                    i.setValueList(item.stringField.valueList);
                                    f.setStringField(i);
                                }
                                return f;
                            });
                        }
                        req.setFilterList(fs);
                        return [4 /*yield*/, this.gRPCClientRequest('getListRecord', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcForgeClient;
}(gRPCClientAbstract));
export default rpcForgeClient;
