var ListRecordName;
(function (ListRecordName) {
    ListRecordName["UNSPECIFIED"] = "UNSPECIFIED";
})(ListRecordName || (ListRecordName = {}));
var UpdateRecordName;
(function (UpdateRecordName) {
    UpdateRecordName["UNSPECIFIED"] = "UNSPECIFIED";
})(UpdateRecordName || (UpdateRecordName = {}));
// ************ For Maubinh
(function (ListRecordName) {
    ListRecordName["GetListMauBinhSetting"] = "getListMauBinhSetting";
    ListRecordName["GetListMauBinhScoreExtend"] = "getListMauBinhScoreExtend";
    ListRecordName["GetListMauBinhNaturalRate"] = "getListMauBinhNaturalRate";
    ListRecordName["GetListMauBinhCombineRate"] = "getListMauBinhCombineRate";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["UpdateMauBinhSetting"] = "updateMauBinhSetting";
    UpdateRecordName["UpdateMauBinhScoreExtend"] = "updateMauBinhScoreExtend";
    UpdateRecordName["UpdateMauBinhNaturalRate"] = "updateMauBinhNaturalRate";
    UpdateRecordName["UpdateMauBinhCombineRate"] = "updateMauBinhCombineRate";
})(UpdateRecordName || (UpdateRecordName = {}));
// ************ /For Maubinh
// ************ For Phom
(function (ListRecordName) {
    ListRecordName["GetListPhomSetting"] = "getListPhomSetting";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["UpdatePhomSetting"] = "updatePhomSetting";
})(UpdateRecordName || (UpdateRecordName = {}));
// ************ /For Phom
// ************ For TLMN
(function (ListRecordName) {
    ListRecordName["GetListTLMNSetting"] = "getListTLMNSetting";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["UpdateTLMNSetting"] = "updateTLMNSetting";
})(UpdateRecordName || (UpdateRecordName = {}));
// ************ /For Phom
// ************ For Poker
(function (ListRecordName) {
    ListRecordName["GetListPokerSetting"] = "getListPokerSetting";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["UpdatePokerSetting"] = "updatePokerSetting";
})(UpdateRecordName || (UpdateRecordName = {}));
// ************ /For Poker
// ************ For Xoc Dia
(function (ListRecordName) {
    ListRecordName["GetListXocDiaStageTime"] = "getListXocDiaStageTime";
    ListRecordName["GetListXocDiaBetLimit"] = "getListXocDiaBetLimit";
    ListRecordName["GetListXocDiaRatio"] = "getListXocDiaRatio";
    ListRecordName["GetListXocDiaSetting"] = "getListXocDiaSetting";
})(ListRecordName || (ListRecordName = {}));
// [UPDATE] For Xoc Dia
(function (UpdateRecordName) {
    UpdateRecordName["UpdateXocDiaStageTime"] = "updateXocDiaStageTime";
    UpdateRecordName["UpdateXocDiaBetLimit"] = "updateXocDiaBetLimit";
    UpdateRecordName["UpdateXocDiaRatio"] = "updateXocDiaRatio";
    UpdateRecordName["UpdateXocDiaSetting"] = "updateXocDiaSetting";
})(UpdateRecordName || (UpdateRecordName = {}));
// ************ /For Xoc Dia
// ************ For BCF
(function (ListRecordName) {
    ListRecordName["GetListBCFWinRate"] = "getListBCFWinRate";
    ListRecordName["GetListBCFStageTime"] = "getListBCFStageTime";
    ListRecordName["GetListBCFBetLimit"] = "getListBCFBetLimit";
    ListRecordName["GetListBCFSetting"] = "getListBCFSetting";
})(ListRecordName || (ListRecordName = {}));
// [UPDATE] For BCF
(function (UpdateRecordName) {
    UpdateRecordName["UpdateBCFWinRate"] = "updateBCFWinRate";
    UpdateRecordName["UpdateBCFStageTime"] = "updateBCFStageTime";
    UpdateRecordName["UpdateBCFBetLimit"] = "updateBCFBetLimit";
    UpdateRecordName["UpdateBCFSetting"] = "updateBCFSetting";
})(UpdateRecordName || (UpdateRecordName = {}));
// ************ /For BCF
// ************ For Sicbo
(function (ListRecordName) {
    ListRecordName["GetListSicboRatio"] = "getListSicboRatio";
    ListRecordName["GetListSicboStageTime"] = "getListSicboStageTime";
    ListRecordName["GetListSicboBetLimit"] = "getListSicboBetLimit";
    ListRecordName["GetListSicboSetting"] = "getListSicboSetting";
})(ListRecordName || (ListRecordName = {}));
// [UPDATE] For Sicbo
(function (UpdateRecordName) {
    UpdateRecordName["UpdateSicboRatio"] = "updateSicboRatio";
    UpdateRecordName["UpdateSicboStageTime"] = "updateSicboStageTime";
    UpdateRecordName["UpdateSicboBetLimit"] = "updateSicboBetLimit";
    UpdateRecordName["UpdateSicboSetting"] = "updateSicboSetting";
})(UpdateRecordName || (UpdateRecordName = {}));
// ************ /For Sicbo
/* ---------------------------- LODE --------------------------- */
(function (ListRecordName) {
    ListRecordName["GetListRegion"] = "getListRegion";
    ListRecordName["GetListSetting"] = "getListSetting";
    ListRecordName["GetListHeadline"] = "getListHeadline";
    ListRecordName["GetListCategoryMapping"] = "getListCategoryMapping";
    ListRecordName["GetListCity"] = "getListCity";
    ListRecordName["GetListGuideline"] = "getListGuideline";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["UpdateRegion"] = "updateRegion";
    UpdateRecordName["UpdateSetting"] = "updateSetting";
    UpdateRecordName["UpdateHeadline"] = "updateHeadline";
    UpdateRecordName["UpdateCategoryMapping"] = "updateCategoryMapping";
    UpdateRecordName["UpdateCity"] = "updateCity";
    UpdateRecordName["UpdateGuideline"] = "updateGuideline";
})(UpdateRecordName || (UpdateRecordName = {}));
/* ---------------------------- Than Tai --------------------------- */
(function (ListRecordName) {
    ListRecordName["GetListGOFNormalDistribution"] = "getListGOFNormalDistribution";
    ListRecordName["GetListGOFFreeDistribution"] = "getListGOFFreeDistribution";
    ListRecordName["GetListGOFBonusGameBox"] = "getListGOFBonusGameBox";
    ListRecordName["GetListGOFBonusGameMultiply"] = "getListGOFBonusGameMultiply";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["UpdateGOFNormalDistribution"] = "updateGOFNormalDistribution";
    UpdateRecordName["UpdateGOFFreeDistribution"] = "updateGOFFreeDistribution";
    UpdateRecordName["UpdateGOFBonusGameBox"] = "updateGOFBonusGameBox";
    UpdateRecordName["UpdateGOFBonusGameMultiply"] = "updateGOFBonusGameMultiply";
})(UpdateRecordName || (UpdateRecordName = {}));
/* ----------------------------  Chien Than --------------------------- */
(function (ListRecordName) {
    ListRecordName["GetListGOWNormalDistribution"] = "getListGOWNormalDistribution";
    ListRecordName["GetListGOWFreeDistribution"] = "getListGOWFreeDistribution";
    ListRecordName["GetListGOWBonusDistribution"] = "getListGOWBonusDistribution";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["UpdateGOWNormalDistribution"] = "updateGOWNormalDistribution";
    UpdateRecordName["UpdateGOWFreeDistribution"] = "updateGOWFreeDistribution";
    UpdateRecordName["UpdateGOWBonusDistribution"] = "updateGOWBonusDistribution";
})(UpdateRecordName || (UpdateRecordName = {}));
/* ---------------------------- LandingPageConfig --------------------------- */
(function (ListRecordName) {
    ListRecordName["getListLandingPage"] = "getListLandingPage";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["updateLandingPage"] = "updateLandingPage";
})(UpdateRecordName || (UpdateRecordName = {}));
/* ------------------------------ PaymentImage ------------------------------ */
(function (ListRecordName) {
    ListRecordName["getListPaymentGuideImage"] = "getListPaymentGuideImage";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["updatePaymentGuideImage"] = "updatePaymentGuideImage";
})(UpdateRecordName || (UpdateRecordName = {}));
/* --------------------------- Foundation - Tinder -------------------------- */
(function (ListRecordName) {
    ListRecordName["getListTinderSetting"] = "getListTinderSetting";
    ListRecordName["getListTinderScore"] = "getListTinderScore";
    ListRecordName["getListTinderResource"] = "getListTinderResource";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["updateTinderSetting"] = "updateTinderSetting";
    UpdateRecordName["updateTinderScore"] = "updateTinderScore";
    UpdateRecordName["updateTinderResource"] = "updateTinderResource";
})(UpdateRecordName || (UpdateRecordName = {}));
/* --------------------------- Foundation - Whatsapp -------------------------- */
(function (ListRecordName) {
    ListRecordName["getListWhatsappApplication"] = "getListWhatsappApplication";
    ListRecordName["getListWhatsappEmoticon"] = "getListWhatsappEmoticon";
    ListRecordName["getListWhatsappEmoticonSet"] = "getListWhatsappEmoticonSet";
    ListRecordName["getListWhatsappSample"] = "getListWhatsappSample";
    ListRecordName["getListWhatsappProfanity"] = "getListWhatsappProfanity";
    ListRecordName["getListWhatsappUiType"] = "getListWhatsappUiType";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["updateWhatsappApplication"] = "updateWhatsappApplication";
    UpdateRecordName["updateWhatsappEmoticon"] = "updateWhatsappEmoticon";
    UpdateRecordName["updateWhatsappEmoticonSet"] = "updateWhatsappEmoticonSet";
    UpdateRecordName["updateWhatsappSample"] = "updateWhatsappSample";
    UpdateRecordName["updateWhatsappProfanity"] = "updateWhatsappProfanity";
})(UpdateRecordName || (UpdateRecordName = {}));
/* ------------------------- Foundation - MyAccount ------------------------- */
(function (ListRecordName) {
    ListRecordName["getListMyAccountBase"] = "getListMyAccountBase";
    ListRecordName["getListMyAccountFrame"] = "getListMyAccountFrame";
    ListRecordName["getListMyAccountFrameCategory"] = "getListMyAccountFrameCategory";
    ListRecordName["getListMyAccountLevel"] = "getListMyAccountLevel";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["updateMyAccountBase"] = "updateMyAccountBase";
    UpdateRecordName["updateMyAccountFrame"] = "updateMyAccountFrame";
    UpdateRecordName["updateMyAccountFrameCategory"] = "updateMyAccountFrameCategory";
    UpdateRecordName["updateMyAccountLevel"] = "updateMyAccountLevel";
})(UpdateRecordName || (UpdateRecordName = {}));
var CreateRecordName;
(function (CreateRecordName) {
    CreateRecordName["createMyAccountFrameCategory"] = "createMyAccountFrameCategory";
})(CreateRecordName || (CreateRecordName = {}));
/* --------------------------- Foundation - Potter -------------------------- */
(function (ListRecordName) {
    ListRecordName["getListJackpot"] = "getListJackpot";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["updateJackpot"] = "updateJackpot";
})(UpdateRecordName || (UpdateRecordName = {}));
/* --------------------------- Banner Management - Dev Only -------------------------- */
(function (ListRecordName) {
    ListRecordName["GetListDevOnly"] = "getListDevOnly";
})(ListRecordName || (ListRecordName = {}));
var DeleteRecordName;
(function (DeleteRecordName) {
    DeleteRecordName["DeleteListDevOnly"] = "deleteListDevOnly";
})(DeleteRecordName || (DeleteRecordName = {}));
/* --------------------------- Maintenance -------------------------- */
(function (ListRecordName) {
    ListRecordName["GetListMaintenance"] = "getListMaintenance";
    ListRecordName["GetListAppUpdate"] = "getListAppUpdate";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["UpdateMaintenance"] = "updateMaintenance";
    UpdateRecordName["UpdateAppUpdate"] = "updateAppUpdate";
})(UpdateRecordName || (UpdateRecordName = {}));
(function (CreateRecordName) {
    CreateRecordName["CreateMaintenance"] = "createMaintenance";
    CreateRecordName["CreateAppUpdate"] = "createAppUpdate";
})(CreateRecordName || (CreateRecordName = {}));
/* --------------------------- Foundation - Potter -------------------------- */
(function (ListRecordName) {
    ListRecordName["getListRTP"] = "getListRTP";
})(ListRecordName || (ListRecordName = {}));
(function (UpdateRecordName) {
    UpdateRecordName["updateListRTP"] = "updateListRTP";
})(UpdateRecordName || (UpdateRecordName = {}));
export { ListRecordName, UpdateRecordName, CreateRecordName, DeleteRecordName };
