import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PeriodType } from '@core/okit/build/context/url_params_context/resolve_url_params';
import { getRangeByPeriod } from '@core/okit/build/utils/date';
import { PERIOD_TYPES } from './const';
import styles from './styles.module.scss';
import { useDateRangeContext } from '../context';
var QuickSelectOfDate = function (_a) {
    var _b = _a.periodOptions, periodOptions = _b === void 0 ? PERIOD_TYPES : _b;
    var t = useTranslation().t;
    var _c = useDateRangeContext(), setDayRangeTmp = _c.setDayRangeTmp, dayRangeTmp = _c.dayRangeTmp;
    var _d = useState(PeriodType.Default), typeActive = _d[0], setTypeActive = _d[1];
    var setDateRange = function (type) {
        var d = getRangeByPeriod(type);
        setDayRangeTmp([d.start, d.end]);
    };
    var onDateRangeChange = function (e) {
        setDateRange(e.target.getAttribute('data-period-type'));
    };
    useEffect(function () {
        if (!dayRangeTmp[0] || !dayRangeTmp[1] || !periodOptions.length)
            return setTypeActive(PeriodType.Default);
        for (var i = 0; i < periodOptions.length; i++) {
            var type = periodOptions[i].type;
            var d = getRangeByPeriod(type);
            if (d.start === dayRangeTmp[0] && d.end === dayRangeTmp[1]) {
                return setTypeActive(type);
            }
        }
        setTypeActive(PeriodType.Default);
    }, [dayRangeTmp[0], dayRangeTmp[1]]); //eslint-disable-line
    return (React.createElement("ul", { className: styles.dateRangeActions }, periodOptions.map(function (_a) {
        var type = _a.type, key = _a.key;
        return (React.createElement("li", { className: cn(typeActive === type && styles.active), onClick: onDateRangeChange, "data-period-type": type, key: key }, t(key)));
    })));
};
export default QuickSelectOfDate;
