var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Giftcode = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M14 2a1 1 0 011 1v4h4a1 1 0 110 2h-4a2 2 0 01-2-2V3a1 1 0 011-1z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M7 4a1 1 0 00-1 1v14a1 1 0 001 1h10a1 1 0 001-1V8.414L13.586 4H7zM4.879 2.879A3 3 0 017 2h7a1 1 0 01.707.293l5 5A1 1 0 0120 8v11a3 3 0 01-3 3H7a3 3 0 01-3-3V5a3 3 0 01.879-2.121z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M7 13a1 1 0 011-1h1a1 1 0 011 1v3a1 1 0 01-1 1H8a1 1 0 01-1-1v-3zM12 12a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zM14 13a1 1 0 011-1h1a1 1 0 011 1v3a1 1 0 01-1 1h-1a1 1 0 01-1-1v-3z", clipRule: "evenodd" })));
};
export default Giftcode;
