var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
export function useHighlightRows(data, state) {
    var highlightRows = (state !== null && state !== void 0 ? state : {}).highlightRows;
    var _a = __read(useState(highlightRows || {}), 2), highlight = _a[0], setHighlight = _a[1];
    useEffect(function () {
        if (!highlightRows)
            return;
        setHighlight(data.reduce(function (acc, _, index) {
            var _a;
            return Object.assign(acc, (_a = {}, _a[index] = highlightRows[index] || false, _a));
        }, {}));
    }, [data, highlightRows]);
    return { highlight: highlight };
}
