var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import React, { useMemo } from 'react';
import TableRow from '../../TableRow';
import FooterCell from './FooterCell';
import Typography from '../../Typography';
import styles from '../styles.module.scss';
var FooterRow = function (_a) {
    var stickyColumn = _a.stickyColumn, hasCheckboxes = _a.hasCheckboxes, classOfCell = _a.classOfCell, header = _a.header, data = _a.data, className = _a.className;
    var checkboxStyle = useMemo(function () {
        return { left: 0, zIndex: 10 };
    }, []);
    var checkboxInFooter = useMemo(function () {
        if (!hasCheckboxes)
            return _jsx(_Fragment, {}, void 0);
        return (_jsx(Typography, { variant: "body2", type: "placeholder", component: "td", width: 56, className: classOfCell, style: checkboxStyle }, void 0));
    }, [hasCheckboxes]);
    return (_jsxs(TableRow, __assign({ className: className }, { children: [checkboxInFooter, header.map(function (_a, columnIdx) {
                var _b;
                var Footer = _a.Footer, width = _a.width, sticky = _a.sticky, align = _a.align;
                return (_jsx(FooterCell, { stickyColumn: stickyColumn, hasCheckboxes: hasCheckboxes, header: header, data: data, classOfCell: cn(classOfCell, (_b = {},
                        _b[styles["align-" + align]] = !!align,
                        _b)), 
                    //
                    Footer: Footer, width: width, sticky: sticky, columnIdx: columnIdx }, columnIdx));
            })] }), "footer"));
};
export default React.memo(FooterRow);
