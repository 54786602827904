export const DEFAULT_LANG = 'en';
export const KEY_I18N = 'Lng';
export const KEY_I18N_LNG = 'i18nextLng';
export const localStorageLng = localStorage.getItem(KEY_I18N_LNG);
export const getLngInitial = (lngs) => {
    if (!localStorageLng)
        return DEFAULT_LANG;
    if (lngs.includes(localStorageLng)) {
        return localStorageLng;
    }
    return DEFAULT_LANG;
};
