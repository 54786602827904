import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
var Loading = function () {
    return _jsx("span", { children: "..." }, void 0);
};
export var DotLoading = function (_a) {
    var show = _a.show, children = _a.children;
    if (show)
        return _jsx(Loading, {}, void 0);
    return _jsx(_Fragment, { children: children }, void 0);
};
