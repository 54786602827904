var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TheCoffeeHouseClient } from '@greyhole/myid/MyidServiceClientPb';
import { CreateUserRequest, GetUserRequest, ListUsersRequest, UserChange, LockUserRequest, Role, Roles, UnlockUserRequest, UpdateUserRequest, ResendCreatePasswordOTPRequest, GetUsersRequest, PhoneNumber, ListStatusLogsRequest, BanUserRequest, UnbanUserRequest, BanUsersRequest, LockUsersRequest, } from '@greyhole/myid/myid_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcTheCoffeeHouseClient = /** @class */ (function (_super) {
    __extends(rpcTheCoffeeHouseClient, _super);
    function rpcTheCoffeeHouseClient(config) {
        var _this = this;
        config.serviceName = 'THE_COFFEE_HOUSE';
        _this = _super.call(this, TheCoffeeHouseClient, config) || this;
        return _this;
    }
    rpcTheCoffeeHouseClient.prototype.getUsers = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new GetUsersRequest();
                        request.setUserIdsList(params.userIdsList);
                        return [4 /*yield*/, this.gRPCClientRequest('getUsers', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.listUsers = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUsersRequest();
                        req.setCursor(p.cursor);
                        req.setEmail(p.email);
                        req.setGroupIdsList(p.groupIdsList);
                        req.setLimit(p.limit);
                        req.setPhoneNumber(p.phoneNumber);
                        req.setQuery(p.query);
                        req.setRoleIdsList(p.roleIdsList);
                        req.setStatusesList(p.statusesList);
                        req.setUserId(p.userId);
                        req.setUsername(p.username);
                        return [4 /*yield*/, this.gRPCClientRequest('listUsers', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.createUser = function (p) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var req, rolesList, phoneNumber;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new CreateUserRequest();
                        rolesList = p.rolesList.map(function (_a) {
                            var roleId = _a.roleId, groupId = _a.groupId;
                            var role = new Role();
                            role.setRoleId(roleId);
                            role.setGroupId(groupId);
                            return role;
                        });
                        req.setEmail(p.email);
                        req.setMetadata(p.metadata);
                        if ((_a = p.phoneNumber) === null || _a === void 0 ? void 0 : _a.nationalNumber) {
                            phoneNumber = new PhoneNumber();
                            phoneNumber.setCountryCode(p.phoneNumber.countryCode);
                            phoneNumber.setNationalNumber(p.phoneNumber.nationalNumber);
                            req.setPhoneNumber(phoneNumber);
                        }
                        req.setUsername(p.username);
                        req.setRolesList(rolesList);
                        return [4 /*yield*/, this.gRPCClientRequest('createUser', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.getUser = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetUserRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getUser', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.updateUser = function (p) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var req, change, changesList, userChange, rs, listOfRoles, userChange, userChange, phoneNumber, userChange;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        req = new UpdateUserRequest();
                        change = p.changesList[0];
                        changesList = [];
                        if ((_a = change.roles) === null || _a === void 0 ? void 0 : _a.rolesList) {
                            userChange = new UserChange();
                            rs = new Roles();
                            listOfRoles = (_b = change.roles) === null || _b === void 0 ? void 0 : _b.rolesList.map(function (_a) {
                                var groupId = _a.groupId, roleId = _a.roleId;
                                var r = new Role();
                                groupId && r.setGroupId(groupId);
                                roleId && r.setRoleId(roleId);
                                return r;
                            });
                            rs.setRolesList(listOfRoles);
                            userChange.setRoles(rs);
                            changesList.push(userChange);
                        }
                        if (change.email) {
                            userChange = new UserChange();
                            userChange.setEmail(change.email);
                            changesList.push(userChange);
                        }
                        if (change.phoneNumber) {
                            userChange = new UserChange();
                            phoneNumber = new PhoneNumber();
                            phoneNumber.setCountryCode(change.phoneNumber.countryCode);
                            phoneNumber.setNationalNumber(change.phoneNumber.nationalNumber);
                            userChange.setPhoneNumber(phoneNumber);
                            changesList.push(userChange);
                        }
                        if (change.metadata) {
                            userChange = new UserChange();
                            userChange.setMetadata(change.metadata);
                            changesList.push(userChange);
                        }
                        req.setChangesList(changesList);
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('updateUser', req)];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.banUser = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new BanUserRequest();
                        req.setId(p.id);
                        req.setReason(p.reason);
                        return [4 /*yield*/, this.gRPCClientRequest('banUser', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.banUsers = function (r) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new BanUsersRequest();
                        req.setIdsList(r.idsList).setReason(r.reason);
                        return [4 /*yield*/, this.gRPCClientRequest('banUsers', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.unbanUser = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UnbanUserRequest();
                        req.setId(p.id);
                        req.setReason(p.reason);
                        return [4 /*yield*/, this.gRPCClientRequest('unbanUser', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.lockUsers = function (r) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new LockUsersRequest();
                        req.setIdsList(r.idsList).setReason(r.reason);
                        return [4 /*yield*/, this.gRPCClientRequest('lockUsers', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.lockUser = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new LockUserRequest();
                        req.setId(p.id);
                        req.setReason(p.reason);
                        return [4 /*yield*/, this.gRPCClientRequest('lockUser', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.unlockUser = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UnlockUserRequest();
                        req.setId(p.id);
                        req.setReason(p.reason);
                        return [4 /*yield*/, this.gRPCClientRequest('unlockUser', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.resendCreatePasswordOTP = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ResendCreatePasswordOTPRequest();
                        req.setUserId(p.userId);
                        return [4 /*yield*/, this.gRPCClientRequest('resendCreatePasswordOTP', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcTheCoffeeHouseClient.prototype.listStatusLogs = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListStatusLogsRequest();
                        req.setCursor(p.cursor);
                        req.setLimit(p.limit);
                        req.setUserId(p.userId);
                        return [4 /*yield*/, this.gRPCClientRequest('listStatusLogs', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcTheCoffeeHouseClient;
}(gRPCClientAbstract));
export default rpcTheCoffeeHouseClient;
