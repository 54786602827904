export var PLAYER_ROUTES = {
    basicInfo: {
        path: '/quinn/player-list/basic-info',
    },
    tagManagement: {
        path: '/quinn/tag-management',
        allPlayer: {
            path: '/quinn/tag-management/all-player',
        },
    },
};
export var CMS_ROUTES = {
    eventManagement: {
        path: '/cms/event-management',
        bannerPromotion: {
            path: '/cms/event-management/banner-promotion',
        },
        firstDepositPromotion: {
            path: '/cms/event-management/first-deposit-promotion',
        },
        holidayDepositBonus: {
            path: '/cms/event-management/holiday-deposit-bonus',
        },
        worldCup: {
            path: '/cms/event-management/world-cup',
            event: {
                path: '/cms/event-management/world-cup/event',
            },
            tab: {
                path: '/cms/event-management/world-cup/:tab',
            },
        },
        popup: {
            path: '/cms/event-management/popup',
        },
        tet: {
            path: '/cms/event-management/tet',
            event: {
                path: '/cms/event-management/tet/event',
            },
            tab: {
                path: '/cms/event-management/tet/:tab',
            },
        },
        weeklyEvent: {
            path: '/cms/event-management/weekly-event',
        },
        spinEvent: {
            path: '/cms/event-management/spin/list',
        },
    },
    missions: {
        path: '/cms/missions',
        daily: {
            path: '/cms/missions/daily',
        },
        tab: {
            path: '/cms/missions/:tab',
        },
    },
    lotteryResult: {
        path: '/cms/lottery-result',
        result: {
            path: '/cms/lottery-result/result',
        },
        playerBetting: {
            path: '/cms/lottery-result/player-betting',
        },
        tab: {
            path: '/cms/lottery-result/:tab',
        },
    },
    portalContents: {
        path: '/cms/portal-contents',
        banner: {
            path: '/cms/portal-contents/game-icons-order',
        },
        bannerManagement: {
            path: '/cms/portal-contents/game-icons-management',
        },
        csAccountConfig: {
            path: '/cms/portal-contents/customer-support-link',
        },
        notification: {
            path: '/cms/portal-contents/notification',
        },
        notificationId: {
            path: '/cms/portal-contents/notification/:id',
        },
        marquee: {
            path: '/cms/portal-contents/marquee',
        },
        marqueeBulletin: {
            path: '/cms/portal-contents/marquee/bulletins',
        },
        marqueeNews: {
            path: '/cms/portal-contents/marquee/news',
        },
        marqueeTab: {
            path: '/cms/portal-contents/marquee/:tab',
        },
        badwords: {
            path: '/cms/portal-contents/badwords',
        },
        badwordsNaming: {
            path: '/cms/portal-contents/badwords/naming',
        },
        badwordsChat: {
            path: '/cms/portal-contents/badwords/chat',
        },
        badwordsTab: {
            path: '/cms/portal-contents/badwords/:tab',
        },
    },
    website: {
        path: '/cms/website',
        landingPageConfiguration: {
            path: '/cms/website/landing-page-configuration',
        },
    },
    botController: {
        path: '/cms/bot-controller',
        detail: {
            path: '/cms/bot-controller/detail',
        },
        controller: {
            path: '/cms/bot-controller/controller',
        },
        tab: {
            path: '/cms/bot-controller/:tab',
        },
    },
    myAccount: {
        path: '/cms/my-account',
        base: {
            path: '/cms/my-account/base',
        },
        frame: {
            path: '/cms/my-account/frame',
        },
        frameCategory: {
            path: '/cms/my-account/-frame-category', // ? The extra dash (-) is workaround for duplicate active sidebar item bug
        },
        level: {
            path: '/cms/my-account/level',
        },
    },
    whatsapp: {
        path: '/cms/whatsapp',
        application: {
            path: '/cms/whatsapp/application',
        },
        emoticon: {
            path: '/cms/whatsapp/emoticon',
        },
        emoticonSet: {
            path: '/cms/whatsapp/-emoticon-set', // ? The extra dash (-) is workaround for duplicate active sidebar item bug
        },
        sample: {
            path: '/cms/whatsapp/sample',
        },
    },
    notifier: {
        path: '/cms/notifier',
    },
    porunga: {
        path: '/cms/porunga',
    },
};
export var USER_ROUTES = {
    usersList: {
        path: '/users/list',
    },
    usersGroups: {
        path: '/users/groups',
    },
    usersActionLogs: {
        path: '/users/action-logs',
    },
};
export var KPI_ROUTES = {
    salesDetailReport: {
        overview: {
            path: '/kpi/sales-detail-report/overview',
        },
        gameType: {
            path: '/kpi/sales-detail-report/game-type',
        },
    },
};
export var GAME_DATA = {
    foundation: {
        path: '/cradle/foundation',
        featureFlag: {
            path: '/cradle/foundation/feature-flag',
        },
        potter: {
            path: '/cradle/foundation/potter',
        },
        tinder: {
            path: '/cradle/foundation/tinder',
            setting: {
                path: '/cradle/foundation/tinder/setting',
            },
            score: {
                path: '/cradle/foundation/tinder/score',
            },
            resource: {
                path: '/cradle/foundation/tinder/resource',
            },
        },
    },
    cardGame: {
        maubinh: {
            setting: {
                path: '/cradle/card-game/mau-binh/setting',
            },
            scoreExtend: {
                path: '/cradle/card-game/mau-binh/score-extend',
            },
            naturalRate: {
                path: '/cradle/card-game/mau-binh/natural-rate',
            },
            combineRate: {
                path: '/cradle/card-game/mau-binh/combine-rate',
            },
        },
        poker: {
            setting: {
                path: '/cradle/card-game/poker/setting',
            },
        },
        tala: {
            setting: {
                path: '/cradle/card-game/tala/setting',
            },
        },
        tlmn: {
            setting: {
                path: '/cradle/card-game/tlmn/setting',
            },
        },
    },
    tableGame: {
        xocdia: {
            setting: {
                path: '/cradle/table-game/xoc-dia/setting',
            },
            stageTime: {
                path: '/cradle/table-game/xoc-dia/stage-time',
            },
            betLimit: {
                path: '/cradle/table-game/xoc-dia/bet-limit',
            },
            ratio: {
                path: '/cradle/table-game/xoc-dia/ratio',
            },
        },
        sicbo: {
            ratio: {
                path: '/cradle/table-game/sicbo/ratio',
            },
            stageTime: {
                path: '/cradle/table-game/sicbo/stage-time',
            },
            betLimit: {
                path: '/cradle/table-game/sicbo/bet-limit',
            },
            setting: {
                path: '/cradle/table-game/sicbo/setting',
            },
        },
        baucuaFull: {
            winRate: {
                path: '/cradle/table-game/bau-cua-full/ratio',
            },
            stageTime: {
                path: '/cradle/table-game/bau-cua-full/stage-time',
            },
            betLimit: {
                path: '/cradle/table-game/bau-cua-full/bet-limit',
            },
            setting: {
                path: '/cradle/table-game/bau-cua-full/setting',
            },
        },
    },
    lotteryGame: {
        lode: {
            region: {
                path: '/cradle/lottery-game/lode/region',
            },
            setting: {
                path: '/cradle/lottery-game/lode/setting',
            },
            headline: {
                path: '/cradle/lottery-game/lode/headline',
            },
            categoryMapping: {
                path: '/cradle/lottery-game/lode/category-mapping',
            },
            city: {
                path: '/cradle/lottery-game/lode/city',
            },
            guideline: {
                path: '/cradle/lottery-game/lode/guideline',
            },
        },
    },
    miniGame: {
        txmn: {
            setting: {
                path: '/cradle/mini-game/txmn/setting',
            },
            stageTime: {
                path: '/cradle/mini-game/txmn/stage-time',
            },
        },
        bcmn: {
            setting: {
                path: '/cradle/mini-game/bcmn/setting',
            },
            stageTime: {
                path: '/cradle/mini-game/bcmn/stage-time',
            },
        },
        pkmn: {
            comboReward: {
                path: '/cradle/mini-game/pkmn/combo-reward',
            },
        },
        updown: {
            setting: {
                path: '/cradle/mini-game/updown/setting',
            },
        },
    },
    slotGame: {
        thantai: {
            normalDistribution: {
                path: '/cradle/slot-game/than-tai/normal-distribution',
            },
            freeDistribution: {
                path: '/cradle/slot-game/than-tai/free-distribution',
            },
            bonusGameBox: {
                path: '/cradle/slot-game/than-tai/bonus-game-box',
            },
            bonusGameMultiply: {
                path: '/cradle/slot-game/than-tai/bonus-game-multiply',
            },
        },
        chienthan: {
            normalDistribution: {
                path: '/cradle/slot-game/chien-than/normal-distribution',
            },
            freeDistribution: {
                path: '/cradle/slot-game/chien-than/free-distribution',
            },
            bonusDistribution: {
                path: '/cradle/slot-game/chien-than/bonus-distribution',
            },
        },
    },
    maintenance: {
        list: {
            path: '/cradle/maintenance/list',
        },
        appUpdate: {
            path: '/cradle/maintenance/app-update',
        },
    },
    gameFake: {
        config: {
            path: '/cradle/game-fake/config',
        },
    },
    riskManagement: {
        path: '/cradle/risk-management',
    },
    avoidPlayer: {
        path: '/cradle/avoid-player',
    },
};
export var DASHBOARD_ROUTES = {
    gameOverview: {
        path: '/faora/game-overview',
    },
    missionOverview: {
        path: '/faora/mission-overview',
    },
    salesOverview: {
        path: '/faora/sales-overview',
    },
    playerOverview: {
        path: '/faora/player-overview',
    },
    screenTraffic: {
        path: '/faora/screen-traffic',
    },
    ipOverview: {
        path: '/faora/ip-overview',
    },
};
export var MONEY_ROUTES = {
    walletSummary: { path: '/rick/wallet-summary' },
    walletConfig: { path: '/rick/wallet-config' },
    withdrawConditionsConfig: { path: '/rick/withdraw-condition-config' },
    houseFund: { path: '/rick/house-fund' },
    telcoExchangeRates: { path: '/rick/telco-exchange-rates' },
    giftcodeManagement: {
        path: '/rick/giftcode-management',
        giftcodeGeneration: {
            path: '/rick/giftcode-management/gift-code-generation',
        },
    },
    paymentInfoConfiguration: {
        path: '/rick/payment',
        paymentAttention: { path: '/rick/payment/payment-attention' },
        paymentImage: { path: '/rick/payment/payment-image' },
    },
    gameWallet: {
        path: '/rick/wallet-summary/game-wallet',
    },
    botWallet: {
        path: '/rick/wallet-summary/bot-wallet',
    },
    eventWallet: {
        path: '/rick/wallet-summary/event-wallet',
    },
};
export var WITHDRAW_APPROVAL_ROUTES = {
    paymentWithdraw: { path: '/zod/payment-withdraw' },
};
export var GAME_INSIGHT_ROUTES = {
    dashboard: { path: '/aquaman/dashboard' },
    slotGame: {
        path: '/aquaman/slot-game',
        gof: {
            path: '/aquaman/slot-game/gof',
        },
        gow: {
            path: '/aquaman/slot-game/gow',
        },
    },
    cardGame: {
        path: '/aquaman/card-game',
        lieng: {
            path: '/aquaman/card-game/lieng',
        },
        poker: {
            path: '/aquaman/card-game/poker',
        },
        tala: {
            path: '/aquaman/card-game/tala',
        },
        maubinh: {
            path: '/aquaman/card-game/mau-binh',
        },
        sam: {
            path: '/aquaman/card-game/sam',
        },
        tlmn: {
            path: '/aquaman/card-game/tlmn',
        },
    },
    tableGame: {
        baucua: {
            path: '/aquaman/table-game/baucua',
        },
        sicbo: {
            path: '/aquaman/table-game/sicbo',
        },
        xocdia: {
            path: '/aquaman/table-game/xocdia',
        },
        baccarat: {
            path: '/aquaman/table-game/baccarat',
        },
    },
    lotteryGame: {
        path: '/aquaman/lottery-game',
        lode: {
            path: '/aquaman/lottery-game/lode',
        },
    },
    miniGame: {
        path: '/aquaman/mini-game',
        updown: {
            path: '/aquaman/mini-game/updown',
        },
        pkmn: {
            path: '/aquaman/mini-game/poker-mini',
        },
        bcmn: {
            path: '/aquaman/mini-game/baucua-mini',
        },
        txmn: {
            path: '/aquaman/mini-game/taixiu-mini',
        },
    },
    fish: { path: '/aquaman/fish' },
};
