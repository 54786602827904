import React from 'react';
import { getMonth, getYear } from 'date-fns';
import { Button } from '@core/oui/build/src/components/Button';
import { AngleRight } from '@core/icons';
import { MAX_YEAR } from '../const';
import styles from '../styles.module.scss';
var NextMonthButton = function (_a) {
    var curMonth = _a.curMonth, onNextMonth = _a.onNextMonth;
    return (React.createElement(Button, { className: getYear(curMonth) - MAX_YEAR > 0 ||
            (getMonth(curMonth) === 11 && getYear(curMonth) - MAX_YEAR >= 0)
            ? styles['icon_arrow_disabled']
            : '', disabled: getYear(curMonth) - MAX_YEAR > 0 ||
            (getMonth(curMonth) === 11 && getYear(curMonth) - MAX_YEAR >= 0), onClick: onNextMonth },
        React.createElement(AngleRight, null)));
};
export default NextMonthButton;
