var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Icon } from '@material-ui/core';
import { BellOn } from '@core/icons';
import Button, { ButtonVariants } from '../../components/Button';
import MenuLanguages from './MenuLanguages';
import AvatarProfile from './AvatarProfile';
import BreadcrumbsContainer from '../Breadcrumbs/BreadcrumbsContainer';
var drawerWidth = 256;
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        appBar: (_a = {
                backgroundColor: '#FFFFFF',
                borderBottom: '1px solid #D6DEFF',
                boxShadow: 'none'
            },
            _a[theme.breakpoints.up('sm')] = {
                width: function (props) {
                    return "calc(100% - " + (props.hasDrawer ? drawerWidth : 0) + "px)";
                },
            },
            _a),
    });
});
var MenuAppBar = function (_a) {
    var hasDrawer = _a.hasDrawer, userInfo = _a.userInfo, menuList = _a.menuList;
    var classes = useStyles({ hasDrawer: hasDrawer });
    return (_jsx(AppBar, __assign({ position: "fixed", className: classes.appBar }, { children: _jsx(Toolbar, { children: _jsxs(Grid, __assign({ container: true, alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Grid, __assign({ item: true, xs: "auto" }, { children: _jsx(Box, { children: _jsx(BreadcrumbsContainer, {}, void 0) }, void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: "auto" }, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "center" }, { children: [_jsx(Box, __assign({ mr: 2 }, { children: _jsx(Button, __assign({ variant: ButtonVariants.ghost }, { children: _jsx(Icon, { component: BellOn }, void 0) }), void 0) }), void 0), _jsx(MenuLanguages, {}, void 0), _jsx(AvatarProfile, { menuList: menuList, userInfo: userInfo }, void 0)] }), void 0) }), void 0)] }), void 0) }, void 0) }), void 0));
};
export default React.memo(MenuAppBar);
