var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var JackpotConfig = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", fill: "currentColor", viewBox: "0 0 24 24" }, props, { xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd", clipPath: "url(#clip0_2517_3592)", clipRule: "evenodd" },
        React.createElement("path", { d: "M12.01 4.011a1.2 1.2 0 00-.85.354l-.003.002-.7.7-.002.002A3.2 3.2 0 018.201 6H7.2A1.2 1.2 0 006 7.2v1c0 .845-.335 1.656-.93 2.255l-.003.002-.7.7-.002.002a1.2 1.2 0 000 1.702l.002.002.702.702c.596.599.93 1.41.931 2.254v1.001a1.2 1.2 0 001.2 1.2h1c.845 0 1.656.335 2.255.93l.002.003.702.702a1.202 1.202 0 001.312.262 1 1 0 01.768 1.846 3.199 3.199 0 01-3.497-.697h-.001l-.696-.697a1.202 1.202 0 00-.846-.349H7.2a3.2 3.2 0 01-3.2-3.2v-1a1.2 1.2 0 00-.348-.844H3.65l-.696-.697-.001-.001a3.199 3.199 0 010-4.536v-.001l.697-.696v-.001A1.2 1.2 0 004 8.2v-1A3.2 3.2 0 017.2 4h1a1.2 1.2 0 00.844-.348V3.65l.697-.696.001-.001a3.2 3.2 0 014.536 0h.001l.696.697h.001A1.2 1.2 0 0015.82 4h1a3.2 3.2 0 013.2 3.2v1c0 .316.125.62.348.844l.697.697.001.001a3.2 3.2 0 01.697 3.497 1 1 0 11-1.846-.768 1.199 1.199 0 00-.262-1.312l-.002-.002-.7-.7-.002-.002a3.2 3.2 0 01-.931-2.254V7.2a1.2 1.2 0 00-1.2-1.2h-1a3.201 3.201 0 01-2.255-.93l-.002-.003-.7-.7-.002-.002a1.2 1.2 0 00-.851-.354z" }),
        React.createElement("g", null,
            React.createElement("path", { d: "M18.5 17.111c-.465 0-.857.39-.857.889 0 .5.392.889.857.889.464 0 .857-.39.857-.889 0-.5-.393-.889-.857-.889zM15.893 18c0-1.464 1.159-2.667 2.607-2.667 1.448 0 2.607 1.203 2.607 2.667 0 1.464-1.159 2.667-2.607 2.667-1.448 0-2.607-1.203-2.607-2.667z" }),
            React.createElement("path", { d: "M18.5 14c.483 0 .875.398.875.889v1.333c0 .491-.392.89-.875.89a.882.882 0 01-.875-.89V14.89c0-.491.391-.889.875-.889z" }),
            React.createElement("path", { d: "M18.5 18.889c.483 0 .875.398.875.889v1.333c0 .491-.392.889-.875.889a.882.882 0 01-.875-.889v-1.333c0-.491.391-.89.875-.89z" }),
            React.createElement("path", { d: "M21.88 15.996a.897.897 0 01-.315 1.216l-1.125.667a.867.867 0 01-1.196-.32.897.897 0 01.314-1.216l1.125-.666a.867.867 0 011.197.32z" }),
            React.createElement("path", { d: "M17.757 18.441a.897.897 0 01-.316 1.216l-1.125.666a.868.868 0 01-1.197-.32.897.897 0 01.316-1.215l1.125-.667a.867.867 0 011.197.32z" }),
            React.createElement("path", { d: "M15.12 15.997a.867.867 0 011.196-.32l1.126.666a.897.897 0 01.315 1.216.867.867 0 01-1.197.32l-1.125-.667a.897.897 0 01-.316-1.215z" }),
            React.createElement("path", { d: "M19.244 18.441a.868.868 0 011.196-.32l1.126.667a.897.897 0 01.315 1.215.868.868 0 01-1.197.32l-1.125-.666a.897.897 0 01-.316-1.216z" })),
        React.createElement("g", null,
            React.createElement("path", { d: "M12.955 9.3c0-.442.339-.8.757-.8h1.515c.603 0 1.181.253 1.607.703.427.45.666 1.06.666 1.697a2.47 2.47 0 01-.666 1.697 2.214 2.214 0 01-1.607.703h-.757v2.4c0 .442-.34.8-.758.8s-.757-.358-.757-.8V9.3zm1.515 2.4h.757c.201 0 .394-.084.536-.234a.823.823 0 00.222-.566.823.823 0 00-.222-.566.738.738 0 00-.536-.234h-.757v1.6z" }),
            React.createElement("path", { d: "M7.5 9.3c0-.442.34-.8.758-.8h3.03c.418 0 .758.358.758.8v4.8a2.47 2.47 0 01-.666 1.697 2.214 2.214 0 01-1.607.703H8.636c-.301 0-.59-.126-.803-.352A1.235 1.235 0 017.5 15.3v-.4c0-.442.34-.8.758-.8s.757.358.757.8h.758c.2 0 .393-.084.535-.234a.823.823 0 00.222-.566v-4H8.258c-.419 0-.758-.358-.758-.8z" }))),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_2517_3592" },
            React.createElement("path", { fill: "#fff", d: "M0 0H24V24H0z" }))))); };
export default JackpotConfig;
