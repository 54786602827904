var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState, memo, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { addMonths, eachDayOfInterval, endOfMonth, getDay, getMonth, startOfMonth, subMonths, } from 'date-fns';
import Paper from '@core/oui/build/src/components/Paper';
import Typography, { TypoVariants, TypoWeights, } from '@core/oui/build/src/components/Typography';
import { AngleDown } from '@core/icons';
import { removeTimezoneInTime, setTimezoneInTime } from '@core/okit';
import DayPicker from './DayPicker';
import styles from './styles.module.scss';
import { PrevMonthButton, NextMonthButton } from './Actions';
import YearPicker from './YearPicker';
import { useDateRangeContext } from '../context';
var getArrDays = function (curMonth) {
    if (!curMonth)
        return [];
    return __spreadArray(__spreadArray([], new Array(getDay(startOfMonth(curMonth))).fill('')), eachDayOfInterval({
        start: startOfMonth(curMonth),
        end: endOfMonth(curMonth),
    }).map(function (d) { return setTimezoneInTime(d.getTime()); }));
};
var TITLE_OF_WEEK = [
    'Common::SUNDAY',
    'Common::MONDAY',
    'Common::TUESDAY',
    'Common::WEDNESDAY',
    'Common::THURSDAY',
    'Common::FRIDAY',
    'Common::SATURDAY',
];
var PopoverCalendar = function (_a) {
    var field = __rest(_a, []);
    var t = useTranslation().t;
    var dayRangeTmp = useDateRangeContext().dayRangeTmp;
    var _b = useState(0), dateHover = _b[0], setDateHover = _b[1];
    var _c = useState(false), isYearShown = _c[0], setIsYearShown = _c[1];
    var _d = useState(Date.now()), curMonth = _d[0], setCurMonth = _d[1];
    useEffect(function () {
        if (dayRangeTmp[1])
            setCurMonth(removeTimezoneInTime(dayRangeTmp[1]));
    }, [dayRangeTmp[1]]);
    var onPrevMonth = function () {
        var monthCurr = subMonths(new Date(curMonth), 1);
        setCurMonth(monthCurr.valueOf());
    };
    var onNextMonth = function () {
        var monthCurr = addMonths(new Date(curMonth), 1);
        setCurMonth(monthCurr.valueOf());
    };
    var onYearSelected = function (y) {
        var yearCurr = new Date(curMonth);
        yearCurr.setFullYear(y);
        setCurMonth(yearCurr.valueOf());
        setIsYearShown(false);
    };
    var toggleOpenYearSelected = function () {
        setIsYearShown(!isYearShown);
    };
    var yearSelected = useMemo(function () {
        if (!dayRangeTmp[0])
            return new Date().getFullYear();
        return new Date(dayRangeTmp[0]).getFullYear();
    }, [dayRangeTmp[0]]);
    var arrDays = useMemo(function () {
        if (!curMonth)
            return [];
        return getArrDays(curMonth);
    }, [curMonth]);
    return (React.createElement(Paper, { className: styles['wrapper_popover_daily'] },
        React.createElement("div", { className: "flex justify-between items-center mb-24" },
            React.createElement("div", { className: "flex items-center cursor-pointer", onClick: toggleOpenYearSelected },
                React.createElement(Typography, { className: styles['text_year'], variant: TypoVariants.bodyHighLevel, weight: TypoWeights.medium }, curMonth
                    ? t("Common::MONTH." + (getMonth(curMonth) + 1)) + ", " + yearSelected
                    : t("Common::MONTH." + (getMonth(Date.now()) + 1)) + ", " + yearSelected),
                React.createElement("span", null,
                    React.createElement(AngleDown, { color: "#090212" }))),
            React.createElement("div", { className: styles['icon_arrow'] },
                React.createElement(PrevMonthButton, { curMonth: curMonth, onPrevMonth: onPrevMonth }),
                React.createElement(NextMonthButton, { curMonth: curMonth, onNextMonth: onNextMonth }))),
        !isYearShown ? (React.createElement("div", null,
            React.createElement("div", { className: styles['wrapper_title_date'] }, TITLE_OF_WEEK.map(function (title) {
                return (React.createElement(Typography, { weight: TypoWeights.bold, key: title }, t(title)));
            })),
            React.createElement("div", { className: styles['wrapper_item_date'] }, arrDays.map(function (day, idx) {
                return (React.createElement(DayPicker, __assign({ key: idx, idx: idx, day: day, dateHover: dateHover, setDateHover: setDateHover }, field)));
            })))) : (React.createElement(YearPicker, { yearSelected: yearSelected, onYearSelected: onYearSelected }))));
};
export default memo(PopoverCalendar);
