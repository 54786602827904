import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AngleDown, AngleUp } from '@core/icons';
import { getCurrentTzNumber, getTzFromLocalStorage, setTzToLocalStorage, } from '@core/okit';
import styles from './styles.module.scss';
import DisplayGMT from './DisplayGMT';
import { Typography } from '../Typography';
import { TypoTypes, TypoVariants, TypoWeights } from '../Typography';
import DisplayTimeSelected from './DisplayTimeSelected';
import useFilter from '@core/uikit/build/src/hooks/useFilter';
var GMT_NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
var TimezoneSelector = function () {
    var _a = useState(false), expanded = _a[0], setExpanded = _a[1];
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = useFilter(), pushQueries = _c.pushQueries, query = _c.query, startDate = _c.startDate, endDate = _c.endDate;
    var currentTz = getCurrentTzNumber();
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
        setExpanded(true);
    };
    var handleClose = function () {
        setAnchorEl(null);
        setExpanded(false);
    };
    var reloadPage = function () {
        window.location.href = "" + window.location.origin + window.location.pathname + "?" + query.toString();
    };
    var updateTimestampByTz = function (tz) {
        if (!startDate || !endDate) {
            setTzToLocalStorage(tz);
            return reloadPage();
        }
        var oldTz = getTzFromLocalStorage() || 0;
        var offset = (oldTz - tz) * 60 * 60 * 1000;
        var newStartDate = startDate + offset;
        var newEndDate = endDate + offset;
        setTzToLocalStorage(tz);
        query.set('startDate', newStartDate.toString());
        query.set('endDate', newEndDate.toString());
        return reloadPage();
    };
    var switchTz = useCallback(function (tz) {
        updateTimestampByTz(tz);
        handleClose();
        // window.location.reload();
    }, []);
    return (React.createElement("div", { className: "flex item-center" },
        React.createElement(Button, { size: "small", "aria-controls": "time-menu", "aria-haspopup": "true", onClick: handleClick, endIcon: expanded ? React.createElement(AngleUp, null) : React.createElement(AngleDown, null), className: styles.menuShow },
            React.createElement(DisplayTimeSelected, { currentTz: currentTz })),
        React.createElement(Menu, { id: "time-menu", className: styles.formDropdown, anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            } }, GMT_NUMBERS.map(function (n) {
            return (React.createElement(MenuItem, { key: n, selected: n === currentTz, onClick: function () { return switchTz(n); } },
                React.createElement(Typography, { type: TypoTypes.primary, variant: TypoVariants.bodyLowLevel, weight: TypoWeights.medium },
                    React.createElement(DisplayGMT, { tz: n }))));
        }))));
};
export default TimezoneSelector;
