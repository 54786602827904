import _get from 'lodash-es/get';
import _isEmpty from 'lodash-es/isEmpty';
import _camelCase from 'lodash-es/camelCase';
import _difference from 'lodash-es/difference';
import _intersection from 'lodash-es/intersection';
/*
  ? Format action list to action name camelCase to compare
  * @param Resource: Payment Top-up
  * @param Action: Auto Approval
  => paymentTopup.autoApproval
*/
export var combineResourceWithActionList = function (permissions) {
    return permissions.reduce(function (resourceAction, _a) {
        var actionsList = _a.actionsList;
        return resourceAction.concat(actionsList.map(function (action) {
            return _camelCase(_get(action, 'resource.name', '')) + "." + _camelCase(_get(action, 'name', ''));
        }));
    }, []);
};
export var isLegalPermission = function (perform, permissions, logic) {
    if (logic === void 0) { logic = 'and'; }
    if (_isEmpty(perform))
        return true;
    if (typeof perform === 'string') {
        return permissions.includes(perform);
    }
    if (Array.isArray(perform)) {
        // all perform entries are into permissions
        return logic === 'and'
            ? _difference(perform, permissions).length === 0
            : _intersection(perform, permissions).length > 0;
    }
    return false;
};
