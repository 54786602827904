var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var HouseDeposit = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 8a1 1 0 011 1v.5h1a1 1 0 110 2h-2.5a.5.5 0 000 1h1a2.5 2.5 0 01.5 4.95V18a1 1 0 11-2 0v-.5h-1a1 1 0 110-2h2.5a.5.5 0 000-1h-1a2.5 2.5 0 01-.5-4.95V9a1 1 0 011-1z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M11.293 2.293a1 1 0 011.414 0l9 9A1 1 0 0121 13h-2a1 1 0 01-.35-1.937L12 4.414l-6.65 6.65A1 1 0 015 13H3a1 1 0 01-.707-1.707l9-9z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M5 11a1 1 0 011 1v7a1 1 0 001 1h10a1 1 0 001-1v-7a1 1 0 112 0v7a3 3 0 01-3 3H7a3 3 0 01-3-3v-7a1 1 0 011-1z", clipRule: "evenodd" }))); };
export default HouseDeposit;
