var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useMemo, useRef, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { AngleDown, AngleUp } from '@core/icons';
import { Box, Grid } from '@material-ui/core';
import Typography, { TypoTypes } from '../Typography';
import Icon from '../Icon';
import Input, { InputSizes } from '../Input';
import InputAdornment from '../InputAdornment';
import Menu from '../Menu';
import MultipleSelectContext from './Context';
import SelectInput from './SelectInput';
import styles from './styles.module.scss';
var defaultProps = {
    component: Input,
    size: InputSizes.md,
};
function calculatePopoverStyle(ref) {
    if (!ref) {
        return {};
    }
    var width = Math.max(ref.getBoundingClientRect().width, 80);
    return {
        minWidth: width + "px",
        maxWidth: '300px',
        maxHeight: '500px',
        overflowY: 'auto',
    };
}
function checkIsUsePlaceholder(value) {
    return value === undefined || value === null || value.length === 0;
}
export var MultipleSelect = forwardRef(function (props, ref) {
    var _a, _b;
    var _c = __assign(__assign({}, defaultProps), props), Component = _c.component, children = _c.children, onChange = _c.onChange, onClick = _c.onClick, onBlur = _c.onBlur, onFocus = _c.onFocus, menuClassName = _c.menuClassName, className = _c.className, placeholder = _c.placeholder, disabled = _c.disabled, selected = _c.selected, onClear = _c.onClear, note = _c.note, rest = __rest(_c, ["component", "children", "onChange", "onClick", "onBlur", "onFocus", "menuClassName", "className", "placeholder", "disabled", "selected", "onClear", "note"]);
    var wrapperRef = useRef();
    var popoverStyle = useRef(null);
    var t = useTranslation().t;
    var _d = __read(useState(false), 2), open = _d[0], setOpen = _d[1];
    var handleOpen = useCallback(function (e) {
        if (!open && !disabled) {
            popoverStyle.current = calculatePopoverStyle(wrapperRef.current);
            setOpen(true);
        }
        if (onFocus) {
            onFocus(e);
        }
        if (onClick) {
            return onClick(e);
        }
    }, [disabled, onClick, onFocus, open]);
    var handleClose = useCallback(function (e) {
        setOpen(false);
        if (onBlur) {
            return onBlur(e);
        }
    }, [onBlur]);
    var afterInput = useMemo(function () { return (_jsx(InputAdornment, __assign({ size: rest.size, onClick: handleOpen, className: styles["addon-size-" + rest.size] + " " + (open ? [styles['is-open']] : '') }, { children: _jsx(Icon, { className: styles.icon, component: open ? AngleUp : AngleDown }, void 0) }), void 0)); }, [open, handleOpen, rest.size]);
    var display = checkIsUsePlaceholder(selected) ? (open ? (placeholder) : (placeholder)) : (_jsxs(_Fragment, { children: [_jsx("b", { children: placeholder }, void 0), ":", ' ', selected.length + " " + t('Common::TEXT.SELECTED')] }, void 0));
    var contextValue = {
        disabled: disabled,
        selected: selected,
        onChange: onChange,
    };
    return (_jsxs(_Fragment, { children: [_jsx(Input, __assign({}, rest, { className: cn(className, styles.input, (_a = {}, _a[styles['is-open']] = open, _a), (_b = {}, _b[styles['is-selected']] = selected.length, _b)), onClick: handleOpen, component: SelectInput, afterInput: afterInput, display: display, ref: ref, readOnly: true, 
                // @ts-ignore
                wrapperRef: wrapperRef }), void 0), open && (_jsx(Menu, __assign({ anchorRef: wrapperRef, onClose: handleClose, menuClassName: menuClassName, style: popoverStyle.current }, { children: _jsxs(Grid, __assign({ container: true, direction: "column", spacing: 1 }, { children: [note && (_jsx(Grid, __assign({ item: true }, { children: _jsx(Box, __assign({ pl: 1.5, pt: 1.5 }, { children: _jsx(Typography, __assign({ type: TypoTypes.titleSub }, { children: note }), void 0) }), void 0) }), void 0)), onClear && !!selected.length && (_jsx(Grid, __assign({ item: true }, { children: _jsx(Box, __assign({ pl: 1.5, pt: 1.5 }, { children: _jsx(Typography, __assign({ className: 'cursor-pointer', weight: 'bold', onClick: onClear }, { children: t('Common::TEXT.RESET') }), void 0) }), void 0) }), void 0)), _jsx(Grid, __assign({ item: true }, { children: _jsx(MultipleSelectContext.Provider, __assign({ value: contextValue }, { children: children }), void 0) }), void 0)] }), void 0) }), void 0))] }, void 0));
});
MultipleSelect.displayName = 'MultipleSelect';
export default MultipleSelect;
