var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import useQuery from './useQuery';
export var propsDefault = {
    sortNameKey: 'sortName',
    orderByKey: 'orderBy'
};
export default function useSort(options) {
    options = __assign(__assign({}, propsDefault), options);
    var query = useQuery();
    var name = query.get(options.sortNameKey);
    var orderBy = query.get(options.orderByKey);
    return {
        name: name,
        orderBy: orderBy
    };
}
