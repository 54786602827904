var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { ApokolipsClient } from '@dceu/apokolips/ApokolipsServiceClientPb';
import { GetTotalLoginDayRequest, GetUserBasicInfoRequest, GetUserIngameInfoRequest, ListUserDevicesRequest, ListUserGamingLogsRequest, ListUserGiftCodeLogsRequest, ListUserLoginLogsRequest, ListUsersRequest, CreateCSCommitmentRequest, UpdateUserRequest, UserChange, PhoneNumber, ListBotsRequest, ListUserBasicInfoRequest, } from '@dceu/apokolips/apokolips_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcApokolipsClient = /** @class */ (function (_super) {
    __extends(rpcApokolipsClient, _super);
    function rpcApokolipsClient(config) {
        var _this = this;
        config.serviceName = 'APOKOLIPS';
        _this = _super.call(this, ApokolipsClient, config) || this;
        return _this;
    }
    rpcApokolipsClient.prototype.listUsers = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUsersRequest();
                        req.setCursor(p.cursor);
                        req.setLimit(p.limit);
                        req.setSearch(p.search);
                        req.setType(p.type);
                        req.setStatus(p.status);
                        return [4 /*yield*/, this.gRPCClientRequest('listUsers', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.getUserBasicInfo = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetUserBasicInfoRequest();
                        req.setUserId(p.userId);
                        return [4 /*yield*/, this.gRPCClientRequest('getUserBasicInfo', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.listUserBasicInfo = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUserBasicInfoRequest();
                        req.setUserIdsList(p.userIdsList);
                        return [4 /*yield*/, this.gRPCClientRequest('listUserBasicInfo', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.listUserDevices = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUserDevicesRequest();
                        req.setCursor(p.cursor);
                        req.setLimit(p.limit);
                        req.setUserId(p.userId);
                        if (p.fromDate) {
                            ts = new Timestamp();
                            ts.setNanos(p.fromDate.nanos);
                            ts.setSeconds(p.fromDate.seconds);
                            req.setFromDate(ts);
                        }
                        if (p.toDate) {
                            ts = new Timestamp();
                            ts.setNanos(p.toDate.nanos);
                            ts.setSeconds(p.toDate.seconds);
                            req.setToDate(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listUserDevices', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.getUserIngameInfo = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetUserIngameInfoRequest();
                        req.setUserId(p.userId);
                        return [4 /*yield*/, this.gRPCClientRequest('getUserIngameInfo', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.listUserGamingLogs = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUserGamingLogsRequest();
                        req.setCursor(p.cursor);
                        req.setFeaturesList(p.featuresList);
                        req.setLimit(p.limit);
                        req.setTypesList(p.typesList);
                        req.setUserId(p.userId);
                        if (p.fromDate) {
                            ts = new Timestamp();
                            ts.setNanos(p.fromDate.nanos);
                            ts.setSeconds(p.fromDate.seconds);
                            req.setFromDate(ts);
                        }
                        if (p.toDate) {
                            ts = new Timestamp();
                            ts.setNanos(p.toDate.nanos);
                            ts.setSeconds(p.toDate.seconds);
                            req.setToDate(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listUserGamingLogs', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.listUserGiftCodeLogs = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUserGiftCodeLogsRequest();
                        req.setCursor(p.cursor);
                        req.setLimit(p.limit);
                        req.setSearch(p.search);
                        req.setUserId(p.userId);
                        if (p.fromDate) {
                            ts = new Timestamp();
                            ts.setNanos(p.fromDate.nanos);
                            ts.setSeconds(p.fromDate.seconds);
                            req.setFromDate(ts);
                        }
                        if (p.toDate) {
                            ts = new Timestamp();
                            ts.setNanos(p.toDate.nanos);
                            ts.setSeconds(p.toDate.seconds);
                            req.setToDate(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listUserGiftCodeLogs', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.listUserLoginLogs = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUserLoginLogsRequest();
                        req.setCursor(p.cursor);
                        req.setFlagsList(p.flagsList);
                        req.setLimit(p.limit);
                        req.setStatusesList(p.statusesList);
                        req.setUserId(p.userId);
                        if (p.fromDate) {
                            ts = new Timestamp();
                            ts.setNanos(p.fromDate.nanos);
                            ts.setSeconds(p.fromDate.seconds);
                            req.setFromDate(ts);
                        }
                        if (p.toDate) {
                            ts = new Timestamp();
                            ts.setNanos(p.toDate.nanos);
                            ts.setSeconds(p.toDate.seconds);
                            req.setToDate(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listUserLoginLogs', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.getTotalLoginDay = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetTotalLoginDayRequest();
                        req.setUserId(p.userId);
                        return [4 /*yield*/, this.gRPCClientRequest('getTotalLoginDay', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.createCSCommitment = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateCSCommitmentRequest();
                        req.setUserId(p.userId);
                        req.setNote(p.note);
                        return [4 /*yield*/, this.gRPCClientRequest('createCSCommitment', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.updateUser = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, _a, email, phoneNumber, userChange, phoneNumberObj;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        req = new UpdateUserRequest();
                        _a = p.change || {}, email = _a.email, phoneNumber = _a.phoneNumber;
                        userChange = new UserChange();
                        email && userChange.setEmail(email);
                        if (phoneNumber) {
                            phoneNumberObj = new PhoneNumber();
                            phoneNumberObj.setCountryCode(phoneNumber.countryCode);
                            phoneNumberObj.setNationalNumber(phoneNumber.nationalNumber);
                            userChange.setPhoneNumber(phoneNumberObj);
                        }
                        req.setUserId(p.userId);
                        req.setChange(userChange);
                        return [4 /*yield*/, this.gRPCClientRequest('updateUser', req)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    rpcApokolipsClient.prototype.listBots = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListBotsRequest();
                        req.setTopic(p.topic);
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        return [4 /*yield*/, this.gRPCClientRequest('listBots', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcApokolipsClient;
}(gRPCClientAbstract));
export default rpcApokolipsClient;
