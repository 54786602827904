import React from 'react';
import { memo, useEffect, useState } from 'react';
import useFilter from '@core/okit/build/hooks/useFilter';
import { DEFAULT_PAGE_NAME } from '@core/okit/build/hooks/usePagination';
import { useUpdateUrlParams } from '@core/okit/build/context/url_params_context/use_url_params';
import { dateRangeDefine } from './const';
import DateRangePicker from '../DateRangePicker';
var FilterDateRange = function (_a) {
    var showTime = _a.showTime, endDateName = _a.endDateName, startDateName = _a.startDateName, periodOptions = _a.periodOptions;
    var getUrlParams = useUpdateUrlParams()[0];
    var _b = useFilter(), pushQueries = _b.pushQueries, getQueryParam = _b.getQueryParam, popQueries = _b.popQueries;
    var _c = getUrlParams(), startDate = _c.startDate, endDate = _c.endDate;
    var _d = useState([0, 0]), dateRange = _d[0], setDateRange = _d[1];
    // for custom startDate and endDate
    var customStartDate = getQueryParam(startDateName);
    var customEndDate = getQueryParam(endDateName);
    useEffect(function () {
        // if exist endDateName check if it has no value then reset time range
        if (startDateName) {
            if (customStartDate === null)
                setDateRange([0, 0]);
            else
                setDateRange([Number(customStartDate), Number(customEndDate)]);
        }
        else {
            setDateRange([startDate || 0, endDate || 0]);
        }
    }, [startDate, endDate, customStartDate]); // eslint-disable-line
    useEffect(function () {
        var _a;
        if (!dateRange[0] || !dateRange[1])
            return;
        popQueries([DEFAULT_PAGE_NAME]);
        pushQueries((_a = {},
            _a[startDateName || dateRangeDefine.startDate] = dateRange[0],
            _a[endDateName || dateRangeDefine.endDate] = dateRange[1],
            _a));
    }, [dateRange[0], dateRange[1]]); // eslint-disable-line
    return (React.createElement(DateRangePicker, { dateRange: dateRange, showTime: showTime, onChange: setDateRange, periodOptions: periodOptions }));
};
export default memo(FilterDateRange);
