var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { SteveClient } from '@dceu/steve/SteveServiceClientPb';
import { FakeGame, ListFakeGameRequest, UpsertFakeGameRequest, ListMaintenancesRequest, PublishMaintenanceRequest, UpsertMaintenanceRequest, Maintenance, } from '@dceu/steve/steve_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
function toMaintenancePb(m) {
    var mtn = new Maintenance();
    mtn.setName((m === null || m === void 0 ? void 0 : m.name) || '');
    mtn.setMessage((m === null || m === void 0 ? void 0 : m.message) || '');
    mtn.setActive((m === null || m === void 0 ? void 0 : m.active) || false);
    if (m === null || m === void 0 ? void 0 : m.startsAt) {
        var ts = new Timestamp()
            .setSeconds(m === null || m === void 0 ? void 0 : m.startsAt.seconds)
            .setNanos(m === null || m === void 0 ? void 0 : m.startsAt.nanos);
        mtn.setStartsAt(ts);
    }
    if (m === null || m === void 0 ? void 0 : m.endsAt) {
        var ts = new Timestamp()
            .setSeconds(m === null || m === void 0 ? void 0 : m.endsAt.seconds)
            .setNanos(m === null || m === void 0 ? void 0 : m.endsAt.nanos);
        mtn.setEndsAt(ts);
    }
    return mtn;
}
var rpcSteveClient = /** @class */ (function (_super) {
    __extends(rpcSteveClient, _super);
    function rpcSteveClient(config) {
        var _this = this;
        config.serviceName = 'STEVE';
        _this = _super.call(this, SteveClient, config) || this;
        return _this;
    }
    rpcSteveClient.prototype.upsertFakeGame = function (p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return __awaiter(this, void 0, void 0, function () {
            var req, reqFakeGame;
            return __generator(this, function (_o) {
                switch (_o.label) {
                    case 0:
                        req = new UpsertFakeGameRequest();
                        reqFakeGame = new FakeGame();
                        reqFakeGame
                            .setName(((_a = p.game) === null || _a === void 0 ? void 0 : _a.name) || '')
                            .setIsUpdate(((_b = p.game) === null || _b === void 0 ? void 0 : _b.isUpdate) || false)
                            .setPaymentEnabled(((_c = p.game) === null || _c === void 0 ? void 0 : _c.paymentEnabled) || false)
                            .setConfigDomainsList(((_d = p.game) === null || _d === void 0 ? void 0 : _d.configDomainsList) || [])
                            .setBentauDomainsList(((_e = p.game) === null || _e === void 0 ? void 0 : _e.bentauDomainsList) || [])
                            .setSanbayDomainsList(((_f = p.game) === null || _f === void 0 ? void 0 : _f.sanbayDomainsList) || [])
                            .setAssetBundleDomainsList(((_g = p.game) === null || _g === void 0 ? void 0 : _g.assetBundleDomainsList) || [])
                            .setWebsocketDomainsList(((_h = p.game) === null || _h === void 0 ? void 0 : _h.websocketDomainsList) || [])
                            .setResourceDomainsList(((_j = p.game) === null || _j === void 0 ? void 0 : _j.resourceDomainsList) || [])
                            .setIosBundleId(((_k = p.game) === null || _k === void 0 ? void 0 : _k.iosBundleId) || '')
                            .setAndroidPackageName(((_l = p.game) === null || _l === void 0 ? void 0 : _l.androidPackageName) || '')
                            .setDeeplinkDomain(((_m = p.game) === null || _m === void 0 ? void 0 : _m.deeplinkDomain) || '');
                        req.setGame(reqFakeGame);
                        return [4 /*yield*/, this.gRPCClientRequest('upsertFakeGame', req)];
                    case 1: return [2 /*return*/, _o.sent()];
                }
            });
        });
    };
    rpcSteveClient.prototype.listFakeGames = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListFakeGameRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listFakeGames', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcSteveClient.prototype.listMaintenances = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListMaintenancesRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listMaintenances', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcSteveClient.prototype.publishMaintenance = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new PublishMaintenanceRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('publishMaintenance', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcSteveClient.prototype.upsertMaintenance = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpsertMaintenanceRequest();
                        req.setName(p.name);
                        req.setMessage(p.message);
                        p.id && req.setId(p.id);
                        p.active && req.setActive(p.active);
                        if (p.startsAt) {
                            ts = new Timestamp()
                                .setSeconds(p.startsAt.seconds)
                                .setNanos(p.startsAt.nanos);
                            req.setStartsAt(ts);
                        }
                        if (p.endsAt) {
                            ts = new Timestamp()
                                .setSeconds(p.endsAt.seconds)
                                .setNanos(p.endsAt.nanos);
                            req.setEndsAt(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('upsertMaintenance', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcSteveClient;
}(gRPCClientAbstract));
export default rpcSteveClient;
