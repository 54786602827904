var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cn from 'classnames';
import { DividerOrientation, DividerType, DividerVarients } from './types';
import styles from './styles.module.scss';
export * from './types';
var defaultProps = {
    component: 'hr',
    type: DividerType.line,
    orientation: DividerOrientation.horizontal,
    variant: DividerVarients.fullWidth,
};
export var Divider = function (props) {
    var _a;
    var _b = __assign(__assign({}, defaultProps), props), Component = _b.component, className = _b.className, type = _b.type, orientation = _b.orientation, variant = _b.variant, absolute = _b.absolute, flexItem = _b.flexItem, rest = __rest(_b, ["component", "className", "type", "orientation", "variant", "absolute", "flexItem"]);
    var classOfComponent = cn(styles.divider, (_a = {},
        _a[styles[String(type)]] = !!type,
        _a[styles[variant]] = variant !== DividerVarients.fullWidth,
        _a[styles.absolute] = !!absolute,
        _a[styles.flexItem] = !!flexItem,
        _a[styles.vertical] = orientation === DividerOrientation.vertical,
        _a), className);
    return _jsx(Component, __assign({}, rest, { className: classOfComponent }), void 0);
};
Divider.displayName = 'Divider';
export default Divider;
