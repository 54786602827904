var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import List from '../List';
import styles from './styles.module.scss';
var defaultProps = {
    component: 'ul',
    direction: 'column',
};
export var MenuList = React.forwardRef(function (props, ref) {
    var _a = __assign(__assign({}, defaultProps), props), className = _a.className, children = _a.children, rest = __rest(_a, ["className", "children"]);
    var classOfComponent = cn(styles.menuList, className);
    return (_jsx(List, __assign({ role: "menu", ref: ref, className: classOfComponent }, rest, { children: children }), void 0));
});
MenuList.displayName = 'MenuList';
export default MenuList;
