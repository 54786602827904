import { combineReducers } from '@reduxjs/toolkit';
import common from 'redux/features/common/slice';
import auth from 'redux/features/auth/slice';
import saleReportOverview from 'redux/features/saleReportOverview/slice';
import saleReportGameType from 'redux/features/saleReportGameType/slice';

const rootReducer = combineReducers({
  common,
  auth,
  saleReportOverview,
  saleReportGameType,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
