var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "#7462E1", d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 013 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 015 3h11l5 5v11c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0119 21H5zm3-4h8c.283 0 .52-.096.712-.288A.965.965 0 0017 16a.968.968 0 00-.288-.713A.967.967 0 0016 15H8a.97.97 0 00-.713.287A.97.97 0 007 16c0 .283.096.52.287.712.192.192.43.288.713.288zm0-8h3a.968.968 0 00.713-.288A.967.967 0 0012 8a.97.97 0 00-.287-.713A.97.97 0 0011 7H8a.97.97 0 00-.713.287A.97.97 0 007 8c0 .283.096.52.287.712.192.192.43.288.713.288zm0 4h8c.283 0 .52-.096.712-.288A.965.965 0 0017 12a.968.968 0 00-.288-.713A.967.967 0 0016 11H8a.97.97 0 00-.713.287A.97.97 0 007 12c0 .283.096.52.287.712.192.192.43.288.713.288zm8-4h3l-4-4v3c0 .283.096.52.288.712A.965.965 0 0016 9z" })));
}
export default SvgComponent;
