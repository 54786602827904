var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import { AngleDown, FlagCN, FlagEN, FlagVN } from '@core/icons';
import Paper from '../../components/Paper';
import Icon from '../../components/Icon';
import Typography, { TypoVariants } from '../../components/Typography';
var useStyles = makeStyles(function (theme) {
    return {
        languages: {
            border: 'none',
            width: '180px',
        },
        dropdownButton: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '150%',
            letterSpacing: 0.5,
            borderRadius: 8,
            border: '1px solid #fff',
            padding: '8px 10px 8px 16px',
            '&:hover , &.active': {
                backgroundColor: '#D6DEFF',
            },
        },
        flagIcon: {
            width: 20,
            height: 14,
        },
    };
});
var LANGUAGES = {
    en: 'English',
    vi: 'Tiếng Việt',
    cn: 'Chinese',
};
var LANGUAGES_FLAGS = {
    en: FlagEN,
    vi: FlagVN,
    cn: FlagCN,
};
export var LANGUAGE_OPTIONS = [
    {
        name: 'English',
        value: 'en',
        flag: _jsx(FlagEN, {}, void 0),
    },
    {
        name: 'Tiếng Việt',
        value: 'vi',
        flag: _jsx(FlagVN, {}, void 0),
    },
    {
        name: '简体汉字',
        value: 'cn',
        flag: _jsx(FlagCN, {}, void 0),
    },
    {
        name: '繁體漢字',
        value: 'cn_trad',
        flag: _jsx(FlagCN, {}, void 0),
    },
];
export var LANGUAGE_INCLUDE_DEBUG_OPTIONS = __spreadArray(__spreadArray([], __read(LANGUAGE_OPTIONS)), [
    {
        name: 'Debug Language',
        value: 'debug',
        flag: null,
    },
]);
export var DEFAULT_LANG = 'vi';
export var KEY_I18N = 'Lng';
export var KEY_I18N_LNG = 'i18nextLng';
export var LANGUAGES_SUPPORT = ['en', 'vi', 'cn', 'cn_trad', 'debug'];
export var NAMESPACES_SUPPORT = [
    'Common',
    'Portal',
    'UserManagement',
    'CMS',
    'KPI',
    'Cradle',
    'Dashboard',
    'Error',
];
export var PREFIX_LANGUAGE_CACHE = 'i18next_res_';
var MenuLanguages = function () {
    var i18n = useTranslation().i18n;
    var classes = useStyles();
    var _a = __read(useState(false), 2), open = _a[0], setOpen = _a[1];
    var _b = __read(useState(localStorage.getItem(KEY_I18N) ||
        DEFAULT_LANG), 2), select = _b[0], setSelect = _b[1];
    var anchorRef = useRef(null);
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var handleClosePopper = function (event) {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    var handleSelect = function (key) {
        setSelect(key);
        i18n.changeLanguage(key);
        localStorage.setItem(KEY_I18N, key);
        window.location.reload();
    };
    var id = open ? 'menu-list-grow' : undefined;
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ ref: anchorRef, "aria-controls": open ? 'menu-list-grow' : undefined, "aria-haspopup": "menu", onClick: handleToggle, className: classes.dropdownButton + " " + (open ? 'active' : ''), startIcon: _jsx(Icon, { component: LANGUAGES_FLAGS[select] }, void 0), endIcon: _jsx(AngleDown, {}, void 0) }, { children: _jsx(Typography, __assign({ variant: TypoVariants.button1 }, { children: LANGUAGES[select] }), void 0) }), void 0), _jsx(Popper, __assign({ id: id, open: open, anchorEl: anchorRef.current, transition: true, placement: "top-end", style: { zIndex: 99999 }, modifiers: {
                    offset: {
                        enabled: true,
                        offset: '0, 8',
                    },
                } }, { children: function (_a) {
                    var TransitionProps = _a.TransitionProps, placement = _a.placement;
                    return (_jsx(Grow, __assign({}, TransitionProps, { style: {
                            transformOrigin: placement === 'bottom' ? 'top-end' : 'bottom-end',
                        } }, { children: _jsx(Paper, { children: _jsx(ClickAwayListener, __assign({ onClickAway: handleClosePopper }, { children: _jsx(MenuList, __assign({ id: "menu-list-grow" }, { children: Object.entries(LANGUAGES).map(function (_a) {
                                        var _b = __read(_a, 2), key = _b[0], label = _b[1];
                                        return (_jsx(MenuItem, __assign({ value: key, onClick: function () { return handleSelect(key); } }, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "center" }, { children: [_jsx(Box, __assign({ mr: 1 }, { children: _jsx(Icon, { className: classes.flagIcon, component: LANGUAGES_FLAGS[key] }, void 0) }), void 0), _jsx(Typography, __assign({ variant: TypoVariants.button1 }, { children: label }), void 0)] }), void 0) }), key));
                                    }) }), void 0) }), void 0) }, void 0) }), void 0));
                } }), void 0)] }, void 0));
};
export default MenuLanguages;
