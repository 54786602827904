import { createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import {
  GetPaymentStatsReply,
  Payment,
  PlayerReport,
} from '@dceu/dailyplanet/dailyplanet_overall_report_pb';

import { stateLoading, stateError, stateSucceeded } from 'redux/common';
import { CommonState } from 'redux/common/types';
import { StatusEnum } from 'redux/constant';
import { RootState } from 'redux/reducers';
import {
  getPlayerReportThunk,
  getPaymentReportThunk,
  getPaymentStatsThunk,
} from './thunks';

type ReferralState = {
  playerReports: PlayerReport.AsObject[];
  playerReportsLoading: boolean;
  paymentReports: Payment.AsObject[];
  paymentReportsLoading: boolean;
  paymentStats: GetPaymentStatsReply.AsObject;
  paymentStatsLoading: boolean;
} & CommonState;

export const initialState: ReferralState = {
  status: StatusEnum.IDLE,
  playerReports: [] as PlayerReport.AsObject[],
  playerReportsLoading: false,
  paymentReports: [] as Payment.AsObject[],
  paymentReportsLoading: false,
  paymentStats: new GetPaymentStatsReply().toObject(),
  paymentStatsLoading: false,
};

const slice = createSlice({
  name: 'saleReportOverview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isPending(
          getPlayerReportThunk,
          getPaymentReportThunk,
          getPaymentStatsThunk,
        ),
        stateLoading,
      )
      .addMatcher(isPending(getPlayerReportThunk), (state) => {
        state.playerReportsLoading = true;
      })
      .addMatcher(isPending(getPaymentReportThunk), (state) => {
        state.paymentReportsLoading = true;
      })
      .addMatcher(isPending(getPaymentStatsThunk), (state) => {
        state.paymentStatsLoading = true;
      })
      .addMatcher(
        isFulfilled(
          getPlayerReportThunk,
          getPaymentReportThunk,
          getPaymentStatsThunk,
        ),
        (state, action) => {
          const { error } = action.payload;
          if (error) return stateError(state, error.message);
          stateSucceeded(state);
        },
      )
      .addMatcher(isFulfilled(getPlayerReportThunk), (state, action) => {
        const { error, response } = action.payload;
        state.playerReportsLoading = false;
        if (error) return;

        state.playerReports = response?.overallPlayersList || [];
      })
      .addMatcher(isFulfilled(getPaymentReportThunk), (state, action) => {
        const { error, response } = action.payload;
        state.paymentReportsLoading = false;
        if (error) return;

        state.paymentReports = response?.paymentsList || [];
      })
      .addMatcher(isFulfilled(getPaymentStatsThunk), (state, action) => {
        const { error, response } = action.payload;
        state.paymentStatsLoading = false;
        if (error) return;

        state.paymentStats = response;
      });
  },
});

export const selectStateSaleReportOverview = (state: RootState) =>
  state.saleReportOverview;
export const isStateSaleReportOverviewLoading = (state: RootState) =>
  state.saleReportOverview.status === StatusEnum.LOADING;

export default slice.reducer;
