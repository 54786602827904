var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import cn from 'classnames';
import Textarea from '../Textarea';
import Typography, { TypoVariants } from '../Typography';
import styles from './styles.module.scss';
import { MinorTooltip } from '../../components/Tooltip';
var defaultProps = {
    component: Textarea,
};
export var TextareaField = forwardRef(function (props, ref) {
    var _a = __assign(__assign({}, defaultProps), props), InputComponentRenderer = _a.component, className = _a.className, optional = _a.optional, note = _a.note, label = _a.label, fieldId = _a.fieldId, inputClassName = _a.inputClassName, inputProps = _a.inputProps, description = _a.description, labelAfter = _a.labelAfter, rest = __rest(_a, ["component", "className", "optional", "note", "label", "fieldId", "inputClassName", "inputProps", "description", "labelAfter"]);
    var classOfComponent = cn(styles['text-field'], className);
    var keyOfField = fieldId || "field-" + rest.name;
    var contentOfLabel = label && (_jsxs(Typography, __assign({ variant: TypoVariants.body2, component: "label", htmlFor: keyOfField, className: styles.label + " " + styles.spaceBetween }, { children: [_jsx(MinorTooltip, __assign({ description: description }, { children: label }), void 0), !!labelAfter && labelAfter] }), void 0));
    var contentOfOptional = optional && (_jsx(Typography, __assign({ variant: TypoVariants.body2, className: styles.optional, component: "span" }, { children: optional }), void 0));
    var contentOfInput = (_jsx(InputComponentRenderer, __assign({}, rest, inputProps, { id: keyOfField, ref: ref, className: inputClassName }), void 0));
    var contentOfNote = note && (_jsx(Typography, __assign({ variant: TypoVariants.caption, className: styles.note, component: "span" }, { children: note }), void 0));
    return (_jsxs("div", __assign({ className: classOfComponent }, { children: [contentOfLabel, contentOfOptional, contentOfInput, contentOfNote] }), void 0));
});
TextareaField.displayName = 'TextareaField';
export default TextareaField;
