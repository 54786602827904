import { useCallback, useMemo } from 'react';
import {
  GetPaymentReportRequest,
  GetPlayerReportRequest,
} from '@dceu/dailyplanet/dailyplanet_overall_report_pb';
import useFilter from '@core/uikit/build/src/hooks/useFilter';

import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getPaymentReportThunk,
  getPaymentStatsThunk,
  getPlayerReportThunk,
} from 'redux/features/saleReportOverview/thunks';
import { selectStateSaleReportOverview } from 'redux/features/saleReportOverview/slice';
import { compareSortOfPayment, compareSortOfPlayer } from './const';

export const useSaleReportOverviewAPI = () => {
  const dispatch = useAppDispatch();
  const {
    playerReports,
    playerReportsLoading,
    paymentReports,
    paymentReportsLoading,
    paymentStats,
    paymentStatsLoading,
  } = useAppSelector(selectStateSaleReportOverview);
  const { orderBy, sortName } = useFilter();

  const getPlayerReport = useCallback(
    (req: GetPlayerReportRequest.AsObject) => {
      dispatch(getPlayerReportThunk(req));
    },
    [dispatch],
  );

  const getPaymentReport = useCallback(
    (req: GetPaymentReportRequest.AsObject) => {
      dispatch(getPaymentReportThunk(req));
    },
    [dispatch],
  );

  const getPaymentStats = useCallback(() => {
    dispatch(getPaymentStatsThunk({}));
  }, [dispatch]);

  const playerReportsWithSort = useMemo(() => {
    if (!orderBy || !sortName) return playerReports;
    return [...playerReports].sort(
      compareSortOfPlayer[`${sortName}_${orderBy}`],
    );
  }, [orderBy, playerReports, sortName]);

  const paymentReportsWithSort = useMemo(() => {
    if (!orderBy || !sortName) return paymentReports;
    return [...paymentReports].sort(
      compareSortOfPayment[`${sortName}_${orderBy}`],
    );
  }, [orderBy, paymentReports, sortName]);

  return {
    playerReportsWithSort,
    playerReportsLoading,
    getPlayerReport,
    paymentReportsWithSort,
    paymentReportsLoading,
    getPaymentReport,
    paymentStats,
    paymentStatsLoading,
    getPaymentStats,
  };
};
