import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetCommissionGamesReportRequest } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';
import { Grid } from '@material-ui/core';
import LiveOpsTable from '@core/uikit/build/src/components/LiveOpsTable/LiveOpsTable';
import WrapperFilter from '@core/uikit/build/src/components/WrapperFilter';
import { FilterDropdown, ResetAllFilter } from '@core/uikit';
import FilterDateRange from '@core/date-range-filter/build/src/FilterDateRange';
import useFilter, { propsDefault } from '@core/uikit/build/src/hooks/useFilter';
import { getExportNameFormat, getLast4Weeks, setTimestampPB } from '@core/okit';
import { ButtonExportCsv } from '@core/oui/build/src/components';

import { useColumn } from './columns';
import { calculateSumCommission } from './const';
import { FilterConfig, VIEW_BY_OPTION, ViewBy, getTenantId } from '../const';
import { useSaleReportGameTypeAPI } from '../useSaleReportGameTypeAPI';

const Commission = () => {
  const { t } = useTranslation();
  const { columns } = useColumn();

  const {
    commissionList,
    commissionLoading,
    commissionListWithSort,
    getCommissionGamesReport,
  } = useSaleReportGameTypeAPI();
  const {
    query,
    startDate,
    endDate,
    startDateObject: from,
    endDateObject: to,
    pushQueries,
  } = useFilter();

  const { start, end } = getLast4Weeks();
  const viewById = query.get(FilterConfig.VIEW_BY) || ViewBy.ALL_PLAYER_TYPE;

  useEffect(() => {
    if (!startDate && !endDate) {
      pushQueries({
        [propsDefault.startDateName]: start,
        [propsDefault.endDateName]: end,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (!startDate) return;
    const req = new GetCommissionGamesReportRequest().toObject();
    req.from = startDate ? from : setTimestampPB(start);
    req.to = endDate ? to : setTimestampPB(end);
    req.tenantIdsList = getTenantId(Number(viewById));

    getCommissionGamesReport(req);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end, endDate, getCommissionGamesReport, start, startDate, viewById]);

  const csvData = useMemo(() => {
    let dataExport = [columns.map((c) => c.Header.toString())];

    if (!commissionListWithSort?.length) return dataExport;
    commissionListWithSort.forEach((item) => {
      dataExport.push([
        t(`TOPIC.${item.topic}`),
        item.sessions.toString(),
        item.players.toString(),
        item.tax.toString(),
        t(`KPI::RTP_TARGET.${item.topic}`),
        item.rtp.toString(),
        item.bet.toString(),
        item.normalWin.toString(),
        item.jackpotWin.toString(),
        item.totalWin.toString(),
        item.progressive.toString(),
        item.fee.toString(),
        item.houseGain.toString(),
      ]);
    });
    return dataExport;
  }, [columns, commissionListWithSort, t]);

  return (
    <>
      <WrapperFilter>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <FilterDateRange />
              </Grid>
              <Grid item>
                <FilterDropdown
                  name={FilterConfig.VIEW_BY}
                  options={VIEW_BY_OPTION()}
                  defaultValue={ViewBy.ALL_PLAYER_TYPE}
                  displayName={t('KPI::RNG.VIEW_BY')}
                  selectText={t('TEXT.SELECT')}
                />
              </Grid>
              <Grid item>
                <ResetAllFilter />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ButtonExportCsv
              csvData={csvData}
              loading={commissionLoading}
              filename={getExportNameFormat('commission_game_report')}
            />
          </Grid>
        </Grid>
      </WrapperFilter>

      <LiveOpsTable
        overflowX
        onlyScreen
        stickyColumn
        columns={columns}
        loading={commissionLoading}
        data={commissionListWithSort}
        footerData={calculateSumCommission(commissionList)}
      />
    </>
  );
};

export default Commission;
