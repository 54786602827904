export var ToggleStatuses;
(function (ToggleStatuses) {
    ToggleStatuses["unchecked"] = "unchecked";
    ToggleStatuses["checked"] = "checked";
})(ToggleStatuses || (ToggleStatuses = {}));
export var ToggleColors;
(function (ToggleColors) {
    ToggleColors["primary"] = "primary";
    ToggleColors["red"] = "red";
    ToggleColors["green"] = "green";
    ToggleColors["blue"] = "blue";
    ToggleColors["inherit"] = "inherit";
})(ToggleColors || (ToggleColors = {}));
