var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
//@ts-nocheck
import { createContext, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resolveUrlParams } from './resolve_url_params';
var UrlParamsContext = createContext({
    urlParams: {},
});
export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        var location = useLocation();
        var navigate = useNavigate();
        var params = useParams();
        return _jsx(Component, __assign({}, props, { router: { location: location, navigate: navigate, params: params } }), void 0);
    }
    return ComponentWithRouterProp;
}
var UrlParamsProvider = withRouter(function (_a) {
    var location = _a.location, children = _a.children;
    var refUrlParams = useRef(resolveUrlParams(location));
    var urlParams = useMemo(function () { return resolveUrlParams(location); }, [location]);
    refUrlParams.current = urlParams;
    var contextValue = useMemo(function () {
        return {
            urlParams: urlParams,
        };
    }, [urlParams]);
    return _jsx(UrlParamsContext.Provider, { children: children, value: contextValue }, void 0);
});
export { UrlParamsProvider, UrlParamsContext };
