var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function AngleDown(props) {
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M12.42 15.678L18.07 10.018C18.1637 9.92508 18.2381 9.81448 18.2889 9.69262C18.3397 9.57076 18.3658 9.44006 18.3658 9.30804C18.3658 9.17603 18.3397 9.04533 18.2889 8.92347C18.2381 8.80161 18.1637 8.69101 18.07 8.59804C17.8826 8.41179 17.6292 8.30725 17.365 8.30725C17.1008 8.30725 16.8474 8.41179 16.66 8.59804L11.66 13.548L6.71 8.59804C6.52264 8.41179 6.26919 8.30725 6.005 8.30725C5.74081 8.30725 5.48736 8.41179 5.3 8.59804C5.20551 8.69066 5.13034 8.8011 5.07884 8.92298C5.02735 9.04485 5.00055 9.17574 5 9.30804C5.00055 9.44035 5.02735 9.57123 5.07884 9.69311C5.13034 9.81498 5.20551 9.92543 5.3 10.018L10.95 15.678C11.0436 15.7795 11.1573 15.8606 11.2838 15.916C11.4103 15.9714 11.5469 16 11.685 16C11.8231 16 11.9597 15.9714 12.0862 15.916C12.2127 15.8606 12.3264 15.7795 12.42 15.678Z", fill: "currentColor" })));
}
export default AngleDown;
