export var formatPriceVND = function (number) {
    var result = Number(number);
    return typeof result === 'number'
        ? result.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.')
        : 0;
};
export function toNumber(value) {
    if (value !== undefined) {
        return parseInt(value, 10);
    }
}
export function toString(value) {
    if (value === '' || value === 'null' || value === 'undefined') {
        return;
    }
    return value;
}
export function toBoolean(value) {
    return value === 'true';
}
export function toFAlse(value) {
    return value === 'true';
}
var ELB_LABEL = '_____EBL_____';
export var disableLog = function (env, debug) {
    var ebl = localStorage.getItem(ELB_LABEL);
    if (ebl && ebl === ELB_LABEL)
        return;
    if (env === 'production' && debug === 'false') {
        console.log = function () { };
        console.warn = function () { };
        console.debug = function () { };
    }
};
