export var THOUSAND_SEPARATOR = ',';
export var DECIMAL_SEPARATOR = '.';
export var SUFFIX_NUMBER = ' đ';
export var FRACTION_DIGITS = 2;
export var convertNumber = function (value) {
    if (typeof value === 'string') {
        return parseInt((value || '').replace(/[^0-9]+/g, ''), 10);
    }
    return value;
};
export var formatMoney = function (num) {
    if (isNaN(+num))
        return num;
    return parseFloat((+num).toFixed(FRACTION_DIGITS))
        .toString()
        .replace(THOUSAND_SEPARATOR, DECIMAL_SEPARATOR)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + THOUSAND_SEPARATOR);
};
export var stringToArrayOfNumber = function (s) {
    return (s === null || s === void 0 ? void 0 : s.split(',').map(Number)) || [];
};
export var stringToArrayOfString = function (s) {
    return (s === null || s === void 0 ? void 0 : s.split(DECIMAL_SEPARATOR)) || [];
};
export var shortenNumber = function (n) {
    if (n > Math.pow(10, 9))
        return n / Math.pow(10, 9) + "B";
    if (n > Math.pow(10, 6))
        return n / Math.pow(10, 6) + "M";
    if (n > Math.pow(10, 3))
        return n / Math.pow(10, 3) + "K";
    return n.toString();
};
