var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ButlerClient } from '@gameloot/mykey/Mykey_butlerServiceClientPb';
import { UpdateUserRequest, ResendResetPasswordOtpRequest, GetTelegramRequest, CreateUpdatePhoneNumberOTPRequest, SubmitUpdatePhoneNumberOTPRequest, } from '@gameloot/mykey/mykey_butler_pb';
import { PhoneNumber } from '@gameloot/mykey/mykey_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcMyKeyClient = /** @class */ (function (_super) {
    __extends(rpcMyKeyClient, _super);
    function rpcMyKeyClient(config) {
        var _this = this;
        config.serviceName = 'MYKEY';
        _this = _super.call(this, ButlerClient, config) || this;
        return _this;
    }
    rpcMyKeyClient.prototype.updateUser = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, changeList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateUserRequest();
                        changeList = [];
                        p.changesList.map(function (_a) {
                            var email = _a.email, phoneNumber = _a.phoneNumber;
                            if (phoneNumber) {
                                var change = new UpdateUserRequest.Change();
                                var phoneNumberObj = new PhoneNumber();
                                phoneNumberObj.setCountryCode(phoneNumber.countryCode);
                                phoneNumberObj.setNationalNumber(phoneNumber.nationalNumber);
                                change.setPhoneNumber(phoneNumberObj);
                                changeList.push(change);
                            }
                            if (email) {
                                var change = new UpdateUserRequest.Change();
                                change.setEmail(email);
                                changeList.push(change);
                            }
                        });
                        req.setUserId(p.userId);
                        req.setChangesList(changeList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateUser', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyKeyClient.prototype.resendResetPasswordOtp = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ResendResetPasswordOtpRequest();
                        req.setUserId(p.userId);
                        return [4 /*yield*/, this.gRPCClientRequest('resendResetPasswordOtp', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyKeyClient.prototype.getTelegram = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetTelegramRequest();
                        req.setUserId(p.userId);
                        return [4 /*yield*/, this.gRPCClientRequest('getTelegram', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyKeyClient.prototype.createUpdatePhoneNumberOTP = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, phoneNumberObj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateUpdatePhoneNumberOTPRequest();
                        req.setUserId(p.userId);
                        req.setMethod(p.method);
                        if (p.phoneNumber) {
                            phoneNumberObj = new PhoneNumber();
                            phoneNumberObj.setCountryCode(p.phoneNumber.countryCode);
                            phoneNumberObj.setNationalNumber(p.phoneNumber.nationalNumber);
                            req.setPhoneNumber(phoneNumberObj);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('createUpdatePhoneNumberOTP', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyKeyClient.prototype.submitUpdatePhoneNumberOTP = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new SubmitUpdatePhoneNumberOTPRequest();
                        req.setOtpId(p.otpId);
                        req.setOtp(p.otp);
                        return [4 /*yield*/, this.gRPCClientRequest('submitUpdatePhoneNumberOTP', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcMyKeyClient;
}(gRPCClientAbstract));
export default rpcMyKeyClient;
