import { useTranslation } from 'react-i18next';
import {
  formatDate,
  getISOWeekTz,
  getMonthTz,
  getQuarterTz,
  getYearTz,
} from '@core/okit/build/utils/date';

import {
  FilterConfig,
  RangeType,
  TimeRange,
} from 'pages/SaleReportOverview/types';

export const useForColumns = (
  qMode: FilterConfig,
  qRange: RangeType,
  qViewBy: RangeType,
) => {
  const { t } = useTranslation();

  const groupByFilter = {
    [RangeType.DAILY]: `${t('DATE')}`,
    [RangeType.WEEKLY]: `${t('WEEK')}`,
    [RangeType.MONTHLY]: `${t('MONTH')}`,
    [RangeType.QUARTERLY]: `${t('QUARTER')}`,
    [RangeType.YEARLY]: `${t('YEAR')}`,
  };

  const dateTimeColumnHeader = () => {
    if (!qMode) return `${t('DATE')}`;

    return qViewBy ? groupByFilter[qViewBy] : groupByFilter[qRange];
  };

  const dateTimeColumnValue = (dateTime: number) => {
    const dateFilter = {
      [RangeType.DAILY]: formatDate(dateTime * 1000),
      [RangeType.WEEKLY]: `${t('WEEK')} ${getISOWeekTz(
        dateTime * 1000,
      )}, ${getYearTz(dateTime * 1000)}`,
      [RangeType.MONTHLY]: `${t(
        `MONTH.${getMonthTz(dateTime * 1000)}`,
      )}, ${getYearTz(dateTime * 1000)}`,
      [RangeType.QUARTERLY]: `${t('QUARTER')} ${getQuarterTz(
        dateTime * 1000,
      )}, ${getYearTz(dateTime * 1000)}`,
      [RangeType.YEARLY]: `${getYearTz(dateTime * 1000)}`,
    };

    if (!qMode) return formatDate(dateTime * 1000);

    if (!qViewBy || qViewBy === TimeRange.Daily) return dateFilter[qRange];
    return dateFilter[qViewBy];
  };

  return { dateTimeColumnHeader, dateTimeColumnValue };
};
