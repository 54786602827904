var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var WithdrawApprovalSelected = function (props) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
    React.createElement("path", { fill: "#7462E1", d: "M14 13c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0017 10c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0014 7c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0011 10c0 .833.292 1.542.875 2.125A2.893 2.893 0 0014 13zm-7 3c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 015 14V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 017 4h14c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v8c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0121 16H7zm-4 4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 011 18V8c0-.283.096-.521.288-.713A.967.967 0 012 7a.97.97 0 01.713.287A.97.97 0 013 8v10h16c.283 0 .52.096.712.288A.965.965 0 0120 19c0 .283-.096.52-.288.712A.965.965 0 0119 20H3zM7 8c.55 0 1.02-.196 1.412-.588C8.804 7.021 9 6.55 9 6H7v2zm14 0V6h-2c0 .55.196 1.02.587 1.412.392.392.863.588 1.413.588zM7 14h2c0-.55-.196-1.021-.588-1.413A1.925 1.925 0 007 12v2zm12 0h2v-2c-.55 0-1.021.196-1.413.587A1.928 1.928 0 0019 14z" }))); };
export default WithdrawApprovalSelected;
