const salesDetailReport = {
  path: '/kpi/sales-detail-report',
  overview: {
    path: '/kpi/sales-detail-report/overview',
  },
  gameType: {
    path: '/kpi/sales-detail-report/game-type',
    rng: {
      path: '/kpi/sales-detail-report/game-type/rng',
    },
    tab: {
      path: '/kpi/sales-detail-report/game-type/:tab',
    },
  },
};

export const routeConfig = {
  home: {
    path: '/',
  },
  personalProfile: {
    path: '/personal-profile',
  },
  login: {
    path: '/login',
  },
  logout: {
    path: '/logout',
  },
  resetPassword: {
    path: '/reset-password',
  },
  changePassword: {
    path: '/change-password',
  },
  sso: {
    path: '/sso',
  },
  kpi: {
    path: '/kpi',
  },
  salesDetailReport,
  compNotFound: {
    path: '*',
  },
};
