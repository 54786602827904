var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Sales = function (props) {
    return (React.createElement("svg", __assign({ width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M9 10a1 1 0 00-1 1v6a1 1 0 001 1h10a1 1 0 001-1v-6a1 1 0 00-1-1H9zm-3 1a3 3 0 013-3h10a3 3 0 013 3v6a3 3 0 01-3 3H9a3 3 0 01-3-3v-6z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M14 13a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M2.879 4.879A3 3 0 015 4h10a3 3 0 013 3v2a1 1 0 11-2 0V7a1 1 0 00-1-1H5a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 110 2H5a3 3 0 01-3-3V7a3 3 0 01.879-2.121z", clipRule: "evenodd" })));
};
export default Sales;
