var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, createStyles } from '@material-ui/core';
import TabPanel from './TabPanel';
import Tabs from './Tabs';
import Tab from './Tab';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            padding: theme.spacing(2),
        },
    });
});
var FormTabs = function (_a) {
    var currentTab = _a.currentTab, tabList = _a.tabList, onChange = _a.onChange;
    var classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx(Tabs, __assign({ value: currentTab, onChange: onChange }, { children: tabList.map(function (tab) { return (_jsx(Tab, { value: tab.value, label: tab.label }, tab.value)); }) }), void 0), tabList.map(function (tab) { return (_jsx(TabPanel, __assign({ value: tab.value, index: currentTab, spacingBox: 0 }, { children: tab.panel }), tab.value)); })] }, void 0));
};
export default FormTabs;
