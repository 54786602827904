export var DividerType;
(function (DividerType) {
    DividerType["line"] = "line";
    DividerType["filled"] = "filled";
})(DividerType || (DividerType = {}));
export var DividerOrientation;
(function (DividerOrientation) {
    DividerOrientation["horizontal"] = "horizontal";
    DividerOrientation["vertical"] = "vertical";
})(DividerOrientation || (DividerOrientation = {}));
export var DividerVarients;
(function (DividerVarients) {
    DividerVarients["fullWidth"] = "fullWidth";
    DividerVarients["inset"] = "inset";
    DividerVarients["middle"] = "middle";
})(DividerVarients || (DividerVarients = {}));
