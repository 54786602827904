var _a;
import React from 'react';
import { Topic } from '@gameloot/topic/topic_pb';
import RedirectTo from './RedirectTo';
var MODULE_GAME_INSIGHT = Number(process.env.REACT_APP_GAME_INSIGHT);
var GAME_INSIGHT_URL = process.env.REACT_APP_GAME_INSIGHT_URL;
var GAME_INSIGHT_PATH = (_a = {},
    _a[Topic.BAU_CUA] = '/aquaman/table-game/baucua',
    _a[Topic.BACCARAT] = '/aquaman/table-game/baccarat',
    _a[Topic.UPDOWN_CARD] = '/aquaman/mini-game/updown',
    _a[Topic.SICBO] = '/aquaman/table-game/sicbo',
    _a[Topic.XOCDIA] = '/aquaman/table-game/xocdia',
    _a[Topic.BACA] = '/aquaman/fish',
    _a[Topic.GOF] = '/aquaman/slot-game/gof',
    _a[Topic.GOW] = '/aquaman/slot-game/gow',
    _a[Topic.BAU_CUA_MINI] = '/aquaman/mini-game/baucua-mini',
    _a[Topic.PKMN] = '/aquaman/mini-game/poker-mini',
    _a[Topic.BAS] = '/aquaman/mini-game/taixiu-mini',
    _a[Topic.SAM] = '/aquaman/card-game/sam',
    _a[Topic.MABI] = '/aquaman/card-game/mau-binh',
    _a[Topic.LODE] = '/aquaman/lottery-game/lode',
    _a[Topic.TALA] = '/aquaman/card-game/tala',
    _a[Topic.POKE] = '/aquaman/card-game/poker',
    _a[Topic.TINA] = '/aquaman/card-game/tlmn',
    _a[Topic.LIEN] = '/aquaman/card-game/lieng',
    _a);
var DEFAULT_PATH = '/aquaman/dashboard';
var RedirectToGameInsight = function (_a) {
    var moduleIdsList = _a.moduleIdsList, topic = _a.topic, sessionId = _a.sessionId, children = _a.children;
    return (React.createElement(RedirectTo, { moduleIdsList: moduleIdsList, currentModule: MODULE_GAME_INSIGHT, origin: GAME_INSIGHT_URL, pathname: topic && sessionId
            ? GAME_INSIGHT_PATH[topic] + "?s=" + sessionId
            : DEFAULT_PATH }, children));
};
export default RedirectToGameInsight;
