var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { AngleLeft, AngleRight } from '@core/icons';
import cx from 'classnames';
import Button, { ButtonVariants } from '../Button';
import LiveOpsFormatNumber from '../FormatNumber';
import Typography, { TypoVariants, TypoWeights, } from '../Typography';
import { START_INDEX, STEP_VALUE } from './const';
import compose from '../../utils/common/compose';
import { getLabelFromIndex } from './utils';
import styles from './styles.module.scss';
var defaultProps = {
    component: 'div',
    displayNumber: 5,
    totalRecord: 0,
    currentOfPage: 1,
    pageSize: 0,
};
export var Pagination = function (props) {
    var _a = __assign(__assign({}, defaultProps), props), Component = _a.component, totalOfPage = _a.total, currentOfPage = _a.current, displayOfButton = _a.displayNumber, className = _a.className, onChangePage = _a.onChangePage, totalRecord = _a.totalRecord, pageSize = _a.pageSize, loading = _a.loading, rest = __rest(_a, ["component", "total", "current", "displayNumber", "className", "onChangePage", "totalRecord", "pageSize", "loading"]);
    var isAllowedPrev = currentOfPage - STEP_VALUE < START_INDEX || loading;
    var isAllowedNext = currentOfPage + STEP_VALUE > totalOfPage || loading;
    var classOfComponent = cx(styles.pagination, className);
    var classOfIconPrev = cx(styles.icon, styles.prev, isAllowedPrev && styles.disabled);
    var classOfIconNext = cx(styles.icon, styles.next, isAllowedNext && styles.disabled);
    var _b = __read(useState(getLabelFromIndex(currentOfPage)), 2), setInputValue = _b[1];
    var handleOnChangePage = useCallback(function (selectedPage) {
        return onChangePage(Math.min(totalOfPage, Math.abs(selectedPage)));
    }, [onChangePage, totalOfPage]);
    var handleOnClickButton = useCallback(function (nextPage) { return function () {
        return compose(handleOnChangePage, function (selectedPage) {
            return setInputValue(selectedPage);
        })(nextPage);
    }; }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalOfPage, handleOnChangePage, setInputValue]);
    var indexOfPrev = Math.max(START_INDEX, currentOfPage - STEP_VALUE);
    var indexOfNext = Math.min(totalOfPage, currentOfPage + STEP_VALUE);
    if (!currentOfPage || !pageSize || !totalRecord)
        return null;
    var fromVal = currentOfPage * pageSize - pageSize + 1;
    var toVal = Math.min(pageSize * currentOfPage, totalRecord);
    var from = _jsx(LiveOpsFormatNumber, { value: fromVal }, void 0);
    var to = _jsx(LiveOpsFormatNumber, { value: toVal }, void 0);
    var total = _jsx(LiveOpsFormatNumber, { value: totalRecord }, void 0);
    return (_jsx(Component, __assign({ className: classOfComponent }, { children: _jsxs(Grid, __assign({ container: true, alignItems: "center", spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: "auto" }, { children: _jsx(Button, { variant: ButtonVariants.plainSecondary, icon: AngleLeft, onClick: handleOnClickButton(indexOfPrev), className: classOfIconPrev, disabled: isAllowedPrev }, void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: "auto", className: styles['display-rows'] }, { children: _jsxs(Typography, __assign({ weight: TypoWeights.bold, variant: TypoVariants.body1 }, { children: [from, " - ", to, " of ", total] }), void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: "auto" }, { children: _jsx(Button, { variant: ButtonVariants.plainSecondary, icon: AngleRight, onClick: handleOnClickButton(indexOfNext), className: classOfIconNext, disabled: isAllowedNext }, void 0) }), void 0)] }), void 0) }), void 0));
};
Pagination.displayName = 'Pagination';
export default Pagination;
