var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import AllowedTo from '../AllowedTo/AllowedTo';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../components/Typography';
import { hasChildren } from './utils';
var useStyles = makeStyles(function (theme) { return ({
    list: {
        padding: theme.spacing(0, 2),
    },
    listItem: {
        marginBottom: theme.spacing(1),
        borderRadius: theme.spacing(1),
    },
    listItemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(1.5),
        '& svg': {
            color: '#78838F',
        },
    },
    listItemSelected: {
        backgroundColor: '#1C222C !important',
        '& svg': {
            color: '#1B945C',
        },
    },
}); });
export default function SidebarList(_a) {
    var sidebar = _a.sidebar;
    return (_jsx(_Fragment, { children: sidebar.map(function (item, key) { return (_jsx(AllowedTo, __assign({ perform: item.permissions, logic: "or" }, { children: _jsx(MenuItem, { item: item }, key) }), void 0)); }) }, void 0));
}
var MenuItem = function (_a) {
    var item = _a.item;
    var Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return _jsx(Component, { item: item }, void 0);
};
var SingleLevel = function (_a) {
    var item = _a.item;
    var classes = useStyles();
    var navigate = useNavigate();
    var location = useLocation();
    return (_jsxs(ListItem, __assign({ button: true, selected: location.pathname.includes(item.path), className: classes.listItem, onClick: function () { return navigate(item.path); }, classes: {
            selected: classes.listItemSelected,
        } }, { children: [_jsx(ListItemIcon, __assign({ classes: {
                    root: classes.listItemIcon,
                } }, { children: item.icon }), void 0), _jsx(ListItemText, { primary: _jsx(Typography, __assign({ variant: TypoVariants.button2, weight: TypoWeights.medium, type: location.pathname.includes(item.path)
                        ? TypoTypes.primary
                        : TypoTypes.sub }, { children: item.title }), void 0) }, void 0)] }), void 0));
};
var MultiLevel = function (_a) {
    var item = _a.item;
    var classes = useStyles();
    var location = useLocation();
    var _b = item.items, children = _b === void 0 ? [] : _b;
    var _c = __read(useState(Boolean(children.find(function (item) { return location.pathname.includes(item.path); }))), 2), open = _c[0], setOpen = _c[1];
    var handleClick = function () {
        setOpen(function (prev) { return !prev; });
    };
    return (_jsxs(React.Fragment, { children: [_jsxs(ListItem, __assign({ button: true, onClick: handleClick, className: classes.listItem, classes: {
                    selected: classes.listItemSelected,
                } }, { children: [_jsx(ListItemIcon, __assign({ classes: {
                            root: classes.listItemIcon,
                        } }, { children: item.icon }), void 0), _jsx(ListItemText, { primary: _jsx(Typography, __assign({ variant: TypoVariants.button2, weight: TypoWeights.medium }, { children: item.title }), void 0) }, void 0), open ? _jsx(ExpandLessIcon, {}, void 0) : _jsx(ExpandMoreIcon, {}, void 0)] }), void 0), _jsx(Collapse, __assign({ in: open, timeout: "auto", unmountOnExit: true }, { children: _jsx(List, __assign({ className: classes.list }, { children: children.map(function (item, key) { return (_jsx(MenuItem, { item: item }, key)); }) }), void 0) }), void 0)] }, void 0));
};
