import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { getLngInitial, DEFAULT_LANG } from '@core/okit';

const EXPIRE_TIME_DEFAULT = 3; // minutes
const supportedLngs = ['en', 'vi', 'cn', 'cn_trad', 'debug'];

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime:
            (process.env.REACT_APP_LANGUAGE_EXPIRE_TIME ||
              EXPIRE_TIME_DEFAULT) *
            60 *
            1000,
        },
        {
          loadPath: `${process.env.REACT_APP_LANGUAGE_URL}/{{ns}}_{{lng}}.json`,
          allowMultiLoading: true,
        },
      ],
    },
    returnEmptyString: false,
    ignoreJSONStructure: true,

    lng: getLngInitial(supportedLngs),

    ns: ['Common', 'KPI', 'Error'],
    defaultNS: 'Common',
    nsSeparator: '::',

    supportedLngs,
    fallbackLng: DEFAULT_LANG, //when specified language translations not present

    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
