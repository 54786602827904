var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Close, Edit } from '@core/icons';
import CommonToggle from '@core/uikit/build/src/components/CommonToggle';
import InputAdornment from '@core/uikit/build/src/components/InputAdornment';
import TextField from '@core/uikit/build/src/components/TextField';
import MoneyField from '@core/uikit/build/src/components/MoneyField';
import { Button, ButtonSizes, ButtonVariants } from '../Button';
import Typography, { TypoVariants, TypoWeights } from '../Typography';
import Icon from '../Icon';
import styles from './styles.module.scss';
var Status;
(function (Status) {
    Status[Status["ACTIVE"] = 1] = "ACTIVE";
    Status[Status["INACTIVE"] = 2] = "INACTIVE";
})(Status || (Status = {}));
var TextEditCard = function (_a) {
    var data = _a.data, orgValue = _a.orgValue, status = _a.status, headerText = _a.headerText, placeholder = _a.placeholder, active = _a.active, showToggle = _a.showToggle, loading = _a.loading, onSubmit = _a.onSubmit, handleToggle = _a.handleToggle, handleToggleRejectEdit = _a.handleToggleRejectEdit, _b = _a.type, type = _b === void 0 ? 'text' : _b, isNegative = _a.isNegative;
    var t = useTranslation().t;
    var initValue = useRef('');
    var _c = useState(false), isEdit = _c[0], setIsEdit = _c[1];
    var _d = useState(''), value = _d[0], setValue = _d[1];
    useEffect(function () {
        setValue(orgValue);
    }, [orgValue]);
    var handleChange = function (event) {
        setValue(event.target.value);
    };
    var handleKeyDown = function (event) {
        if ([109, 173, 189].includes(event.keyCode) && isNegative) {
            event.preventDefault();
        }
        if (event.keyCode === 190) {
            event.preventDefault();
        }
    };
    var handleEdit = function () {
        initValue.current = value;
        status && status === Status.ACTIVE
            ? handleToggleRejectEdit && handleToggleRejectEdit(true)
            : setIsEdit(!isEdit);
    };
    var handleRemoveValue = function () {
        setValue('');
    };
    var handleCancel = function (event) {
        event.preventDefault();
        event.stopPropagation();
        setValue(initValue.current);
        setTimeout(function () {
            setIsEdit(false);
        }, 0);
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSubmit(value)];
                case 1:
                    _a.sent();
                    setIsEdit(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: styles.wrapperItemCard },
        React.createElement("div", { className: "flex justify-between items-center " + styles.titleStyle },
            React.createElement(Typography, { variant: TypoVariants.buttonHighLevel, weight: TypoWeights.bold }, headerText),
            !isEdit ? (React.createElement("div", { className: "flex justify-end" },
                showToggle && (React.createElement(CommonToggle, { checked: !!active, data: data, handleToggle: handleToggle, disabledText: true })),
                React.createElement(Icon, { className: "cursor-pointer", onClick: handleEdit },
                    React.createElement(Edit, null)))) : (React.createElement("div", { className: "flex gap-8 justify-end" },
                React.createElement(Button, { variant: ButtonVariants.secondary, size: ButtonSizes.sm, onClick: handleCancel }, t('COMMON.TEXT.CANCEL')),
                React.createElement(Button, { size: ButtonSizes.sm, loading: loading, disabled: loading, onClick: handleSubmit }, t('TEXT.UPDATE'))))),
        type === 'text' && (React.createElement(TextField, { className: cn(styles.customTextField, !isEdit && styles.wrapperTextStyle, !isEdit && value && styles.textStyle), name: "", placeholder: placeholder, value: value ? value : isEdit ? '' : t('COMMON.NOT_PROVIDER_YET'), disabled: !isEdit, onChange: handleChange, afterInput: [
                React.createElement(InputAdornment, { onClick: handleRemoveValue },
                    React.createElement(Close, null)),
            ] })),
        type === 'number' && (React.createElement(MoneyField, { className: cn(styles.customTextField, !isEdit && styles.wrapperTextStyle, !isEdit && value && styles.textStyle), name: "", placeholder: placeholder, value: value ? value : isEdit ? '' : t('COMMON.NOT_PROVIDER_YET'), disabled: !isEdit, suffix: "", onChange: handleChange, onKeyDown: handleKeyDown, afterInput: [
                React.createElement(InputAdornment, { onClick: handleRemoveValue },
                    React.createElement(Close, null)),
            ] }))));
};
export default TextEditCard;
