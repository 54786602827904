var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var BauCuaMini = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M10.3115 5.5H19.5C19.5 5.5 19.5 8 18 9.5C16.5 11 15 11.5 14.5 11.5C14 11.5 9.25001 12 8.00001 13C6.75001 14 6.00051 15.75 8.00001 17.5C9.99951 19.25 13.5 20 16 19C18.5 18 20 15 20 15L22 20C22 20 18 21.5 14.5 22C11 22.5 7.50001 22 4.50001 19C1.50001 16 0.999513 12.25 3.50001 9C5.58301 6.292 8.70701 5.667 9.69101 5.5335C9.89686 5.50836 10.1042 5.49717 10.3115 5.5V5.5Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M13 11.5L10 5.5", stroke: "currentColor", strokeWidth: "2" }),
    React.createElement("path", { d: "M9 2H16C16.9283 2 17.8185 2.36875 18.4749 3.02513C19.1313 3.6815 19.5 4.57174 19.5 5.5V5.5", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M15 9C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7C14.4477 7 14 7.44772 14 8C14 8.55228 14.4477 9 15 9Z", fill: "currentColor" }))); };
export default BauCuaMini;
