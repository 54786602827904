export var ButtonVariants;
(function (ButtonVariants) {
    ButtonVariants["primary"] = "primary";
    ButtonVariants["secondary"] = "secondary";
    ButtonVariants["danger"] = "danger";
    ButtonVariants["ghost"] = "ghost";
    ButtonVariants["invert"] = "invert";
    ButtonVariants["select"] = "select";
    ButtonVariants["outline"] = "outline";
    ButtonVariants["disable"] = "disable";
    ButtonVariants["plainSecondary"] = "plain-secondary";
    ButtonVariants["plainDanger"] = "plain-danger";
    ButtonVariants["plainPrimary"] = "plain-primary";
})(ButtonVariants || (ButtonVariants = {}));
export var ButtonSizes;
(function (ButtonSizes) {
    ButtonSizes["lg"] = "lg";
    ButtonSizes["md"] = "md";
    ButtonSizes["sm"] = "sm";
})(ButtonSizes || (ButtonSizes = {}));
