export var TextareaSize;
(function (TextareaSize) {
    TextareaSize["md"] = "md";
    TextareaSize["lg"] = "lg";
})(TextareaSize || (TextareaSize = {}));
export var TextareaStatus;
(function (TextareaStatus) {
    TextareaStatus["primary"] = "primary";
    TextareaStatus["success"] = "success";
    TextareaStatus["warning"] = "warning";
    TextareaStatus["error"] = "error";
})(TextareaStatus || (TextareaStatus = {}));
