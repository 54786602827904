export var InputSizes;
(function (InputSizes) {
    InputSizes["md"] = "md";
    InputSizes["lg"] = "lg";
    InputSizes["xl"] = "xl";
})(InputSizes || (InputSizes = {}));
export var InputStatuses;
(function (InputStatuses) {
    InputStatuses["primary"] = "primary";
    InputStatuses["success"] = "success";
    InputStatuses["warning"] = "warning";
    InputStatuses["error"] = "error";
})(InputStatuses || (InputStatuses = {}));
