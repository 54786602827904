var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { ArrowSort, ArrowSortDesc, ArrowSortAsc } from '@core/icons';
import useFilter from '../../../hooks/useFilter';
import styles from './styles.module.scss';
import useSort, { propsDefault } from '../../../hooks/useTableSort';
var arrowByOrder = {
    asc: _jsx(ArrowSortAsc, {}, void 0),
    desc: _jsx(ArrowSortDesc, {}, void 0),
    none: _jsx(ArrowSort, {}, void 0),
};
var NextSortState = {
    none: 'desc',
    desc: 'asc',
    asc: 'none',
};
var SortCell = function (_a) {
    var _b;
    var children = _a.children, sortName = _a.sortName, align = _a.align;
    var _c = useFilter(), pushQueries = _c.pushQueries, popQueries = _c.popQueries;
    var _d = useSort(), orderBy = _d.orderBy, currentName = _d.name;
    var changeState = function () {
        var _a;
        if (currentName !== sortName) {
            orderBy = 'none'; // set default
        }
        var nextOrderBy = NextSortState[orderBy] || 'none';
        if (nextOrderBy === 'none') {
            return popQueries([propsDefault.sortNameKey, propsDefault.orderByKey]);
        }
        pushQueries((_a = {},
            _a[propsDefault.sortNameKey] = sortName,
            _a[propsDefault.orderByKey] = nextOrderBy,
            _a));
    };
    if (!sortName)
        return _jsx(_Fragment, { children: children }, void 0);
    var cls = classNames(styles.sort, (_b = {},
        _b[styles.headerAlignReverse] = align === 'right',
        _b));
    if (sortName !== currentName) {
        return (_jsxs("div", __assign({ onClick: changeState, className: cls }, { children: [children, _jsx("div", __assign({ className: styles.arrowBox }, { children: _jsx(ArrowSort, {}, void 0) }), void 0)] }), void 0));
    }
    var displayArrow = arrowByOrder[orderBy] && (_jsx("div", __assign({ className: styles.arrowBox }, { children: arrowByOrder[orderBy] }), void 0));
    return (_jsxs("div", __assign({ onClick: changeState, className: cls }, { children: [children, displayArrow] }), void 0));
};
export default SortCell;
