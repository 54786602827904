import { createPortal } from 'react-dom';
import usePortal from '../../hooks/usePortal';
import checkIsBrowser from '../../utils/common/checkIsBrowser';
export var PortalIds;
(function (PortalIds) {
    PortalIds["popover"] = "payment-popover";
    PortalIds["dialog"] = "payment-dialog";
    PortalIds["toast"] = "payment-toast";
    PortalIds["tooltip"] = "payment-popper";
})(PortalIds || (PortalIds = {}));
var Portal = function (_a) {
    var id = _a.id, children = _a.children;
    var isBrowser = checkIsBrowser();
    if (!isBrowser) {
        return null;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    var element = usePortal(id);
    return createPortal(children, element);
};
export default Portal;
