var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var PaymentConfig = function (props) {
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fill: "currentColor", d: "M12 22c-1.3 0-2.3-.8-2.6-2.1 0-.1-.1-.2-.2-.3-.1-.1-.2-.2-.3-.2-.1 0-.2-.1-.3-.1-.1 0-.2 0-.3.1-1.1.7-2.4.5-3.3-.4-.9-.9-1.1-2.2-.4-3.3.1-.1.1-.2.1-.3 0-.1 0-.2-.1-.3-.1-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2C2.8 14.3 2 13.3 2 12s.8-2.3 2.1-2.6c.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3-.7-1.1-.6-2.5.3-3.4.9-.9 2.3-1 3.4-.4.2.1.4.1.7.1.2-.1.4-.3.4-.5C9.7 2.8 10.7 2 12 2s2.3.8 2.6 2.1c0 .1.1.2.2.3.1.1.2.2.3.2.1 0 .2.1.3.1.1 0 .2 0 .3-.1 1.1-.7 2.4-.5 3.3.4.9.9 1.1 2.2.4 3.3-.1.1-.1.2-.1.3 0 .1 0 .2.1.3 0 .1.1.2.2.3.1.1.2.1.3.2 1.3.3 2.1 1.3 2.1 2.6s-.8 2.3-2.1 2.6c-.1 0-.2.1-.3.2-.1.1-.2.2-.2.3 0 .1-.1.2-.1.3 0 .1 0 .2.1.3.7 1.1.5 2.4-.4 3.3-.9.9-2.2 1.1-3.3.4-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3.1-.1 0-.2.1-.3.2-.1.1-.1.2-.2.3-.3 1.3-1.3 2.1-2.6 2.1zm-3.4-4.6c.4 0 .7.1 1 .2.4.2.8.4 1 .7.4.3.6.7.7 1.1.1.6.6.6.7.6.1 0 .6 0 .7-.6.1-.4.3-.8.6-1.1.3-.3.6-.6 1-.7.4-.2.8-.2 1.3-.2.4 0 .8.2 1.2.4.4.3.8 0 .9-.1.1-.1.4-.4.1-.9-.2-.4-.4-.8-.4-1.2 0-.4 0-.9.2-1.2.2-.4.4-.8.7-1 .3-.3.7-.5 1.1-.6.6-.2.6-.7.6-.8 0-.1 0-.6-.6-.7-.4-.1-.8-.3-1.1-.6-.3-.3-.6-.6-.7-1-.2-.4-.2-.8-.2-1.3 0-.4.2-.8.4-1.2.3-.5 0-.8-.1-.9-.1-.1-.4-.4-.9-.1-.4.2-.8.4-1.2.4-.4 0-.9 0-1.3-.2s-.8-.4-1-.7c-.3-.3-.5-.7-.6-1.1-.1-.6-.6-.6-.7-.6-.1 0-.6 0-.7.6-.2.8-.8 1.5-1.6 1.8-.8.4-1.7.3-2.5-.2-.4-.2-.8 0-.9.1-.1.1-.3.5-.1.9.3.4.4.8.4 1.2 0 .4 0 .9-.2 1.3-.1.4-.4.7-.7 1-.3.3-.7.5-1.1.6-.6.1-.6.6-.6.7 0 .1 0 .6.6.7.4.1.8.3 1.1.6.3.3.6.6.7 1 .2.4.2.8.2 1.3s-.2.8-.4 1.2c-.3.5 0 .8.1.9.1.1.4.4.9.1.4-.2.8-.4 1.2-.4h.2z" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 7c.552 0 1 .407 1 .91v.454h1c.552 0 1 .407 1 .909s-.448.909-1 .909h-2.5a.526.526 0 00-.354.133.435.435 0 00-.146.321c0 .12.053.237.146.322a.527.527 0 00.354.133h1c.663 0 1.299.24 1.768.666.469.426.732 1.004.732 1.607 0 .602-.263 1.18-.732 1.607-.35.317-.791.531-1.268.62v.5c0 .502-.448.909-1 .909s-1-.407-1-.91v-.454h-1c-.552 0-1-.407-1-.909s.448-.909 1-.909h2.5c.133 0 .26-.048.354-.133a.435.435 0 00.146-.321.435.435 0 00-.146-.322.527.527 0 00-.354-.133h-1c-.663 0-1.299-.24-1.768-.666A2.173 2.173 0 019 10.636c0-.602.263-1.18.732-1.607.35-.317.791-.531 1.268-.62v-.5c0-.502.448-.909 1-.909z", clipRule: "evenodd" })));
};
export default PaymentConfig;
