import { Outlet, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NoNavBarLayout } from '@core/uikit/build/src/components/Layout/NoNavBarLayout';
import RedirectPagePortal from '@core/uikit/build/src/components/RedirectPagePortal';
import RedirectPersonalProfile from '@core/uikit/build/src/components/RedirectPersonalProfile';
import { OpsRouteObject } from '@core/oui/build/src/components/OpsRoutes';
import { ReportMoney as ReportMoneyIcon } from '@core/icons';

import MainLayout from 'components/Layout';
import { routeConfig } from 'configs/routes/config';
import { CompNotFound } from 'configs/layout/CommonLayout';

import Logout from 'pages/Logout';
import SSO from 'pages/SSO';

import SaleReportOverview from 'pages/SaleReportOverview';
import SaleReportGameType from 'pages/SaleReportGameType';

export function useRouteObjects() {
  const { t } = useTranslation();

  const routeObjectsWithSidebar: OpsRouteObject[] = [
    {
      index: true,
      element: <Navigate to={routeConfig.salesDetailReport.overview.path} />,
    },
    {
      path: routeConfig.salesDetailReport.path,
      icon: <ReportMoneyIcon />,
      title: t('SIDEBAR.SALES_DETAIL_REPORT'),
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Navigate to={routeConfig.salesDetailReport.overview.path} />,
        },
        {
          path: routeConfig.salesDetailReport.overview.path,
          title: t('SIDEBAR.SALE_REPORT.OVERVIEW'),
          element: <SaleReportOverview />,
        },
        {
          path: routeConfig.salesDetailReport.gameType.path,
          title: t('SIDEBAR.SALE_REPORT.GAME_REPORT'),
          element: <Navigate to={routeConfig.salesDetailReport.gameType.rng.path} />,
        },
        {
          path: routeConfig.salesDetailReport.gameType.tab.path,
          element: <SaleReportGameType />,
        },
      ],
    },

    { path: '*', element: <CompNotFound /> },
  ];

  const rootRouteObjects: OpsRouteObject[] = [
    {
      path: routeConfig.home.path,
      element: (
        <NoNavBarLayout>
          <Outlet />
        </NoNavBarLayout>
      ),
      children: [
        {
          index: true,
          element: <RedirectPagePortal />,
        },
        {
          path: routeConfig.personalProfile.path,
          element: <RedirectPersonalProfile />,
        },
        {
          path: routeConfig.logout.path,
          element: <Logout />,
        },
        {
          path: routeConfig.sso.path,
          element: <SSO />,
        },
        { path: '*', element: <CompNotFound /> },
      ],
    },
    {
      path: routeConfig.kpi.path,
      element: (
        <MainLayout routes={routeObjectsWithSidebar}>
          <Outlet />
        </MainLayout>
      ),
      children: routeObjectsWithSidebar,
    },
  ];

  return { routes: rootRouteObjects } as const;
}
