var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import pick from 'lodash-es/pick';
import omit from 'lodash-es/omit';
import styles from '../styles.module.scss';
import BodyRow from './BodyRow';
var emptyObj = {};
function BodyRows(_a) {
    var header = _a.header, data = _a.data, keys = _a.keys, 
    //
    classOfRow = _a.classOfRow, classOfOverrideRow = _a.classOfOverrideRow, classOfCell = _a.classOfCell, classOfCheckboxCell = _a.classOfCheckboxCell, 
    //
    stickyColumn = _a.stickyColumn, highlight = _a.highlight, 
    //
    hasCheckboxes = _a.hasCheckboxes, checkboxes = _a.checkboxes, handleToggleCheckboxRow = _a.handleToggleCheckboxRow, 
    //
    tableRowProps = _a.tableRowProps, 
    //
    expandedRows = _a.expandedRows, handleToggleExpandRow = _a.handleToggleExpandRow, tableRowSubComponent = _a.tableRowSubComponent, classByDataRow = _a.classByDataRow, rest = __rest(_a, ["header", "data", "keys", "classOfRow", "classOfOverrideRow", "classOfCell", "classOfCheckboxCell", "stickyColumn", "highlight", "hasCheckboxes", "checkboxes", "handleToggleCheckboxRow", "tableRowProps", "expandedRows", "handleToggleExpandRow", "tableRowSubComponent", "classByDataRow"]);
    var keysIsExisted = !!keys;
    var keysValue = Object.values(keys || {});
    return (_jsx(_Fragment, { children: data.map(function (rowData, rowIdx) {
            var _a;
            var pickedData = pick(rowData, keysValue);
            var dataOfItem = !keysIsExisted ? rowData : pickedData;
            var omittedData = omit(rowData, keysValue);
            var otherProps = !keysIsExisted
                ? emptyObj
                : omittedData;
            var isRowSelected = !!checkboxes[rowIdx];
            var isRowHighlight = !!highlight[rowIdx];
            var isRowExpanded = !!expandedRows[rowIdx];
            var classOfCurrentRow = cn(classOfRow, (_a = {},
                _a[styles['selected-row']] = isRowSelected,
                _a[styles['highlight-row']] = isRowHighlight,
                _a[styles['expanded-row']] = isRowExpanded,
                _a), classOfOverrideRow, classByDataRow && classByDataRow(rowData));
            return (_jsx(BodyRow, __assign({ rowIdx: rowIdx, stickyColumn: stickyColumn, 
                //
                classOfCurrentRow: classOfCurrentRow, classOfCheckboxCell: classOfCheckboxCell, classOfCell: classOfCell, 
                //
                header: header, rowData: rowData, dataOfItem: dataOfItem, otherProps: otherProps, 
                //
                isRowSelected: isRowSelected, handleToggleCheckboxRow: handleToggleCheckboxRow, hasCheckboxes: hasCheckboxes, 
                //
                tableRowProps: tableRowProps, 
                //
                isRowExpanded: isRowExpanded, handleToggleExpandRow: handleToggleExpandRow, tableRowSubComponent: tableRowSubComponent }, rest), rowData.id || rowIdx));
        }) }, void 0));
}
export default React.memo(BodyRows);
