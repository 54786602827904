var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '@core/oui/build/src/components/Typography';
var useStyles = makeStyles(function (theme) { return ({
    formControl: {
        width: '100%',
    },
}); });
var TypographyField = function (_a) {
    var label = _a.label, _b = _a.labelProps, labelProps = _b === void 0 ? {
        variant: TypoVariants.supportTextNormal,
        type: TypoTypes.secondary,
        weight: TypoWeights.medium,
    } : _b, value = _a.value, formatValue = _a.formatValue;
    var classes = useStyles();
    return (_jsxs(FormControl, __assign({ component: "fieldset", className: classes.formControl }, { children: [label && (_jsx(FormLabel, { children: _jsx(Typography, __assign({ variant: TypoVariants.supportTextNormal, type: TypoTypes.secondary, weight: TypoWeights.medium }, labelProps, { children: label }), void 0) }, void 0)), _jsx(Typography, __assign({ variant: TypoVariants.bodyHighLevel }, { children: formatValue ? formatValue(value) : value }), void 0)] }), void 0));
};
export default TypographyField;
