export var MODULE_PAYMENT = 1;
export var MIN_BALANCE = 50000000; // 50.000.000
export var MIN_MONEY_PAYMENT = 1000;
export var MAX_MONEY_PAYMENT = 5000000;
export var MAX_TOPIC = 2000000;
export var getBalanceStatus = function (balance) {
    if (balance <= 0) {
        return 'uneligible';
    }
    if (balance <= MIN_BALANCE) {
        return 'runningOut';
    }
    return 'eligible';
};
export var formatCurrency = function (number) {
    if (!number)
        return '0';
    var result = Number(number);
    return typeof result === 'number' ? result.toLocaleString('vi-VN') : '0';
};
function getDateFromUTC(_a) {
    var seconds = _a.seconds;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(seconds);
    return d;
}
function getDateMonthYear(d) {
    var dateNum = d.getDate();
    var date = dateNum.toString().padStart(2, '0');
    var monthNum = d.getMonth();
    var month = (monthNum + 1).toString().padStart(2, '0');
    var yearNum = d.getFullYear();
    var year = yearNum.toString();
    var hoursNum = d.getHours();
    var hours = hoursNum.toString().padStart(2, '0');
    var minutesNum = d.getMinutes();
    var minutes = minutesNum.toString().padStart(2, '0');
    var secondsNum = d.getSeconds();
    var seconds = secondsNum.toString().padStart(2, '0');
    return {
        date: date,
        month: month,
        year: year,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
}
export var getDateFormat = function (date) {
    var _a = getDateMonthYear(getDateFromUTC(date)), d = _a.date, m = _a.month, y = _a.year, hours = _a.hours, minutes = _a.minutes;
    return d + "/" + m + "/" + y + ", " + hours + ":" + minutes;
};
export var isJsonString = function (str) {
    try {
        return JSON.parse(str) && !!str;
    }
    catch (e) {
        return false;
    }
};
export var parseJson = function (str, defaultValue) {
    return isJsonString(str) ? JSON.parse(str) : defaultValue || {};
};
export function isFunction(functionToCheck) {
    return functionToCheck && typeof functionToCheck === 'function';
}
export function sleep(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
export function getRestHeightOfBrowser(element, height) {
    if (height === void 0) { height = 0; }
    if (!element)
        return 0;
    var rectEl = element.getBoundingClientRect();
    var rest = window.innerHeight - rectEl.top + height;
    return rest;
}
export var getRandomColor = function () {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
};
export var getColorWithCharacter = function (letter) {
    if (!letter)
        return getRandomColor();
    var character = letter.slice(0, 1);
    return defaultCharacterColorMapping[character] || getRandomColor();
};
export var formatAmountToPercent = function (amount, totalValue) {
    return Math.round((amount / totalValue) * 100);
};
export var defaultCharacterColorMapping = {
    '-': '#7C84A3',
    '': '#7C84A3',
    undefined: '#7C84A3',
    a: '#0934E0',
    A: '#0934E0',
    b: '#031352',
    B: '#031352',
    c: '#99ADFF',
    C: '#99ADFF',
    d: '#031352',
    D: '#031352',
    e: '#F53131',
    E: '#F53131',
    f: '#7C84A3',
    F: '#7C84A3',
    g: '#FF8585',
    G: '#FF8585',
    h: '#FFD52F',
    H: '#FFD52F',
    i: '#2BE990',
    I: '#2BE990',
    j: '#5D1BE0',
    J: '#5D1BE0',
    k: '#0934E0',
    K: '#0934E0',
    l: '#031352',
    L: '#031352',
    m: '#99ADFF',
    M: '#99ADFF',
    n: '#031352',
    N: '#031352',
    o: '#F53131',
    O: '#F53131',
    p: '#7C84A3',
    P: '#7C84A3',
    q: '#FF8585',
    Q: '#FF8585',
    r: '#FFD52F',
    R: '#FFD52F',
    s: '#2BE990',
    S: '#2BE990',
    t: '#5D1BE0',
    T: '#5D1BE0',
    u: '#0934E0',
    U: '#0934E0',
    v: '#031352',
    V: '#031352',
    w: '#99ADFF',
    W: '#99ADFF',
    x: '#031352',
    X: '#031352',
    y: '#F53131',
    Y: '#F53131',
    z: '#5D1BE0',
    Z: '#5D1BE0',
};
export var formatOptions = function (arr, configProps) {
    if (arr === void 0) { arr = []; }
    return arr.map(function (item) { return ({
        name: item[configProps.name] ? item[configProps.name] : '',
        value: item[configProps.value]
            ? configProps.valueType === 'string'
                ? item[configProps.value].toString()
                : item[configProps.value]
            : undefined,
    }); });
};
export var ignoreSSOPage = function () {
    var reg = new RegExp(/\/sso/, 'g');
    if (reg.test(window.location.pathname))
        return true;
    return false;
};
