import { parse } from 'query-string';
export function toNumber(value) {
    if (value !== undefined) {
        return parseInt(value, 10);
    }
    return 0;
}
export function toString(value) {
    if (value === '' || value === 'null' || value === 'undefined') {
        return;
    }
    return value;
}
export function toQuery(search) {
    return search ? parse(search.slice(1), { sort: false }) : {};
}
export const toDecimal = (n, maximumFractionDigits = 2) => {
    return n ? parseFloat(n.toFixed(maximumFractionDigits)) : 0;
};
