import { KEY_ACCESS_TOKEN } from '@core/myservice';
export var useTokenQuery = function () {
    var params = new URLSearchParams(window.location.search);
    var token = params.get(KEY_ACCESS_TOKEN);
    var accessToken = localStorage.getItem(KEY_ACCESS_TOKEN);
    if (token) {
        localStorage.setItem(KEY_ACCESS_TOKEN, token);
    }
    return {
        token: token || accessToken,
    };
};
