var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { AccessPoint, Baccarat, BauCuaMini, ContentManagement, Dashboard, Event, Fish, Game, Giftcode, Gof, Gow, History, HouseDeposit, Lieng, LoDe, Lottery, Management, MauBinh, Mission, PaymentConfig, Phom, Poker, PokerMini, ReportMoney, Sales, Sam, Settings, Sicbo, Tag, TaiXiuMini, TienLen, UpDown, Website, XocDia, PlayerOverview, Screen, IPAddress, JackpotConfig, } from '@core/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CMS_URL, DASHBOARD_URL, FORGE_URL, GAME_INSIGHT_URL, KPI_URL, MONEY_MANAGEMENT_URL, PLAYER_MANAGEMENT_URL, USER_MANAGEMENT_URL, WITHDRAW_APPROVAL_MANAGEMENT_URL, } from '../SpaceList/const';
import { gamesInGameData } from './const';
import { CMS_ROUTES, DASHBOARD_ROUTES, GAME_INSIGHT_ROUTES, KPI_ROUTES, MONEY_ROUTES, PLAYER_ROUTES, USER_ROUTES, WITHDRAW_APPROVAL_ROUTES, } from './route';
export var useSearchData = function () {
    var t = useTranslation().t;
    var parseExtendSearchToString = function (keys) {
        if (!(keys === null || keys === void 0 ? void 0 : keys.length))
            return '';
        return keys.map(function (k) { return t(k); }).join(' ');
    };
    var PLAYER_SEARCH_DATA = [
        {
            title: t('SIDEBAR.PLAYER_LIST'),
            description: t('SPACE.PLAYER_MANAGEMENT'),
            icon: Management,
            path: PLAYER_ROUTES.basicInfo.path,
            url: PLAYER_MANAGEMENT_URL,
            default: true, // suggest item default
        },
        {
            title: t('SIDEBAR.TAG_MANAGEMENT'),
            description: t('SPACE.PLAYER_MANAGEMENT'),
            icon: Tag,
            path: PLAYER_ROUTES.tagManagement.allPlayer.path,
            url: PLAYER_MANAGEMENT_URL,
            default: false, // suggest item default
        },
    ];
    var CMS_SEARCH_DATA = [
        {
            title: t('SIDEBAR.OT.GAME_ICONS_ORDER'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: ContentManagement,
            path: CMS_ROUTES.portalContents.banner.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.CONTENT.GAME_ICONS_MANAGEMENT'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: ContentManagement,
            path: CMS_ROUTES.portalContents.bannerManagement.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.CONTENT.CUSTOMER_SUPPORT_LINKS'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: ContentManagement,
            path: CMS_ROUTES.portalContents.csAccountConfig.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.OT.NOTIFICATION'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: ContentManagement,
            path: CMS_ROUTES.portalContents.notification.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.OT.MARQUEE'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: ContentManagement,
            path: CMS_ROUTES.portalContents.marqueeBulletin.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.OT.BAD_WORDS'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: ContentManagement,
            path: CMS_ROUTES.portalContents.badwordsNaming.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        // * WEBSITE
        {
            title: t('SIDEBAR.CONTENT.LANDING_PAGE_CONFIGURATION'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Website,
            path: CMS_ROUTES.website.landingPageConfiguration.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        // * BOT CONTROLLER
        {
            title: t('SIDEBAR.BOT_CONTROLLER'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Website,
            path: CMS_ROUTES.botController.detail.path,
            url: CMS_URL,
            default: true, // suggest item default
        },
        // * EVENT MANAGEMENT
        {
            title: t('SIDEBAR.BANNER_PROMOTION'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Event,
            path: CMS_ROUTES.eventManagement.bannerPromotion.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.EVENT_MANAGEMENT'),
        },
        {
            title: t('EVENT_MANAGEMENT.FIRST_DEPOSIT_PROMOTION'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Event,
            path: CMS_ROUTES.eventManagement.firstDepositPromotion.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.EVENT_MANAGEMENT'),
        },
        {
            title: t('EVENT_MANAGEMENT.TET'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Event,
            path: CMS_ROUTES.eventManagement.tet.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.EVENT_MANAGEMENT'),
        },
        {
            title: t('EVENT_MANAGEMENT.HOLIDAY_DEPOSIT_BONUS'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Event,
            path: CMS_ROUTES.eventManagement.holidayDepositBonus.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.EVENT_MANAGEMENT'),
        },
        {
            title: t('EVENT_MANAGEMENT.WORLD_CUP'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Event,
            path: CMS_ROUTES.eventManagement.worldCup.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.EVENT_MANAGEMENT'),
        },
        {
            title: t('SIDEBAR.EVENT_MANAGEMENT.POPUP'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Event,
            path: CMS_ROUTES.eventManagement.popup.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.EVENT_MANAGEMENT'),
        },
        {
            title: t('SIDEBAR.EVENT_MANAGEMENT.WEEKLY_EVENT'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Event,
            path: CMS_ROUTES.eventManagement.weeklyEvent.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.EVENT_MANAGEMENT'),
        },
        {
            title: t('SIDEBAR.EVENT_MANAGEMENT.SPIN_EVENT'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Event,
            path: CMS_ROUTES.eventManagement.spinEvent.path,
            url: CMS_URL,
            default: true,
            extendSearch: t('SIDEBAR.EVENT_MANAGEMENT'),
        },
        // * MISSIONS
        {
            title: t('SIDEBAR.MISSION_MANAGEMENT'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Mission,
            path: CMS_ROUTES.missions.daily.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        // * LOTTERY RESULT
        {
            title: t('SIDEBAR.LODE_OPS'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Lottery,
            path: CMS_ROUTES.lotteryResult.result.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        // * MY ACCOUNT
        {
            title: t('MY_ACCOUNT.BASE'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Management,
            path: CMS_ROUTES.myAccount.base.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.MY_ACCOUNT'),
        },
        {
            title: t('MY_ACCOUNT.FRAME'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Management,
            path: CMS_ROUTES.myAccount.frame.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.MY_ACCOUNT'),
        },
        {
            title: t('MY_ACCOUNT.LEVEL'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Management,
            path: CMS_ROUTES.myAccount.level.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.MY_ACCOUNT'),
        },
        {
            title: t('MY_ACCOUNT.FRAME_CATEGORY'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Management,
            path: CMS_ROUTES.myAccount.frameCategory.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.MY_ACCOUNT'),
        },
        // * CHAT SETTINGS
        {
            title: t('SIDEBAR.WHATSAPP.APPLICATION'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Management,
            path: CMS_ROUTES.whatsapp.application.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.WHATSAPP'),
        },
        {
            title: t('SIDEBAR.WHATSAPP.EMOTICON'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Management,
            path: CMS_ROUTES.whatsapp.emoticon.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.WHATSAPP'),
        },
        {
            title: t('SIDEBAR.WHATSAPP.EMOTICON_SET'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Management,
            path: CMS_ROUTES.whatsapp.emoticonSet.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.WHATSAPP'),
        },
        {
            title: t('SIDEBAR.WHATSAPP.SAMPLE'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Management,
            path: CMS_ROUTES.whatsapp.sample.path,
            url: CMS_URL,
            default: false,
            extendSearch: t('SIDEBAR.WHATSAPP'),
        },
        // * NOTIFIER
        {
            title: t('SIDEBAR.NOTIFIER'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: Settings,
            path: CMS_ROUTES.notifier.path,
            url: CMS_URL,
            default: false, // suggest item default
        },
        // * PORUNGA
        {
            title: t('SIDEBAR.PORUNGA'),
            description: t('SPACE.CONTENT_MANAGEMENT'),
            icon: JackpotConfig,
            path: CMS_ROUTES.porunga.path,
            url: CMS_URL,
            default: false,
        },
    ];
    var USER_SEARCH_DATA = [
        {
            title: t('SIDEBAR.ALL_USER'),
            description: t('SPACE.USER_MANAGEMENT'),
            icon: Dashboard,
            path: USER_ROUTES.usersList.path,
            url: USER_MANAGEMENT_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.GROUPS'),
            description: t('SPACE.USER_MANAGEMENT'),
            icon: AccessPoint,
            path: USER_ROUTES.usersGroups.path,
            url: USER_MANAGEMENT_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.ACTION_LOGS'),
            description: t('SPACE.USER_MANAGEMENT'),
            icon: History,
            path: USER_ROUTES.usersActionLogs.path,
            url: USER_MANAGEMENT_URL,
            default: false, // suggest item default
        },
    ];
    var KPI_SEARCH_DATA = [
        // * Sales detail report
        {
            title: t('SIDEBAR.SALE_REPORT.OVERVIEW'),
            description: t('SIDEBAR.SALES_DETAIL_REPORT') + " - " + t('SPACE.KPI'),
            icon: ReportMoney,
            path: KPI_ROUTES.salesDetailReport.overview.path,
            url: KPI_URL,
            default: false,
            extendSearch: t('SIDEBAR.SALES_DETAIL_REPORT'),
        },
        {
            title: t('SIDEBAR.SALE_REPORT.GAME_REPORT'),
            description: t('SIDEBAR.SALES_DETAIL_REPORT') + " - " + t('SPACE.KPI'),
            icon: ReportMoney,
            path: KPI_ROUTES.salesDetailReport.gameType.path,
            url: KPI_URL,
            default: true,
            extendSearch: t('SIDEBAR.SALES_DETAIL_REPORT'),
        },
    ];
    var GAME_DATA = useMemo(function () {
        var dataTemp = [];
        gamesInGameData.forEach(function (g) {
            var tmp = g.data.map(function (d) {
                return {
                    title: t(d.title),
                    description: (d.parentTitle ? t(d.parentTitle) + " - " : '') +
                        t('SPACE.GAME_DATA'),
                    icon: g.icon,
                    path: d.path,
                    url: FORGE_URL,
                    default: d.default || false,
                    extendSearch: parseExtendSearchToString(g.searchDefault) + " " + parseExtendSearchToString(d.extendSearch),
                };
            });
            dataTemp.push.apply(dataTemp, tmp);
        });
        return dataTemp;
    }, []);
    var DASHBOARD_SEARCH_DATA = [
        {
            title: t('SIDEBAR.GAME_OVERVIEW'),
            description: t('SPACE.DASHBOARD'),
            icon: Game,
            path: DASHBOARD_ROUTES.gameOverview.path,
            url: DASHBOARD_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.MISSION_OVERVIEW'),
            description: t('SPACE.DASHBOARD'),
            icon: Mission,
            path: DASHBOARD_ROUTES.missionOverview.path,
            url: DASHBOARD_URL,
            default: false,
        },
        {
            title: t('SIDEBAR.SALES_OVERVIEW'),
            description: t('SPACE.DASHBOARD'),
            icon: Mission,
            path: DASHBOARD_ROUTES.salesOverview.path,
            url: DASHBOARD_URL,
            default: false,
        },
        {
            title: t('SIDEBAR.PLAYER_OVERVIEW'),
            description: t('SPACE.DASHBOARD'),
            icon: PlayerOverview,
            path: DASHBOARD_ROUTES.playerOverview.path,
            url: DASHBOARD_URL,
            default: false,
        },
        {
            title: t('SIDEBAR.SCREEN_TRAFFIC'),
            description: t('SPACE.DASHBOARD'),
            icon: Screen,
            path: DASHBOARD_ROUTES.screenTraffic.path,
            url: DASHBOARD_URL,
            default: false,
        },
        {
            title: t('SIDEBAR.IP_OVERVIEW'),
            description: t('SPACE.DASHBOARD'),
            icon: IPAddress,
            path: DASHBOARD_ROUTES.ipOverview.path,
            url: DASHBOARD_URL,
            default: false,
        },
    ];
    var MONEY_SEARCH_DATA = [
        {
            title: t('SIDEBAR.WALLET_SUMMARY'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: Sales,
            path: MONEY_ROUTES.walletSummary.path,
            url: MONEY_MANAGEMENT_URL,
            default: true, // suggest item default
        },
        {
            title: t('SIDEBAR.WALLET_SUMMARY.GAME_WALLET'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: Sales,
            path: MONEY_ROUTES.gameWallet.path,
            url: MONEY_MANAGEMENT_URL,
            default: false,
            extendSearch: t('SIDEBAR.WALLET_SUMMARY'),
        },
        {
            title: t('SIDEBAR.WALLET_SUMMARY.BOT_WALLET'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: Sales,
            path: MONEY_ROUTES.botWallet.path,
            url: MONEY_MANAGEMENT_URL,
            default: false,
            extendSearch: t('SIDEBAR.WALLET_SUMMARY'),
        },
        {
            title: t('SIDEBAR.WALLET_SUMMARY.EVENT_WALLET'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: Sales,
            path: MONEY_ROUTES.eventWallet.path,
            url: MONEY_MANAGEMENT_URL,
            default: false,
            extendSearch: t('SIDEBAR.WALLET_SUMMARY'),
        },
        {
            title: t('SIDEBAR.WALLET.CONFIG'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: Sales,
            path: MONEY_ROUTES.walletConfig.path,
            url: MONEY_MANAGEMENT_URL,
            default: false,
        },
        {
            title: t('SIDEBAR.HOUSE_DEPOSIT'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: HouseDeposit,
            path: MONEY_ROUTES.houseFund.path,
            url: MONEY_MANAGEMENT_URL,
            default: false,
        },
        {
            title: t('SIDEBAR.TELCO_EXCHANGE_RATES'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: PaymentConfig,
            path: MONEY_ROUTES.telcoExchangeRates.path,
            url: MONEY_MANAGEMENT_URL,
            default: false,
        },
        {
            title: t('SIDEBAR.GIFTCODE_GENERATION'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: Giftcode,
            path: MONEY_ROUTES.giftcodeManagement.giftcodeGeneration.path,
            url: MONEY_MANAGEMENT_URL,
            default: false,
        },
        {
            title: t('SIDEBAR.CONTENT.PAYMENT_ATTENTION'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: PaymentConfig,
            path: MONEY_ROUTES.paymentInfoConfiguration.paymentAttention.path,
            url: MONEY_MANAGEMENT_URL,
            default: false,
            extendSearch: t('SIDEBAR.PAYMENT_INFO_CONFIGURATION'),
        },
        {
            title: t('SIDEBAR.CONTENT.PAYMENT_IMAGE'),
            description: t('SPACE.MONEY_MANAGEMENT'),
            icon: PaymentConfig,
            path: MONEY_ROUTES.paymentInfoConfiguration.paymentImage.path,
            url: MONEY_MANAGEMENT_URL,
            default: false,
            extendSearch: t('SIDEBAR.PAYMENT_INFO_CONFIGURATION'),
        },
    ];
    var WITHDRAW_APPROVAL_SEARCH_DATA = [
        {
            title: t('SIDEBAR.PAYMENT_WITHDRAW'),
            description: t('SPACE.WITHDRAW_APPROVAL'),
            icon: Settings,
            path: WITHDRAW_APPROVAL_ROUTES.paymentWithdraw.path,
            url: WITHDRAW_APPROVAL_MANAGEMENT_URL,
            default: false, // suggest item default
        },
    ];
    var GAME_INSIGHT_SEARCH_DATA = [
        {
            title: t('SIDEBAR.OVERVIEW'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Dashboard,
            path: GAME_INSIGHT_ROUTES.dashboard.path,
            url: GAME_INSIGHT_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.LIENG'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Lieng,
            path: GAME_INSIGHT_ROUTES.cardGame.lieng.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.CARD_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.POKE'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Poker,
            path: GAME_INSIGHT_ROUTES.cardGame.poker.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.CARD_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.TALA'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Phom,
            path: GAME_INSIGHT_ROUTES.cardGame.tala.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.CARD_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.MABI'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: MauBinh,
            path: GAME_INSIGHT_ROUTES.cardGame.maubinh.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.CARD_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.SAM'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Sam,
            path: GAME_INSIGHT_ROUTES.cardGame.sam.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.CARD_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.TLMN'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: TienLen,
            path: GAME_INSIGHT_ROUTES.cardGame.tlmn.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.CARD_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.BAU_CUA'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: TienLen,
            path: GAME_INSIGHT_ROUTES.tableGame.baucua.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.TABLE_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.SICBO'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Sicbo,
            path: GAME_INSIGHT_ROUTES.tableGame.sicbo.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.TABLE_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.XOC_DIA'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: XocDia,
            path: GAME_INSIGHT_ROUTES.tableGame.xocdia.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.TABLE_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.BACCARAT'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Baccarat,
            path: GAME_INSIGHT_ROUTES.tableGame.baccarat.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.TABLE_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.CHIEN_THAN'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Gow,
            path: GAME_INSIGHT_ROUTES.slotGame.gow.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.SLOT_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.THAN_TAI'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Gof,
            path: GAME_INSIGHT_ROUTES.slotGame.gof.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.SLOT_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.LO_DE'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: LoDe,
            path: GAME_INSIGHT_ROUTES.lotteryGame.lode.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.LOTTERY_GAME'),
        },
        {
            title: t('SIDEBAR.FISHING_GAME'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: Fish,
            path: GAME_INSIGHT_ROUTES.fish.path,
            url: GAME_INSIGHT_URL,
            default: false, // suggest item default
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.BAU_CUA_MINI'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: BauCuaMini,
            path: GAME_INSIGHT_ROUTES.miniGame.bcmn.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.POP_UP_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.PKMN'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: PokerMini,
            path: GAME_INSIGHT_ROUTES.miniGame.pkmn.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.POP_UP_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.TXMN'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: TaiXiuMini,
            path: GAME_INSIGHT_ROUTES.miniGame.txmn.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.POP_UP_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.UP_DOWN'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: UpDown,
            path: GAME_INSIGHT_ROUTES.miniGame.updown.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.POP_UP_GAME'),
        },
        {
            title: t('SIDEBAR.GAME_TRACKING.UP_DOWN'),
            description: t('SPACE.GAME_INSIGHT'),
            icon: UpDown,
            path: GAME_INSIGHT_ROUTES.miniGame.updown.path,
            url: GAME_INSIGHT_URL,
            default: false,
            extendSearch: t('SIDEBAR.POP_UP_GAME'),
        },
    ];
    var dataSearch = useMemo(function () { return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], PLAYER_SEARCH_DATA), CMS_SEARCH_DATA), USER_SEARCH_DATA), KPI_SEARCH_DATA), GAME_DATA), DASHBOARD_SEARCH_DATA), MONEY_SEARCH_DATA), WITHDRAW_APPROVAL_SEARCH_DATA), GAME_INSIGHT_SEARCH_DATA); }, []);
    return { dataSearch: dataSearch };
};
