var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CreateSystemMailRequest, Attachment, GetSystemMailRequest, UpdateSystemMailRequest, ListSystemMailsRequest, Target, ListMailLogsRequest, GetCashAttachmentRequest, } from '@gameloot/mymail/mymail_pb';
import { StewardClient } from '@gameloot/mymail/MymailServiceClientPb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcMyMailClient = /** @class */ (function (_super) {
    __extends(rpcMyMailClient, _super);
    function rpcMyMailClient(config) {
        var _this = this;
        config.serviceName = 'MY_MAIL';
        _this = _super.call(this, StewardClient, config) || this;
        return _this;
    }
    rpcMyMailClient.prototype.createSystemMail = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, attachment, payloadList, expiresAt, startsAt, endsAt;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateSystemMailRequest();
                        req.setIndex(p.index);
                        req.setLabel(p.label);
                        req.setTarget(p.target);
                        req.setUserIdsList(p.target === Target.INDIVIDUAL ? p.userIdsList || [] : []);
                        if (p.attachment) {
                            attachment = new Attachment();
                            payloadList = (p.attachment.payloadsList || []).map(function (att) {
                                var payload = new Attachment.Payload();
                                payload.setId(att.id);
                                payload.setStatus(att.status);
                                if (att.frame) {
                                    payload.setFrame(att.frame);
                                }
                                else {
                                    if (att.cash) {
                                        var cash = new Attachment.Payload.Cash();
                                        cash.setType(att.cash.type);
                                        cash.setAmount(att.cash.amount);
                                        cash.setTopic(att.cash.topic);
                                        payload.setCash(cash);
                                    }
                                }
                                return payload;
                            });
                            attachment.setPayloadsList(payloadList);
                            if (p.attachment.expiresAt) {
                                expiresAt = new Timestamp();
                                expiresAt.setNanos(p.attachment.expiresAt.nanos);
                                expiresAt.setSeconds(p.attachment.expiresAt.seconds);
                                attachment.setExpiresAt(expiresAt);
                            }
                            req.setAttachment(attachment);
                        }
                        if (p.startsAt) {
                            startsAt = new Timestamp();
                            startsAt.setNanos(p.startsAt.nanos);
                            startsAt.setSeconds(p.startsAt.seconds);
                            req.setStartsAt(startsAt);
                        }
                        if (p.endsAt) {
                            endsAt = new Timestamp();
                            endsAt.setNanos(p.endsAt.nanos);
                            endsAt.setSeconds(p.endsAt.seconds);
                            req.setEndsAt(endsAt);
                        }
                        req.setTitle(p.title);
                        req.setHeader(p.header);
                        req.setBody(p.body);
                        return [4 /*yield*/, this.gRPCClientRequest('createSystemMail', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyMailClient.prototype.getSystemMail = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetSystemMailRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getSystemMail', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyMailClient.prototype.updateSystemMail = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateSystemMailRequest();
                        req.setId(p.id);
                        p.changesList.forEach(function (l) {
                            var _a, _b, _c, _d;
                            if (l.status) {
                                var changeStatus = new UpdateSystemMailRequest.Change();
                                changeStatus.setStatus(l.status);
                                req.addChanges(changeStatus);
                            }
                            if (l.index) {
                                var changeIndex = new UpdateSystemMailRequest.Change();
                                changeIndex.setIndex(l.index);
                                req.addChanges(changeIndex);
                            }
                            if (l.label) {
                                var changeLabel = new UpdateSystemMailRequest.Change();
                                changeLabel.setLabel(l.label);
                                req.addChanges(changeLabel);
                            }
                            if (l.target) {
                                var changeTarget = new UpdateSystemMailRequest.Change();
                                changeTarget.setTarget(l.target);
                                req.addChanges(changeTarget);
                            }
                            if ((_a = l.users) === null || _a === void 0 ? void 0 : _a.userIdsList) {
                                var changeUsers = new UpdateSystemMailRequest.Change();
                                var users = new UpdateSystemMailRequest.Users();
                                users.setUserIdsList(l.target === Target.INDIVIDUAL ? ((_b = l.users) === null || _b === void 0 ? void 0 : _b.userIdsList) || [] : []);
                                changeUsers.setUsers(users);
                                req.addChanges(changeUsers);
                            }
                            if (l.attachment) {
                                var changeAttachment = new UpdateSystemMailRequest.Change();
                                var attachment = new Attachment();
                                attachment.setPayloadsList((((_c = l.attachment) === null || _c === void 0 ? void 0 : _c.payloadsList) || []).map(function (att) {
                                    var payload = new Attachment.Payload();
                                    payload.setId(att.id);
                                    payload.setStatus(att.status);
                                    if (att.frame) {
                                        payload.setFrame(att.frame);
                                    }
                                    else {
                                        if (att.cash) {
                                            var cash = new Attachment.Payload.Cash();
                                            cash.setType(att.cash.type);
                                            cash.setAmount(att.cash.amount);
                                            payload.setCash(cash);
                                        }
                                    }
                                    return payload;
                                }));
                                if ((_d = l.attachment) === null || _d === void 0 ? void 0 : _d.expiresAt) {
                                    var expiresAt = new Timestamp();
                                    expiresAt.setNanos(l.attachment.expiresAt.nanos);
                                    expiresAt.setSeconds(l.attachment.expiresAt.seconds);
                                    attachment.setExpiresAt(expiresAt);
                                }
                                changeAttachment.setAttachment(attachment);
                                req.addChanges(changeAttachment);
                            }
                            if (l.startsAt) {
                                var changeStartsAt = new UpdateSystemMailRequest.Change();
                                var startsAt = new Timestamp();
                                startsAt.setNanos(l.startsAt.nanos);
                                startsAt.setSeconds(l.startsAt.seconds);
                                changeStartsAt.setStartsAt(startsAt);
                                req.addChanges(changeStartsAt);
                            }
                            if (l.endsAt) {
                                var changeEndsAt = new UpdateSystemMailRequest.Change();
                                var endsAt = new Timestamp();
                                endsAt.setNanos(l.endsAt.nanos);
                                endsAt.setSeconds(l.endsAt.seconds);
                                changeEndsAt.setEndsAt(endsAt);
                                req.addChanges(changeEndsAt);
                            }
                            if (l.title) {
                                var changeTitle = new UpdateSystemMailRequest.Change();
                                changeTitle.setTitle(l.title);
                                req.addChanges(changeTitle);
                            }
                            if (l.header) {
                                var changeHeader = new UpdateSystemMailRequest.Change();
                                changeHeader.setHeader(l.header);
                                req.addChanges(changeHeader);
                            }
                            if (l.body) {
                                var changeBody = new UpdateSystemMailRequest.Change();
                                changeBody.setBody(l.body);
                                req.addChanges(changeBody);
                            }
                        });
                        return [4 /*yield*/, this.gRPCClientRequest('updateSystemMail', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyMailClient.prototype.listSystemMails = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListSystemMailsRequest();
                        req.setSearch(p.search);
                        req.setIndex(p.index);
                        req.setLabelsList(p.labelsList);
                        req.setTargetsList(p.targetsList);
                        req.setStatusesList(p.statusesList);
                        req.setTypesList(p.typesList);
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        return [4 /*yield*/, this.gRPCClientRequest('listSystemMails', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyMailClient.prototype.listMailLogs = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListMailLogsRequest();
                        req.setUserId(p.userId);
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setHasCashOnly(p.hasCashOnly);
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        return [4 /*yield*/, this.gRPCClientRequest('listMailLogs', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyMailClient.prototype.getCashAttachment = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetCashAttachmentRequest();
                        req.setUserId(p.userId);
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setNanos(p.from.nanos);
                            ts.setSeconds(p.from.seconds);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            ts = new Timestamp();
                            ts.setNanos(p.to.nanos);
                            ts.setSeconds(p.to.seconds);
                            req.setTo(ts);
                        }
                        req.setClaimedOnly(p.claimedOnly);
                        return [4 /*yield*/, this.gRPCClientRequest('getCashAttachment', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcMyMailClient;
}(gRPCClientAbstract));
export default rpcMyMailClient;
