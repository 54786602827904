var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { IconButton, Box } from '@material-ui/core';
import cn from 'classnames';
import { X } from '@core/icons';
import Typography, { TypoVariants, TypoWeights, } from '../Typography';
import DialogStepper from './Stepper';
import styles from './styles.module.scss';
var DialogTitle = function (props) {
    var _a;
    var handleClose = props.handleClose, subtitle = props.subtitle, activeStep = props.activeStep, stepsLabel = props.stepsLabel, title = props.title;
    return (_jsxs(Box, __assign({ className: cn(styles.boxTitle, (_a = {},
            _a[styles.notShowBorderBottom] = subtitle || stepsLabel,
            _a)) }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "space-between" }, { children: [React.isValidElement(title) ? (title) : (_jsx(Typography, __assign({ variant: TypoVariants.head3, weight: TypoWeights.medium }, { children: title }), void 0)), _jsx(IconButton, __assign({ "aria-label": "close", onClick: handleClose, className: styles.iconClose }, { children: _jsx(X, { style: { color: '#090212' } }, void 0) }), void 0)] }), void 0), subtitle && (_jsx(Box, __assign({ mt: 2 }, { children: _jsx(Typography, __assign({ variant: TypoVariants.body1, weight: TypoWeights.medium }, { children: subtitle }), void 0) }), void 0)), stepsLabel ? (_jsx(DialogStepper, { activeStep: activeStep, stepsLabel: stepsLabel }, void 0)) : null] }), void 0));
};
export default DialogTitle;
