var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../Typography';
var useStyles = makeStyles(function (theme) { return ({
    formControl: {
        width: '100%',
    },
}); });
var ComponentField = function (_a) {
    var label = _a.label, Component = _a.component, fieldProps = _a.fieldProps, restProps = __rest(_a, ["label", "component", "fieldProps"]);
    var classes = useStyles();
    var methods = useFormContext();
    var formState = methods.formState;
    var errors = formState.errors;
    var status = errors[restProps.name] ? 'error' : 'primary';
    return (_jsxs(FormControl, __assign({ component: "fieldset", className: classes.formControl }, { children: [label && (_jsx(FormLabel, { children: _jsx(Typography, __assign({ variant: TypoVariants.body2, type: TypoTypes.sub, weight: TypoWeights.bold }, { children: label }), void 0) }, void 0)), _jsx(Component, __assign({ status: status }, fieldProps, restProps), void 0)] }), void 0));
};
export default ComponentField;
