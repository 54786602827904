var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../Typography';
import { FieldErrorMessage } from '..';
var errorStyle = {
    backgroundColor: '#ffd6d6',
    color: '#f53131',
    borderColor: '#f53131',
};
var DatePickerField = function (_a) {
    var label = _a.label, readOnly = _a.readOnly, onChange = _a.onChange, value = _a.value, name = _a.name, restProps = __rest(_a, ["label", "readOnly", "onChange", "value", "name"]);
    var methods = useFormContext();
    var formState = methods.formState;
    var errors = formState.errors, touchedFields = formState.touchedFields;
    var error = !!errors[name];
    var handleDateChange = function (date) {
        if (typeof onChange === 'function') {
            onChange(date);
        }
    };
    return (_jsx(MuiPickersUtilsProvider, __assign({ utils: DateFnsUtils }, { children: _jsxs(FormControl, __assign({ component: "fieldset", style: { width: '100%' } }, { children: [label && (_jsx(FormLabel, { children: _jsx(Typography, __assign({ variant: TypoVariants.body2, type: TypoTypes.sub, weight: TypoWeights.bold }, { children: label }), void 0) }, void 0)), _jsx(KeyboardDatePicker, __assign({}, restProps, { id: "date-picker-dialog", format: "dd/MM/yyyy", value: value, onChange: handleDateChange, KeyboardButtonProps: {
                        'aria-label': 'change date',
                    }, readOnly: readOnly, placeholder: "dd/mm/yyyy", InputProps: {
                        readOnly: readOnly,
                        style: __assign({ height: 56, marginBottom: 3, fontSize: 16 }, (error ? errorStyle : {})),
                    }, invalidDateMessage: _jsx(FieldErrorMessage, { message: "Invalid date format" }, void 0), minDateMessage: _jsx(FieldErrorMessage, { message: "Date should not be before minimal date" }, void 0), maxDateMessage: _jsx(FieldErrorMessage, { message: "Date should not be after maximal date" }, void 0), error: error }), void 0)] }), void 0) }), void 0));
};
export default DatePickerField;
