var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var SlotGame = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd", d: "M5.05467 15.6H8.5V8L5.05467 8C5.03128 8.03251 5 8.09992 5 8.2L5 15.4C5 15.5001 5.03128 15.5675 5.05467 15.6ZM13.5 15.6H10.5V8L13.5 8V15.6ZM15.5 15.6H18.9453C18.9687 15.5675 19 15.5001 19 15.4V8.2C19 8.09992 18.9687 8.03251 18.9453 8L15.5 8V15.6ZM5 17.6C3.73246 17.6 3 16.4366 3 15.4V8.2C3 7.16343 3.73246 6 5 6H19C20.2675 6 21 7.16343 21 8.2V15.4C21 16.4366 20.2675 17.6 19 17.6L5 17.6Z" }))); };
export default SlotGame;
