var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var ReportMoney = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M4.879 4.879A3 3 0 017 4h2a1 1 0 010 2H7a1 1 0 00-1 1v12a1 1 0 001 1h10a1 1 0 001-1V7a1 1 0 00-1-1h-2a1 1 0 110-2h2a3 3 0 013 3v12a3 3 0 01-3 3H7a3 3 0 01-3-3V7a3 3 0 01.879-2.121z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M11 4a1 1 0 100 2h2a1 1 0 100-2h-2zM8 5a3 3 0 013-3h2a3 3 0 110 6h-2a3 3 0 01-3-3zM9.732 10.732A2.5 2.5 0 0111.5 10H14a1 1 0 110 2h-2.5a.5.5 0 000 1h1a2.5 2.5 0 010 5H10a1 1 0 110-2h2.5a.5.5 0 000-1h-1a2.5 2.5 0 01-1.768-4.268z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 9a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zm0 7a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1z", clipRule: "evenodd" }))); };
export default ReportMoney;
