import { createAsyncThunk } from '@reduxjs/toolkit';
import { dailyPlanetOverall } from '@core/myservice/build/client';
import {
  GetCardGamesReportRequest,
  GetCommissionGamesReportRequest,
  GetRNGGamesReportRequest,
} from '@dceu/dailyplanet/dailyplanet_overall_report_pb';

export const getRNGGamesReportThunk = createAsyncThunk(
  'saleReportOverview/getRNGGamesReport',
  async (payload: GetRNGGamesReportRequest.AsObject) => {
    return await dailyPlanetOverall.getRNGGamesReport(payload);
  },
);

export const getCommissionGamesReportThunk = createAsyncThunk(
  'saleReportOverview/getCommissionGamesReport',
  async (payload: GetCommissionGamesReportRequest.AsObject) => {
    return await dailyPlanetOverall.getCommissionGamesReport(payload);
  },
);

export const getCardGamesReportThunk = createAsyncThunk(
  'saleReportOverview/getCardGamesReport',
  async (payload: GetCardGamesReportRequest.AsObject) => {
    return await dailyPlanetOverall.getCardGamesReport(payload);
  },
);
