var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { StatusCode as grpcStatusCode } from 'grpc-web';
export var KEY_ACCESS_TOKEN = 'accessToken';
export var QUERY_PARAM_ACCESS_TOKEN = 'token';
export var IGNORE_TOAST_CODES = [grpcStatusCode.UNAUTHENTICATED];
var gRPCClientAbstract = /** @class */ (function () {
    function gRPCClientAbstract(Client, config) {
        this.client = null;
        this.clientName = '';
        this.clientName = (Client === null || Client === void 0 ? void 0 : Client.name) || 'NoClientName';
        this.client = new Client(config.hostname);
        this.onError = config.onError;
        this.serviceName = config.serviceName || 'UNNAMED';
    }
    gRPCClientAbstract.prototype.logFuncName = function (funcName) {
        return this.clientName + "." + funcName;
    };
    gRPCClientAbstract.prototype.gRPCClientRequest = function (func, request) {
        return __awaiter(this, void 0, void 0, function () {
            var token, option, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = localStorage.getItem(KEY_ACCESS_TOKEN);
                        option = {};
                        if (token) {
                            option = __assign(__assign({}, option), { Authorization: "Bearer " + token });
                        }
                        console.log("%c gRPCClientRequest -> [" + this.logFuncName(func) + "] -> REQUEST:", 'background-color: #deeb34; color: #000; font-size: 14px');
                        console.log('>>> request:', request.toObject());
                        console.log('>>> option:', option);
                        return [4 /*yield*/, this.client[func](request, option)];
                    case 1:
                        response = _a.sent();
                        console.log("%c>>>>> gRPCClientResponse -> [" + this.logFuncName(func) + "] -> SUCCESS:", 'background-color: #23d947; color: #000; font-size: 14px', response.toObject());
                        return [2 /*return*/, {
                                error: null,
                                response: response.toObject(),
                            }];
                    case 2:
                        error_1 = _a.sent();
                        switch (error_1 === null || error_1 === void 0 ? void 0 : error_1.code) {
                            case grpcStatusCode.UNAUTHENTICATED:
                                console.log("%c>>>>> gRPCClientResponse -> [" + this.logFuncName(func) + "] -> ERROR -> UNAUTHENTICATED: ", 'background-color: #c0392b; color: #000; font-size: 14px', error_1);
                                break;
                            case grpcStatusCode.UNKNOWN:
                            case grpcStatusCode.UNIMPLEMENTED:
                            case grpcStatusCode.INVALID_ARGUMENT:
                            case grpcStatusCode.NOT_FOUND:
                                console.log("%c>>>>> gRPCClientResponse -> [" + this.logFuncName(func) + "] -> ERROR: ", 'background-color: #c0392b; color: #000; font-size: 14px', error_1);
                                break;
                            default:
                                console.log("%c>>>>> gRPCClientResponse  -> [" + this.logFuncName(func) + "] -> ERROR: ", 'background-color: #c0392b; color: #000; font-size: 14px', error_1);
                                break;
                        }
                        this.onError && this.onError(error_1, this.serviceName);
                        return [2 /*return*/, {
                                error: error_1,
                                response: null,
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return gRPCClientAbstract;
}());
export default gRPCClientAbstract;
