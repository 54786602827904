import { useTranslation } from 'react-i18next';
import { GroupBy } from '@dceu/dailyplanet/dailyplanet_enum_pb';
import { PlayerReport } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';
import { Column } from '@core/uikit';
import LiveOpsFormatNumber from '@core/uikit/build/src/components/FormatNumber';
import Typography, {
  TypoWeights,
} from '@core/oui/build/src/components/Typography';
import useFilter from '@core/uikit/build/src/hooks/useFilter';

import { useForColumns } from 'hooks/useForColumns';
import { getRangeTypeByGroupBy } from '../const';
import { FilterConfig, PlayerReportSortName } from '../types';

export const useColumns = () => {
  const { t } = useTranslation();
  const { query } = useFilter();
  const groupBy =
    Number(query.get(FilterConfig.GROUP_BY)) || GroupBy.GROUP_BY_WEEKLY;

  const { dateTimeColumnValue } = useForColumns(
    FilterConfig.VIEW_BY,
    getRangeTypeByGroupBy(groupBy),
    getRangeTypeByGroupBy(groupBy),
  );

  const columns: Column<PlayerReport.AsObject>[] = [
    {
      Header: t('TEXT.DATE'),
      accessor: (row) => (
        <Typography weight={TypoWeights.bold}>
          {dateTimeColumnValue(row?.datetime?.seconds)}
        </Typography>
      ),
      width: 180,
      sticky: 'left',
    },
    {
      Header: t('TEXT.NEW_REGISTER_PLAYER'),
      accessor: (row) => <LiveOpsFormatNumber value={row?.nru || 0} />,
      align: 'right',
      sort: PlayerReportSortName.REGISTER_PLAYER,
    },
    {
      Header: t('TEXT.LOGIN_PLAYER'),
      accessor: (row) => <LiveOpsFormatNumber value={row?.dlu || 0} />,
      align: 'right',
      sort: PlayerReportSortName.LOGIN_PLAYER,
    },
    {
      Header: t('TEXT.ACTIVE_PLAYER'),
      columns: [
        {
          Header: t('TEXT.QUANTITY'),
          accessor: (row) => {
            return <LiveOpsFormatNumber value={row.dau} />;
          },
          align: 'right',
          sort: PlayerReportSortName.ACTIVE_PLAYER_QUANTITY,
        },
        {
          Header: t('TEXT.PERCENT'),
          accessor: (row) => {
            if (!row.dlu) return 0;
            return (
              <LiveOpsFormatNumber
                value={row.dau / row.dlu}
                percent
                afterNumber="%"
              />
            );
          },
          align: 'right',
          sort: PlayerReportSortName.ACTIVE_PLAYER_PERCENT,
        },
      ],
    },
    {
      Header: t('TEXT.DEPOSIT_PLAYER'),
      columns: [
        {
          Header: t('TEXT.QUANTITY'),
          accessor: (row) => {
            return <LiveOpsFormatNumber value={row.dtu} />;
          },
          align: 'right',
          sort: PlayerReportSortName.DEPOSIT_PLAYER_QUANTITY,
        },
        {
          Header: t('TEXT.PERCENT'),
          accessor: (row) => {
            if (!row.dlu) return 0;
            return (
              <LiveOpsFormatNumber
                value={row.dtu / row.dlu}
                percent
                afterNumber="%"
              />
            );
          },
          align: 'right',
          sort: PlayerReportSortName.DEPOSIT_PLAYER_PERCENT,
        },
      ],
    },
    {
      Header: t('TEXT.WITHDRAW_PLAYER'),
      accessor: (row) => <LiveOpsFormatNumber value={row?.dwu || 0} />,
      align: 'right',
      sort: PlayerReportSortName.WITHDRAW_PLAYER,
    },
    {
      Header: t('TEXT.ARPU'),
      headerTooltip: t('TEXT.ARPU_TOOLTIP'),
      accessor: (row) => (
        <LiveOpsFormatNumber
          value={row?.arpu || 0}
          variantColor={(row?.arpu || 0) < 0 ? 'danger' : undefined}
          unit
        />
      ),
      align: 'right',
      sort: PlayerReportSortName.ARPP,
    },
    {
      Header: t('TEXT.DEPOSIT_BY_PLAYER'),
      accessor: (row) => (
        <LiveOpsFormatNumber value={row?.topUpByUser || 0} unit />
      ),
      align: 'right',
      sort: PlayerReportSortName.DEPOSIT_BY_PLAYER,
    },
    {
      Header: t('TEXT.WITHDRAW_BY_PLAYER'),
      accessor: (row) => (
        <LiveOpsFormatNumber value={row?.withdrawByUser || 0} unit />
      ),
      align: 'right',
      sort: PlayerReportSortName.WITHDRAW_PLAYER,
    },
  ];

  return { columns };
};
