var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import ListItemText from '../ListItemText';
import SelectContext from '../DropdownList/select-context';
import MenuItem from '../MenuItem';
import { InputSizes } from '../Input';
var defaultProps = {
    component: MenuItem,
    size: InputSizes.lg,
};
export var Option = function (props) {
    var _a = __assign(__assign({}, defaultProps), props), Component = _a.component, valueProps = _a.value, children = _a.children, size = _a.size, rest = __rest(_a, ["component", "value", "children", "size"]);
    var selectContext = useContext(SelectContext);
    function handleClick() {
        if (!rest.disabled && valueProps !== (selectContext === null || selectContext === void 0 ? void 0 : selectContext.value)) {
            selectContext === null || selectContext === void 0 ? void 0 : selectContext.onChange(valueProps, (children === null || children === void 0 ? void 0 : children.toString()) || valueProps);
        }
    }
    var activated = (selectContext === null || selectContext === void 0 ? void 0 : selectContext.value) === valueProps;
    return (_jsx(Component, __assign({}, rest, { onClick: handleClick, activated: activated, size: rest }, { children: _jsx(ListItemText, __assign({ size: size }, { children: children }), void 0) }), void 0));
};
Option.displayName = 'Option';
export default Option;
