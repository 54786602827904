var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import DropdownList from '../../DropdownList';
import Option from '../../Option';
import Typography from '../../Typography';
import useFilter from '../../../hooks/useFilter';
var FilterDropdown = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'dropdown' : _b, _c = _a.pageKey, pageKey = _c === void 0 ? 'p' : _c, _d = _a.options, options = _d === void 0 ? [] : _d, _e = _a.defaultValue, defaultValue = _e === void 0 ? 0 : _e, _f = _a.displayName, displayName = _f === void 0 ? name : _f, _g = _a.selectText, selectText = _g === void 0 ? 'Select' : _g, renderSelected = _a.renderSelected, value = _a.value, restProps = __rest(_a, ["name", "pageKey", "options", "defaultValue", "displayName", "selectText", "renderSelected", "value"]);
    var _h = useFilter(), query = _h.query, pushQuery = _h.pushQuery, popQuery = _h.popQuery;
    var _j = __read(useState(false), 2), openDropDown = _j[0], setOpenDropDown = _j[1];
    var selected = query.get(name) || value || defaultValue;
    var selectedOption = useMemo(function () {
        return options.find(function (o) { return +o.value === +selected; });
    }, [options, selected]);
    var defaultRenderSelected = function (idx) {
        return (_jsxs("div", { children: [_jsx(Typography, __assign({ variant: 'body1', weight: 'bold', type: 'primary' }, { children: displayName + ": " }), void 0), openDropDown ? (_jsx(Typography, __assign({ variant: 'body1', type: 'disable' }, { children: selectText }), void 0)) : (_jsx(Typography, __assign({ variant: 'body1', type: 'primary' }, { children: selectedOption.name }), void 0))] }, idx));
    };
    var handleChange = function (v) {
        pushQuery(name, v.value);
        popQuery(pageKey);
    };
    var display = function (idx) {
        return renderSelected
            ? renderSelected(openDropDown, idx)
            : defaultRenderSelected(idx);
    };
    return (_jsx(DropdownList, __assign({ value: selectedOption, placeholder: displayName, onChange: handleChange, display: display, setOpenDropDown: setOpenDropDown }, restProps, { children: options.map(function (item, idx) { return (_jsx(Option, __assign({ value: item }, { children: item.name }), idx)); }) }), void 0));
};
export default FilterDropdown;
