var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Divider } from '@material-ui/core';
import i18n from 'i18n';
import Typography from '../../Typography';
import useFilter from '../../../hooks/useFilter';
import styles from '../styles.module.scss';
var ResetAll = function (_a) {
    var _b = _a.text, text = _b === void 0 ? i18n.t('Common::TEXT.RESET') : _b, _c = _a.pageName, pageName = _c === void 0 ? 'p' : _c, _d = _a.pageSizeName, pageSizeName = _d === void 0 ? 'sz' : _d, _e = _a.exceptionList, exceptionList = _e === void 0 ? [] : _e;
    var _f = useFilter(), navigate = _f.navigate, query = _f.query;
    var resetFilter = function () {
        var e_1, _a;
        query.delete(pageName);
        var arr = __spreadArray([], __read(query.entries())); // clone
        try {
            for (var arr_1 = __values(arr), arr_1_1 = arr_1.next(); !arr_1_1.done; arr_1_1 = arr_1.next()) {
                var _b = __read(arr_1_1.value, 1), key = _b[0];
                key !== pageSizeName && !exceptionList.includes(key) && query.delete(key);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (arr_1_1 && !arr_1_1.done && (_a = arr_1.return)) _a.call(arr_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var search = query.toString();
        navigate({ search: search });
    };
    var isExistAnyFilter = function () {
        // if query contain any filter except page & pageSize & exceptionList
        var cloneQuery = new URLSearchParams(query);
        cloneQuery.delete(pageName);
        cloneQuery.delete(pageSizeName);
        exceptionList.map(function (name) { return cloneQuery.delete(name); });
        return !!cloneQuery.toString();
    };
    return (_jsx(_Fragment, { children: isExistAnyFilter() && (_jsxs(Box, __assign({ className: styles['reset-wrapper'] }, { children: [_jsx(Box, __assign({ mr: 2 }, { children: _jsx(Divider, { orientation: "vertical", className: styles['divider'] }, void 0) }), void 0), _jsx(Typography, __assign({ className: "cursor-pointer", weight: "bold", type: "primary", onClick: resetFilter }, { children: text }), void 0)] }), void 0)) }, void 0));
};
export default ResetAll;
