import * as React from 'react';
import cn from 'classnames';
import { MAX_YEAR, MIN_YEAR } from './const';
import styles from './styles.module.scss';
var YearPicker = function (_a) {
    var onYearSelected = _a.onYearSelected, yearSelected = _a.yearSelected;
    var yItems = [];
    var _loop_1 = function (i) {
        var _b;
        yItems.push(React.createElement("li", { key: i, className: cn((_b = {}, _b[styles.active] = i === yearSelected, _b)), onClick: function () { return onYearSelected(i); } },
            React.createElement("span", null, i)));
    };
    for (var i = MIN_YEAR; i <= MAX_YEAR; i++) {
        _loop_1(i);
    }
    return React.createElement("ul", { className: styles.yearPicker }, yItems);
};
export default YearPicker;
