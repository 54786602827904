export var FormTypes;
(function (FormTypes) {
    FormTypes[FormTypes["INPUT"] = 0] = "INPUT";
    FormTypes[FormTypes["INPUT_NUMBER"] = 1] = "INPUT_NUMBER";
    FormTypes[FormTypes["INPUT_PASSWORD"] = 2] = "INPUT_PASSWORD";
    FormTypes[FormTypes["SELECT"] = 3] = "SELECT";
    FormTypes[FormTypes["COMPONENT"] = 4] = "COMPONENT";
    FormTypes[FormTypes["DATE"] = 5] = "DATE";
    FormTypes[FormTypes["AUTOCOMPLETE"] = 6] = "AUTOCOMPLETE";
    FormTypes[FormTypes["RADIO"] = 7] = "RADIO";
    FormTypes[FormTypes["PHONE_NUMBER"] = 8] = "PHONE_NUMBER";
    FormTypes[FormTypes["TEXT_AREA"] = 9] = "TEXT_AREA";
    FormTypes[FormTypes["TYPOGRAPHY"] = 10] = "TYPOGRAPHY";
    FormTypes[FormTypes["CHECKBOX"] = 11] = "CHECKBOX";
    FormTypes[FormTypes["INPUT_MONEY"] = 12] = "INPUT_MONEY";
    FormTypes[FormTypes["SWITCH"] = 13] = "SWITCH";
    FormTypes[FormTypes["TIME"] = 14] = "TIME";
    FormTypes[FormTypes["DIVIDE"] = 15] = "DIVIDE";
    FormTypes[FormTypes["AUTOCOMPLETE_MULTI"] = 16] = "AUTOCOMPLETE_MULTI";
    FormTypes[FormTypes["DATE_V2"] = 17] = "DATE_V2";
})(FormTypes || (FormTypes = {}));
