import { KEY_ACCESS_TOKEN } from '@core/myservice';
import { DEFAULT_LANG, KEY_I18N, REDIRECT_TO, setTzToLocalStorage, TZ_KEY, } from '../utils';
import useQuery from './useQuery';
export const useSSO = ({ portalUrl }) => {
    const query = useQuery();
    const token = query.get(KEY_ACCESS_TOKEN);
    const lang = query.get(KEY_I18N) || DEFAULT_LANG;
    const tz = query.get(TZ_KEY);
    const redirectTo = query.get(REDIRECT_TO);
    if (tz)
        setTzToLocalStorage(Number(tz));
    if (token) {
        localStorage.setItem(KEY_ACCESS_TOKEN, token);
    }
    else {
        window.location.assign(portalUrl);
    }
    return { lang, token, redirectTo };
};
