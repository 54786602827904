import React from 'react';
export default function useImage(_a) {
    var crossOrigin = _a.crossOrigin, referrerPolicy = _a.referrerPolicy, src = _a.src, srcSet = _a.srcSet;
    var _b = React.useState('idle'), status = _b[0], setStatus = _b[1];
    React.useLayoutEffect(function () {
        var active = true;
        function doFetch() {
            if (!src && !srcSet) {
                return setStatus('empty');
            }
            setStatus('loading');
            var image = new Image();
            image.crossOrigin = crossOrigin || null;
            image.referrerPolicy = referrerPolicy || '';
            image.src = src;
            if (srcSet) {
                image.srcset = srcSet;
            }
            image.onerror = function () {
                if (active) {
                    setStatus('error');
                }
            };
            image.onload = function () {
                if (active) {
                    setStatus('success');
                }
            };
            return;
        }
        doFetch();
        return function () {
            active = false;
        };
    }, [crossOrigin, referrerPolicy, src, srcSet]);
    var isBlob = src.startsWith('blob:');
    var isLoading = status === 'loading' && !isBlob;
    var isError = status === 'error';
    var isEmpty = status === 'empty';
    var isSuccess = status === 'success' || isBlob;
    return {
        isLoading: isLoading,
        isError: isError,
        isSuccess: isSuccess,
        isEmpty: isEmpty,
        status: status,
    };
}
