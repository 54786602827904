var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import cn from 'classnames';
import TextareaBase from '../TextareaBase';
import { TextareaSize } from './types';
import { getArrayOf } from '../../utils/common/getArrayOf';
import styles from './styles.module.scss';
var defaultProps = {
    component: TextareaBase,
    size: TextareaSize.md,
};
export var Textarea = forwardRef(function (_props, ref) {
    var _a = __assign(__assign({}, defaultProps), _props), Component = _a.component, className = _a.className, wrapperRef = _a.wrapperRef, status = _a.status, afterInput = _a.afterInput, rest = __rest(_a, ["component", "className", "wrapperRef", "status", "afterInput"]);
    var classOfRoot = cn(styles.root, styles["status-" + status], className, rest.disabled && styles.disabled);
    var afterInputs = getArrayOf(afterInput);
    var classOfTextareaBase = cn(styles.textarea, styles["size-" + rest.size], afterInputs.length > 0 && styles['has-after'], rest.disabled && styles.disabled);
    return (_jsxs("div", __assign({ className: classOfRoot, role: "presentation", ref: wrapperRef }, { children: [_jsx(Component, __assign({}, rest, { ref: ref, className: classOfTextareaBase }), void 0), afterInputs.map(function (component, index) {
                // @ts-ignore
                return React.cloneElement(component, {
                    key: index.toString(),
                    disabled: rest.disabled,
                    size: rest.size,
                    className: styles['button-clear'],
                });
            })] }), void 0));
});
Textarea.displayName = 'Textarea';
export default Textarea;
