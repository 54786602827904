import styles from './styles.module.scss';

export const CompCommon = (props: any) => {
  const {
    location: { pathname },
  } = props;

  return (
    <>
      Page match for <code>{pathname}</code>
    </>
  );
};

export const CompNotFound = () => {
  return (
    <div className={styles.main}>
      <div className={styles.number}>404</div>
      <div className={styles.text}>Page not found</div>
    </div>
  );
};
