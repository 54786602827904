var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
function Screen(props) {
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { clipPath: "url(#prefix__clip0_501_3914)", fillRule: "evenodd", clipRule: "evenodd", fill: "currentColor" },
            React.createElement("path", { d: "M2 19a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM4.586 5.586A2 2 0 016 5h12a2 2 0 012 2v8a2 2 0 01-2 2H6a2 2 0 01-2-2V7a2 2 0 01.586-1.414zM18 7H6v8h12V7z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "prefix__clip0_501_3914" },
                React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" })))));
}
export default Screen;
