import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear, subDays, subWeeks, subMonths, subYears, getDate, getMonth, getYear, } from 'date-fns';
import { endOfDay, endOfYesterday, startOfDay, startOfYesterday, } from 'date-fns/esm';
import { PeriodType } from '../context/url_params_context/resolve_url_params';
import { getCurrentTzNumber, getTzLocal } from './tzLocalStorage';
import i18next from 'i18next';
const A_THOUSAND = 1000;
export const convertMillisecondsToSeconds = (milliseconds) => {
    if (!milliseconds)
        return 0;
    return Math.trunc(milliseconds / A_THOUSAND);
};
export const convertSecondsToMilliseconds = (seconds) => {
    if (!seconds)
        return 0;
    return seconds * A_THOUSAND;
};
export const setTimestampPB = (milliseconds) => {
    return {
        nanos: 0,
        seconds: convertMillisecondsToSeconds(milliseconds),
    };
};
const convertNumberToGMT = (n) => {
    if (n > 0)
        return `Etc/GMT-${n}`;
    if (n < 0)
        return `Etc/GMT+${n}`;
    return 'Etc/GMT';
};
export const formatTimeStampToSeconds = (timestamp) => Math.floor(timestamp / 1000);
export const formatWithSchema = (timestamp, options) => {
    const currentTzNumber = getCurrentTzNumber();
    const optionsDefault = {
        // hour12: false,
        timeZone: convertNumberToGMT(currentTzNumber),
        hourCycle: 'h23',
        // fa
    };
    return new Intl.DateTimeFormat('en-US', Object.assign(Object.assign({}, optionsDefault), options)).format(timestamp);
};
export const formatDate = (timestamp) => formatWithSchema(timestamp, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
});
export const formatDateStyleMedium = (timestamp) => {
    const dayString = getDate(timestamp);
    const monthString = i18next.t(`Common::MONTH.${getMonth(timestamp) + 1}`);
    const yearString = getYear(timestamp);
    return `${dayString} ${monthString} ${yearString}`;
};
export const formatTime = (timestamp) => formatWithSchema(timestamp, {
    hour: '2-digit',
    minute: '2-digit',
});
export const getDayTz = (timestamp) => formatWithSchema(timestamp, {
    day: '2-digit',
});
export const setEndDay = (day) => {
    return day + (24 * 3600 - 1) * 1000 + 999;
};
export const getYearTz = (timestamp) => formatWithSchema(timestamp, {
    year: 'numeric',
});
export const formatDateTime = (timestamp) => {
    if (!timestamp)
        return '-';
    return formatWithSchema(timestamp, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });
};
export const getHourTz = (timestamp) => {
    return formatWithSchema(timestamp, {
        hour: '2-digit',
    });
};
export const getMonthTz = (timestamp) => {
    return formatWithSchema(timestamp, {
        month: 'numeric',
    });
};
export const getQuarterTz = (timestamp) => {
    const monthInt = parseInt(formatWithSchema(timestamp, {
        month: 'numeric',
    })) + 1;
    return Math.ceil(monthInt / 3).toString();
};
export const getISOWeekTz = (timestamp) => {
    const date = new Date(timestamp);
    const currentTzNumber = getCurrentTzNumber();
    const dateInTimeZone = new Date(date.valueOf() + currentTzNumber * 60 * 60 * 1000);
    const dayOfWeek = dateInTimeZone.getUTCDay() || 7;
    dateInTimeZone.setUTCDate(dateInTimeZone.getUTCDate() + 4 - dayOfWeek);
    const yearStart = new Date(Date.UTC(dateInTimeZone.getUTCFullYear(), 0, 1));
    return Math.ceil(((dateInTimeZone.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7).toString();
};
export const formatTimestamp = (ts) => {
    if (!(ts === null || ts === void 0 ? void 0 : ts.seconds))
        return '-';
    const seconds = ts.seconds || 0;
    return formatWithSchema(seconds * 1000, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });
};
export const removeSlashInDate = (date) => {
    return date.replace(/\//g, '');
};
export const getTimezoneOffset = () => {
    const tzLocal = getTzLocal();
    const tzCurrent = getCurrentTzNumber();
    const offset = (tzLocal - tzCurrent) * 60 * 60 * A_THOUSAND;
    return offset;
};
export const setTimezoneInTime = (timestamp) => {
    if (timestamp <= 0)
        return 0;
    const offset = getTimezoneOffset();
    return timestamp + offset;
};
export const removeTimezoneInTime = (timestamp) => {
    if (timestamp <= 0)
        return 0;
    const offset = getTimezoneOffset();
    return timestamp - offset;
};
export const getNowTz = () => {
    return setTimezoneInTime(Date.now().valueOf());
};
export const formatTimeStampToObject = (timestamp, nanos = 0) => ({
    seconds: Math.floor(timestamp / 1000),
    nanos,
});
export const getLastWeek = () => {
    const dateOfLastWeek = subDays(new Date(), 7);
    return {
        start: setTimezoneInTime(+startOfWeek(dateOfLastWeek, { weekStartsOn: 1 })),
        end: setTimezoneInTime(+endOfWeek(dateOfLastWeek, { weekStartsOn: 1 })),
    };
};
export const getThisWeek = () => {
    const date = new Date();
    return {
        start: setTimezoneInTime(+startOfWeek(date, { weekStartsOn: 1 })),
        end: setTimezoneInTime(+endOfWeek(date, { weekStartsOn: 1 })),
    };
};
export const getLast4Weeks = () => {
    const dateOfLast4Weeks = subWeeks(new Date(), 4);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfLast4Weeks))),
        end: setTimezoneInTime(Math.floor(+endOfDay(new Date()))),
    };
};
export const getLastMonth = () => {
    const lastMonth = subMonths(new Date(), 1);
    return {
        start: setTimezoneInTime(+startOfMonth(lastMonth)),
        end: setTimezoneInTime(+endOfMonth(lastMonth)),
    };
};
export const getLast3Months = () => {
    const dateOfLast3Months = subMonths(new Date(), 3);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfLast3Months))),
        end: setTimezoneInTime(Math.floor(+endOfDay(new Date()))),
    };
};
export const getThisMonth = () => {
    const date = new Date();
    return {
        start: setTimezoneInTime(+startOfMonth(date)),
        end: setTimezoneInTime(+endOfMonth(date)),
    };
};
export const getFromThisTimeLastDay = () => {
    const dateOfLastDay = subDays(new Date(), 1);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfLastDay))),
        end: setTimezoneInTime(Math.floor(+endOfDay(new Date()))),
    };
};
export const getFromThisTimeYesterday = () => {
    const dateOfYesterday = subDays(new Date(), 1);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfYesterday))),
        end: setTimezoneInTime(Math.floor(+endOfDay(dateOfYesterday))),
    };
};
export const getFromThisTimeLastWeek = () => {
    const dateOfLastWeek = subDays(new Date(), 7);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfLastWeek))),
        end: setTimezoneInTime(Math.floor(+endOfDay(new Date()))),
    };
};
export const getFromThisTimeLastMonth = () => {
    const dateOfLastMonth = subMonths(new Date(), 1);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfLastMonth))),
        end: setTimezoneInTime(Math.floor(+endOfDay(new Date()))),
    };
};
export const getLast7Days = (options) => {
    const { showCurrentDate } = options || {};
    const now = new Date();
    const dateOfLast7Days = subDays(now, showCurrentDate ? 6 : 7);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfLast7Days))),
        end: setTimezoneInTime(Math.floor(+endOfDay(now))),
    };
};
export const getLast30Days = () => {
    const dateOfLast30Days = subDays(new Date(), 30);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfLast30Days))),
        end: setTimezoneInTime(Math.floor(+endOfDay(new Date()))),
    };
};
export const getLastYear = () => {
    const lastYear = subYears(new Date(), 1);
    return {
        start: setTimezoneInTime(+startOfYear(lastYear)),
        end: setTimezoneInTime(+endOfYear(lastYear)),
    };
};
export const getLast3Years = () => {
    const dateOfLast3Years = subYears(new Date(), 3);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfLast3Years))),
        end: setTimezoneInTime(Math.floor(+endOfDay(new Date()))),
    };
};
export const getThisYear = () => {
    const date = new Date();
    return {
        start: setTimezoneInTime(+startOfYear(date)),
        end: setTimezoneInTime(+endOfYear(date)),
    };
};
export const getCurrentDay = () => {
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(new Date()))),
        end: setTimezoneInTime(Math.floor(+endOfDay(new Date()))),
    };
};
export const getYesterday = () => {
    return {
        start: setTimezoneInTime(Math.floor(+startOfYesterday())),
        end: setTimezoneInTime(Math.floor(+endOfYesterday())),
    };
};
export const getLast6Months = () => {
    const dateOfLast6Months = subMonths(new Date(), 6);
    return {
        start: setTimezoneInTime(Math.floor(+startOfDay(dateOfLast6Months))),
        end: setTimezoneInTime(Math.floor(+endOfDay(new Date()))),
    };
};
export const getRangeByPeriod = (period) => {
    switch (period) {
        case PeriodType.Daily:
            return getCurrentDay();
        case PeriodType.Yesterday:
            return getYesterday();
        case PeriodType.Last7Days:
            return getLast7Days();
        case PeriodType.Last30Days:
            return getLast30Days();
        case PeriodType.Weekly:
            return getThisWeek();
        case PeriodType.ThisWeek:
            return getThisWeek();
        case PeriodType.LastWeek:
            return getLastWeek();
        case PeriodType.Last4Weeks:
            return getLast4Weeks();
        case PeriodType.Monthly:
            return getThisMonth();
        case PeriodType.ThisMonth:
            return getThisMonth();
        case PeriodType.LastMonth:
            return getLastMonth();
        case PeriodType.Last3Months:
            return getLast3Months();
        case PeriodType.Last6Months:
            return getLast6Months();
        case PeriodType.ThisYear:
            return getThisYear();
        case PeriodType.LastYear:
            return getLastYear();
        case PeriodType.Last3Years:
            return getLast3Years();
        default:
            throw new Error(`missing get range function for period: ${period}`);
    }
};
