var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState, } from 'react';
import CalendarPicker from './CalendarPicker';
import { DateRangeContext } from './context';
var DateRangePicker = function (_a) {
    var dateRange = _a.dateRange, onChange = _a.onChange, description = _a.description, periodOptions = _a.periodOptions, field = __rest(_a, ["dateRange", "onChange", "description", "periodOptions"]);
    var _b = useState([0, 0]), dayRangeTmp = _b[0], setDayRangeTmp = _b[1];
    useEffect(function () {
        setDayRangeTmp(dateRange);
    }, [dateRange[0], dateRange[1]]);
    return (React.createElement(DateRangeContext.Provider, { value: {
            dayRangeTmp: dayRangeTmp,
            setDayRangeTmp: setDayRangeTmp,
        } },
        React.createElement(CalendarPicker, __assign({ value: dateRange, onChange: onChange, periodOptions: periodOptions }, field))));
};
export default DateRangePicker;
