var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import styles from './styles.module.scss';
import TimeSelector from './TimeSelector';
import { DatePickerContext } from '../PopoverCalendar';
var PREFIX_HOUR = 'hour';
var PREFIX_MINUTE = 'minute';
var TimePicker = function () {
    var dateTimeContext = React.useContext(DatePickerContext);
    var scrollIntoViewOfHour = function () {
        var hrElt = document.getElementById(PREFIX_HOUR + "_timepicker_" + dateTimeContext.time[0]);
        if (!hrElt)
            return;
        hrElt.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };
    var scrollIntoViewOfMinute = function () {
        var mElt = document.getElementById(PREFIX_MINUTE + "_timepicker_" + dateTimeContext.time[1]);
        if (!mElt)
            return;
        mElt.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };
    React.useEffect(function () {
        setTimeout(function () {
            scrollIntoViewOfHour();
        }, 200);
        setTimeout(function () {
            scrollIntoViewOfMinute();
        }, 700);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTimeContext.time]);
    var parseTime = React.useCallback(function (time) {
        if (time < 10)
            return "0" + time;
        return time;
    }, []);
    var changeHour = function (hour) {
        var t = __spreadArray([], __read(dateTimeContext.time));
        dateTimeContext.setTime([hour, t[1], t[2]]);
    };
    var changeMinute = function (m) {
        var t = __spreadArray([], __read(dateTimeContext.time));
        dateTimeContext.setTime([t[0], m, t[2]]);
    };
    return (_jsxs("div", __assign({ className: styles.timePicker }, { children: [_jsxs("div", __assign({ className: styles.timeText }, { children: [parseTime(dateTimeContext.time[0]), ":", parseTime(dateTimeContext.time[1])] }), void 0), _jsxs("div", __assign({ className: styles.timeSelectorBox, id: "time_selector" }, { children: [_jsx(TimeSelector, { max: 24, numberSelected: dateTimeContext.time[0], onChange: changeHour, name: PREFIX_HOUR }, void 0), _jsx(TimeSelector, { max: 60, numberSelected: dateTimeContext.time[1], onChange: changeMinute, name: PREFIX_MINUTE }, void 0)] }), void 0)] }), void 0));
};
export default TimePicker;
