import { useNavigate, useLocation } from 'react-router-dom';
export function useNavigateTab() {
    const location = useLocation();
    const navigate = useNavigate();
    function genTabPath(newTab) {
        const segments = location.pathname.split('/');
        segments[segments.length - 1] = newTab;
        const newTabPath = segments.join('/');
        return newTabPath;
    }
    function navigateTab(newTab, search = '') {
        navigate(genTabPath(newTab) + search);
    }
    return {
        navigateTab,
    };
}
