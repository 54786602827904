import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const OpsTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#000',
    fontSize: '14px',
    border: '1px solid #e0e0e0',
    padding: '8px 16px',
    filter: 'drop-shadow(0px -4px 16px rgba(3, 19, 82, 0.16))',
  },
  arrow: {
    '&::before': {
      backgroundColor: '#fff',
      border: '1px solid #e0e0e0',
    },
  },
}))(Tooltip);
