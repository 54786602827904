var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Phom = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_1544_3191)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.42857 8C6.34372 8 6.24318 8.03594 6.15495 8.13202C6.06409 8.23095 6 8.38203 6 8.55556V19.4444C6 19.618 6.06409 19.769 6.15495 19.868C6.24319 19.9641 6.34372 20 6.42857 20H13.5714C13.6563 20 13.7568 19.9641 13.8451 19.868C13.9359 19.769 14 19.618 14 19.4444C14 18.8922 14.4477 18.4444 15 18.4444C15.5523 18.4444 16 18.8922 16 19.4444C16 20.096 15.7631 20.7363 15.3181 21.2208C14.8705 21.7082 14.2443 22 13.5714 22H6.42857C5.75566 22 5.12947 21.7082 4.68189 21.2208C4.23693 20.7363 4 20.096 4 19.4444V8.55556C4 7.90396 4.23693 7.26372 4.68189 6.77921C5.12947 6.29184 5.75567 6 6.42857 6C6.98086 6 7.42857 6.44772 7.42857 7C7.42857 7.55228 6.98086 8 6.42857 8Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.4286 4C10.3437 4 10.2432 4.03594 10.1549 4.13202C10.0641 4.23095 10 4.38203 10 4.55556V15.4444C10 15.618 10.0641 15.769 10.1549 15.868C10.2432 15.9641 10.3437 16 10.4286 16H17.5714C17.6563 16 17.7568 15.9641 17.8451 15.868C17.9359 15.769 18 15.618 18 15.4444V4.55556C18 4.38203 17.9359 4.23095 17.8451 4.13202C17.7568 4.03594 17.6563 4 17.5714 4H10.4286ZM8.68189 2.77921C9.12947 2.29184 9.75567 2 10.4286 2H17.5714C18.2443 2 18.8705 2.29184 19.3181 2.77921C19.7631 3.26372 20 3.90396 20 4.55556V15.4444C20 16.096 19.7631 16.7363 19.3181 17.2208C18.8705 17.7082 18.2443 18 17.5714 18H10.4286C9.75566 18 9.12947 17.7082 8.68189 17.2208C8.23693 16.7363 8 16.096 8 15.4444V4.55556C8 3.90396 8.23693 3.26372 8.68189 2.77921Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 6C14.3344 6 14.6466 6.1671 14.8321 6.4453L16.8321 9.4453C17.056 9.7812 17.056 10.2188 16.8321 10.5547L14.8321 13.5547C14.6466 13.8329 14.3344 14 14 14C13.6656 14 13.3534 13.8329 13.1679 13.5547L11.1679 10.5547C10.944 10.2188 10.944 9.7812 11.1679 9.4453L13.1679 6.4453C13.3534 6.1671 13.6656 6 14 6ZM13.2019 10L14 11.1972L14.7981 10L14 8.80278L13.2019 10Z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1544_3191" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" }))))); };
export default Phom;
