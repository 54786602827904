import { createStyles, makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function () {
    return createStyles({
        '@global': {
            '*': {
                boxSizing: 'border-box',
                margin: 0,
                padding: 0,
            },
            html: {
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
                height: '100%',
                width: '100%',
            },
            body: {
                backgroundColor: '#fff',
                height: '100%',
                width: '100%',
            },
            a: {
                textDecoration: 'none',
            },
            '#root': {
                height: '100%',
                width: '100%',
            },
        },
    });
});
var GlobalStyles = function () {
    useStyles();
    return null;
};
export default GlobalStyles;
