var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import TableRow from '../../TableRow';
import HeaderCheckbox from './HeaderCheckbox';
import HeaderCells from './HeaderCells';
var HeaderRow = function (_a) {
    var hasCheckboxes = _a.hasCheckboxes, classOfRow = _a.classOfRow, classOfCheckboxCell = _a.classOfCheckboxCell, classOfHeaderCell = _a.classOfHeaderCell, isAllIntermediate = _a.isAllIntermediate, handleToggleCheckboxAll = _a.handleToggleCheckboxAll, header = _a.header, stickyColumn = _a.stickyColumn, isAllSelected = _a.isAllSelected, isAllRowsExpanded = _a.isAllRowsExpanded, handleToggleExpandAll = _a.handleToggleExpandAll;
    var isRowMerged = !!header.find(function (h) { return h.columns && h.columns.length; });
    var subHeader = [];
    if (isRowMerged) {
        header.forEach(function (h) {
            if (h.columns && h.columns.length) {
                subHeader = __spreadArray(__spreadArray([], __read(subHeader)), __read(h.columns));
            }
        });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, __assign({ className: classOfRow }, { children: [hasCheckboxes && (_jsx(HeaderCheckbox, { isRowMerged: isRowMerged, classOfCheckboxCell: classOfCheckboxCell, handleToggleCheckboxAll: handleToggleCheckboxAll, isAllIntermediate: isAllIntermediate, isAllSelected: isAllSelected }, void 0)), _jsx(HeaderCells, { classOfHeaderCell: classOfHeaderCell, hasCheckboxes: hasCheckboxes, header: header, stickyColumn: stickyColumn, isAllRowsExpanded: isAllRowsExpanded, handleToggleExpandAll: handleToggleExpandAll, isRowMerged: isRowMerged }, void 0)] }), void 0), isRowMerged && (_jsx(TableRow, __assign({ className: classOfRow }, { children: _jsx(HeaderCells, { classOfHeaderCell: classOfHeaderCell, hasCheckboxes: hasCheckboxes, header: subHeader, stickyColumn: stickyColumn, isAllRowsExpanded: isAllRowsExpanded, handleToggleExpandAll: handleToggleExpandAll }, void 0) }), void 0))] }, void 0));
};
export default React.memo(HeaderRow);
