var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Checkbox } from '../../Checkbox';
import TableCell from '../../TableCell';
var BodyRowCheckboxCell = function (_a) {
    var isRowSelected = _a.isRowSelected, rowIdx = _a.rowIdx, classOfCheckboxCell = _a.classOfCheckboxCell, handleToggleCheckboxRow = _a.handleToggleCheckboxRow;
    return (_jsx(TableCell, __assign({ className: classOfCheckboxCell }, { children: _jsx(Checkbox, { name: rowIdx.toString(), value: rowIdx.toString(), checked: isRowSelected, onClick: handleToggleCheckboxRow }, void 0) }), "checkbox-" + rowIdx));
};
export default React.memo(BodyRowCheckboxCell);
