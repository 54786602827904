import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
export default function BreadcrumbsContainer() {
    // const location = useLocation();
    // const { routes } = useContext(PermissionContext);
    // const route = routes.find((item) =>
    //   matchPath(location.pathname, {
    //     path: item.path,
    //     exact: true,
    //     strict: false,
    //   }),
    // );
    // if (route) {
    //   const { breadcrumbs: Breadcrumbs } = route;
    //   if (typeof Breadcrumbs === 'function') {
    //     return <Breadcrumbs />;
    //   }
    // }
    return _jsx(_Fragment, {}, void 0);
}
