var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactIs from 'react-is';
import { isFunction } from '../../../utils/common';
import Typography from '../../Typography';
import { getCellStyle, getStickyCellClassName } from '../helper';
var FooterCell = function (_a) {
    var stickyColumn = _a.stickyColumn, header = _a.header, width = _a.width, sticky = _a.sticky, columnIdx = _a.columnIdx, hasCheckboxes = _a.hasCheckboxes, classOfCell = _a.classOfCell, data = _a.data, Footer = _a.Footer;
    var cellStyle = getCellStyle(stickyColumn, header, width, sticky, columnIdx, hasCheckboxes);
    var classOfCurrentCell = getStickyCellClassName(classOfCell, {
        stickyColumn: stickyColumn,
        sticky: sticky,
        width: width,
    });
    var cellContent = isFunction(Footer) ? Footer(data) : Footer;
    if (typeof cellContent === 'string' ||
        typeof cellContent === 'number' ||
        ReactIs.isFragment(cellContent)) {
        cellContent = _jsx("div", { children: cellContent }, void 0);
    }
    return (_jsx(Typography, __assign({ variant: "body2", type: "placeholder", component: "td", width: width, className: classOfCurrentCell, style: cellStyle }, { children: cellContent }), "footer-" + columnIdx));
};
export default React.memo(FooterCell);
