var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import cn from 'classnames';
import React from 'react';
import HeaderCell from './HeaderCell';
import styles from '../styles.module.scss';
var ROW_MERGED_DEFAULT = 2;
var HeaderCells = function (_a) {
    var hasCheckboxes = _a.hasCheckboxes, classOfHeaderCell = _a.classOfHeaderCell, header = _a.header, stickyColumn = _a.stickyColumn, isAllRowsExpanded = _a.isAllRowsExpanded, handleToggleExpandAll = _a.handleToggleExpandAll, isRowMerged = _a.isRowMerged;
    return (_jsx(_Fragment, { children: header.map(function (_a, columnIdx) {
            var _b;
            var label = _a.label, width = _a.width, sticky = _a.sticky, headerTooltip = _a.headerTooltip, className = _a.className, sort = _a.sort, columns = _a.columns, align = _a.align;
            var attrs = {
                className: cn(className, (_b = {}, _b[styles["align-" + align]] = !!align, _b)),
                classOfHeaderCell: classOfHeaderCell,
                columnIdx: columnIdx,
                hasCheckboxes: hasCheckboxes,
                header: header,
                headerTooltip: headerTooltip,
                label: label,
                sticky: sticky,
                stickyColumn: stickyColumn,
                width: width,
                isAllRowsExpanded: isAllRowsExpanded,
                handleToggleExpandAll: handleToggleExpandAll,
                sort: sort,
                align: align,
            };
            if (isRowMerged) {
                if (!columns || !columns.length) {
                    attrs.rowSpan = ROW_MERGED_DEFAULT;
                }
                else {
                    attrs.colSpan = columns.length;
                }
            }
            return _jsx(HeaderCell, __assign({}, attrs), columnIdx);
        }) }, void 0));
};
export default React.memo(HeaderCells);
