import _identity from 'lodash-es/identity';
import _pickBy from 'lodash-es/pickBy';
import { toNumber, toString, toQuery } from '../../utils';
export var PeriodType;
(function (PeriodType) {
    PeriodType["Default"] = "";
    PeriodType["Daily"] = "daily";
    PeriodType["Weekly"] = "weekly";
    PeriodType["Monthly"] = "monthly";
    PeriodType["Yesterday"] = "yesterday";
    PeriodType["ThisWeek"] = "thisWeek";
    PeriodType["LastWeek"] = "lastWeek";
    PeriodType["Last4Weeks"] = "last4Weeks";
    PeriodType["ThisMonth"] = "thisMonth";
    PeriodType["LastMonth"] = "lastMonth";
    PeriodType["Last3Months"] = "last3Months";
    PeriodType["Last7Days"] = "last7Days";
    PeriodType["Last30Days"] = "last30Days";
    PeriodType["Last6Months"] = "last6Months";
    PeriodType["ThisYear"] = "thisYear";
    PeriodType["LastYear"] = "lastYear";
    PeriodType["Last3Years"] = "last3Years";
    PeriodType["Custom"] = "custom";
})(PeriodType || (PeriodType = {}));
export const Periods = Object.values(PeriodType);
export function resolveUrlParams(location) {
    const query = toQuery(location.search);
    const { page, pageSize, period: periodParams, merchant: merchantIdParams, merchant_ids, startDate, endDate, startTime, endTime, } = query;
    const period = Periods.includes(periodParams)
        ? toString(periodParams)
        : Periods[0];
    return _pickBy({
        page: toNumber(page) || 0,
        pageSize: pageSize ? toNumber(pageSize) : undefined,
        period,
        merchant: merchantIdParams,
        merchant_ids,
        startDate: +startDate,
        endDate: +endDate,
        startTime: +startTime,
        endTime: +endTime,
    }, _identity);
}
