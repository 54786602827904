import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DotLoading } from '@core/uikit/build/src/components/DotLoading';
import FormatNumber from '@core/uikit/build/src/components/FormatNumber';
import Typography, {
  TypoTypes,
  TypoVariants,
  TypoWeights,
} from '@core/oui/build/src/components/Typography';

import { useSaleReportOverviewAPI } from 'pages/SaleReportOverview/useSaleReportOverviewAPI';
import styles from './styles.module.scss';

const BankingStatistics = () => {
  const { t } = useTranslation();

  const { paymentStats, paymentStatsLoading, getPaymentStats } =
    useSaleReportOverviewAPI();

  useEffect(() => {
    getPaymentStats();
  }, [getPaymentStats]);

  const bankings = [
    { title: 'KPI::DEPOSIT_REQUEST', value: paymentStats?.countDeposit },
    {
      title: 'KPI::UNIQUE_PLAYER_DEPOSIT',
      value: paymentStats?.countUserDeposit,
    },
    { title: 'KPI::WITHDRAW_REQUEST', value: paymentStats?.countWithdraw },
    {
      title: 'KPI::UNIQUE_PLAYER_WITHDRAW',
      value: paymentStats?.countUserWithdraw,
    },
    {
      title: 'KPI::UNIQUE_BANK_ACC_USED_WITHDRAW',
      value: paymentStats?.countWithdrawCard,
    },
  ];

  return (
    <div className={styles.containerBanking}>
      <Typography variant={TypoVariants.title} weight={TypoWeights.medium}>
        {t('KPI::BANKING_STATISTICS')}
      </Typography>
      <div className="mt-16 flex gap-24">
        {bankings?.map((item, index) => (
          <div key={index} className="flex gap-24">
            <div className="flex flex-col gap-4">
              <Typography
                variant={TypoVariants.title}
                weight={TypoWeights.medium}
                type={TypoTypes.emphasize}
              >
                <DotLoading show={paymentStatsLoading}>
                  <FormatNumber value={item.value || 0} />
                </DotLoading>
              </Typography>
              <Typography
                variant={TypoVariants.buttonHighLevel}
                weight={TypoWeights.bold}
              >
                {t(item.title)}
              </Typography>
            </div>
            {index !== bankings.length - 1 && (
              <div className={styles.divider}></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BankingStatistics;
