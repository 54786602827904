import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { User, Exit, Lock } from '@core/icons';
import { DEFAULT_LANG, KEY_I18N, REDIRECT_TO } from '@core/okit';

import { routeConfig } from 'configs/routes/config';

export type MenuProfile = {
  key: string;
  title: string;
  icon: any;
  divideBefore?: boolean;
  danger?: boolean;
  onClick?: () => void;
};

export const useProfileConfig = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setExpanded(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpanded(false);
  };

  const getLinkProfile = `${process.env.REACT_APP_PORTAL_URL}/sso?${KEY_I18N}=${
    i18n?.language || DEFAULT_LANG
  }&${REDIRECT_TO}=${encodeURIComponent(`/personal-profile`)}`;

  const menuProfile: MenuProfile[] = useMemo(
    () => [
      {
        key: routeConfig.personalProfile.path,
        title: 'HEADER.PROFILE',
        icon: User,
        onClick: () => {
          window.location.assign(getLinkProfile);
        },
      },
      {
        key: routeConfig.changePassword.path,
        title: 'HEADER.CHANGE_PASSWORD',
        icon: Lock,
        onClick: () => {
          handleClose();
          setOpenChangePassword(true);
        },
      },
      {
        key: routeConfig.logout.path,
        divideBefore: true,
        title: 'HEADER.LOG_OUT',
        icon: Exit,
        danger: true,
        onClick: () => {
          navigate(routeConfig.logout.path);
        },
      },
    ],
    [], //eslint-disable-line
  );

  return {
    menuProfile,
    openChangePassword,
    setOpenChangePassword,
    open,
    expanded,
    anchorEl,
    handleClick,
    handleClose,
  };
};
