import React, { useEffect } from 'react';
import { onLogout } from '@core/myservice/build/client';

const Logout = () => {
  useEffect(() => {
    onLogout();
  }, []);
  return <></>;
};

export default Logout;
