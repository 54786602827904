import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from '@core/icons';
import Typography, { TypoTypes, TypoVariants } from '../Typography';
import SearchEngine from '../SearchEngine';
import styles from './styles.module.scss';
var OSType = {
    MAC: 'Mac',
    WINDOW: 'Window',
};
var SearchBox = function () {
    var t = useTranslation().t;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var os = '';
    if (navigator.platform.indexOf('Mac') > -1)
        os = OSType.MAC;
    if (navigator.platform.indexOf('Win') > -1)
        os = OSType.WINDOW;
    var handleClick = function () {
        setOpen(true);
    };
    var onClose = function () {
        setOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.wrapper, onClick: handleClick },
            React.createElement(Search, null),
            React.createElement("div", { className: styles.textSearch },
                React.createElement(Typography, { variant: TypoVariants.bodyHighLevel, type: TypoTypes.secondary }, t('TEXT.SEARCH'))),
            React.createElement(Typography, { variant: TypoVariants.bodyHighLevel, type: TypoTypes.secondary }, " (" + (os === OSType.MAC ? 'CMD' : 'CTRL') + " + K)")),
        React.createElement(SearchEngine, { open: open, onClose: onClose })));
};
export default SearchBox;
