var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MyIDClient } from '@greyhole/myid/MyidServiceClientPb';
import { UpdateMetadataRequest, ConfirmSignInRequest, ResetPasswordRequest, ChangePasswordRequest, VerifyResetPasswordRequest, SubmitResetPasswordRequest, CreateAccessTokenRequest, UpdateEmailRequest, VerifyEmailRequest, CreatePasswordRequest, ResendSignInOTPRequest, SignInV2Request, } from '@greyhole/myid/myid_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcMyIdClient = /** @class */ (function (_super) {
    __extends(rpcMyIdClient, _super);
    function rpcMyIdClient(config) {
        var _this = this;
        config.serviceName = 'MYID';
        _this = _super.call(this, MyIDClient, config) || this;
        return _this;
    }
    rpcMyIdClient.prototype.signIn = function (username, password) {
        return __awaiter(this, void 0, void 0, function () {
            var myId, req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        myId = new SignInV2Request.MyID();
                        myId.setUsername(username);
                        myId.setPassword(password);
                        req = new SignInV2Request();
                        req.setMyId(myId);
                        return [4 /*yield*/, this.gRPCClientRequest('signInV2', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.confirmSignIn = function (otpId, otp) {
        return __awaiter(this, void 0, void 0, function () {
            var confirmSignIn;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        confirmSignIn = new ConfirmSignInRequest();
                        confirmSignIn.setOtpId(otpId);
                        confirmSignIn.setOtp(otp);
                        return [4 /*yield*/, this.gRPCClientRequest('confirmSignIn', confirmSignIn)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.resendSignInOTP = function (otpId) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ResendSignInOTPRequest();
                        req.setOtpId(otpId);
                        return [4 /*yield*/, this.gRPCClientRequest('resendSignInOTP', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.resetPassword = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ResetPasswordRequest();
                        if (params.email)
                            req.setEmail(params.email);
                        if (params.phoneNumber)
                            req.setPhoneNumber(params.phoneNumber);
                        console.log(params);
                        return [4 /*yield*/, this.gRPCClientRequest('resetPassword', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.getMe = function () {
        return __awaiter(this, void 0, void 0, function () {
            var empty;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        empty = new Empty();
                        return [4 /*yield*/, this.gRPCClientRequest('me', empty)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.changePassword = function (r) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ChangePasswordRequest();
                        req.setCurrentPassword(r.currentPassword);
                        req.setNewPassword(r.newPassword);
                        req.setConfirmNewPassword(r.confirmNewPassword);
                        return [4 /*yield*/, this.gRPCClientRequest('changePassword', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.updateMetadata = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new UpdateMetadataRequest();
                        request.setMetadata(params.metadata);
                        return [4 /*yield*/, this.gRPCClientRequest('updateMetadata', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.verifyResetPassword = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new VerifyResetPasswordRequest();
                        req.setOtpId(params.otpId);
                        req.setOtp(params.otp);
                        return [4 /*yield*/, this.gRPCClientRequest('verifyResetPassword', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.submitResetPassword = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new SubmitResetPasswordRequest();
                        req.setNewPassword(params.newPassword);
                        req.setConfirmNewPassword(params.confirmNewPassword);
                        req.setOtpId(params.otpId);
                        req.setOtp(params.otp);
                        return [4 /*yield*/, this.gRPCClientRequest('submitResetPassword', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.createAccessToken = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateAccessTokenRequest();
                        req.setIdToken(params.idToken);
                        return [4 /*yield*/, this.gRPCClientRequest('createAccessToken', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.updateEmail = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateEmailRequest();
                        req.setEmail(params.email);
                        return [4 /*yield*/, this.gRPCClientRequest('updateEmail', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.verifyEmail = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new VerifyEmailRequest();
                        req.setOtpId(params.otpId);
                        req.setOtp(params.otp);
                        return [4 /*yield*/, this.gRPCClientRequest('verifyEmail', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.createPassword = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreatePasswordRequest();
                        req.setOtpId(params.otpId);
                        req.setOtp(params.otp);
                        req.setNewPassword(params.newPassword);
                        req.setConfirmNewPassword(params.confirmNewPassword);
                        return [4 /*yield*/, this.gRPCClientRequest('createPassword', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyIdClient.prototype.signOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new Empty();
                        return [4 /*yield*/, this.gRPCClientRequest('signOut', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcMyIdClient;
}(gRPCClientAbstract));
export default rpcMyIdClient;
