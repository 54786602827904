import getWindow from '../utils/common/getWindow';
import useEventListener from './useEventListener';
export default function useKeydown(allowCode, fn, element) {
    var onKeydown = function (event) {
        var allowList = !Array.isArray(allowCode) ? [allowCode] : allowCode;
        var shouldBreak = !allowList.includes(event.keyCode);
        if (shouldBreak)
            return;
        fn(event);
    };
    var el = element || getWindow();
    var destroy = useEventListener(el, 'keydown', onKeydown);
    return destroy;
}
