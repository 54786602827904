var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles, Tooltip } from '@material-ui/core';
export var HeaderTooltip = function (props) {
    var classes = useStyles();
    return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", interactive: true, classes: {
            tooltip: classes.tooltip,
            tooltipPlacementTop: classes.tooltipPlacementTop,
            arrow: classes.arrow,
        } }, props), void 0));
};
var useStyles = makeStyles({
    tooltip: {
        backgroundColor: 'white',
        color: '#272f3d',
        fontSize: '16px',
        border: '1px solid #e0e0e0',
        padding: '8px 16px',
        filter: 'drop-shadow(0px -4px 16px rgba(3, 19, 82, 0.16))',
    },
    tooltipPlacementTop: {
        marginBottom: '8px',
    },
    arrow: {
        '&::before': {
            backgroundColor: '#fff',
            border: '1px solid #e0e0e0',
        },
    },
});
