var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Box, DialogActions, } from '@material-ui/core';
import { Button, ButtonSizes, ButtonVariants } from '../Button';
import DialogTitle from './Title';
var useStyles = makeStyles(function (theme) { return ({
    MuiDialogRoot: {
        borderRadius: '16px',
    },
    dialogActions: {
        width: '100%',
        backgroundColor: '#F6F7F7',
        padding: '15px 24px 16px 24px',
        '& > button:not(:last-child)': {
            marginBottom: '16px',
        },
    },
    strokeHeader: {
        borderTop: '1px solid #D6DEFF',
    },
    strokeFooter: {
        borderTop: '1px solid #D6DEFF',
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dialog: {
        position: 'relative',
    },
    dialogContent: {
        position: 'relative',
        padding: theme.spacing(3),
    },
    dialogSubtitle: {
        position: 'relative',
        padding: theme.spacing(3),
        paddingTop: 8,
        paddingBottom: 8,
    },
    dialogBottom: {
        padding: 0,
    },
}); });
var LiveOpsDialog = function (_a) {
    var _b, _c;
    var title = _a.title, subtitle = _a.subtitle, onClose = _a.onClose, activeStep = _a.activeStep, actions = _a.actions, steps = _a.steps, children = _a.children, handleBack = _a.handleBack, errorMessage = _a.errorMessage, _d = _a.open, open = _d === void 0 ? true : _d, preventClose = _a.preventClose, isStroke = _a.isStroke, bodyClassNames = _a.bodyClassNames, actionsClassNames = _a.actionsClassNames, props = __rest(_a, ["title", "subtitle", "onClose", "activeStep", "actions", "steps", "children", "handleBack", "errorMessage", "open", "preventClose", "isStroke", "bodyClassNames", "actionsClassNames"]);
    var classes = useStyles();
    var t = useTranslation().t;
    var _e = __read(useState({
        name: undefined,
        value: undefined,
    }), 2), dialog = _e[0], setDialog = _e[1];
    var handleClose = function (e) {
        e.stopPropagation();
        if (preventClose) {
            setDialog({
                name: 'discard',
                value: undefined,
            });
        }
        else {
            onClose();
        }
    };
    var classOfDialogActions = cn((_b = {},
        _b[classes.dialogBottom] = classes.dialogBottom,
        _b[classes.strokeFooter] = isStroke,
        _b));
    var classOfDialogContent = cn(subtitle ? classes.dialogSubtitle : classes.dialogContent, (_c = {},
        _c[classes.strokeHeader] = isStroke,
        _c), bodyClassNames);
    var classOfDialog = cn(classes.dialog);
    return (_jsx(_Fragment, { children: dialog.name === undefined && (_jsxs(Dialog, __assign({ disableEnforceFocus: true, fullWidth: true, open: open, onClose: handleClose, className: classOfDialog }, props, { children: [_jsx(DialogTitle, { stepsLabel: steps, title: title, subtitle: subtitle, activeStep: activeStep, handleClose: handleClose }, void 0), children && (_jsxs(DialogContent, __assign({ className: classOfDialogContent }, { children: [errorMessage && (_jsx(Box, __assign({ mb: 2 }, { children: _jsx(Alert, __assign({ severity: "error" }, { children: errorMessage }), void 0) }), void 0)), children] }), void 0)), actions && (_jsx(DialogActions, __assign({ className: classOfDialogActions }, { children: _jsxs(Box, __assign({ className: cn(classes.dialogActions, actionsClassNames) }, { children: [actions, handleBack && activeStep > 0 && (_jsx(Button, __assign({ fullWidth: true, variant: ButtonVariants.secondary, size: ButtonSizes.lg, onClick: handleBack }, { children: t('Common::TEXT.PREVIOUS_STEP') }), void 0))] }), void 0) }), void 0))] }), void 0)) }, void 0));
};
export default LiveOpsDialog;
