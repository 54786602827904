var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var UserX = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd", clipPath: "url(#clip0_1610_29500)", clipRule: "evenodd" },
        React.createElement("path", { d: "M9 4a3 3 0 100 6 3 3 0 000-6zM4 7a5 5 0 1110 0A5 5 0 014 7zM7 16a3 3 0 00-3 3v2a1 1 0 11-2 0v-2a5 5 0 015-5h4a5 5 0 015 5v2a1 1 0 11-2 0v-2a3 3 0 00-3-3H7zM16.293 8.293a1 1 0 011.414 0L19 9.586l1.293-1.293a1 1 0 111.414 1.414L20.414 11l1.293 1.293a1 1 0 01-1.414 1.414L19 12.414l-1.293 1.293a1 1 0 01-1.414-1.414L17.586 11l-1.293-1.293a1 1 0 010-1.414z" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1610_29500" },
            React.createElement("path", { fill: "#fff", d: "M0 0H24V24H0z" }))))); };
export default UserX;
