import { Stats } from '@dceu/dailyplanet/dailyplanet_overall_report_pb';

export interface RNGFooter {
  rtp: number;
  bet: number;
  normalWin: number;
  jackpotWin: number;
  totalWin: number;
  houseGain: number;
}

export function calculateSumRNG(data: Stats.AsObject[]): RNGFooter {
  const sum = data.reduce(
    (acc, item) => {
      // acc.rtp += item.rtp;
      acc.bet += item.bet;
      acc.normalWin += item.normalWin;
      acc.jackpotWin += item.jackpotWin;
      acc.totalWin += item.totalWin;
      acc.houseGain += item.houseGain;
      return acc;
    },
    {
      rtp: 0,
      bet: 0,
      normalWin: 0,
      jackpotWin: 0,
      totalWin: 0,
      houseGain: 0,
    },
  );

  sum.rtp = sum.totalWin / sum.bet;

  return sum;
}

export enum RNGSortName {
  SESSIONS = 'sessions',
  PLAYERS = 'players',
  RTP_TARGET = 'rtpTarget',
  RTP = 'rtp',
  BET = 'bet',
  NORMAL_WIN = 'normalWin',
  JACKPOT_WIN = 'jackpotWin',
  TOTAL_WIN = 'totalWin',
  HOUSE_GAIN = 'houseGain',
}

export const compareSortOfRNG = {
  [`${RNGSortName.SESSIONS}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.sessions - a.sessions,
  [`${RNGSortName.SESSIONS}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.sessions - b.sessions,

  [`${RNGSortName.PLAYERS}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.players - a.players,
  [`${RNGSortName.PLAYERS}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.players - b.players,

  [`${RNGSortName.RTP}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.rtp - a.rtp,
  [`${RNGSortName.RTP}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.rtp - b.rtp,

  [`${RNGSortName.BET}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.bet - a.bet,
  [`${RNGSortName.BET}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.bet - b.bet,

  [`${RNGSortName.NORMAL_WIN}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.normalWin - a.normalWin,
  [`${RNGSortName.NORMAL_WIN}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.normalWin - b.normalWin,

  [`${RNGSortName.JACKPOT_WIN}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.jackpotWin - a.jackpotWin,
  [`${RNGSortName.JACKPOT_WIN}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.jackpotWin - b.jackpotWin,

  [`${RNGSortName.TOTAL_WIN}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.totalWin - a.totalWin,
  [`${RNGSortName.TOTAL_WIN}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.totalWin - b.totalWin,

  [`${RNGSortName.HOUSE_GAIN}_desc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    b.houseGain - a.houseGain,
  [`${RNGSortName.HOUSE_GAIN}_asc`]: (a: Stats.AsObject, b: Stats.AsObject) =>
    a.houseGain - b.houseGain,
};
