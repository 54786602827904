import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import viLocale from 'date-fns/locale/vi';
import cnLocale from 'date-fns/locale/zh-CN';
import cnTradLocale from 'date-fns/locale/zh-TW';
import { KEY_I18N_LNG, DEFAULT_LANG } from '../../widgets/AppBar/MenuLanguages';
export var localeMap = {
    en: enLocale,
    vi: viLocale,
    cn: cnLocale,
    cn_trad: cnTradLocale,
};
export function useCurrentLang() {
    var currentLng = localStorage.getItem(KEY_I18N_LNG) || DEFAULT_LANG;
    return currentLng;
}
export var formatTimeStampToSeconds = function (timestamp) {
    return Math.floor(timestamp / 1000);
};
export var formatWithSchema = function (timestamp, schema) {
    return format(timestamp, schema, {
        locale: localeMap[localStorage.getItem(KEY_I18N_LNG) || DEFAULT_LANG],
    });
};
export var formatDate = function (timestamp) {
    return formatWithSchema(timestamp, 'dd MMM yyyy');
};
export var formatTime = function (timestamp) {
    return formatWithSchema(timestamp, 'HH:mm');
};
export var formatDateTime = function (timestamp) {
    if (!timestamp)
        return '-';
    return formatWithSchema(timestamp, 'dd MMM yyyy, HH:mm');
};
