var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Uneye = function (props) {
    return (React.createElement("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M8.94 4.082c.35-.054.704-.08 1.06-.08 3.178 0 6.166 2.289 7.905 5.997a15.21 15.21 0 01-.9 1.64.999.999 0 101.7 1.049c.466-.732.87-1.5 1.21-2.299a.999.999 0 000-.79c-2.02-4.687-5.818-7.596-9.916-7.596a7.767 7.767 0 00-1.4.12 1.014 1.014 0 00.34 1.999v-.04zM1.713.294a1.003 1.003 0 10-1.42 1.42l3.1 3.088A14.613 14.613 0 00.083 9.599a1 1 0 000 .8c2.019 4.688 5.817 7.596 9.915 7.596a9.255 9.255 0 005.048-1.539l3.238 3.248a.998.998 0 001.42 0 .998.998 0 000-1.419L1.712.294zM8.07 9.479l2.449 2.45A2 2 0 018.07 9.48zM10 15.996c-3.18 0-6.168-2.289-7.897-5.997a12.084 12.084 0 012.699-3.788l1.769 1.79a3.998 3.998 0 005.427 5.427l1.59 1.569a7.238 7.238 0 01-3.589 1z", fill: "currentColor" })));
};
export default Uneye;
