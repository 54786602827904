var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MyRoleClient } from '@greyhole/myrole/MyroleServiceClientPb';
import { CreateActionRequest, CreateGroupRequest, CreateModuleRequest, CreateResourceRequest, CreateRoleRequest, GetActionRequest, GetGroupRequest, GetModuleRequest, GetResourceRequest, GetRoleRequest, ListActionsRequest, ListGroupsRequest, ListModulesRequest, ListResourcesRequest, ListRolesRequest, UpdateActionRequest, UpdateGroupRequest, UpdateModuleRequest, UpdateResourceRequest, UpdateRolePermissionRequest, UpdateRoleRequest, } from '@greyhole/myrole/myrole_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcMyRoleClient = /** @class */ (function (_super) {
    __extends(rpcMyRoleClient, _super);
    function rpcMyRoleClient(config) {
        var _this = this;
        config.serviceName = 'MY_ROLE';
        _this = _super.call(this, MyRoleClient, config) || this;
        return _this;
    }
    rpcMyRoleClient.prototype.createAction = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateActionRequest();
                        req.setName(p.name);
                        req.setDescription(p.description);
                        req.setResourceId(p.resourceId);
                        req.setPath(p.path);
                        return [4 /*yield*/, this.gRPCClientRequest('createAction', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.createGroup = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateGroupRequest();
                        req.setModuleIdsList(p.moduleIdsList);
                        req.setName(p.name);
                        req.setDescription(p.description);
                        return [4 /*yield*/, this.gRPCClientRequest('createGroup', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.createResource = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateResourceRequest();
                        req.setName(p.name);
                        req.setDescription(p.description);
                        return [4 /*yield*/, this.gRPCClientRequest('createResource', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.createRole = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateRoleRequest();
                        req.setGroupId(p.groupId);
                        req.setName(p.name);
                        req.setDescription(p.description);
                        return [4 /*yield*/, this.gRPCClientRequest('createRole', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.createModule = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateModuleRequest();
                        req.setName(p.name);
                        req.setDescription(p.description);
                        return [4 /*yield*/, this.gRPCClientRequest('createModule', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.getAction = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetActionRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getAction', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.getGroup = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetGroupRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getGroup', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.getResource = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetResourceRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getResource', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.getRole = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetRoleRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getRole', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.getModule = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetModuleRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getModule', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.listActions = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListActionsRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listActions', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.listGroups = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListGroupsRequest();
                        req.setModuleIdsList(p.moduleIdsList);
                        return [4 /*yield*/, this.gRPCClientRequest('listGroups', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.listResources = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListResourcesRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listResources', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.listRoles = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListRolesRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listRoles', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.listModules = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListModulesRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listModules', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.updateAction = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateActionRequest();
                        req.setId(p.id);
                        req.setName(p.name);
                        req.setDescription(p.description);
                        req.setResourceId(p.resourceId);
                        req.setPath(p.path);
                        return [4 /*yield*/, this.gRPCClientRequest('updateAction', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.updateGroup = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateGroupRequest();
                        req.setId(p.id);
                        req.setName(p.name);
                        req.setDescription(p.description);
                        req.setModuleIdsList(p.moduleIdsList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateGroup', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.updateResource = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateResourceRequest();
                        req.setId(p.id);
                        req.setName(p.name);
                        req.setDescription(p.description);
                        return [4 /*yield*/, this.gRPCClientRequest('updateResource', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.updateRole = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateRoleRequest();
                        req.setId(p.id);
                        req.setGroupId(p.groupId);
                        req.setName(p.name);
                        req.setDescription(p.description);
                        return [4 /*yield*/, this.gRPCClientRequest('updateRole', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.updateModule = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateModuleRequest();
                        req.setId(p.id);
                        req.setName(p.name);
                        req.setDescription(p.description);
                        return [4 /*yield*/, this.gRPCClientRequest('updateModule', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcMyRoleClient.prototype.updateRolePermission = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, permissionsList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateRolePermissionRequest();
                        permissionsList = p.permissionsList.map(function (_a) {
                            var actionIdsList = _a.actionIdsList, resourceId = _a.resourceId;
                            var permission = new UpdateRolePermissionRequest.Permission();
                            permission.setActionIdsList(actionIdsList);
                            permission.setResourceId(resourceId);
                            return permission;
                        });
                        req.setGroupId(p.groupId);
                        req.setRoleId(p.roleId);
                        req.setPermissionsList(permissionsList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateRolePermission', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcMyRoleClient;
}(gRPCClientAbstract));
export default rpcMyRoleClient;
