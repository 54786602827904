var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Fish = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.3427 6.68237C17.7611 7.04283 17.8081 7.67425 17.4476 8.09268C16.512 9.17879 15.9982 10.5652 16 11.9987L16 12C16 13.4983 16.5479 14.8662 17.4558 15.9173C17.8168 16.3353 17.7706 16.9668 17.3527 17.3278C16.9347 17.6888 16.3033 17.6426 15.9422 17.2247C14.7321 15.8238 14 13.9958 14 12H15L14 12.0013C14 12.0009 14 12.0004 14 12C13.9979 10.0869 14.6837 8.23679 15.9324 6.78733C16.2928 6.3689 16.9243 6.3219 17.3427 6.68237Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.7736 6.1128C16.6444 5.52248 20.057 7.34497 22.8283 11.4417C23.0572 11.78 23.0573 12.2236 22.8285 12.562C20.0575 16.6616 16.645 18.4862 12.7739 17.8969C10.0592 17.4836 7.26332 15.8974 4.43868 13.3798C3.88207 13.9339 3.31774 14.5312 2.74615 15.1718C2.37844 15.5839 1.7463 15.6198 1.33422 15.2521C0.922137 14.8844 0.886166 14.2523 1.25387 13.8402C1.83136 13.193 2.40846 12.5807 2.98475 12.0051C2.40845 11.4295 1.83133 10.817 1.25384 10.1697C0.886152 9.75765 0.922159 9.12551 1.33426 8.75782C1.74636 8.39014 2.3785 8.42615 2.74618 8.83825C3.31778 9.4789 3.88212 10.0763 4.43874 10.6305C7.26333 8.11307 10.0591 6.52678 12.7736 6.1128ZM5.90317 12.0051C8.49721 14.2892 10.905 15.5893 13.0749 15.9196C15.8103 16.3361 18.4041 15.2366 20.7772 12.0022C18.4044 8.77095 15.8107 7.67276 13.0751 8.08994C10.9051 8.42089 8.49723 9.72125 5.90317 12.0051Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18 10C18.5523 10 19 10.4477 19 11V11.01C19 11.5623 18.5523 12.01 18 12.01C17.4477 12.01 17 11.5623 17 11.01V11C17 10.4477 17.4477 10 18 10Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.0549 9.66808C12.5144 9.97454 12.6384 10.5954 12.3319 11.0549C12.0856 11.4243 11.9998 11.7344 11.9998 12C11.9998 12.2656 12.0856 12.5757 12.3319 12.9451C12.6384 13.4046 12.5144 14.0255 12.0549 14.3319C11.5954 14.6384 10.9745 14.5143 10.6681 14.0549C10.2475 13.4243 9.99976 12.7344 9.99976 12C9.99976 11.2656 10.2475 10.5757 10.6681 9.94511C10.9745 9.48565 11.5954 9.36162 12.0549 9.66808Z", fill: "currentColor" }))); };
export default Fish;
