var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Settings = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 20 20", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16.1169 10.7833C16.1512 10.5333 16.1683 10.275 16.1683 10C16.1683 9.73333 16.1512 9.46667 16.1084 9.21667L17.8475 7.9C18.0017 7.78333 18.0445 7.55833 17.9503 7.39167L16.3054 4.625C16.2026 4.44167 15.9884 4.38333 15.8 4.44167L13.7524 5.24167C13.3241 4.925 12.87 4.65833 12.3645 4.45833L12.0561 2.34167C12.0218 2.14167 11.8505 2 11.6449 2H8.35511C8.1495 2 7.98672 2.14167 7.95245 2.34167L7.64404 4.45833C7.13857 4.65833 6.67595 4.93333 6.25616 5.24167L4.20861 4.44167C4.02013 4.375 3.80595 4.44167 3.70315 4.625L2.06682 7.39167C1.96402 7.56667 1.99829 7.78333 2.16963 7.9L3.90876 9.21667C3.86592 9.46667 3.83166 9.74167 3.83166 10C3.83166 10.2583 3.84879 10.5333 3.89163 10.7833L2.1525 12.1C1.99829 12.2167 1.95545 12.4417 2.04969 12.6083L3.69458 15.375C3.79739 15.5583 4.01157 15.6167 4.20004 15.5583L6.24759 14.7583C6.67595 15.075 7.13001 15.3417 7.63547 15.5417L7.94389 17.6583C7.98672 17.8583 8.1495 18 8.35511 18H11.6449C11.8505 18 12.0218 17.8583 12.0475 17.6583L12.356 15.5417C12.8614 15.3417 13.3241 15.075 13.7438 14.7583L15.7914 15.5583C15.9799 15.625 16.194 15.5583 16.2969 15.375L17.9417 12.6083C18.0445 12.425 18.0017 12.2167 17.8389 12.1L16.1169 10.7833ZM10 13C8.30371 13 6.91583 11.65 6.91583 10C6.91583 8.35 8.30371 7 10 7C11.6963 7 13.0842 8.35 13.0842 10C13.0842 11.65 11.6963 13 10 13Z" }))); };
export default Settings;
