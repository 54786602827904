var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import BaseTable from '../BaseTable';
var LiveOpsTable = function (props) {
    var _a = __assign({}, props), columns = _a.columns, _b = _a.data, data = _b === void 0 ? [] : _b, pagination = _a.pagination, tableRowCheckboxProps = _a.tableRowCheckboxProps, tableRowProps = _a.tableRowProps, rest = __rest(_a, ["columns", "data", "pagination", "tableRowCheckboxProps", "tableRowProps"]);
    var setHeaderColumns = function (col) {
        return {
            label: col.Header,
            headerTooltip: col.headerTooltip,
            value: col.accessor,
            width: col.width,
            sticky: col.sticky,
            style: col.style,
            Footer: col.Footer,
            cellRender: col.cellRender,
            headerRender: col.headerRender,
            sort: col.sort,
            align: col.align,
            clickOnCell: col.clickOnCell,
        };
    };
    var header = React.useMemo(function () {
        return columns
            .filter(function (col) { return !col.hidden; })
            .map(function (col) {
            var headerColumns = setHeaderColumns(col);
            if (!col.columns)
                return headerColumns;
            // TODO: update later
            var hColumns = col.columns
                .filter(function (c) { return !c.hidden; })
                .map(function (c) {
                return setHeaderColumns(c);
            });
            headerColumns.columns = hColumns;
            return headerColumns;
        });
    }, [columns]);
    // ! Workaround to optimize the table
    var memoPagination = React.useMemo(function () { return pagination; }, [data, columns]);
    var memoTableRowProps = React.useMemo(function () { return tableRowProps; }, [data, columns]);
    var memoTableRowCheckboxProps = React.useMemo(function () { return tableRowCheckboxProps; }, [data, columns]);
    return (_jsx(BaseTable, __assign({ header: header, data: data, pagination: memoPagination, tableRowCheckboxProps: memoTableRowCheckboxProps, tableRowProps: memoTableRowProps }, rest), void 0));
};
export default LiveOpsTable;
