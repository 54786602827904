import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
export function getTimestampMsg(value) {
    if (!value) {
        return undefined;
    }
    var ts = new Timestamp();
    ts.setNanos(value.nanos);
    ts.setSeconds(value.seconds);
    return ts;
}
