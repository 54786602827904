var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Checkbox } from '../../Checkbox';
import TableCell from '../../TableCell';
var HeaderCheckbox = function (_a) {
    var classOfCheckboxCell = _a.classOfCheckboxCell, isAllIntermediate = _a.isAllIntermediate, handleToggleCheckboxAll = _a.handleToggleCheckboxAll, isAllSelected = _a.isAllSelected, isRowMerged = _a.isRowMerged;
    var attrs = {};
    if (isRowMerged) {
        attrs.rowSpan = 2;
    }
    return (_jsx(TableCell, __assign({ className: classOfCheckboxCell, inHeader: true }, attrs, { children: _jsx(Checkbox, { value: "all", name: "all", isIntermediate: isAllIntermediate, checked: isAllSelected, onClick: handleToggleCheckboxAll }, void 0) }), 'all'));
};
export default React.memo(HeaderCheckbox);
