var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useCallback, useContext } from 'react';
import cx from 'classnames';
import ListItem from '../ListItem';
import MenuContext from '../Menu/menu-context';
import styles from './styles.module.scss';
var defaultProps = {
    component: 'a',
};
export var MenuItem = forwardRef(function (props, ref) {
    var _a;
    var _b = __assign(__assign({}, defaultProps), props), className = _b.className, rest = __rest(_b, ["className"]);
    var menuContext = useContext(MenuContext);
    var classOfComponent = cx(styles.menuItem, className, (_a = {},
        _a[styles.disabled] = !!rest.disabled,
        _a));
    var handleOnClick = useCallback(function (event) {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        if (rest.onClick) {
            rest.onClick(event);
        }
        menuContext === null || menuContext === void 0 ? void 0 : menuContext.close();
    }, [rest, menuContext]);
    return (_jsx(ListItem, __assign({}, rest, { ref: ref, className: classOfComponent, onClick: handleOnClick }), void 0));
});
MenuItem.displayName = 'MenuItem';
export default MenuItem;
