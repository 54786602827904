import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography, {
  TypoTypes,
  TypoVariants,
  TypoWeights,
} from '@core/uikit/build/src/components/Typography';
import { AngleDown, AngleUp } from '@core/icons';
import cn from 'classnames';
import Avatar from 'boring-avatars';

import ChangePassword from 'pages/ChangePassword';
import { useAppSelector } from 'redux/store';
import { selectUserInfo } from 'redux/features/auth/slice';
import { useProfileConfig } from '../const';
import styles from './styles.module.scss';

const Profile = () => {
  const { t } = useTranslation();
  const userInfo = useAppSelector(selectUserInfo);
  const {
    menuProfile,
    openChangePassword,
    setOpenChangePassword,
    open,
    expanded,
    anchorEl,
    handleClick,
    handleClose,
  } = useProfileConfig();

  const parseJSON = (jsonStr: string) => {
    if (!jsonStr) return;
    try {
      return JSON.parse(jsonStr);
    } catch (error) {
      return;
    }
  };

  const profileName = useMemo(() => {
    if (!userInfo?.metadata) return t('NO_NAME');
    const data = parseJSON(userInfo?.metadata);
    if (!data) return t('NO_NAME');
    return data.fullName || t('NO_NAME');
  }, [userInfo?.metadata, t]);

  const nothing = () => {};

  return (
    <>
      <Button
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={
          expanded ? (
            <AngleUp className={styles.expand} />
          ) : (
            <AngleDown className={styles.expand} />
          )
        }
        className={styles.menuShow}
      >
        <Avatar
          size={24}
          name={profileName}
          variant="marble"
          colors={['#f5f4fd', '#13a58b', '#F0AB3D', '#C271B4', '#7462e0']}
        />
        <div className={styles.profileName}>
          <Typography
            type={TypoTypes.text}
            variant={TypoVariants.button1}
            weight={TypoWeights.medium}
          >
            {profileName}
          </Typography>
        </div>
      </Button>
      <Menu
        id="simple-menu"
        className={styles.menuProfile}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: styles.pepperCustom }}
      >
        {menuProfile.map((menu) => (
          <MenuItem
            key={menu.key}
            onClick={menu.onClick ? menu.onClick : nothing}
            className={cn({ [styles.danger]: menu.danger })}
          >
            <div className="mr-8">
              <menu.icon />
            </div>
            <Typography
              type={TypoTypes.text}
              variant={TypoVariants.button2}
              weight={TypoWeights.bold}
            >
              {t(menu.title)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <ChangePassword
        openChangePassword={openChangePassword}
        setOpenChangePassword={setOpenChangePassword}
      />
    </>
  );
};
export default Profile;
