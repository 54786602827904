import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { disableLog } from '@core/uikit/build/src/utils/index';
import CSSBaseLine from '@core/uikit/build/src/components/CSSBaseLine';
import { ThemeProvider } from '@material-ui/core/styles';

import { theme } from './configs/theme';
import store from 'redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

disableLog(process.env.NODE_ENV, process.env.REACT_APP_DEBUG);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <App />
            <CSSBaseLine />
          </HelmetProvider>
        </ThemeProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
