var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormLabel, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Close, Search } from '@core/icons';
import classnames from 'classnames';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../../Typography';
import styles from './styles.module.scss';
var useStyles = makeStyles({
    root: {},
    inputRoot: {
        minHeight: '60px !important',
        '& $input': {
            padding: '16px !important',
            minWidth: '100px !important',
            marginRight: '92px',
        },
    },
    endAdornment: {
        top: 0,
        position: 'unset',
    },
    clearIndicator: {
        padding: '11px',
        margin: '0px',
    },
    popupIndicator: {
        padding: '11px',
        margin: '0px',
    },
    popupIndicatorOpen: {
        transform: 'rotate(0deg)',
    },
});
export var FormAutoComplete = React.forwardRef(function (props, ref) {
    var _a;
    var classes = useStyles();
    var _b = useFormContext(), setValue = _b.setValue, errors = _b.formState.errors;
    var name = props.name, label = props.label, placeholder = props.placeholder, value = props.value, options = props.options, 
    // helperText,
    disabled = props.disabled, popupIcon = props.popupIcon, 
    // onChange,
    onBlur = props.onBlur, pure = props.pure, onChangePure = props.onChangePure;
    var error = !!errors[name];
    return (_jsxs(_Fragment, { children: [label && (_jsx(FormLabel, { children: _jsx(Typography, __assign({ variant: TypoVariants.body2, type: TypoTypes.sub, weight: TypoWeights.bold }, { children: label }), void 0) }, void 0)), _jsx(Autocomplete, { fullWidth: true, autoComplete: true, autoHighlight: true, value: (options && options.find(function (option) { return option.value === value; })) ||
                    null, classes: __assign(__assign({}, classes), { inputRoot: classnames(classes.inputRoot, (_a = {},
                        _a[styles.errorNotDisable] = error && !disabled,
                        _a)) }), options: options || [], groupBy: function (_a) {
                    var category = _a.category;
                    return category || '';
                }, getOptionLabel: function (option) { return option.name; }, getOptionDisabled: function (_a) {
                    var disabled = _a.disabled;
                    return !!disabled;
                }, disabled: !!disabled, onChange: function (e, option) {
                    if (pure) {
                        onChangePure === null || onChangePure === void 0 ? void 0 : onChangePure(e, option);
                    }
                    else {
                        e.preventDefault();
                        setValue(name, option === null || option === void 0 ? void 0 : option.value);
                    }
                }, onBlur: onBlur, popupIcon: popupIcon || _jsx(Search, {}, void 0), closeIcon: _jsx(Close, {}, void 0), renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { placeholder: placeholder, inputRef: ref, InputLabelProps: {
                        shrink: true,
                    } }), void 0)); } }, void 0)] }, void 0));
});
