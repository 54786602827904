var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { DECIMAL_SEPARATOR, FRACTION_DIGITS, THOUSAND_SEPARATOR, } from '../../utils/common/numberFormat';
import styles from './styles.module.scss';
function FormatNumber(props) {
    var _a;
    var _b = props.value, value = _b === void 0 ? 0 : _b, _c = props.percent, percent = _c === void 0 ? false : _c, fractionDigits = props.fractionDigits, unit = props.unit, showPlus = props.showPlus, afterNumber = props.afterNumber, variantColor = props.variantColor, className = props.className;
    var roundedValue = value
        ? parseFloat((value * (percent ? 100 : 1)).toFixed(fractionDigits || FRACTION_DIGITS))
        : 0;
    var unitCurrency = function () {
        if (unit && typeof unit === 'boolean')
            return _jsx("span", { children: "\u20AB" }, void 0);
        return _jsx("div", __assign({ className: styles.otherUnit }, { children: unit }), void 0);
    };
    return (_jsxs("div", __assign({ className: classNames(className, styles.displayBlock, (_a = {},
            _a[styles.danger] = variantColor === 'danger',
            _a[styles.success] = variantColor === 'success',
            _a)) }, { children: [unit && unitCurrency(), " ", showPlus && '+', _jsx(NumberFormat, { value: roundedValue, decimalSeparator: DECIMAL_SEPARATOR, displayType: "text", type: "text", thousandSeparator: THOUSAND_SEPARATOR }, void 0), afterNumber] }), void 0));
}
export default memo(FormatNumber);
