var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useState } from 'react';
export function useCheckbox(data, tableRowCheckboxProps) {
    var _a = __read(useState({}), 2), checkboxes = _a[0], setCheckboxes = _a[1];
    var checkboxValues = Object.values(checkboxes);
    var checkboxCount = checkboxValues.length;
    var isAllIntermediate = checkboxValues.filter(Boolean).length !== data.length;
    var isAllSelected = checkboxValues.some(function (item) { return item === true; });
    var handleToggleCheckboxRow = useCallback(function (event) {
        event.stopPropagation();
        var _a = event.target, checked = _a.checked, value = _a.value;
        // ! Workaround - check value
        // ? Because checkbox render twice
        if (value) {
            setCheckboxes(function (prevCheckboxes) {
                var _a;
                var newCheckboxes = __assign(__assign({}, prevCheckboxes), (_a = {}, _a[value] = checked, _a));
                tableRowCheckboxProps === null || tableRowCheckboxProps === void 0 ? void 0 : tableRowCheckboxProps.onClick(Object.entries(newCheckboxes)
                    .filter(function (_a) {
                    var _b = __read(_a, 2), _ = _b[0], isCheck = _b[1];
                    return isCheck;
                })
                    .map(function (item) { return data[+item[0]]; }));
                return newCheckboxes;
            });
        }
    }, [data, tableRowCheckboxProps === null || tableRowCheckboxProps === void 0 ? void 0 : tableRowCheckboxProps.onClick]);
    var handleToggleCheckboxAll = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        setCheckboxes(function (prevCheckboxes) {
            var checkboxValues = Object.values(prevCheckboxes);
            var shouldSelectAll = checkboxValues.some(function (item) { return item === false; }) ||
                checkboxValues.length === 0;
            var newCheckboxes = data.reduce(function (acc, _, index) {
                var _a;
                return Object.assign(acc, (_a = {}, _a[index] = shouldSelectAll, _a));
            }, {});
            tableRowCheckboxProps === null || tableRowCheckboxProps === void 0 ? void 0 : tableRowCheckboxProps.onClick(Object.entries(newCheckboxes)
                .filter(function (_a) {
                var _b = __read(_a, 2), _ = _b[0], isCheck = _b[1];
                return isCheck;
            })
                .map(function (item) { return data[+item[0]]; }));
            return newCheckboxes;
        });
    }, [data, tableRowCheckboxProps === null || tableRowCheckboxProps === void 0 ? void 0 : tableRowCheckboxProps.onClick]);
    var clearCheckboxData = function () {
        setCheckboxes({});
    };
    return {
        checkboxes: checkboxes,
        setCheckboxes: setCheckboxes,
        checkboxCount: checkboxCount,
        isAllIntermediate: isAllIntermediate,
        isAllSelected: isAllSelected,
        handleToggleCheckboxRow: handleToggleCheckboxRow,
        handleToggleCheckboxAll: handleToggleCheckboxAll,
        clearCheckboxData: clearCheckboxData,
    };
}
