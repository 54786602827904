export var PaperRadius;
(function (PaperRadius) {
    PaperRadius["none"] = "none";
    PaperRadius["light"] = "light";
    PaperRadius["regular"] = "regular";
    PaperRadius["bold"] = "bold";
    PaperRadius["max"] = "max";
})(PaperRadius || (PaperRadius = {}));
export var PaperBackgrounds;
(function (PaperBackgrounds) {
    PaperBackgrounds["regular"] = "regular";
    PaperBackgrounds["ghost"] = "ghost";
    PaperBackgrounds["gray"] = "gray";
})(PaperBackgrounds || (PaperBackgrounds = {}));
