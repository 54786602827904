import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" },
        React.createElement("g", { fill: "currentColor", fillRule: "evenodd", clipPath: "url(#clip0_3935_3472)", clipRule: "evenodd" },
            React.createElement("path", { d: "M6.793 3.793a1 1 0 011.414 1.414l-2.5 2.5a1 1 0 01-1.414 0l-1.5-1.5a1 1 0 011.414-1.414L5 5.586l1.793-1.793zM6.793 9.793a1 1 0 011.414 1.414l-2.5 2.5a1 1 0 01-1.414 0l-1.5-1.5a1 1 0 111.414-1.414l.793.793 1.793-1.793zM6.793 15.793a1 1 0 011.414 1.414l-2.5 2.5a1 1 0 01-1.414 0l-1.5-1.5a1 1 0 111.414-1.414l.793.793 1.793-1.793zM10 6a1 1 0 011-1h9a1 1 0 110 2h-9a1 1 0 01-1-1zM10 12a1 1 0 011-1h9a1 1 0 110 2h-9a1 1 0 01-1-1zM10 18a1 1 0 011-1h9a1 1 0 110 2h-9a1 1 0 01-1-1z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_3935_3472" },
                React.createElement("path", { fill: "#fff", d: "M0 0H24V24H0z" })))));
}
export default SvgComponent;
