import _identity from 'lodash-es/identity';
import _pickBy from 'lodash-es/pickBy';
import { toNumber, toString } from '../../utils';
import { toQuery } from '../../utils/url_helpers';
export var PeriodType;
(function (PeriodType) {
    PeriodType["Default"] = "";
    PeriodType["Daily"] = "daily";
    PeriodType["Weekly"] = "weekly";
    PeriodType["Monthly"] = "monthly";
    PeriodType["Yesterday"] = "yesterday";
    PeriodType["ThisWeek"] = "thisWeek";
    PeriodType["LastWeek"] = "lastWeek";
    PeriodType["Last4Weeks"] = "last4Weeks";
    PeriodType["ThisMonth"] = "thisMonth";
    PeriodType["LastMonth"] = "lastMonth";
    PeriodType["Last3Months"] = "last3Months";
    PeriodType["Last7Days"] = "last7Days";
    PeriodType["Last30Days"] = "last30Days";
    PeriodType["LastYear"] = "lastYear";
    PeriodType["Last3Years"] = "last3Years";
    PeriodType["ThisYear"] = "thisYear";
    PeriodType["Custom"] = "custom";
})(PeriodType || (PeriodType = {}));
export var Periods = Object.values(PeriodType);
export function resolveUrlParams(location) {
    var query = toQuery(location.search);
    var page = query.page, pageSize = query.pageSize, periodParams = query.period, merchantIdParams = query.merchant, merchant_ids = query.merchant_ids, startDate = query.startDate, endDate = query.endDate, startTime = query.startTime, endTime = query.endTime;
    var period = Periods.includes(periodParams)
        ? toString(periodParams)
        : Periods[0];
    return _pickBy({
        page: toNumber(page) || 0,
        pageSize: pageSize ? toNumber(pageSize) : undefined,
        period: period,
        merchant: merchantIdParams,
        merchant_ids: merchant_ids,
        startDate: +startDate,
        endDate: +endDate,
        startTime: +startTime,
        endTime: +endTime,
    }, _identity);
}
