var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ id: "CardIcon", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { id: "Essential Icon" },
            React.createElement("path", { id: "Card", fillRule: "evenodd", clipRule: "evenodd", d: "M18 5C18 4.44772 17.5523 4 17 4L7 4C6.44771 4 6 4.44772 6 5L6 19C6 19.5523 6.44771 20 7 20H17C17.5523 20 18 19.5523 18 19V5ZM17 2C18.6569 2 20 3.34315 20 5V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19L4 5C4 3.34315 5.34315 2 7 2L17 2Z", fill: "currentColor" }),
            React.createElement("path", { id: "Number", fillRule: "evenodd", clipRule: "evenodd", d: "M7 6C7 5.44772 7.44772 5 8 5H8.01C8.56228 5 9.01 5.44772 9.01 6C9.01 6.55228 8.56228 7 8.01 7H8C7.44772 7 7 6.55228 7 6Z", fill: "currentColor" }),
            React.createElement("path", { id: "Number_2", fillRule: "evenodd", clipRule: "evenodd", d: "M15 18C15 17.4477 15.4477 17 16 17H16.01C16.5623 17 17.01 17.4477 17.01 18C17.01 18.5523 16.5623 19 16.01 19H16C15.4477 19 15 18.5523 15 18Z", fill: "currentColor" }),
            React.createElement("path", { id: "Type", fillRule: "evenodd", clipRule: "evenodd", d: "M12 7C12.3148 7 12.6111 7.14819 12.8 7.4L15.8 11.4C16.0667 11.7556 16.0667 12.2444 15.8 12.6L12.8 16.6C12.6111 16.8518 12.3148 17 12 17C11.6852 17 11.3889 16.8518 11.2 16.6L8.2 12.6C7.93333 12.2444 7.93333 11.7556 8.2 11.4L11.2 7.4C11.3889 7.14819 11.6852 7 12 7ZM10.25 12L12 14.3333L13.75 12L12 9.66667L10.25 12Z", fill: "currentColor" }))));
}
export default SvgComponent;
