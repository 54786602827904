import React from 'react';
import { Loading, NoImageFoundPortrait, FallbackImage } from '@core/icons';
import useImage from './hooks/useImage';
import styles from './styles.module.scss';
function Image(props) {
    var _a = useImage(props), isLoading = _a.isLoading, isError = _a.isError, isSuccess = _a.isSuccess, isEmpty = _a.isEmpty;
    return (React.createElement("div", { className: styles.imageWrapper },
        isLoading && React.createElement(Loading, { className: styles.loading }),
        isEmpty && React.createElement(NoImageFoundPortrait, { className: styles.image }),
        isError && React.createElement(FallbackImage, { className: styles.image }),
        isSuccess && (React.createElement("img", { src: props.src, alt: props.alt, className: styles.image }))));
}
export default React.memo(Image);
