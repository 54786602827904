import { createStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
export default withStyles(function (theme) {
    return createStyles({
        root: {
            borderBottom: '1px solid #C9CCCF',
            backgroundColor: '#FFF',
            '& > div': {
                marginLeft: '24px',
            },
        },
        indicator: {
            backgroundColor: '#7462E1',
        },
    });
})(Tabs);
