var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import cn from 'classnames';
import styles from './styles.module.scss';
var TimeSelector = function (_a) {
    var max = _a.max, name = _a.name, numberSelected = _a.numberSelected, onChange = _a.onChange;
    return (_jsx("ul", __assign({ className: styles.timeSeletor }, { children: Array.from(Array(max).keys()).map(function (m) {
            var _a;
            return (_jsx("li", __assign({ id: name + "_timepicker_" + m, onClick: function () { return onChange(m); }, className: cn(styles.item, (_a = {},
                    _a[styles.itemActive] = numberSelected === m,
                    _a)) }, { children: m < 10 ? "0" + m : m }), m));
        }) }), void 0));
};
export default TimeSelector;
