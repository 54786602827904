var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ButlerClient } from '@marketplace/eqs/Eqs_butlerServiceClientPb';
import { CreateLiveRequest, CreateSeasonRequest, EditResultRequest, Game, GetMoneyReportRequest, GetSeasonRequest, GetTicketReportRequest, GetTicketsRequest, ListSeasonsRequest, ListUsersRequest, Reward, SendRewardsRequest, SetResultRequest, StatsRequest, UpdateResultRequest, UpdateSeasonRequest, } from '@marketplace/eqs/eqs_butler_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
import { getTimestampMsg } from '../helper';
var rpcEQSClient = /** @class */ (function (_super) {
    __extends(rpcEQSClient, _super);
    function rpcEQSClient(config) {
        var _this = this;
        config.serviceName = 'EQS';
        _this = _super.call(this, ButlerClient, config) || this;
        return _this;
    }
    rpcEQSClient.prototype.createSeason = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateSeasonRequest();
                        req.setName(p.name).setBannerId(p.bannerId).setTicketLoop(p.ticketLoop);
                        p.startsAt && req.setStartsAt(getTimestampMsg(p.startsAt));
                        p.endsAt && req.setEndsAt(getTimestampMsg(p.endsAt));
                        p.startsTicketingAt &&
                            req.setStartsTicketingAt(getTimestampMsg(p.startsTicketingAt));
                        p.endsTicketingAt &&
                            req.setEndsTicketingAt(getTimestampMsg(p.endsTicketingAt));
                        p.startsLivestreamAt &&
                            req.setStartsLivestreamAt(getTimestampMsg(p.startsLivestreamAt));
                        p.endsLivestreamAt &&
                            req.setEndsLivestreamAt(getTimestampMsg(p.endsLivestreamAt));
                        return [4 /*yield*/, this.gRPCClientRequest('createSeason', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.listSeasons = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListSeasonsRequest();
                        req
                            .setLimit(p.limit)
                            .setCursor(p.cursor)
                            .setStatus(p.status)
                            .setName(p.name);
                        return [4 /*yield*/, this.gRPCClientRequest('listSeasons', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.getSeason = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetSeasonRequest();
                        req.setSeasonId(p.seasonId);
                        return [4 /*yield*/, this.gRPCClientRequest('getSeason', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.createLive = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateLiveRequest();
                        req.setSeasonId(p.seasonId);
                        return [4 /*yield*/, this.gRPCClientRequest('createLive', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.listUsers = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, reqSort;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListUsersRequest();
                        req
                            .setSeasonId(p.seasonId)
                            .setPrizesList(p.prizesList)
                            .setUsername(p.username)
                            .setPhoneNumber(p.phoneNumber)
                            .setTicket(p.ticket)
                            .setRewarded(p.rewarded)
                            .setStatus(p.status)
                            .setOffset(p.offset)
                            .setLimit(p.limit);
                        if (p.sort) {
                            reqSort = new ListUsersRequest.Sort();
                            reqSort.setOrder(p.sort.order);
                            reqSort.setBy(p.sort.by);
                            req.setSort(reqSort);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listUsers', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.sendRewards = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new SendRewardsRequest();
                        req.setSeasonId(p.seasonId).setUserIdsList(p.userIdsList);
                        return [4 /*yield*/, this.gRPCClientRequest('sendRewards', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.updateResult = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var resultList, req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        resultList = p.resultsList.map(function (r) {
                            var rq = new UpdateResultRequest.Result();
                            rq.setPrize(r.prize).setTicket(r.ticket);
                            return rq;
                        });
                        req = new UpdateResultRequest();
                        req.setSeasonId(p.seasonId);
                        req.setResultsList(resultList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateResult', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.getTickets = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetTicketsRequest();
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        req.setSeasonId(p.seasonId);
                        req.setUserId(p.userId);
                        req.setTicket(p.ticket);
                        req.setPrizesList(p.prizesList);
                        return [4 /*yield*/, this.gRPCClientRequest('getTickets', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.setResult = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new SetResultRequest();
                        req.setSeasonId(p.seasonId);
                        req.setPrize(p.prize);
                        req.setTicket(p.ticket);
                        return [4 /*yield*/, this.gRPCClientRequest('setResult', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.editResult = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new EditResultRequest();
                        req.setSeasonId(p.seasonId);
                        req.setTicket(p.ticket);
                        req.setResultId(p.resultId);
                        return [4 /*yield*/, this.gRPCClientRequest('editResult', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.updateSeason = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var changesList, req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        changesList = [];
                        p.changesList.map(function (_a) {
                            var name = _a.name, startsAt = _a.startsAt, endsAt = _a.endsAt, bannerId = _a.bannerId, startsTicketingAt = _a.startsTicketingAt, endsTicketingAt = _a.endsTicketingAt, ticketLoop = _a.ticketLoop, startsLivestreamAt = _a.startsLivestreamAt, endsLivestreamAt = _a.endsLivestreamAt, requiredDepositAmount = _a.requiredDepositAmount, gamesSetting = _a.gamesSetting, rewardsSetting = _a.rewardsSetting, enabled = _a.enabled;
                            if (name) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setName(name);
                                changesList.push(change);
                            }
                            if (bannerId) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setBannerId(bannerId);
                                changesList.push(change);
                            }
                            if (ticketLoop) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setTicketLoop(ticketLoop);
                                changesList.push(change);
                            }
                            if (startsTicketingAt) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setStartsTicketingAt(getTimestampMsg(startsTicketingAt));
                                changesList.push(change);
                            }
                            if (endsTicketingAt) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setEndsTicketingAt(getTimestampMsg(endsTicketingAt));
                                changesList.push(change);
                            }
                            if (startsLivestreamAt) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setStartsLivestreamAt(getTimestampMsg(startsLivestreamAt));
                                changesList.push(change);
                            }
                            if (endsLivestreamAt) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setEndsLivestreamAt(getTimestampMsg(endsLivestreamAt));
                                changesList.push(change);
                            }
                            if (startsAt) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setStartsAt(getTimestampMsg(startsAt));
                                changesList.push(change);
                            }
                            if (endsAt) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setEndsAt(getTimestampMsg(endsAt));
                                changesList.push(change);
                            }
                            if (requiredDepositAmount) {
                                var change = new UpdateSeasonRequest.Change();
                                change.setRequiredDepositAmount(requiredDepositAmount);
                                changesList.push(change);
                            }
                            if (gamesSetting) {
                                var change = new UpdateSeasonRequest.Change();
                                var games = new UpdateSeasonRequest.Change.GamesSetting();
                                games.setGamesList(gamesSetting.gamesList.map(function (g) {
                                    var game = new Game();
                                    game.setTopic(g.topic);
                                    game.setWageringContribution(g.wageringContribution);
                                    game.setTicketPrice(g.ticketPrice);
                                    game.setTicketLimit(g.ticketLimit);
                                    game.setEnabled(g.enabled);
                                    return game;
                                }));
                                change.setGamesSetting(games);
                                changesList.push(change);
                            }
                            if (rewardsSetting) {
                                var change = new UpdateSeasonRequest.Change();
                                var rewards = new UpdateSeasonRequest.Change.RewardsSetting();
                                rewards.setRewardsList(rewardsSetting.rewardsList.map(function (r) {
                                    var reward = new Reward();
                                    reward.setPrize(r.prize);
                                    reward.setTickets(r.tickets);
                                    reward.setValue(r.value);
                                    return reward;
                                }));
                                change.setRewardsSetting(rewards);
                                changesList.push(change);
                            }
                        });
                        req = new UpdateSeasonRequest();
                        req.setSeasonId(p.seasonId);
                        req.setChangesList(changesList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateSeason', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.stats = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new StatsRequest();
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        req.setSeasonIdsList(p.seasonIdsList);
                        req.setName(p.name);
                        return [4 /*yield*/, this.gRPCClientRequest('stats', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.getTicketReport = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetTicketReportRequest();
                        req.setSeasonId(p.seasonId);
                        return [4 /*yield*/, this.gRPCClientRequest('getTicketReport', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEQSClient.prototype.getMoneyReport = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetMoneyReportRequest();
                        req.setSeasonId(p.seasonId);
                        return [4 /*yield*/, this.gRPCClientRequest('getMoneyReport', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcEQSClient;
}(gRPCClientAbstract));
export default rpcEQSClient;
