var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EveClient } from '@gameloot/jarvis/EveServiceClientPb';
import { CreateFirstDepositEventRequest, CreateHolidayEventRequest, ListFirstDepositEventsRequest, ListHolidayEventsRequest, UpdateFirstDepositEventRequest, UpdateHolidayEventRequest, } from '@gameloot/jarvis/eve_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcEveClient = /** @class */ (function (_super) {
    __extends(rpcEveClient, _super);
    function rpcEveClient(config) {
        var _this = this;
        config.serviceName = 'EVE';
        _this = _super.call(this, EveClient, config) || this;
        return _this;
    }
    rpcEveClient.prototype.createFirstDepositEvent = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateFirstDepositEventRequest();
                        req.setName(p.name);
                        req.setPriority(p.priority);
                        req.setBonusPercent(p.bonusPercent);
                        req.setWithdrawRequiredBet(p.withdrawRequiredBet);
                        req.setLimitedDuration(p.limitedDuration);
                        req.setMinDepositAmount(p.minDepositAmount);
                        req.setMaxBonusValue(p.maxBonusValue);
                        if (p.startsAt) {
                            ts = new Timestamp();
                            ts.setSeconds(p.startsAt.seconds);
                            ts.setNanos(p.startsAt.nanos);
                            req.setStartsAt(ts);
                        }
                        if (p.endsAt) {
                            ts = new Timestamp();
                            ts.setSeconds(p.endsAt.seconds);
                            ts.setNanos(p.endsAt.nanos);
                            req.setEndsAt(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('createFirstDepositEvent', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEveClient.prototype.listFirstDepositEvents = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListFirstDepositEventsRequest();
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        req.setStatus(p.status);
                        return [4 /*yield*/, this.gRPCClientRequest('listFirstDepositEvents', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEveClient.prototype.updateFirstDepositEvent = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, changesList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateFirstDepositEventRequest();
                        req.setId(p.id);
                        changesList = [];
                        p.changesList.map(function (_a) {
                            var name = _a.name, startsAt = _a.startsAt, endsAt = _a.endsAt, priority = _a.priority, bonusPercent = _a.bonusPercent, withdrawRequiredBet = _a.withdrawRequiredBet, limitedDuration = _a.limitedDuration, minDepositAmount = _a.minDepositAmount, active = _a.active, maxBonusValue = _a.maxBonusValue;
                            if (name) {
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setName(name);
                                changesList.push(change);
                            }
                            if (priority) {
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setPriority(priority);
                                changesList.push(change);
                            }
                            if (bonusPercent) {
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setBonusPercent(bonusPercent);
                                changesList.push(change);
                            }
                            if (withdrawRequiredBet) {
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setWithdrawRequiredBet(withdrawRequiredBet);
                                changesList.push(change);
                            }
                            if (limitedDuration) {
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setLimitedDuration(limitedDuration);
                                changesList.push(change);
                            }
                            if (minDepositAmount) {
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setMinDepositAmount(minDepositAmount);
                                changesList.push(change);
                            }
                            if (maxBonusValue) {
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setMaxBonusValue(maxBonusValue);
                                changesList.push(change);
                            }
                            if (startsAt) {
                                var ts = new Timestamp();
                                ts.setSeconds(startsAt.seconds);
                                ts.setNanos(startsAt.nanos);
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setStartsAt(ts);
                                changesList.push(change);
                            }
                            if (endsAt) {
                                var ts = new Timestamp();
                                ts.setSeconds(endsAt.seconds);
                                ts.setNanos(endsAt.nanos);
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setEndsAt(ts);
                                changesList.push(change);
                            }
                            if (active !== null || active !== undefined) {
                                var change = new UpdateFirstDepositEventRequest.Change();
                                change.setActive(active);
                                changesList.push(change);
                            }
                        });
                        req.setChangesList(changesList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateFirstDepositEvent', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEveClient.prototype.createHolidayEvent = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateHolidayEventRequest();
                        req.setName(p.name);
                        req.setMinDepositAmount(p.minDepositAmount);
                        req.setBonusAmount(p.bonusAmount);
                        req.setWithdrawRequiredBet(p.withdrawRequiredBet);
                        req.setLimitedDuration(p.limitedDuration);
                        if (p.startsAt) {
                            ts = new Timestamp();
                            ts.setSeconds(p.startsAt.seconds);
                            ts.setNanos(p.startsAt.nanos);
                            req.setStartsAt(ts);
                        }
                        if (p.endsAt) {
                            ts = new Timestamp();
                            ts.setSeconds(p.endsAt.seconds);
                            ts.setNanos(p.endsAt.nanos);
                            req.setEndsAt(ts);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('createHolidayEvent', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEveClient.prototype.listHolidayEvents = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListHolidayEventsRequest();
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        req.setStatus(p.status);
                        return [4 /*yield*/, this.gRPCClientRequest('listHolidayEvents', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcEveClient.prototype.updateHolidayEvent = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, changesList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateHolidayEventRequest();
                        req.setId(p.id);
                        changesList = [];
                        p.changesList.map(function (_a) {
                            var name = _a.name, startsAt = _a.startsAt, endsAt = _a.endsAt, minDepositAmount = _a.minDepositAmount, bonusAmount = _a.bonusAmount, withdrawRequiredBet = _a.withdrawRequiredBet, limitedDuration = _a.limitedDuration, active = _a.active;
                            if (name) {
                                var change = new UpdateHolidayEventRequest.Change();
                                change.setName(name);
                                changesList.push(change);
                            }
                            if (minDepositAmount) {
                                var change = new UpdateHolidayEventRequest.Change();
                                change.setMinDepositAmount(minDepositAmount);
                                changesList.push(change);
                            }
                            if (bonusAmount) {
                                var change = new UpdateHolidayEventRequest.Change();
                                change.setBonusAmount(bonusAmount);
                                changesList.push(change);
                            }
                            if (withdrawRequiredBet) {
                                var change = new UpdateHolidayEventRequest.Change();
                                change.setWithdrawRequiredBet(withdrawRequiredBet);
                                changesList.push(change);
                            }
                            if (limitedDuration) {
                                var change = new UpdateHolidayEventRequest.Change();
                                change.setLimitedDuration(limitedDuration);
                                changesList.push(change);
                            }
                            if (startsAt) {
                                var ts = new Timestamp();
                                ts.setSeconds(startsAt.seconds);
                                ts.setNanos(startsAt.nanos);
                                var change = new UpdateHolidayEventRequest.Change();
                                change.setStartsAt(ts);
                                changesList.push(change);
                            }
                            if (endsAt) {
                                var ts = new Timestamp();
                                ts.setSeconds(endsAt.seconds);
                                ts.setNanos(endsAt.nanos);
                                var change = new UpdateHolidayEventRequest.Change();
                                change.setEndsAt(ts);
                                changesList.push(change);
                            }
                            if (active !== null || active !== undefined) {
                                var change = new UpdateHolidayEventRequest.Change();
                                change.setActive(active);
                                changesList.push(change);
                            }
                        });
                        req.setChangesList(changesList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateHolidayEvent', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcEveClient;
}(gRPCClientAbstract));
export default rpcEveClient;
