var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import _get from 'lodash-es/get';
import cn from 'classnames';
import { CheckCircle, Close, Eye, Uneye } from '@core/icons';
import Icon from '../../Icon';
import { InputSizes } from '../../Input';
import InputAdornment from '../../InputAdornment';
import TextField from '../../TextField';
import Grid from '../../Grid';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../Typography';
import styles from '../styles.module.scss';
var PasswordField = function (_a) {
    var validation = _a.validation, errorPattern = _a.errorPattern, errorMessage = _a.errorMessage, restProps = __rest(_a, ["validation", "errorPattern", "errorMessage"]);
    var _b = __read(useState(false), 2), toggleVisible = _b[0], setToggleVisible = _b[1];
    var _c = useFormContext(), getValues = _c.getValues, formState = _c.formState, setValue = _c.setValue;
    var errors = formState.errors;
    var formValues = getValues();
    var password = _get(formValues, restProps.name);
    var patterns = errorPattern ? Object.keys(errorPattern) : [];
    var status = errors[restProps.name] ? 'error' : 'primary';
    var onClickBtnClear = function () {
        setValue(restProps.name, '');
    };
    return (_jsxs("div", __assign({ className: styles['password'] }, { children: [_jsx(TextField, __assign({}, restProps, { status: status, size: InputSizes.lg, type: toggleVisible ? 'text' : 'password', afterInput: [
                    _jsx(InputAdornment, __assign({ onClick: function () { return setToggleVisible(function (prev) { return !prev; }); } }, { children: _jsx(Icon, { component: toggleVisible ? Eye : Uneye }, void 0) }), void 0),
                    restProps.value && !restProps.disabled ? (_jsx(InputAdornment, __assign({ onClick: onClickBtnClear }, { children: _jsx(Icon, { style: { cursor: 'pointer' }, component: Close }, void 0) }), void 0)) : (_jsx(_Fragment, {}, void 0)),
                ] }), void 0), validation && (_jsx(Box, __assign({ mt: 1.5, mb: 1 }, { children: _jsx(Grid, __assign({ container: true, direction: "column", spacing: 1 }, { children: _jsx(Grid, __assign({ item: true }, { children: _jsx(Grid, __assign({ container: true, direction: "column", spacing: 1 }, { children: patterns.map(function (key, index) {
                                var _a;
                                return (_jsx(Grid, __assign({ item: true }, { children: _jsxs(Grid, __assign({ container: true, spacing: 1, alignItems: "center" }, { children: [_jsx(Grid, __assign({ item: true, xs: "auto" }, { children: _jsx(Icon, { className: cn(styles['icon'], (_a = {},
                                                        _a[styles['success-icon']] = !!password && errorPattern[key].test(password),
                                                        _a)), component: CheckCircle }, void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: "auto" }, { children: _jsx(Box, __assign({ pt: 0.2 }, { children: _jsx(Typography, __assign({ variant: TypoVariants.body1, weight: TypoWeights.medium, type: TypoTypes.sub }, { children: errorMessage[key] }), void 0) }), void 0) }), void 0)] }), void 0) }), index + "-" + key));
                            }) }), void 0) }), void 0) }), void 0) }), void 0))] }), void 0));
};
export default PasswordField;
