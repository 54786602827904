var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Sam = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_1544_3213)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.3266 2.26071C11.7088 1.91254 12.2934 1.91317 12.6749 2.26217L17.5939 6.76217C17.6001 6.76785 17.6063 6.7736 17.6123 6.77944C18.2405 7.3839 18.826 7.99306 19.3706 8.60805L19.3726 8.61034C20.3653 9.73831 20.9384 11.1742 20.9952 12.6757C21.052 14.1772 20.589 15.6523 19.6844 16.8521L19.6835 16.8533C18.6171 18.263 16.8937 18.9183 15.2612 18.6214L15.9486 20.6838C16.0503 20.9887 15.9991 21.3239 15.8112 21.5847C15.6232 21.8455 15.3214 22 14.9999 22H8.99994C8.6785 22 8.37665 21.8455 8.1887 21.5847C8.00075 21.3239 7.94961 20.9887 8.05126 20.6838L8.75733 18.5656C7.1304 18.8519 5.38402 18.265 4.31634 16.8532L4.31549 16.8521C3.41086 15.6523 2.94787 14.1772 3.00467 12.6757C3.06147 11.1742 3.63457 9.73831 4.62725 8.61034L4.63012 8.60708L4.63013 8.60709C5.1917 7.97456 5.77821 7.36463 6.38828 6.77874L6.40437 6.76363C8.04185 5.25911 9.68258 3.75813 11.3266 2.26071ZM7.76572 8.22884C7.1973 8.77513 6.65072 9.3437 6.12726 9.93321C5.4396 10.7153 5.04261 11.7106 5.00324 12.7513C4.96386 13.7924 5.28477 14.8152 5.91182 15.6472C6.7269 16.7246 8.31321 16.9543 9.42935 16.1788C9.73505 15.9664 10.1334 15.9413 10.4633 16.1138C10.7932 16.2863 10.9999 16.6278 10.9999 17V18C10.9999 18.1075 10.9826 18.2143 10.9486 18.3162L10.3874 20H13.6125L13.0513 18.3162C13.0173 18.2143 12.9999 18.1075 12.9999 18V17C12.9999 16.6206 13.2147 16.2738 13.5544 16.1047C13.8941 15.9357 14.3002 15.9734 14.6029 16.2022C15.6831 17.0186 17.2202 16.7942 18.0882 15.647C18.7151 14.8151 19.036 13.7924 18.9966 12.7513C18.9573 11.7103 18.5601 10.7148 17.8721 9.9326C17.3676 9.36295 16.8225 8.79546 16.2346 8.22926L11.9987 4.35417C10.5853 5.64309 9.1743 6.93465 7.76572 8.22884Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 9C11.8674 9 11.7402 9.05268 11.6464 9.14645C11.5527 9.24021 11.5 9.36739 11.5 9.5V14C11.5 14.5523 11.0523 15 10.5 15C9.94772 15 9.5 14.5523 9.5 14V9.5C9.5 8.83696 9.76339 8.20107 10.2322 7.73223C10.7011 7.26339 11.337 7 12 7C12.663 7 13.2989 7.26339 13.7678 7.73223C14.2366 8.20107 14.5 8.83696 14.5 9.5V14C14.5 14.5523 14.0523 15 13.5 15C12.9477 15 12.5 14.5523 12.5 14V9.5C12.5 9.36739 12.4473 9.24021 12.3536 9.14645C12.2598 9.05268 12.1326 9 12 9Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.5 12C9.5 11.4477 9.94772 11 10.5 11H13.5C14.0523 11 14.5 11.4477 14.5 12C14.5 12.5523 14.0523 13 13.5 13H10.5C9.94772 13 9.5 12.5523 9.5 12Z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1544_3213" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" }))))); };
export default Sam;
