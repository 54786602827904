var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isValidElement } from 'react';
import { Container } from '@material-ui/core';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../Typography';
import styles from './styles.module.scss';
var LayoutContainer = function (_a) {
    var children = _a.children, header = _a.header, actions = _a.actions, _b = _a.maxWidth, maxWidth = _b === void 0 ? 'md' : _b, className = _a.className, props = __rest(_a, ["children", "header", "actions", "maxWidth", "className"]);
    return (_jsx("div", __assign({ className: styles.wrapper + " " + className }, { children: _jsxs(Container, __assign({ maxWidth: maxWidth, className: styles.root }, props, { children: [header && (_jsxs("div", __assign({ className: styles.header }, { children: [isValidElement(header) ? (header) : (_jsx(Typography, __assign({ variant: TypoVariants.head2, type: TypoTypes.text, weight: TypoWeights.bold }, { children: header }), void 0)), _jsx("div", __assign({ className: styles.actions }, { children: actions }), void 0)] }), void 0)), children] }), void 0) }), void 0));
};
export default LayoutContainer;
