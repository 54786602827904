const FIREBASE_ENABLE = process.env.REACT_APP_FIREBASE_ENABLE || '';
export const isFirebaseEnable = FIREBASE_ENABLE.toLowerCase() === 'true';
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
export const hasFirebaseConfig = Boolean(firebaseConfig.apiKey && firebaseConfig.projectId);
export const isDevEnv = process.env.NODE_ENV === 'development';
export const isDebugFirebase = process.env.REACT_APP_DEBUG_FIREBASE === 'true';
