var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useCallback, useContext, useMemo, useRef, } from 'react';
import cn from 'classnames';
import { Context } from '../CheckboxGroup';
import Icon from '../Icon';
import InputBase from '../InputBase';
import compose from '../../utils/common/compose';
import { getIconByStatus, getStatusOfCheckbox } from './utils';
import styles from './styles.module.scss';
export * from './types';
var defaultProps = {
    component: 'label',
    disabled: false,
};
export var Checkbox = forwardRef(function (props, ref) {
    var _a;
    var _b = __assign(__assign({}, defaultProps), props), Component = _b.component, className = _b.className, disabled = _b.disabled, onChange = _b.onChange, value = _b.value, checked = _b.checked, isIntermediate = _b.isIntermediate, name = _b.name, iconRef = _b.iconRef, iconProps = _b.iconProps, children = _b.children, rest = __rest(_b, ["component", "className", "disabled", "onChange", "value", "checked", "isIntermediate", "name", "iconRef", "iconProps", "children"]);
    var context = useContext(Context);
    var checkboxName = name || context.name;
    var isDisabled = disabled || context.disabled;
    var checkIsBoolean = typeof checked === 'boolean';
    var isChecked = checkIsBoolean
        ? checked
        : (context.selected || []).includes(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var onChangeComposed = useCallback(compose(onChange, context.onChange), [
        onChange,
        context.onChange,
    ]);
    var refOfInput = useRef();
    var statusOfCheckbox = getStatusOfCheckbox(isChecked, isIntermediate);
    var iconOfCheckbox = getIconByStatus(statusOfCheckbox);
    var classOfRoot = cn(styles.root, className, (_a = {},
        _a[styles.disabled] = isDisabled,
        _a[styles.checked] = isChecked,
        _a[styles.intermediate] = isIntermediate,
        _a));
    var _children = useMemo(function () { return children && _jsx("span", __assign({ className: styles.content }, { children: children }), void 0); }, [children]);
    return (_jsxs(Component, __assign({}, rest, { ref: ref, className: classOfRoot, role: "checkbox" }, { children: [_jsx(Icon, __assign({}, iconProps, { 
                // ref={iconRef}
                className: styles.icon, component: iconOfCheckbox }), void 0), _children, _jsx(InputBase, { readOnly: true, type: "checkbox", ref: refOfInput, name: checkboxName, value: value, className: styles.input, checked: isChecked, onChange: onChangeComposed }, void 0)] }), void 0));
});
Checkbox.displayName = 'Checkbox';
export default React.memo(Checkbox);
