var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, } from 'react';
import { createPopper } from '@popperjs/core';
import useForkRef from '@rooks/use-fork-ref';
import Portal, { PortalIds } from '../Portal';
import useEnhancedEffect from '../../hooks/useEnhancedEffect';
import setRef from '../../utils/common/setRef';
export * from './types';
function getAnchorEl(anchorEl) {
    return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
}
export var Popper = forwardRef(function (props, ref) {
    var anchorEl = props.anchorEl, children = props.children, container = props.container, popperOptions = props.popperOptions, popperRefProp = props.popperRef, rest = __rest(props, ["anchorEl", "children", "container", "popperOptions", "popperRef"]);
    var tooltipRef = useRef(null);
    // @ts-ignore
    var ownRef = useForkRef(tooltipRef, ref);
    var popperRef = useRef(null);
    var handlePopperRef = useForkRef(
    // @ts-ignore
    popperRef, popperRefProp);
    var handlePopperRefRef = useRef(handlePopperRef);
    useEnhancedEffect(function () {
        handlePopperRefRef.current = handlePopperRef;
    }, [handlePopperRef]);
    useImperativeHandle(popperRefProp, function () { return popperRef.current; }, []);
    useEffect(function () {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });
    var handleOpen = useCallback(function () {
        if (!tooltipRef.current || !anchorEl) {
            return;
        }
        if (popperRef.current) {
            popperRef.current.destroy();
            handlePopperRefRef.current(null);
        }
        var popper = createPopper(getAnchorEl(anchorEl), tooltipRef.current, popperOptions);
        handlePopperRefRef.current(popper);
    }, [anchorEl, popperOptions]);
    var handleRef = useCallback(function (node) {
        setRef(ownRef, node);
        handleOpen();
    }, [ownRef, handleOpen]);
    var handleClose = function () {
        if (!popperRef.current) {
            return;
        }
        popperRef.current.destroy();
        handlePopperRefRef.current(null);
    };
    useEffect(function () {
        handleOpen();
    }, [handleOpen]);
    useEffect(function () {
        return function () {
            handleClose();
        };
    }, []);
    return (_jsx(Portal, __assign({ id: PortalIds.tooltip }, { children: _jsx("div", __assign({ ref: handleRef }, rest, { children: children }), void 0) }), void 0));
});
Popper.displayName = 'Popper';
export default Popper;
