var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Gof = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_1544_3388)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.5 4C9.36739 4 9.24021 4.05268 9.14645 4.14645C9.05268 4.24021 9 4.36739 9 4.5C9 5.16304 9.26339 5.79893 9.73223 6.26777C10.2011 6.73661 10.837 7 11.5 7H12.5C13.163 7 13.7989 6.73661 14.2678 6.26777C14.7366 5.79893 15 5.16304 15 4.5C15 4.36739 14.9473 4.24021 14.8536 4.14645C14.7598 4.05268 14.6326 4 14.5 4H9.5ZM7.73223 2.73223C8.20107 2.26339 8.83696 2 9.5 2H14.5C15.163 2 15.7989 2.26339 16.2678 2.73223C16.7366 3.20107 17 3.83696 17 4.5C17 5.69347 16.5259 6.83807 15.682 7.68198C14.8381 8.52589 13.6935 9 12.5 9H11.5C10.3065 9 9.16193 8.52589 8.31802 7.68198C7.47411 6.83807 7 5.69347 7 4.5C7 3.83696 7.26339 3.20107 7.73223 2.73223Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 9C10.1435 9 8.36301 9.7375 7.05025 11.0503C5.7375 12.363 5 14.1435 5 16V17C5 17.7956 5.31607 18.5587 5.87868 19.1213C6.44129 19.6839 7.20435 20 8 20H16C16.7956 20 17.5587 19.6839 18.1213 19.1213C18.6839 18.5587 19 17.7956 19 17V16C19 14.1435 18.2625 12.363 16.9497 11.0503C15.637 9.7375 13.8565 9 12 9ZM5.63604 9.63604C7.32387 7.94821 9.61305 7 12 7C14.3869 7 16.6761 7.94821 18.364 9.63604C20.0518 11.3239 21 13.6131 21 16V17C21 18.3261 20.4732 19.5979 19.5355 20.5355C18.5979 21.4732 17.3261 22 16 22H8C6.67392 22 5.40215 21.4732 4.46447 20.5355C3.52678 19.5979 3 18.3261 3 17V16C3 13.6131 3.94821 11.3239 5.63604 9.63604Z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1544_3388" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" }))))); };
export default Gof;
