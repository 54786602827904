var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import { createContext, useMemo } from 'react';
var PermissionContext = createContext({
    userPermissions: [],
    routes: [],
    isAuthenticated: false,
});
var PermissionProvider = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var contextValue = useMemo(function () {
        return props.value;
    }, [props.value]);
    return (_jsx(PermissionContext.Provider, { children: children, value: contextValue }, void 0));
};
export { PermissionProvider, PermissionContext };
