var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useMemo, useRef } from 'react';
import cn from 'classnames';
import Input from '../Input';
import { ToggleColors } from './types';
import styles from './styles.module.scss';
export * from './types';
var defaultProps = {
    component: 'label',
    color: ToggleColors.red,
    disabled: false,
};
export var Toggle = forwardRef(function (props, ref) {
    var _a;
    var _b = __assign(__assign({}, defaultProps), props), Component = _b.component, className = _b.className, disabled = _b.disabled, onChange = _b.onChange, checked = _b.checked, name = _b.name, color = _b.color, iconRef = _b.iconRef, children = _b.children, rest = __rest(_b, ["component", "className", "disabled", "onChange", "checked", "name", "color", "iconRef", "children"]);
    var refOfInput = useRef();
    var classOfRoot = cn(styles.root, className, (_a = {},
        _a[styles.disabled] = disabled,
        _a[styles.checked] = checked,
        _a[styles[color]] = !!color,
        _a));
    var _children = useMemo(function () { return children && _jsx("span", __assign({ className: styles.content }, { children: children }), void 0); }, [children]);
    return (_jsxs(Component, __assign({}, rest, { ref: ref, className: classOfRoot, role: "checkbox" }, { children: [_jsxs("div", __assign({ className: styles.icon }, { children: [_jsx("div", { className: styles.dot }, void 0), _jsx("div", { className: styles.nav }, void 0)] }), void 0), _children, _jsx(Input, { readOnly: true, type: "checkbox", ref: refOfInput, name: name, className: styles.input, checked: checked, onChange: onChange }, void 0)] }), void 0));
});
Toggle.displayName = 'Toggle';
export default Toggle;
