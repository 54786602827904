var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LunaClient } from '@gameloot/luna/LunaServiceClientPb';
import gRPCClientAbstract from '../abstract/gRPCClient';
import { ListDepositsRequest, ListScenariosRequest, ListUsersRequest, SummaryOverviewRequest, SwitchScenarioRequest, SummaryRequest, ProcessDepositsRequest, ProcessDepositAllRequest, ListAutoApprovalsRequest, SetAutoApprovalRequest, ListLabelsRequest, UpdateLabelRequest, } from '@gameloot/luna/luna_pb';
import { getTimestampMsg } from '../helper';
import { Sort } from '@gameloot/luna/luna_model_pb';
var rpcLunaClient = /** @class */ (function (_super) {
    __extends(rpcLunaClient, _super);
    function rpcLunaClient(config) {
        var _this = this;
        config.serviceName = 'LUNA';
        _this = _super.call(this, LunaClient, config) || this;
        return _this;
    }
    rpcLunaClient.prototype.listScenarios = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new ListScenariosRequest();
                        request
                            .setTopicList(params.topicList)
                            .setIsAbandon(params.isAbandon)
                            .setIdsList(params.idsList);
                        return [4 /*yield*/, this.gRPCClientRequest('listScenarios', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.switchScenario = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new SwitchScenarioRequest();
                        request.setId(params.id);
                        request.setActive(params.active);
                        return [4 /*yield*/, this.gRPCClientRequest('switchScenario', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.listUsers = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new ListUsersRequest();
                        request.setTopic(params.topic);
                        request.setCursor(params.cursor);
                        request.setLimit(params.limit);
                        request.setStatus(params.status);
                        request.setBalanceStatus(params.balanceStatus);
                        request.setClassBot(params.classBot);
                        request.setChipsList(params.chipsList);
                        return [4 /*yield*/, this.gRPCClientRequest('listUsers', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.summary = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new SummaryRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('summary', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.summaryOverview = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new SummaryOverviewRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('summaryOverview', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.processDeposits = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new ProcessDepositsRequest();
                        request.setIdsList(p.idsList).setStatus(p.status).setExecutor(p.executor);
                        return [4 /*yield*/, this.gRPCClientRequest('processDeposits', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.listDeposits = function (p) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var request, sort;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        request = new ListDepositsRequest();
                        request
                            .setLimit(p.limit)
                            .setPage(p.page)
                            .setTopicsList(p.topicsList)
                            .setStatusList(p.statusList)
                            .setSlaveId(p.slaveId)
                            .setScenarioIdsList(p.scenarioIdsList);
                        if (((_a = p.sort) === null || _a === void 0 ? void 0 : _a.order) && ((_b = p.sort) === null || _b === void 0 ? void 0 : _b.order) && ((_c = p.sort) === null || _c === void 0 ? void 0 : _c.requestSortBy)) {
                            sort = new Sort();
                            sort.setOrder((_d = p.sort) === null || _d === void 0 ? void 0 : _d.order).setRequestSortBy((_e = p.sort) === null || _e === void 0 ? void 0 : _e.requestSortBy);
                            request.setSort(sort);
                        }
                        if (p.from) {
                            request.setFrom(getTimestampMsg(p.from));
                        }
                        if (p.to) {
                            request.setTo(getTimestampMsg(p.to));
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listDeposits', request)];
                    case 1: return [2 /*return*/, _f.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.processDepositAll = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new ProcessDepositAllRequest();
                        request
                            .setTopicsList(p.topicsList)
                            .setExecutor(p.executor)
                            .setScenarioIdsList(p.scenarioIdsList)
                            .setRequestProcess(p.requestProcess);
                        if (p.from) {
                            request.setFrom(getTimestampMsg(p.from));
                        }
                        if (p.to) {
                            request.setTo(getTimestampMsg(p.to));
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('processDepositAll', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.listAutoApprovals = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = new ListAutoApprovalsRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listAutoApprovals', request)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.setAutoApproval = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new SetAutoApprovalRequest();
                        req.setTopic(p.topic).setIsApproved(p.isApproved);
                        return [4 /*yield*/, this.gRPCClientRequest('setAutoApproval', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.listLabels = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListLabelsRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listLabels', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcLunaClient.prototype.updateLabel = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateLabelRequest();
                        req.setId(p.id).setStatus(p.status);
                        return [4 /*yield*/, this.gRPCClientRequest('updateLabel', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcLunaClient;
}(gRPCClientAbstract));
export default rpcLunaClient;
