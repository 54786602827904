export var REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~])[A-Za-z\d`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]{8,}$/;
export var PASSWORD_RULES = {
    oneLowercaseLetter: /[a-z]/,
    oneUppercaseLetter: /[A-Z]/,
    oneNumberLetter: /(?=.*\d)/,
    oneSpecialCharacter: /(?=.*\W)/,
    minEightCharacters: /(^.{8,})/,
    hasSpaceCharacter: /^\S*$/,
};
export var getErrorMessage = function (t) {
    return {
        oneLowercaseLetter: t('Common::PORTAL.VALIDATE.LOWER_CASE_LETTER'),
        oneUppercaseLetter: t('Common::PORTAL.VALIDATE.UPPER_CASE_LETTER'),
        oneNumberLetter: t('Common::PORTAL.VALIDATE.NUMBER_LETTER'),
        oneSpecialCharacter: t('Common::PORTAL.VALIDATE.SPECIAL_CHARACTER'),
        minEightCharacters: t('Common::PORTAL.VALIDATE.EIGHT_CHARACTERS'),
        hasSpaceCharacter: t('Common::PORTAL.VALIDATE.HAS_SPACE'),
    };
};
