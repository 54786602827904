var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from 'i18n';
import Typography from '../../Typography';
var getDefaultEmptyText = function () { return _jsx(Typography, { children: (i18n === null || i18n === void 0 ? void 0 : i18n.t('Common::TABLE.NO_DATA')) || 'No data' }, void 0); };
function TableEmpty(_a) {
    var isEmpty = _a.isEmpty, className = _a.className, loading = _a.loading, customEmptyContent = _a.customEmptyContent;
    var emptyContent = customEmptyContent || getDefaultEmptyText();
    if (!isEmpty)
        return null;
    return (_jsx(Grid, __assign({ container: true, alignItems: "center", justifyContent: "center", className: className }, { children: _jsx(Grid, __assign({ item: true, xs: "auto" }, { children: !loading ? emptyContent : '' }), void 0) }), void 0));
}
export default React.memo(TableEmpty);
