var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import _startsWith from 'lodash-es/startsWith';
import i18n from 'i18n';
import PhoneNumberField from '../../PhoneNumberField';
var PhoneNumber = function (_a) {
    var _b, _c;
    var restProps = __rest(_a, []);
    return (_jsx(PhoneNumberField, __assign({}, restProps, { value: (((_b = restProps === null || restProps === void 0 ? void 0 : restProps.value) === null || _b === void 0 ? void 0 : _b.countryCode) || '') + " " + (((_c = restProps === null || restProps === void 0 ? void 0 : restProps.value) === null || _c === void 0 ? void 0 : _c.nationalNumber) || ''), specialLabel: "", searchNotFound: i18n.t('TEXT.NOT_FOUND'), enableAreaCodeStretch: true, masks: { vn: '... ... ...' }, preferredCountries: ['vn'], country: 'vn', onChange: function (phone, data) {
            if (typeof restProps.onChange === 'function') {
                restProps.onChange({
                    // @ts-ignore
                    target: {
                        name: restProps.name,
                        value: {
                            nationalNumber: phone.slice(data.dialCode.length),
                            countryCode: data.dialCode,
                        },
                    },
                });
            }
        }, isValid: function (inputNumber, country, countries) {
            return countries.some(function (country) {
                return (_startsWith(inputNumber, country.dialCode) ||
                    _startsWith(country.dialCode, inputNumber));
            });
        } }), void 0));
};
export default PhoneNumber;
