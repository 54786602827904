var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactIs from 'react-is';
import cn from 'classnames';
import get from 'lodash-es/get';
import { isFunction } from '../../../utils/common';
import Typography from '../../Typography';
import { getCellStyle, getStickyCellClassName } from '../helper';
import styles from '../styles.module.scss';
var BodyRowCell = function (_a) {
    var _b;
    var _c;
    var rowIdx = _a.rowIdx, columnIdx = _a.columnIdx, 
    //
    header = _a.header, dataOfItem = _a.dataOfItem, value = _a.value, 
    //
    classOfCell = _a.classOfCell, className = _a.className, stickyColumn = _a.stickyColumn, sticky = _a.sticky, width = _a.width, style = _a.style, 
    //
    hasCheckboxes = _a.hasCheckboxes, isRowExpanded = _a.isRowExpanded, handleToggleExpandRow = _a.handleToggleExpandRow, onClickInCell = _a.onClickInCell;
    var cellContent = isFunction(value)
        ? value(dataOfItem)
        : get(dataOfItem, value);
    var cellStyle = getCellStyle(stickyColumn, header, width, sticky, columnIdx, hasCheckboxes);
    var customClassName = isFunction(style)
        ? ((_c = style) === null || _c === void 0 ? void 0 : _c(dataOfItem)) || ''
        : '';
    var customStyle = typeof style === 'object' ? style : {};
    var classOfCurrentCell = getStickyCellClassName([classOfCell, className], {
        stickyColumn: stickyColumn,
        sticky: sticky,
        width: width,
    });
    var shouldApplyEllipsis = !!width;
    if (typeof cellContent === 'string' ||
        typeof cellContent === 'number' ||
        ReactIs.isFragment(cellContent)) {
        cellContent = (_jsx("div", __assign({ className: cn((_b = {}, _b[styles['ellipsis']] = shouldApplyEllipsis, _b)) }, { children: cellContent }), void 0));
    }
    var cellRender = header[columnIdx].cellRender;
    if (cellRender) {
        cellContent = cellRender({
            data: dataOfItem,
            rowIdx: rowIdx,
            isRowExpanded: isRowExpanded,
            handleToggleExpandRow: handleToggleExpandRow,
        });
    }
    var clickInCell = function () {
        var _a;
        var clickOnCell = (_a = header[columnIdx]) === null || _a === void 0 ? void 0 : _a.clickOnCell;
        return clickOnCell && clickOnCell(dataOfItem);
    };
    return (_jsx(Typography, __assign({ variant: "body2", type: "placeholder", component: "td", 
        // {...rest}
        width: width, className: cn(classOfCurrentCell, customClassName), style: __assign(__assign({}, cellStyle), customStyle), onClick: clickInCell }, { children: cellContent }), void 0));
};
export default React.memo(BodyRowCell);
