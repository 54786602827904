import { StatusCode as grpcStatusCode } from 'grpc-web';
import {
  MeReply,
  OTP,
  Permission,
  TokenInfo,
  UserInfo,
} from '@greyhole/myid/myid_pb';
import { combineResourceWithActionList } from '@core/uikit';
import { GRPCClientResponse, KEY_ACCESS_TOKEN } from '@core/myservice';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from 'redux/constant';
import { RootState } from 'redux/reducers';
import { getAuthThunk } from './thunks';

export interface AuthState {
  status: StatusEnum;
  statusAuth: StatusEnum;
  error: string | null;
  errorAuth: string | null;
  accessToken: string | null;
  tokenInfo: TokenInfo.AsObject | null;
  confirmOtp: OTP.AsObject | null;
  userInfo: UserInfo.AsObject | null;
  userPermissions: string[];
  moduleIdsList: number[];
  permissionList: Permission.AsObject[];
}

const initialState: AuthState = {
  confirmOtp: null,
  tokenInfo: null,
  accessToken: null,
  ...{
    status: StatusEnum.IDLE,
    error: null,
    userInfo: null,
  },
  ...{
    statusAuth: StatusEnum.IDLE,
    errorAuth: null,
    userPermissions: [],
    moduleIdsList: [],
    permissionList: [],
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem(KEY_ACCESS_TOKEN);
      return { ...initialState };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAuthThunk.pending, (state) => {
        state.statusAuth = StatusEnum.LOADING;
      })
      .addCase(
        getAuthThunk.fulfilled,
        (
          state,
          action: PayloadAction<GRPCClientResponse<MeReply.AsObject>>,
        ) => {
          const { response, error } = action.payload;
          if (error) {
            if (error.code === grpcStatusCode.UNAUTHENTICATED) {
              localStorage.removeItem(KEY_ACCESS_TOKEN);
            }
            state.statusAuth = StatusEnum.FAILED;
            return;
          }
          state.userInfo = response.user;
          state.moduleIdsList = response.moduleIdsList;
          state.permissionList = response.permissionsList;
          state.userPermissions = combineResourceWithActionList(
            response.permissionsList,
          );

          state.statusAuth = StatusEnum.SUCCEEDED;
        },
      ),
});

const { reducer } = slice;

export const { logout } = slice.actions;

export default reducer;

export const selectAuthState = (state: RootState) => state.auth;

export const selectAuthError = createSelector(
  selectAuthState,
  (state) => state.error,
);

export const selectAuthStatus = createSelector(
  selectAuthState,
  (state) => state.statusAuth,
);

export const selectUserStatus = createSelector(
  selectAuthState,
  (state) => state.status,
);

export const selectUserInfo = createSelector(
  selectAuthState,
  (state) => state.userInfo,
);

export const selectTokenInfo = createSelector(
  selectAuthState,
  (state) => state.tokenInfo,
);

export const selectConfirmOtp = createSelector(
  selectAuthState,
  (state) => state.confirmOtp,
);

export const selectAccessToken = createSelector(
  selectAuthState,
  (state) => state.accessToken,
);

export const selectUserModuleIdsList = createSelector(
  selectAuthState,
  (state) => state.moduleIdsList,
);

export const selectUserPermissionList = createSelector(
  selectAuthState,
  (state) => state.permissionList,
);

export const selectUserPermission = createSelector(
  selectAuthState,
  (state) => state.userPermissions,
);
