var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
// import { useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useQuery from '../hooks/useQuery';
import { formatTimeStampToObject } from '../utils/date';
export var propsDefault = {
    startDateName: 'startDate',
    endDateName: 'endDate',
    searchName: 's',
    sortName: 'sortName',
    orderBy: 'orderBy',
};
export var getQuery = function (query, name) {
    if (query === null || query === void 0 ? void 0 : query.has(name))
        return query.get(name);
    return '';
};
export default function useFilter(options) {
    options = __assign(__assign({}, propsDefault), options);
    var query = useQuery();
    var navigate = useNavigate();
    var search = query.get(options.searchName) || '';
    var startDate = +query.get(options.startDateName);
    var endDate = +query.get(options.endDateName);
    var startDateObject = startDate && formatTimeStampToObject(startDate);
    var endDateObject = endDate && formatTimeStampToObject(endDate);
    var sortName = query.get(options.sortName);
    var orderBy = query.get(options.orderBy);
    var aggregateQuery = function (key, value) {
        if (query.has(key)) {
            if (value) {
                query.set(key, Array.isArray(value) ? value.join(',') : value);
            }
            else {
                query.delete(key);
            }
        }
        else {
            query.append(key, value);
        }
    };
    var pushQuery = function (key, value) {
        aggregateQuery(key, value);
        navigate({ search: query.toString() });
    };
    var pushQueries = function (q) {
        Object.entries(q).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            aggregateQuery(key, value);
        });
        navigate({ search: query.toString() });
    };
    var pushGroupQuery = function (groups) {
        Object.entries(groups).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], values = _b[1];
            pushQuery(key, values);
        });
    };
    var popQuery = function (key) {
        if (query.has(key)) {
            query.delete(key);
        }
        navigate({ search: query.toString() });
    };
    var popQueries = function (keys) {
        if (!(keys === null || keys === void 0 ? void 0 : keys.length))
            return;
        keys.forEach(function (k) {
            if (query.has(k)) {
                query.delete(k);
            }
        });
        navigate({ search: query.toString() });
    };
    var clearQuery = function (_a) {
        var e_1, _b;
        var _c = _a.exceptionList, exceptionList = _c === void 0 ? [] : _c;
        var arr = __spreadArray([], __read(query.entries()));
        try {
            for (var arr_1 = __values(arr), arr_1_1 = arr_1.next(); !arr_1_1.done; arr_1_1 = arr_1.next()) {
                var _d = __read(arr_1_1.value, 1), key = _d[0];
                if (!exceptionList.includes(key)) {
                    query.delete(key);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (arr_1_1 && !arr_1_1.done && (_b = arr_1.return)) _b.call(arr_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var search = query.toString();
        navigate({ search: search });
    };
    return {
        query: query,
        navigate: navigate,
        search: search,
        startDate: startDate,
        endDate: endDate,
        startDateObject: startDateObject,
        endDateObject: endDateObject,
        pushQuery: pushQuery,
        pushQueries: pushQueries,
        popQuery: popQuery,
        popQueries: popQueries,
        pushGroupQuery: pushGroupQuery,
        clearQuery: clearQuery,
        sortName: sortName,
        orderBy: orderBy,
    };
}
