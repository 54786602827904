var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Maintenance = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("g", { clipPath: "url(#clip0_633_2627)" },
            React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M8.379 3.964l2.328 2.329A1 1 0 0111 7v3a1 1 0 01-1 1H7a1 1 0 01-.707-.293L3.964 8.38a5 5 0 007.105 5.219 1 1 0 011.138.195l6 6a1.121 1.121 0 001.586-1.586l-6-6a1 1 0 01-.195-1.138 5 5 0 00-5.22-7.105zm-2.31-1.366a7 7 0 019.61 8.667l5.528 5.528a3.122 3.122 0 01-4.414 4.414l-5.528-5.528a7 7 0 01-8.667-9.61 1 1 0 011.61-.276L7.413 9H9V7.414L5.793 4.207a1 1 0 01.276-1.61z", clipRule: "evenodd" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_633_2627" },
                React.createElement("path", { fill: "#fff", d: "M0 0H24V24H0z" })))));
};
export default Maintenance;
