var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "#7462E1", d: "M4 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 16V8c0-.55.196-1.02.588-1.412A1.923 1.923 0 014 6h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v8c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 18H4zm4-3a.968.968 0 00.713-.288A.967.967 0 009 14v-1h1a.968.968 0 00.713-.288A.967.967 0 0011 12a.97.97 0 00-.287-.713A.97.97 0 0010 11H9v-1a.97.97 0 00-.287-.713A.97.97 0 008 9a.97.97 0 00-.713.287A.97.97 0 007 10v1H6a.97.97 0 00-.713.287A.97.97 0 005 12c0 .283.096.52.287.712.192.192.43.288.713.288h1v1c0 .283.096.52.287.712.192.192.43.288.713.288zm6.5 0c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0014.5 12c-.417 0-.77.146-1.062.438A1.444 1.444 0 0013 13.5c0 .417.146.77.438 1.062.291.292.645.438 1.062.438zm3-3c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0017.5 9c-.417 0-.77.146-1.062.438A1.444 1.444 0 0016 10.5c0 .417.146.77.438 1.062.291.292.645.438 1.062.438z" })));
}
export default SvgComponent;
