import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { REDIRECT_TO } from '@core/okit';
import { useRedirectParams } from '@core/okit/build/hooks/useRedirectParams';
import { useSSO } from '@core/okit/build/hooks/useSSO';
import useQuery from '@core/uikit/build/src/hooks/useQuery';

import { routeConfig } from 'configs/routes/config';

const SSO: React.FunctionComponent = (props: any) => {
  const { i18n, t } = useTranslation();
  const { pathDecoded, portalUrlWithQueryString } = useRedirectParams();
  const { lang } = useSSO({ portalUrl: portalUrlWithQueryString });

  const query = useQuery();
  const redirectTo = query.get(REDIRECT_TO);

  useEffect(() => {
    i18n?.language !== lang && i18n.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let to = pathDecoded || routeConfig.salesDetailReport.overview.path;
  if (redirectTo) to = decodeURIComponent(redirectTo);

  return (
    <>
      <Helmet>
        <title>{`${t('TEXT.REDIRECT_TO')} ${t(t('SPACE.KPI'))}`}</title>
      </Helmet>
      <Navigate to={to} />
    </>
  );
};

export default SSO;
