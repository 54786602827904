import { useState, useEffect } from 'react';
import { GroupBy } from '@dceu/dailyplanet/dailyplanet_enum_pb';
import useFilter from './useFilter';
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE_NAME = 'p';
export const DEFAULT_PAGE_SIZE_NAME = 'sz';
const defaultProps = {
    pageName: DEFAULT_PAGE_NAME,
    pageSizeName: DEFAULT_PAGE_SIZE_NAME,
    defaultPageSize: DEFAULT_PAGE_SIZE,
};
export default function usePagination(options) {
    const { defaultPageSize, pageName } = Object.assign(Object.assign({}, defaultProps), options);
    const [listCursors, setListCursor] = useState([]);
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [pageSize, setPageSize] = useState(defaultPageSize || DEFAULT_PAGE_SIZE);
    const [viewBy, setViewBy] = useState(GroupBy.GROUP_BY_DAILY);
    const { getQueryParam, popQueries, pushQueries } = useFilter();
    const offset = (page - 1) * pageSize;
    const pageQuery = getQueryParam(pageName);
    useEffect(() => {
        page === DEFAULT_PAGE
            ? pageQuery && popQueries([pageName || DEFAULT_PAGE_NAME])
            : pushQueries({ [pageName || DEFAULT_PAGE_NAME]: page });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
    useEffect(() => {
        if (!pageQuery) {
            setPage(DEFAULT_PAGE);
            setListCursor([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageQuery]);
    const handleChangePage = (p, nextCursor) => {
        p > page ? nextCursor && listCursors.push(nextCursor) : listCursors.pop();
        setPage(p);
        setListCursor([...listCursors]);
    };
    const handleChangePageSize = (sz) => {
        setPage(DEFAULT_PAGE);
        setListCursor([]);
        setPageSize(sz);
    };
    const handleChangeViewBy = (viewBy) => {
        setPage(DEFAULT_PAGE);
        setListCursor([]);
        setViewBy(viewBy);
    };
    return {
        page,
        pageSize,
        viewBy,
        cursor: listCursors[listCursors.length - 1] || 0,
        offset,
        handleChangePage,
        handleChangePageSize,
        handleChangeViewBy,
    };
}
