var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import LanguageDebuggerModal from './Modal';
import Profile from './Profile';
import withDebug from './withDebug';
var eltIdName = 'language-debugger';
var mElt = document.createElement('div');
mElt.setAttribute('id', eltIdName);
var initLanguageDebuggerElt = function () {
    if (!document.getElementById(eltIdName))
        document.body.appendChild(mElt);
};
initLanguageDebuggerElt();
var LanguageDebugger = function () {
    var _a = __read(useState(false), 2), open = _a[0], setOpen = _a[1];
    var onClose = useCallback(function () {
        setOpen(false);
    }, []);
    var onOpen = useCallback(function () {
        setOpen(true);
    }, []);
    return ReactDOM.createPortal(_jsxs(_Fragment, { children: [_jsx(Profile, { onOpen: onOpen }, void 0), open && _jsx(LanguageDebuggerModal, { onClose: onClose }, void 0)] }, void 0), mElt);
};
export default withDebug(LanguageDebugger);
