var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from 'classnames';
import { CHECK_BOX_WIDTH } from './const';
import styles from './styles.module.scss';
export function getCellStyle(stickyColumn, header, width, sticky, columnIdx, hasCheckboxes) {
    if (typeof width === 'string')
        return {};
    var offset = 0;
    if (sticky === 'left') {
        for (var i = 0; i < columnIdx; i++) {
            var col = header[i];
            if (col.sticky === sticky && typeof col.width === 'number') {
                var width_1 = col.width || 0;
                offset += width_1;
            }
        }
        if (stickyColumn && hasCheckboxes) {
            offset += CHECK_BOX_WIDTH + 1;
        }
    }
    if (sticky === 'right') {
        for (var i = columnIdx + 1; i < header.length; i++) {
            var col = header[i];
            if (col.sticky === sticky && typeof col.width === 'number') {
                var width_2 = col.width || 0;
                offset += width_2;
            }
        }
    }
    var widthStyle = {
        width: width,
        maxWidth: width,
        minWidth: width,
    };
    var stickyStyle = {
        left: sticky === 'left' && stickyColumn ? offset : undefined,
        right: sticky === 'right' && stickyColumn ? offset : undefined,
    };
    return __assign(__assign({}, widthStyle), stickyStyle);
}
export function getTableClassNames(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var loading = _a.loading, overflowX = _a.overflowX, isEmpty = _a.isEmpty, className = _a.className, classes = _a.classes, stickyColumn = _a.stickyColumn, tableLayout = _a.tableLayout, isRowClickable = _a.isRowClickable, shouldShowPagination = _a.shouldShowPagination, showBorderSides = _a.showBorderSides, showBorderRadius = _a.showBorderRadius, withoutScroll = _a.withoutScroll;
    var classOfOuterWrapper = cn(styles['table-outer-wrapper'], (_b = {},
        _b[styles['table-outer-wrapper-loading']] = loading && !overflowX,
        _b[styles['show-border-sides']] = showBorderSides,
        _b[styles['show-border-radius']] = showBorderRadius,
        _b));
    var classOfInnerWrapper = cn(styles['table-inner-wrapper'], (_c = {},
        _c[styles['table-loading']] = loading,
        _c), classes.innerWrapper, (_d = {}, _d[styles.withoutScroll] = withoutScroll, _d));
    var classOfEmptyOrLoadingWrapper = cn((_e = {},
        _e[styles['empty-loading']] = isEmpty,
        _e));
    var classOfRoot = cn(styles.root, className, classes.root, (_f = {},
        _f[styles['table-overflow-x']] = overflowX,
        _f[styles['table-sticky']] = stickyColumn,
        _f[styles['table-layout-auto']] = tableLayout === 'auto',
        _f));
    var classOfHeader = cn(styles.header, classes.header);
    var classOfBody = cn(styles.body, classes.body);
    var classOfRow = cn(styles.row, (_g = {}, _g[styles['can-click']] = isRowClickable, _g));
    var classOfFooter = cn(styles.footer, (_h = {},
        _h[styles.footerWithPagination] = shouldShowPagination,
        _h), classes.footer);
    var classOfHeaderCell = cn(styles.cell, styles['header-cell'], classes.cell);
    var classOfCell = cn(styles.cell, classes.cell);
    var classOfCheckboxCell = cn(classOfCell, styles.checkbox, (_j = {},
        _j[styles['sticky-cell']] = stickyColumn,
        _j[styles['sticky-checkbox']] = stickyColumn,
        _j));
    var classOfRowEmpty = cn(styles['row-empty'], classes['row-empty']);
    return {
        classOfCheckboxCell: classOfCheckboxCell,
        classOfHeaderCell: classOfHeaderCell,
        classOfCell: classOfCell,
        classOfRow: classOfRow,
        classOfRowEmpty: classOfRowEmpty,
        classOfOuterWrapper: classOfOuterWrapper,
        classOfInnerWrapper: classOfInnerWrapper,
        classOfRoot: classOfRoot,
        classOfHeader: classOfHeader,
        classOfBody: classOfBody,
        classOfFooter: classOfFooter,
        classOfEmptyOrLoadingWrapper: classOfEmptyOrLoadingWrapper,
    };
}
export function getStickyCellClassName(rootClass, stickyOptions) {
    var _a;
    var stickyColumn = stickyOptions.stickyColumn, sticky = stickyOptions.sticky, width = stickyOptions.width;
    return cn(rootClass, (_a = {},
        _a[styles['sticky-cell']] = stickyColumn && sticky,
        _a[styles['ellipsis']] = !!width,
        _a[styles.stickyRight] = sticky === 'right',
        _a));
}
