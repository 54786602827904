var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import styles from './styles.module.scss';
import Typography, { TypoVariants, TypoTypes, TypoWeights, } from '../Typography';
var DialogStepper = function (props) {
    var activeStep = props.activeStep, stepsLabel = props.stepsLabel;
    // const classes = useStyles();
    var t = useTranslation().t;
    return (_jsx(_Fragment, { children: activeStep < stepsLabel.length ? (_jsxs(Box, { children: [_jsx(Box, __assign({ my: 2 }, { children: _jsx("div", __assign({ className: "flex" }, { children: stepsLabel.map(function (_, indexStep, index) {
                            var _a;
                            return (_jsx("div", { className: clsx(styles.step, (_a = {},
                                    _a[styles.stepActive] = indexStep <= activeStep,
                                    _a)) }, index));
                        }) }), void 0) }), void 0), _jsxs(Typography, __assign({ variant: TypoVariants.head3, weight: TypoWeights.bold, type: TypoTypes.sub }, { children: [t('Common::TEXT.STEP'), " ", activeStep + 1, " ", t('Common::TEXT.OF'), " 2:", ' ', stepsLabel[activeStep]] }), void 0)] }, void 0)) : null }, void 0));
};
export default DialogStepper;
