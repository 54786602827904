var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { InfoCircle } from '@core/icons';
import Typography, { TypoVariants, TypoWeights, } from '../../components/Typography';
import styles from './styles.module.scss';
var HtmlTooltip = withStyles(function (theme) { return ({
    tooltip: {
        minWidth: 360,
        minHeight: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        color: '#090212',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #E0E0E0',
        borderRadius: 8,
    },
    arrow: {
        background: '#FFF',
        '&::before': {
            backgroundColor: '#FFF',
            border: '1px solid #E0E0E0',
        },
    },
}); })(Tooltip);
var ToolTip = function (_a) {
    var children = _a.children;
    return (_jsx(HtmlTooltip, __assign({ arrow: true, title: _jsx(Typography, __assign({ variant: TypoVariants.subtitle2, weight: TypoWeights.medium }, { children: children }), void 0) }, { children: _jsx("div", __assign({ className: styles['icon_circle'] }, { children: _jsx(InfoCircle, {}, void 0) }), void 0) }), void 0));
};
export default ToolTip;
