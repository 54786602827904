var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "currentColor", d: "M8 17a.968.968 0 00.713-.288A.967.967 0 009 16v-5a.97.97 0 00-.287-.713A.97.97 0 008 10a.97.97 0 00-.713.287A.97.97 0 007 11v5c0 .283.096.52.287.712.192.192.43.288.713.288zm4 0a.968.968 0 00.713-.288A.967.967 0 0013 16V8a.97.97 0 00-.287-.713A.97.97 0 0012 7a.967.967 0 00-.712.287A.968.968 0 0011 8v8c0 .283.096.52.288.712A.965.965 0 0012 17zm4 0c.283 0 .52-.096.712-.288A.965.965 0 0017 16v-2a.968.968 0 00-.288-.713A.967.967 0 0016 13a.967.967 0 00-.712.287A.968.968 0 0015 14v2c0 .283.096.52.288.712A.965.965 0 0016 17zM5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 013 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 015 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0119 21H5zm0-2h14V5H5v14zm0 0V5v14z" })));
}
export default SvgComponent;
