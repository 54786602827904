import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../hooks/useQuery';
import { formatTimeStampToObject } from '../utils/date';
export const propsDefault = {
    startDateName: 'startDate',
    endDateName: 'endDate',
    searchName: 's',
    sortName: 'sortName',
    orderBy: 'orderBy',
};
export const getQuery = (query, name) => {
    if (query === null || query === void 0 ? void 0 : query.has(name))
        return query.get(name);
    return '';
};
export default function useFilter(options) {
    options = Object.assign(Object.assign({}, propsDefault), options);
    const query = useQuery();
    const navigate = useNavigate();
    const getQueryParam = useCallback((key) => {
        if (!key)
            return '';
        if (!query.has(key))
            return '';
        return query.get(key);
    }, []);
    const aggregateQuery = (key, value) => {
        if (query.has(key)) {
            if (value) {
                query.set(key, Array.isArray(value) ? value.join(',') : value);
            }
            else {
                query.delete(key);
            }
        }
        else {
            query.append(key, value);
        }
    };
    const pushQuery = (key, value) => {
        aggregateQuery(key, value);
        navigate({ search: query.toString() });
    };
    const pushQueries = (q) => {
        Object.entries(q).forEach(([key, value]) => {
            aggregateQuery(key, value);
        });
        navigate({ search: query.toString() });
    };
    const pushGroupQuery = (groups) => {
        Object.entries(groups).forEach(([key, values]) => {
            pushQuery(key, values);
        });
    };
    const popQueries = (keys) => {
        if (!(keys === null || keys === void 0 ? void 0 : keys.length))
            return;
        keys.forEach((k) => {
            if (query.has(k)) {
                query.delete(k);
            }
        });
        navigate({ search: query.toString() });
    };
    const clearQuery = ({ exceptionList = [] }) => {
        const arr = [...query.entries()];
        for (const [key] of arr) {
            if (!exceptionList.includes(key)) {
                query.delete(key);
            }
        }
        const search = query.toString();
        navigate({ search });
    };
    const startDate = Number(getQueryParam(options.searchName));
    const endDate = Number(getQueryParam(options.startDateName));
    const startDateObject = startDate && formatTimeStampToObject(startDate);
    const endDateObject = endDate && formatTimeStampToObject(endDate);
    return {
        pushQueries,
        popQueries,
        pushGroupQuery,
        clearQuery,
        getQueryParam,
        startDateObject,
        endDateObject,
        startDate,
        endDate,
        search: getQueryParam(options.searchName),
        sortName: getQueryParam(options.sortName),
        orderBy: getQueryParam(options.orderBy),
    };
}
