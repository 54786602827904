var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import cn from 'classnames';
import { AngleDown, AngleUp } from '@core/icons';
import { logEvent, EVENTS } from '@greyhole/ui-tracking';
import { OpsTooltip } from './OpsTooltip';
import styles from './styles.module.scss';
import Tag from './Tag';
var MenuItem = function (_a) {
    var _b, _c;
    var _d;
    var title = _a.title, icon = _a.icon, children = _a.children, _e = _a.path, path = _e === void 0 ? '' : _e, tag = _a.tag;
    var location = useLocation();
    var match = location.pathname.includes(path);
    var _f = React.useState(function () {
        return location.pathname.includes(path);
    }), expand = _f[0], setExpand = _f[1];
    var hasChildren = children && ((_d = children === null || children === void 0 ? void 0 : children.filter(function (route) { return Boolean(route.title); })) === null || _d === void 0 ? void 0 : _d.length) >= 1;
    var onClickMenu = function (e) {
        setExpand(!expand);
        if (hasChildren) {
            e.preventDefault();
        }
        if (!hasChildren && !match) {
            logEvent(EVENTS.SIDEBAR_ITEM_CLICK_EVENT, {
                page_title: title,
                page_path: path,
            });
        }
    };
    if (!title && !icon) {
        return null;
    }
    return (React.createElement("li", { className: cn((_b = {},
            _b[styles.active] = match,
            _b[styles.subMenuExpand] = hasChildren,
            _b)) },
        React.createElement(OpsTooltip, { title: title || '', placement: "right", arrow: true },
            React.createElement(Link, { to: !hasChildren ? path : '', onClick: onClickMenu },
                icon && React.createElement("div", { className: styles.icon }, icon),
                React.createElement("span", { className: styles.text }, title),
                React.createElement("div", null, !tag || React.createElement(Tag, { title: tag })),
                hasChildren && (React.createElement("div", { className: styles.arrow }, expand ? React.createElement(AngleUp, null) : React.createElement(AngleDown, null))))),
        hasChildren && (children === null || children === void 0 ? void 0 : children.length) && (React.createElement("ul", { className: cn(styles.subMenu, (_c = {}, _c[styles.subMenuActive] = expand, _c)) }, children.map(function (subMenu, idx) { return (React.createElement(MenuItem, __assign({}, subMenu, { key: subMenu.path || idx }))); })))));
};
export default React.memo(MenuItem);
