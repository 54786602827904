import { getAnalytics, logEvent as gaLogEvent, setUserId as gaSetUserId, setUserProperties as gaSetUserProperties, } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { firebaseConfig, hasFirebaseConfig, isDevEnv, isFirebaseEnable, isDebugFirebase, } from '../configs/firebase';
import { TENANT_ID } from './const';
// Initialize Firebase
const app = isFirebaseEnable && hasFirebaseConfig
    ? initializeApp(firebaseConfig)
    : undefined;
const analytics = isFirebaseEnable && hasFirebaseConfig ? getAnalytics(app) : undefined;
if (isFirebaseEnable && !hasFirebaseConfig) {
    console.error('Firebase: ❗❗❗ MISSING CONFIG ❗❗❗');
}
export function setUserId(userId) {
    if (analytics) {
        gaSetUserId(analytics, userId);
    }
}
export function setUserProperties(properties, options) {
    if (analytics) {
        gaSetUserProperties(analytics, properties, options);
    }
}
export function setUserTenantId(tenantId) {
    if (analytics) {
        gaSetUserProperties(analytics, { [TENANT_ID]: tenantId });
    }
}
export function logEvent(eventName, eventParams = {}, options) {
    if (isFirebaseEnable && hasFirebaseConfig && analytics) {
        gaLogEvent(analytics, eventName, eventParams, options);
    }
    if (isDevEnv && isDebugFirebase) {
        console.log('🐾', {
            eventName,
            eventParams,
            options,
        }, eventParams.page_path, document.title);
    }
}
