export var TypoTypes;
(function (TypoTypes) {
    TypoTypes["primary"] = "primary";
    TypoTypes["secondary"] = "secondary";
    TypoTypes["default"] = "default";
    TypoTypes["titleDefault"] = "titleDefault";
    TypoTypes["text"] = "text";
    TypoTypes["white"] = "white";
    TypoTypes["textButton"] = "textButton";
    TypoTypes["disable"] = "disable";
    TypoTypes["error"] = "error";
    TypoTypes["invert"] = "invert";
    TypoTypes["link"] = "link";
    TypoTypes["sub"] = "sub";
    TypoTypes["titleSub"] = "titleSub";
    TypoTypes["textSub"] = "textSub";
    TypoTypes["success"] = "success";
    TypoTypes["warning"] = "warning";
    TypoTypes["brandLighter"] = "brandLighter";
    TypoTypes["placeholder"] = "placeholder";
    TypoTypes["amount"] = "amount";
    TypoTypes["inherit"] = "inherit";
})(TypoTypes || (TypoTypes = {}));
export var TypoVariants;
(function (TypoVariants) {
    TypoVariants["head1"] = "head1";
    TypoVariants["head2"] = "head2";
    TypoVariants["head3"] = "head3";
    TypoVariants["head4"] = "head4";
    TypoVariants["body1"] = "body1";
    TypoVariants["body2"] = "body2";
    TypoVariants["caption"] = "caption";
    TypoVariants["inherit"] = "inherit";
    TypoVariants["subtitle1"] = "subtitle1";
    TypoVariants["subtitle2"] = "subtitle2";
    TypoVariants["button1"] = "button1";
    TypoVariants["button2"] = "button2";
    TypoVariants["buttonLink"] = "buttonLink";
    TypoVariants["descriptionHeader"] = "descriptionHeader";
})(TypoVariants || (TypoVariants = {}));
export var TypoWeights;
(function (TypoWeights) {
    TypoWeights["bold"] = "bold";
    TypoWeights["medium"] = "medium";
    TypoWeights["regular"] = "regular";
    TypoWeights["light"] = "light";
    TypoWeights["inherit"] = "inherit";
})(TypoWeights || (TypoWeights = {}));
