const GMT_TZ_KEY = '__GMT_TZ__';
export const TZ_KEY = 'tz';
export const getTzFromLocalStorage = () => {
    return (localStorage.getItem(GMT_TZ_KEY) && Number(localStorage.getItem(GMT_TZ_KEY)));
};
export const getTzLocal = () => {
    return -(new Date().getTimezoneOffset() / 60);
};
export const setTzToLocalStorage = (tz) => {
    return localStorage.setItem(GMT_TZ_KEY, `${tz}`);
};
export const getCurrentTzNumber = () => getTzFromLocalStorage() || getTzLocal();
