import { createAsyncThunk } from '@reduxjs/toolkit';
import { overallReportClient } from '@core/myservice/build/client';
import {
  GetPaymentReportRequest,
  GetPaymentStatsRequest,
  GetPlayerReportRequest,
} from '@dceu/dailyplanet/dailyplanet_overall_report_pb';

export const getPlayerReportThunk = createAsyncThunk(
  'saleReportOverview/getPlayerReport',
  async (payload: GetPlayerReportRequest.AsObject) => {
    return await overallReportClient.getPlayerReport(payload);
  },
);

export const getPaymentReportThunk = createAsyncThunk(
  'saleReportOverview/getPaymentReport',
  async (payload: GetPaymentReportRequest.AsObject) => {
    return await overallReportClient.getPaymentReport(payload);
  },
);

export const getPaymentStatsThunk = createAsyncThunk(
  'saleReportOverview/getPaymentStats',
  async (payload: GetPaymentStatsRequest.AsObject) => {
    return await overallReportClient.getPaymentStats();
  },
);
