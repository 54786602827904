var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Dashboard = function (props) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 12a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M16.207 8.793a1 1 0 010 1.414l-2.05 2.05a1 1 0 01-1.414-1.414l2.05-2.05a1 1 0 011.414 0z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 4.954A8 8 0 006.76 19h10.48A8 8 0 0012 4.954zm-5.79-.153a10 10 0 0112.012 15.982A1 1 0 0117.6 21H6.4a1 1 0 01-.622-.217A10 10 0 016.21 4.8z", clipRule: "evenodd" }))); };
export default Dashboard;
