import { createMuiTheme } from '@material-ui/core/styles';
import { shadows } from './shadows';
import { typography } from './typography';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1B945C',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#5D1BE0',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#2BE990',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F53131',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFD52F',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#031352',
      secondary: '#7C84A3',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FAFBFF',
    },
  },
  shadows,
  typography,
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
  overrides: {
    MuiCssBaseline: {},
    MuiButton: {
      root: {
        textTransform: 'inherit',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        letterSpacing: '0.75px',
        padding: '8px 16px',
      },
      label: {
        fontSize: '16px',
      },
      sizeLarge: {
        padding: '14px 16px',
        fontSize: '16px',
      },
      outlined: {
        border: '1px solid #E0E0E0',
      },
    },
    MuiTypography: {
      button: {
        textTransform: 'inherit',
      },
    },
    MuiInput: {
      root: {
        borderRadius: '8px',
        border: '1px solid #E0E0E0',
        padding: '2px',
        '&:hover': {
          border: '2px solid #3896E3',
          padding: 0,
        },
        outlined: {
          border: '1px solid #E0E0E0',
          '& $notchedOutline': {
            borderColor: '1px solid #E0E0E0',
          },
          readonly: {
            background: 'red',
          },
        },
        '& $notchedOutline': {
          borderColor: '1px solid #E0E0E0',
        },
      },
      input: {
        letterSpacing: '0.5px',
        padding: '13px 16px',
      },
      underline: {
        '&:before, &:after': {
          display: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        color: '#272f3d',
        border: '1px solid #E0E0E0',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'red',
          },
          '&:hover fieldset': {
            borderColor: 'yellow',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'green',
          },
          readonly: {
            background: 'red',
          },
        },
      },
      input: {
        padding: '16px 24px',
        height: '24px',
      },
      multiline: {
        padding: '16px 24px',
        height: '24px',
      },
      notchedOutline: {
        borderColor: 'transparent',
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.5px',
        color: '#272F3D',
        marginBottom: '8px',
        padding: '1px',

        '&.Mui-disabled': {
          background: '#f5f5f5',
          boxShadow: 'none',
          border: '1px solid #E0E0E0 !important',
          padding: '1px !important',
          '&:hover': {
            border: '1px solid #E0E0E0 !important',
            padding: '1px !important',
          },
          '& $input, & .MuiIconButton-root': {
            color: '#272f3d',
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '146%',
        letterSpacing: '0.75px',
        color: '#556785',
        marginBottom: '8px',
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '146%',
        letterSpacing: '0.75px',
        '&$error': {
          color: '#F53131',
        },
      },
    },
    MuiSelect: {
      root: {
        paddingRight: '36px !important',
      },
      select: {
        paddingRight: '24px',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        color: '#031352 !important',
        width: 24,
        height: 24,
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
      },
    },
  },
  props: {
    MuiButton: {},
  },
});
