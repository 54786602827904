var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Lottery = function (props) {
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M9 8a1 1 0 011-1h4a1 1 0 01.97 1.243l-2 8a1 1 0 11-1.94-.485L12.72 9H10a1 1 0 01-1-1z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M12 4a8 8 0 100 16 8 8 0 000-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z", clipRule: "evenodd" })));
};
export default Lottery;
