var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { FatebookClient } from '@gameloot/fatebook/FatebookServiceClientPb';
import { AttachLabelRequest, CreateLabelRequest, DateRange, GetBalanceRequest, GetIpRequest, ListIpsRequest, ListLabelLogsRequest, ListLabelsRequest, SearchRequest, Sort, UpdateIpRequest, UpdateUserLabelsRequest, } from '@gameloot/fatebook/fatebook_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcClient = /** @class */ (function (_super) {
    __extends(rpcClient, _super);
    function rpcClient(config) {
        var _this = this;
        config.serviceName = 'FATEBOOK';
        _this = _super.call(this, FatebookClient, config) || this;
        return _this;
    }
    rpcClient.prototype.search = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, reqDate, ts, ts, reqSort;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new SearchRequest();
                        req.setUserIdsList(p.userIdsList);
                        req.setSafeIdsList(p.safeIdsList);
                        req.setUsername(p.username);
                        req.setEmail(p.email);
                        req.setPhoneNumber(p.phoneNumber);
                        req.setStatusesList(p.statusesList);
                        req.setDisplayName(p.displayName);
                        req.setLabelFilter(p.labelFilter);
                        req.setLabelIdsList(p.labelIdsList);
                        req.setPaymentIdsList(p.paymentIdsList);
                        req.setPaymentTypesList(p.paymentTypesList);
                        req.setPaymentMethodsList(p.paymentMethodsList);
                        req.setPaymentStatusesList(p.paymentStatusesList);
                        req.setPaymentNote(p.paymentNote);
                        req.setLimit(p.limit);
                        req.setOffset(p.offset);
                        req.setXffsList(p.xffsList);
                        req.setFilterLogic(p.filterLogic);
                        req.setSignInXffsList(p.signInXffsList);
                        req.setSignUpXffsList(p.signUpXffsList);
                        p.tenantId && req.setTenantId(p.tenantId);
                        p.telegramChatId && req.setTelegramChatId(p.telegramChatId);
                        if (p.dateRange) {
                            reqDate = new DateRange();
                            if (p.dateRange.startAt) {
                                ts = new Timestamp();
                                ts.setSeconds(p.dateRange.startAt.seconds);
                                ts.setNanos(p.dateRange.startAt.nanos);
                                reqDate.setStartAt(ts);
                            }
                            if (p.dateRange.endAt) {
                                ts = new Timestamp();
                                ts.setSeconds(p.dateRange.endAt.seconds);
                                ts.setNanos(p.dateRange.endAt.nanos);
                                reqDate.setEndAt(ts);
                            }
                            reqDate.setColumn(p.dateRange.column);
                            req.setDateRange(reqDate);
                        }
                        if (p.sort) {
                            reqSort = new Sort();
                            reqSort.setOrder(p.sort.order);
                            reqSort.setColumn(p.sort.column);
                            req.setSort(reqSort);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('search', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.listLabels = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListLabelsRequest();
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        req.setUserId(p.userId);
                        req.setName(p.name);
                        return [4 /*yield*/, this.gRPCClientRequest('listLabels', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.createLabel = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new CreateLabelRequest();
                        req.setName(p.name || '');
                        req.setDescription(p.description || '');
                        req.setColor(p.color || '');
                        req.setUserIdsList(p.userIdsList || []);
                        req.setNote(p.note || '');
                        return [4 /*yield*/, this.gRPCClientRequest('createLabel', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.listLabelLogs = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, sAt, eAt;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListLabelLogsRequest();
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        req.setUserId(p.userId);
                        if (p.startAt && p.endAt) {
                            sAt = new Timestamp();
                            sAt.setSeconds(p.startAt.seconds);
                            req.setStartAt(sAt);
                            eAt = new Timestamp();
                            eAt.setSeconds(p.endAt.seconds);
                            req.setEndAt(eAt);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listLabelLogs', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.attachLabel = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new AttachLabelRequest();
                        req.setLabelIdsList(p.labelIdsList);
                        req.setUserIdsList(p.userIdsList);
                        req.setNote(p.note);
                        return [4 /*yield*/, this.gRPCClientRequest('attachLabel', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateUserLabels = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateUserLabelsRequest();
                        req.setUserIdsList(p.userIdsList);
                        req.setAddLabelIdsList(p.addLabelIdsList);
                        req.setRemoveLabelIdsList(p.removeLabelIdsList);
                        req.setNote(p.note);
                        return [4 /*yield*/, this.gRPCClientRequest('updateUserLabels', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.listIps = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, reqSort;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListIpsRequest();
                        req
                            .setLimit(p.limit)
                            .setOffset(p.offset)
                            .setIpAddress(p.ipAddress)
                            .setLabelsList(p.labelsList);
                        if (p.sort) {
                            reqSort = new Sort();
                            reqSort.setOrder(p.sort.order);
                            reqSort.setColumn(p.sort.column);
                            req.setSort(reqSort);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listIps', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.getIp = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetIpRequest();
                        req.setId(p.id);
                        return [4 /*yield*/, this.gRPCClientRequest('getIp', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.updateIp = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, changesList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new UpdateIpRequest();
                        changesList = [];
                        p.changesList.map(function (_a) {
                            var label = _a.label, note = _a.note;
                            if (label) {
                                var change = new UpdateIpRequest.Change();
                                change.setLabel(label);
                                changesList.push(change);
                            }
                            if (note) {
                                var change = new UpdateIpRequest.Change();
                                change.setNote(note);
                                changesList.push(change);
                            }
                        });
                        req.setId(p.id);
                        req.setChangesList(changesList);
                        return [4 /*yield*/, this.gRPCClientRequest('updateIp', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcClient.prototype.getBalance = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetBalanceRequest();
                        req
                            .setTenantId(p.tenantId)
                            .setLabelIdsList(p.labelIdsList)
                            .setStatusesList(p.statusesList);
                        return [4 /*yield*/, this.gRPCClientRequest('getBalance', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcClient;
}(gRPCClientAbstract));
export default rpcClient;
