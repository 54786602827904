var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useMemo, } from 'react';
import cx from 'classnames';
import noop from 'lodash-es/noop';
import Backdrop, { BackdropVariant } from '../Backdrop';
import Paper, { PaperRadius } from '../Paper';
import Popper, { PopperPlacements } from '../Popper';
import useClickOutside from '../../hooks/useClickOutside';
import useKeydown from '../../hooks/useKeydown';
import styles from './styles.module.scss';
export * from './types';
var defaultProps = {
    component: 'div',
    placement: PopperPlacements.bottom,
    backdrop: BackdropVariant.transparent,
};
var defaultPopperOptions = {
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [0, 8],
            },
        },
    ],
};
export var Popover = forwardRef(function (_props, ref) {
    var _a = __assign(__assign({}, defaultProps), _props), Component = _a.component, children = _a.children, placement = _a.placement, className = _a.className, anchorEl = _a.anchorEl, anchorRef = _a.anchorRef, style = _a.style, menuClassName = _a.menuClassName, backdrop = _a.backdrop, onClose = _a.onClose, rest = __rest(_a, ["component", "children", "placement", "className", "anchorEl", "anchorRef", "style", "menuClassName", "backdrop", "onClose"]);
    var _b = __read(useClickOutside(onClose), 2), onParentClick = _b[0], onChildClick = _b[1];
    useKeydown(27, onClose || noop);
    var popperOptions = useMemo(function () { return (__assign(__assign({}, defaultPopperOptions), { placement: placement })); }, [placement]);
    var anchor = anchorEl || (anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current);
    if (anchor) {
        return (_jsx(Backdrop, __assign({ onClick: onParentClick, variant: backdrop }, { children: _jsx(Popper, __assign({ component: Component, className: cx(styles.popover, className), popperOptions: popperOptions, ref: ref, anchorEl: anchor }, rest, { children: _jsx(Paper, __assign({ elevation: 2, radius: PaperRadius.bold, className: menuClassName, style: style, onClick: onChildClick }, { children: children }), void 0) }), void 0) }), void 0));
    }
    return null;
});
Popover.displayName = 'Popover';
export default Popover;
