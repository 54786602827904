var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var IPAddress = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", fill: "currentColor", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd", clipPath: "url(#clip0_1447_3776)", clipRule: "evenodd" },
        React.createElement("path", { d: "M12 4a7 7 0 00-4.95 11.95l4.244 4.242a1 1 0 001.413 0l4.243-4.242A7 7 0 0012 4zm6.364 13.364a9 9 0 10-12.728 0l4.244 4.243a3.001 3.001 0 004.24 0l4.244-4.243z" }),
        React.createElement("path", { d: "M10 8.25a.75.75 0 01.75.75v5.333a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zM11.586 9a.75.75 0 01.75-.75h1.333a2.083 2.083 0 010 4.167h-.583v1.916a.75.75 0 01-1.5 0V9zm1.5 1.917h.583a.583.583 0 000-1.167h-.583v1.167z" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1447_3776" },
            React.createElement("path", { fill: "#fff", d: "M0 0H24V24H0z" }))))); };
export default IPAddress;
