import { PeriodType } from '@core/okit/build/context/url_params_context/resolve_url_params';
export var MIN_YEAR = 2021;
export var MAX_YEAR = 2030;
export var PERIOD_TYPES = [
    {
        type: PeriodType.Daily,
        key: 'DATE_TIME.TODAY',
    },
    {
        type: PeriodType.Yesterday,
        key: 'DATE_TIME.YESTERDAY',
    },
    {
        type: PeriodType.Last7Days,
        key: 'DATE_TIME.LAST_7_DAYS',
    },
    {
        type: PeriodType.Last30Days,
        key: 'DATE_TIME.LAST_30_DAYS',
    },
    {
        type: PeriodType.LastWeek,
        key: 'DATE_TIME.LAST_WEEK',
    },
    {
        type: PeriodType.ThisWeek,
        key: 'DATE_TIME.THIS_WEEK',
    },
    {
        type: PeriodType.LastMonth,
        key: 'DATE_TIME.LAST_MONTH',
    },
    {
        type: PeriodType.ThisMonth,
        key: 'DATE_TIME.THIS_MONTH',
    },
    {
        type: PeriodType.LastYear,
        key: 'DATE_TIME.LAST_YEAR',
    },
    {
        type: PeriodType.ThisYear,
        key: 'DATE_TIME.THIS_YEAR',
    },
];
