import React from 'react';
import RedirectTo from './RedirectTo';
var MODULE_PLAYER_MANAGEMENT = Number(process.env.REACT_APP_MODULE_PLAYER_MANAGEMENT);
var PLAYER_MANAGEMENT_URL = process.env.REACT_APP_PLAYER_MANAGEMENT_URL;
var PLAYER_MANAGEMENT_PATH = '/quinn/player-list/basic-info';
var RedirectToPlayerDetail = function (_a) {
    var moduleIdsList = _a.moduleIdsList, userId = _a.userId, tab = _a.tab, children = _a.children;
    return (React.createElement(RedirectTo, { moduleIdsList: moduleIdsList, currentModule: MODULE_PLAYER_MANAGEMENT, origin: PLAYER_MANAGEMENT_URL, pathname: PLAYER_MANAGEMENT_PATH + "/" + userId + "/" + tab }, children));
};
export default RedirectToPlayerDetail;
