var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import cn from 'classnames';
import React from 'react';
import BodyRowCell from './BodyRowCell';
import styles from '../styles.module.scss';
var BodyRowCells = function (_a) {
    var header = _a.header, dataOfItem = _a.dataOfItem, 
    //
    stickyColumn = _a.stickyColumn, hasCheckboxes = _a.hasCheckboxes, classOfCell = _a.classOfCell, rowIdx = _a.rowIdx, 
    //
    isRowExpanded = _a.isRowExpanded, handleToggleExpandRow = _a.handleToggleExpandRow, rest = __rest(_a, ["header", "dataOfItem", "stickyColumn", "hasCheckboxes", "classOfCell", "rowIdx", "isRowExpanded", "handleToggleExpandRow"]);
    return (_jsx(_Fragment, { children: header.map(function (_a, columnIdx) {
            var _b;
            var value = _a.value, width = _a.width, sticky = _a.sticky, style = _a.style, className = _a.className, align = _a.align;
            return (_jsx(BodyRowCell, __assign({ className: cn(className, (_b = {}, _b[styles["align-" + align]] = !!align, _b)), rowIdx: rowIdx, columnIdx: columnIdx, hasCheckboxes: hasCheckboxes, header: header, sticky: sticky, stickyColumn: stickyColumn, width: width, classOfCell: classOfCell, dataOfItem: dataOfItem, style: style, value: value, isRowExpanded: isRowExpanded, handleToggleExpandRow: handleToggleExpandRow }, rest), columnIdx));
        }) }, void 0));
};
export default React.memo(BodyRowCells);
