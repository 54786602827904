var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import cn from 'classnames';
import Typography, { TypoVariants, TypoWeights } from '../Typography';
import styles from './styles.module.scss';
var defaultProps = {
    component: PhoneInput,
};
export var PhoneNumberField = forwardRef(function (props, ref) {
    var _a = __assign(__assign({}, defaultProps), props), InputComponentRenderer = _a.component, className = _a.className, optional = _a.optional, note = _a.note, label = _a.label, fieldId = _a.fieldId, rest = __rest(_a, ["component", "className", "optional", "note", "label", "fieldId"]);
    var classOfField = cn(styles['text-field'], styles["variant-" + rest.status], styles["status-" + rest.status], className);
    var classOfComponent = cn(styles["variant-" + rest.status], styles["status-" + rest.status]);
    var keyOfField = fieldId || "field-" + rest.name;
    var contentOfLabel = label && (_jsx(Typography, __assign({ variant: TypoVariants.body2, weight: TypoWeights.bold, className: styles.label, component: "label", htmlFor: keyOfField }, { children: label }), void 0));
    var contentOfOptional = optional && (_jsx(Typography, __assign({ variant: TypoVariants.body2, className: styles.optional, component: "span" }, { children: optional }), void 0));
    var contentOfInput = (_jsx(InputComponentRenderer, __assign({}, rest, { id: keyOfField, dropdownClass: styles['country-list'], ref: ref, className: classOfComponent }), void 0));
    var contentOfNote = note && (_jsx(Typography, __assign({ variant: TypoVariants.caption, className: styles.note, component: "span" }, { children: note }), void 0));
    return (_jsxs("div", __assign({ className: classOfField }, { children: [contentOfLabel, contentOfOptional, contentOfInput, contentOfNote] }), void 0));
});
PhoneNumberField.displayName = 'PhoneNumberField';
export default PhoneNumberField;
