var _a;
import { Checked, MultiChecked, Uncheck } from '@core/icons';
import { CheckboxStatuses } from './types';
var mapOfIcons = (_a = {},
    _a[CheckboxStatuses.unchecked] = Uncheck,
    _a[CheckboxStatuses.checked] = Checked,
    _a[CheckboxStatuses.intermediate] = MultiChecked,
    _a);
export var getIconByStatus = function (status) { return mapOfIcons[status]; };
export var getStatusOfCheckbox = function (isChecked, isIntermediate) {
    return (!isChecked && CheckboxStatuses.unchecked) ||
        (isIntermediate ? CheckboxStatuses.intermediate : CheckboxStatuses.checked);
};
