var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", __assign({ id: "FrameIcon", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { id: "Essential Icon" },
            React.createElement("g", { id: "Group 1134" },
                React.createElement("path", { id: "Shape1", fillRule: "evenodd", clipRule: "evenodd", d: "M18 18V20H20V18H18ZM17 16C16.4477 16 16 16.4477 16 17V21C16 21.5523 16.4477 22 17 22H21C21.5523 22 22 21.5523 22 21V17C22 16.4477 21.5523 16 21 16H17Z", fill: "currentColor" }),
                React.createElement("path", { id: "Shape2", fillRule: "evenodd", clipRule: "evenodd", d: "M11 18V20H13V18H11ZM10 16C9.44772 16 9 16.4477 9 17V21C9 21.5523 9.44772 22 10 22H14C14.5523 22 15 21.5523 15 21V17C15 16.4477 14.5523 16 14 16H10Z", fill: "currentColor" }),
                React.createElement("path", { id: "Shape3", fillRule: "evenodd", clipRule: "evenodd", d: "M4 18V20H6V18H4ZM3 16C2.44772 16 2 16.4477 2 17V21C2 21.5523 2.44772 22 3 22H7C7.55228 22 8 21.5523 8 21V17C8 16.4477 7.55228 16 7 16H3Z", fill: "currentColor" }),
                React.createElement("path", { id: "Shape4", fillRule: "evenodd", clipRule: "evenodd", d: "M18 11V13H20V11H18ZM17 9C16.4477 9 16 9.44772 16 10V14C16 14.5523 16.4477 15 17 15H21C21.5523 15 22 14.5523 22 14V10C22 9.44772 21.5523 9 21 9H17Z", fill: "currentColor" }),
                React.createElement("path", { id: "Shape5", fillRule: "evenodd", clipRule: "evenodd", d: "M11 11V13H13V11H11ZM10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15H14C14.5523 15 15 14.5523 15 14V10C15 9.44772 14.5523 9 14 9H10Z", fill: "currentColor" }),
                React.createElement("path", { id: "Shape6", fillRule: "evenodd", clipRule: "evenodd", d: "M4 11V13H6V11H4ZM3 9C2.44772 9 2 9.44772 2 10V14C2 14.5523 2.44772 15 3 15H7C7.55228 15 8 14.5523 8 14V10C8 9.44772 7.55228 9 7 9H3Z", fill: "currentColor" }),
                React.createElement("path", { id: "Shape7", fillRule: "evenodd", clipRule: "evenodd", d: "M18 4V6H20V4H18ZM17 2C16.4477 2 16 2.44772 16 3V7C16 7.55228 16.4477 8 17 8H21C21.5523 8 22 7.55228 22 7V3C22 2.44772 21.5523 2 21 2H17Z", fill: "currentColor" })))));
}
export default SvgComponent;
