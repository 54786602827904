var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { startOfDay, format } from 'date-fns';
import Button from '../../../../Button';
import Typography, { TypoTypes } from '../../../../Typography';
import styles from './styles.module.scss';
var DayPicker = function (_a) {
    var idx = _a.idx, day = _a.day, valDate = _a.valDate, onDateSelected = _a.onDateSelected, field = __rest(_a, ["idx", "day", "valDate", "onDateSelected"]);
    // @ts-ignore
    var minDate = field.minDate, maxDate = field.maxDate;
    return (_jsx("span", { children: _jsx(Button, __assign({ className: styles['item_date'] + " " + (!day
                ? styles['item_date_disable']
                : valDate.getDate() === day.getDate() &&
                    valDate.getMonth() === day.getMonth() &&
                    valDate.getFullYear() === day.getFullYear()
                    ? styles['item_date_active']
                    : styles['item_date_base']) + " " + (day &&
                new Date().getDate() === day.getDate() &&
                new Date().getMonth() === day.getMonth() &&
                new Date().getFullYear() === day.getFullYear() &&
                styles['item_date_current']), disabled: (minDate && day < startOfDay(minDate)) || (maxDate && day > startOfDay(maxDate)), onClick: function () { return onDateSelected(day); } }, { children: _jsx(Typography, __assign({ className: (minDate && day < startOfDay(minDate)) || (maxDate && day > startOfDay(maxDate))
                    ? styles['day_disable']
                    : '', type: TypoTypes.placeholder }, { children: day ? format(day, 'dd') : '' }), void 0) }), void 0) }, idx));
};
export default DayPicker;
