var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import styles from './styles.module.scss';
import TranslateList from './TranslateList';
var LanguageDebuggerModal = function (_a) {
    var onClose = _a.onClose;
    var _b = __read(useState([]), 2), keys = _b[0], setKeys = _b[1];
    var _c = __read(useState(''), 2), prefix = _c[0], setPrefix = _c[1];
    var onKeyChange = function (e) {
        var keys = e.target.value.split('\n');
        setKeys(keys);
    };
    var onPrefixChange = function (e) {
        setPrefix(e.target.value);
    };
    var getKey = keys.join('\n');
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.main }, { children: [_jsxs("div", __assign({ className: styles.body }, { children: [_jsxs("div", __assign({ className: styles.key }, { children: [_jsx("div", __assign({ className: styles.title }, { children: "Prefix" }), void 0), _jsx("input", { className: styles.prefixInput, value: prefix, onChange: onPrefixChange }, void 0)] }), void 0), _jsxs("div", __assign({ className: styles.key }, { children: [_jsx("div", __assign({ className: styles.title }, { children: "Keys" }), void 0), _jsx("textarea", { name: "keys", rows: 3, className: styles.input, value: getKey, onChange: onKeyChange }, void 0)] }), void 0), _jsxs("div", __assign({ className: styles.translator }, { children: [_jsx("div", __assign({ className: styles.title }, { children: "Result:" }), void 0), _jsx(TranslateList, { list: keys, prefix: prefix }, void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: styles.close, onClick: onClose }, { children: _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", className: "h-6 w-6", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor", strokeWidth: 2 }, { children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M6 18L18 6M6 6l12 12" }, void 0) }), void 0) }), void 0)] }), void 0), _jsx("div", { className: styles.overlay, onClick: onClose }, void 0)] }, void 0));
};
export default LanguageDebuggerModal;
