var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var SvgComponent = function (props) { return (React.createElement("svg", __assign({ width: 24, height: 24, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M2 5h20v14H2V5Z", fill: "#41479B" }),
    React.createElement("path", { d: "M22 17.177 14.604 12 22 6.823V5h-2.604L12 10.177 4.604 5H2v1.823L9.396 12 2 17.177V19h2.604L12 13.823 19.396 19H22v-1.823Z", fill: "#fff" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 5v.73L10.958 12 2 18.27V19h1.042L12 12.73 20.958 19H22v-.73L13.042 12 22 5.73V5h-1.042L12 11.27 3.042 5H2Z", fill: "#DC251C" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.5 9.667V5h5v4.667H22v4.666h-7.5V19h-5v-4.667H2V9.667h7.5Z", fill: "#fff" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.75 10.833V5h2.5v5.833H22v2.334h-8.75V19h-2.5v-5.833H2v-2.334h8.75Z", fill: "#DC251C" }))); };
export default SvgComponent;
