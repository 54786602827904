import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { getTime } from 'date-fns/esm';
import { GroupBy } from '@dceu/dailyplanet/dailyplanet_enum_pb';
import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';
import { ButtonExportCsv } from '@core/oui/build/src/components';
import FilterDateRange from '@core/date-range-filter/build/src/FilterDateRange';
import LayoutContainer from '@core/uikit/build/src/components/Layout/LayoutContainer';
import { ResetAllFilter } from '@core/uikit';
import { getExportNameFormat, getThisMonth } from '@core/okit';
import useFilter, { propsDefault } from '@core/uikit/build/src/hooks/useFilter';

import { useAppSelector } from 'redux/store';
import { isStateSaleReportOverviewLoading } from 'redux/features/saleReportOverview/slice';
import { useForColumns } from 'hooks/useForColumns';
import { useSaleReportOverviewAPI } from 'pages/SaleReportOverview/useSaleReportOverviewAPI';
import BankingStatistics from './BankingStatistics';
import PlayerReport from './PlayerReport';
import PaymentReport from './PaymentReport';
import { getEmptyRowInTable, getRangeTypeByGroupBy } from './const';
import { FilterConfig } from './types';
import styles from './styles.module.scss';

interface ISaleReportOverviewProps {}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SaleReportOverview: React.FunctionComponent<ISaleReportOverviewProps> = (
  props,
) => {
  const { t } = useTranslation();
  const loading = useAppSelector(isStateSaleReportOverviewLoading);
  const { query, pushQueries, startDate } = useFilter();

  const { playerReportsWithSort, paymentReportsWithSort, paymentStats } =
    useSaleReportOverviewAPI();

  const changeDateUTC = useCallback(
    (date: number) => {
      const { start, end } = getThisMonth();

      pushQueries({
        [propsDefault.startDateName]: getTime(start),
        [propsDefault.endDateName]: getTime(end),
        [FilterConfig.GROUP_BY]: GroupBy.GROUP_BY_WEEKLY,
      });
    },
    [pushQueries],
  );

  useEffect(() => {
    let tmpDate = startDate;
    if (!tmpDate) {
      changeDateUTC(tmpDate);
    }
  }, [changeDateUTC, startDate]);

  const groupBy = query.get(FilterConfig.GROUP_BY) || GroupBy.GROUP_BY_WEEKLY;

  const { dateTimeColumnValue } = useForColumns(
    FilterConfig.VIEW_BY,
    getRangeTypeByGroupBy(Number(groupBy)),
    getRangeTypeByGroupBy(Number(groupBy)),
  );

  const tabValue = useMemo(() => {
    if (+groupBy === GroupBy.GROUP_BY_MONTHLY) return GroupBy.GROUP_BY_MONTHLY;
    return GroupBy.GROUP_BY_WEEKLY;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: GroupBy) => {
    pushQueries({ [FilterConfig.GROUP_BY]: newValue });
  };

  const csvDataPlayerReport = useMemo(() => {
    let dataExport = [
      [
        t('TEXT.DATE'),
        t('TEXT.NEW_REGISTER_PLAYER'),
        t('TEXT.LOGIN_PLAYER'),
        `${t('TEXT.ACTIVE_PLAYER')} - ${t('TEXT.QUANTITY')}`,
        `${t('TEXT.ACTIVE_PLAYER')} - ${t('TEXT.PERCENT')}`,
        `${t('TEXT.DEPOSIT_PLAYER')} - ${t('TEXT.QUANTITY')}`,
        `${t('TEXT.DEPOSIT_PLAYER')} - ${t('TEXT.PERCENT')}`,
        t('TEXT.WITHDRAW_PLAYER'),
        t('TEXT.ARPU'),
        t('TEXT.DEPOSIT_BY_PLAYER'),
        t('TEXT.WITHDRAW_BY_PLAYER'),
      ],
    ];

    if (!playerReportsWithSort?.length) return dataExport;
    playerReportsWithSort.forEach((p) => {
      dataExport.push([
        `${dateTimeColumnValue(p.datetime.seconds)}`,
        String(p.nru),
        String(p.dlu),
        String(p.dau),
        String(p.dlu ? p.dau / p.dlu : 0),
        String(p.dtu),
        String(p.dlu ? p.dtu / p.dlu : 0),
        String(p.dwu),
        String(p.arpu),
        String(p.topUpByUser),
        String(p.withdrawByUser),
      ]);
    });
    return dataExport;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, playerReportsWithSort?.length]);

  const csvDataPaymentReport = useMemo(() => {
    let dataExport = [
      [
        t('TEXT.DATE'),
        `${t('TEXT.DEPOSIT')} - ${t('TEXT.BANK')}`,
        `${t('TEXT.DEPOSIT')} - ${t('TEXT.TELCO')}`,
        `${t('TEXT.DEPOSIT')} - ${t('TEXT.TICKETS')}`,
        `${t('TEXT.DEPOSIT')} - ${t('TEXT.TOTAL')}`,
        `${t('TEXT.WITHDRAW')} - ${t('TEXT.BANK')}`,
        `${t('TEXT.WITHDRAW')} - ${t('TEXT.TELCO')}`,
        `${t('TEXT.WITHDRAW')} - ${t('TEXT.TICKETS')}`,
        `${t('TEXT.WITHDRAW')} - ${t('TEXT.TOTAL')}`,
        t('TEXT.CASH'),
      ],
    ];

    if (!paymentReportsWithSort?.length) return dataExport;
    paymentReportsWithSort.forEach((p) => {
      dataExport.push([
        `${dateTimeColumnValue(p.datetime.seconds)}`,
        String(p.totalDepositBank),
        String(p.totalDepositTelco),
        String(p.totalDepositTicket),
        String(p.totalDepositBank + p.totalDepositTelco),
        String(p.totalWithdrawBank),
        String(p.totalWithdrawTelco),
        String(p.totalWithdrawTicket),
        String(p.totalWithdrawBank + p.totalWithdrawTelco),
        String(
          p.totalDepositBank +
            p.totalDepositTelco -
            (p.totalWithdrawBank + p.totalWithdrawTelco),
        ),
      ]);
    });
    return dataExport;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, paymentReportsWithSort?.length]);

  const csvDataPaymentStats = useMemo(() => {
    let arrPaymentStats = [];
    arrPaymentStats.push({
      ...paymentStats,
    });
    let dataExport = [
      [
        t('KPI::DEPOSIT_REQUEST'),
        t('KPI::UNIQUE_PLAYER_DEPOSIT'),
        t('KPI::WITHDRAW_REQUEST'),
        t('KPI::UNIQUE_PLAYER_WITHDRAW'),
        t('KPI::UNIQUE_BANK_ACC_USED_WITHDRAW'),
      ],
    ];

    if (!arrPaymentStats?.length) return dataExport;
    arrPaymentStats.forEach((p) => {
      dataExport.push([
        String(p.countDeposit),
        String(p.countUserDeposit),
        String(p.countWithdraw),
        String(p.countUserWithdraw),
        String(p.countWithdrawCard),
      ]);
    });
    return dataExport;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, paymentStats]);

  const handleExport = async () => {
    return true;
  };

  return (
    <>
      <Helmet>
        <title>{`${t('KPI::KPI.SALES')} | ${t('SPACE.KPI')}`}</title>
      </Helmet>
      <LayoutContainer
        header={t('SIDEBAR.SALE_REPORT.OVERVIEW')}
        maxWidth={false}
      >
        <div className="p-24">
          <div className="pb-24 flex flex-col gap-24">
            <BankingStatistics />
          </div>
          <Grid container justifyContent="space-between">
            <Grid item container xs={10} spacing={2} alignItems="center">
              <Grid item>
                <FilterDateRange />
              </Grid>
              <Grid item>
                <div className={styles.wrapperTabs}>
                  <AppBar className={styles.tabs} position="static">
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      aria-label="Payment log tabs"
                      className="kpi-tab-filter"
                    >
                      <Tab
                        value={GroupBy.GROUP_BY_WEEKLY}
                        className={styles.tab1}
                        label={t('TAB.WEEKLY')}
                        {...a11yProps(0)}
                      />
                      <Tab
                        value={GroupBy.GROUP_BY_MONTHLY}
                        className={styles.tab2}
                        label={t('TAB.MONTHLY')}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </AppBar>
                </div>
              </Grid>
              <Grid item>
                <ResetAllFilter />
              </Grid>
            </Grid>
            <Grid item container xs={2} justifyContent="flex-end">
              <Grid item>
                <ButtonExportCsv
                  csvData={[
                    [t('KPI::BANKING_STATISTICS')],
                    ...csvDataPaymentStats,
                    ...getEmptyRowInTable(3),
                    [t('KPI::PLAYER_REPORT')],
                    ...csvDataPlayerReport,
                    ...getEmptyRowInTable(3),
                    [t('KPI::PAYMENT_REPORT')],
                    ...csvDataPaymentReport,
                  ]}
                  filename={getExportNameFormat('sale_report_overview')}
                  canExport={handleExport}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Grid>
          <div className="mt-32 flex flex-col gap-24">
            <div className={styles.wrapperTable}>
              <PlayerReport />
            </div>
            <div className={styles.wrapperTable}>
              <PaymentReport />
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default SaleReportOverview;
