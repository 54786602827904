export enum FilterConfig {
  GROUP_BY = 'groupBy',
  VIEW_BY = 'viewBy',
}

export const TimeRange = {
  Daily: 'daily',
} as const;

export enum RangeType {
  NONE = 'none',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export enum PlayerReportSortName {
  REGISTER_PLAYER = 'registerPlayer',
  LOGIN_PLAYER = 'loginPlayer',
  ACTIVE_PLAYER_QUANTITY = 'activePlayerQuantity',
  ACTIVE_PLAYER_PERCENT = 'activePlayerPercent',
  DEPOSIT_PLAYER_QUANTITY = 'depositPlayerQuantity',
  DEPOSIT_PLAYER_PERCENT = 'depositPlayerPercent',
  WITHDRAW_PLAYER = 'withdrawPlayer',
  ARPP = 'arpp',
  DEPOSIT_BY_PLAYER = 'depositByPlayer',
  WITHDRAW_BY_PLAYER = 'withdrawByPlayer',
}

export enum PaymentReportSortName {
  DEPOSIT_BANK = 'depositBank',
  DEPOSIT_TELCO = 'depositTelco',
  DEPOSIT_TICKETS = 'depositTickets',
  DEPOSIT_TOTAL = 'depositTotal',
  WITHDRAW_BANK = 'withdrawBank',
  WITHDRAW_TELCO = 'withdrawTelco',
  WITHDRAW_TICKETS = 'withdrawTickets',
  WITHDRAW_TOTAL = 'withdrawTotal',
  CASH = 'cash',
}
