var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, createContext, useRef, } from 'react';
import { useTranslation } from 'react-i18next';
import { addMonths, eachDayOfInterval, endOfMonth, getDate, getDay, getMonth, getYear, startOfMonth, subMonths, startOfDay, } from 'date-fns';
import cn from 'classnames';
import { formatDateTime, getTimezoneOffset, setTimezoneInTime, } from '@core/okit';
import { AngleLeft, AngleRight } from '@core/icons';
import Paper from '../../../../Paper';
import { Button } from '../../../../Button';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../../../Typography';
import { MIN_YEAR, MAX_YEAR } from './const';
import DayPicker from './DayPicker';
import styles from './styles.module.scss';
import TimePicker from './TimePicker';
var getArrDays = function (curDay) {
    if (!curDay)
        return [];
    return __spreadArray(__spreadArray([], __read(new Array(getDay(startOfMonth(curDay))).fill(''))), __read(eachDayOfInterval({
        start: startOfMonth(curDay),
        end: endOfMonth(curDay),
    })));
};
export var DatePickerContext = createContext({
    dateTime: 0,
    time: [0, 0, 0],
    setTime: function (v) {
        console.log(v);
    },
    setDate: function (v) {
        console.log(v);
    },
});
function parseDateToTime(dateTime) {
    if (!dateTime)
        return [0, 0, 0];
    var dTmp = formatDateTime(dateTime);
    var d = new Date(dTmp);
    return [d.getHours(), d.getMinutes(), d.getSeconds()];
}
var PopoverCalendar = function (_a) {
    var onChangeDate = _a.onChangeDate, handleClose = _a.handleClose, value = _a.value, showTime = _a.showTime, field = __rest(_a, ["onChangeDate", "handleClose", "value", "showTime"]);
    var t = useTranslation().t;
    var _b = __read(useState(false), 2), isYearShown = _b[0], setIsYearShown = _b[1];
    var _c = __read(useState(value ? +value : Date.now()), 2), curMonth = _c[0], setCurMonth = _c[1];
    var _d = __read(useState(getArrDays(+value)), 2), arrDays = _d[0], setArrDays = _d[1];
    var _e = __read(useState([0, 0, 0]), 2), time = _e[0], setTime = _e[1];
    var _f = __read(useState(0), 2), date = _f[0], setDate = _f[1];
    var refDateBody = useRef(null);
    var arrTitleWeek = [
        t('Common::SUNDAY'),
        t('Common::MONDAY'),
        t('Common::TUESDAY'),
        t('Common::WEDNESDAY'),
        t('Common::THURSDAY'),
        t('Common::FRIDAY'),
        t('Common::SATURDAY'),
    ];
    useEffect(function () {
        if (!value)
            return;
        setTime(parseDateToTime(+value));
        setDate(+value);
    }, []);
    useEffect(function () {
        setArrDays(getArrDays(curMonth));
        setTimeout(function () {
            var elt = document.getElementById('time_selector');
            if (!elt)
                return;
            elt.style.height = refDateBody.current.clientHeight + 'px';
        }, 300);
    }, [curMonth]);
    var onDateSelected = function (d) {
        var dateCurr = new Date(curMonth);
        dateCurr.setDate(getDate(d));
        setIsYearShown(false);
        setDate(startOfDay(dateCurr).valueOf());
        if (!showTime) {
            onChangeDate(startOfDay(dateCurr).valueOf());
            handleClose();
        }
    };
    var onPrevMonth = function () {
        var monthCurr = subMonths(new Date(curMonth), 1);
        setCurMonth(monthCurr.valueOf());
    };
    var onNextMonth = function () {
        var monthCurr = addMonths(new Date(curMonth), 1);
        setCurMonth(monthCurr.valueOf());
    };
    var onYearSelected = function (y) {
        var yearCurr = new Date(curMonth);
        yearCurr.setFullYear(y);
        setCurMonth(yearCurr.valueOf());
        setIsYearShown(false);
    };
    var toggleOpenYearSelected = function () {
        setIsYearShown(!isYearShown);
    };
    var onDateTimeSelected = function () {
        var dateCurr = new Date(date);
        dateCurr.setHours(time[0], time[1], 0);
        onChangeDate(setTimezoneInTime(dateCurr.getTime()));
        handleClose();
    };
    return (_jsx(DatePickerContext.Provider, __assign({ value: { dateTime: +value, time: time, setTime: setTime, setDate: setDate } }, { children: _jsxs(Paper, __assign({ className: "" + cn(styles.wrapper_popover_daily) }, { children: [_jsxs("div", __assign({ className: styles.datetimePicker }, { children: [_jsxs("div", __assign({ className: styles.datePicker }, { children: [_jsxs("div", __assign({ className: styles.datePickerHeader + " " + styles['icon_arrow'] }, { children: [_jsx(Button, __assign({ className: "mr-16 " + (getYear(curMonth) - MIN_YEAR < 0 ||
                                                (getMonth(curMonth) === 0 &&
                                                    getYear(curMonth) - MIN_YEAR <= 0)
                                                ? styles['icon_arrow_disabled']
                                                : ''), disabled: getYear(curMonth) - MIN_YEAR < 0 ||
                                                (getMonth(curMonth) === 0 &&
                                                    getYear(curMonth) - MIN_YEAR <= 0), onClick: onPrevMonth, fullWidth: false }, { children: _jsx(AngleLeft, {}, void 0) }), void 0), _jsx(Typography, __assign({ className: styles['text_year'], variant: TypoVariants.head4, weight: TypoWeights.bold, type: TypoTypes.text, onClick: toggleOpenYearSelected }, { children: curMonth
                                                ? t("Common::MONTH." + (getMonth(curMonth) + 1)) + ", " + getYear(curMonth)
                                                : t("Common::MONTH." + (getMonth(Date.now()) + 1)) + ", " + getYear(Date.now()) }), void 0), _jsx(Button, __assign({ className: getYear(curMonth) - MAX_YEAR > 0 ||
                                                (getMonth(curMonth) === 11 &&
                                                    getYear(curMonth) - MAX_YEAR >= 0)
                                                ? styles['icon_arrow_disabled']
                                                : '', disabled: getYear(curMonth) - MAX_YEAR > 0 ||
                                                (getMonth(curMonth) === 11 &&
                                                    getYear(curMonth) - MAX_YEAR >= 0), onClick: onNextMonth, fullWidth: false }, { children: _jsx(AngleRight, {}, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: styles.datePickerBody, ref: refDateBody }, { children: !isYearShown ? (_jsxs("div", { children: [_jsx("div", __assign({ className: styles['wrapper_title_date'] }, { children: arrTitleWeek.map(function (i) {
                                                    return (_jsx(Typography, __assign({ weight: TypoWeights.bold, type: TypoTypes.text }, { children: i }), i));
                                                }) }), void 0), _jsx("div", __assign({ className: styles['wrapper_item_date'] }, { children: arrDays.map(function (day, idx) {
                                                    var valDate = new Date(date - getTimezoneOffset());
                                                    return (_jsx(DayPicker, __assign({ idx: idx, day: day, valDate: valDate, onDateSelected: onDateSelected }, field), idx));
                                                }) }), void 0)] }, void 0)) : (_jsx(YearSelectedList, { year: curMonth
                                            ? new Date(curMonth).getFullYear()
                                            : getYear(Date.now()), onYearSelected: onYearSelected }, void 0)) }), void 0)] }), void 0), showTime && _jsx(TimePicker, {}, void 0)] }), void 0), showTime && (_jsx("div", __assign({ className: styles.actions }, { children: _jsx(Button, __assign({ onClick: onDateTimeSelected, disabled: !date }, { children: "OK" }), void 0) }), void 0))] }), void 0) }), void 0));
};
var YearSelectedList = function (_a) {
    var year = _a.year, onYearSelected = _a.onYearSelected;
    var yItems = [];
    var _loop_1 = function (i) {
        yItems.push(_jsx("li", __assign({ className: i === year ? styles['active'] : '', onClick: function () { return onYearSelected(i); } }, { children: _jsx("span", { children: i }, void 0) }), i));
    };
    for (var i = MIN_YEAR; i <= MAX_YEAR; i++) {
        _loop_1(i);
    }
    return _jsx("ul", __assign({ className: styles['year'] }, { children: yItems }), void 0);
};
export default PopoverCalendar;
