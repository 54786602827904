var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { KEY_ACCESS_TOKEN } from '@core/myservice';
import { DEFAULT_LANG, getCurrentTzNumber, KEY_I18N, REDIRECT_TO, TZ_KEY, } from '@core/okit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
export var useRedirectPath = function () {
    var token = localStorage.getItem(KEY_ACCESS_TOKEN);
    var i18n = useTranslation().i18n;
    var getDefaultPath = useCallback(function () {
        return [
            KEY_ACCESS_TOKEN + "=" + token,
            KEY_I18N + "=" + ((i18n === null || i18n === void 0 ? void 0 : i18n.language) || DEFAULT_LANG),
            TZ_KEY + "=" + getCurrentTzNumber(),
        ];
    }, []);
    var getRedirectPath = function (redirectPath) {
        var paths = getDefaultPath();
        if (redirectPath) {
            paths = __spreadArray(__spreadArray([], paths), [REDIRECT_TO + "=" + encodeURIComponent(redirectPath)]);
        }
        return paths.join('&');
    };
    return { getRedirectPath: getRedirectPath };
};
