var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var ArrowLeft = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M4.293 11.293a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414l-6-6a1 1 0 010-1.414z", clipRule: "evenodd" }),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M11.707 5.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414l6-6a1 1 0 011.414 0z", clipRule: "evenodd" }))); };
export default ArrowLeft;
