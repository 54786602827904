var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Gow = function (props) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.226 2.36676C15.416 2.13463 15.7001 2 16 2H21C21.5523 2 22 2.44772 22 3V8C22 8.29993 21.8654 8.58403 21.6332 8.77396L10.6718 17.7424L6.70711 21.7071C6.31658 22.0976 5.68342 22.0976 5.29289 21.7071L2.29289 18.7071C1.90237 18.3166 1.90237 17.6834 2.29289 17.2929L6.25763 13.3282L15.226 2.36676ZM16.4739 4L7.77396 14.6332C7.75291 14.659 7.7306 14.6836 7.70711 14.7071L4.41421 18L6 19.5858L9.29289 16.2929C9.31639 16.2694 9.34104 16.2471 9.36676 16.226L20 7.52612V4H16.4739Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11.7071 18.2929C12.0976 18.6834 12.0976 19.3166 11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.9278 13.9279C17.3184 13.5374 17.9515 13.5374 18.3421 13.9279L21.7071 17.2929C22.0976 17.6834 22.0976 18.3166 21.7071 18.7071L18.7071 21.7071C18.3165 22.0976 17.6834 22.0976 17.2928 21.7071L13.6128 18.0271C13.2223 17.6366 13.2223 17.0034 13.6128 16.6129C14.0034 16.2224 14.6365 16.2224 15.0271 16.6129L17.9999 19.5858L19.5857 18L16.9278 15.3421C16.5373 14.9516 16.5373 14.3184 16.9278 13.9279Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 3C2 2.44772 2.44772 2 3 2H8C8.30378 2 8.5911 2.13809 8.78087 2.3753L10.7809 4.8753C11.1259 5.30657 11.056 5.93586 10.6247 6.28087C10.1934 6.62588 9.56414 6.55596 9.21913 6.12469L7.51938 4H4V7.53162L6.64018 9.73178C7.06446 10.0853 7.12179 10.7159 6.76822 11.1402C6.41466 11.5645 5.78409 11.6218 5.35982 11.2682L2.35982 8.76822C2.13182 8.57823 2 8.29678 2 8V3Z", fill: "currentColor" }))); };
export default Gow;
