import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { iToast } from '@greyhole/ui-toast';
import { ChangePasswordRequest } from '@greyhole/myid/myid_pb';
import Button, {
  ButtonSizes,
  ButtonVariants,
} from '@core/uikit/build/src/components/Button';
import FormData from '@core/uikit/build/src/components/Form';

import { useAppDispatch } from 'redux/store';
import { changePassword } from 'redux/features/auth/thunks';
import { useField } from './fields';
import { FormFields } from '../../types';

export declare interface BasicInfoFormProps {
  handleClose: () => void;
  formFields: FormFields;
  setFormFields: (formFields: FormFields) => void;
}

export default function BasicInfoForm(props: BasicInfoFormProps) {
  const { handleClose, formFields, setFormFields } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { fields } = useField();

  const form = useForm({
    defaultValues: formFields,
  });

  const onSubmit = async (
    dataFromDynamicForm: ChangePasswordRequest.AsObject,
  ) => {
    setFormFields(dataFromDynamicForm);
    const { newPassword, confirmNewPassword } = dataFromDynamicForm;
    if (newPassword !== confirmNewPassword) {
      form.setError('confirmNewPassword', {
        type: 'password',
        message: t('PASSWORD_NOT_MATCH'),
      });
      return;
    }

    setLoading(true);
    const { error } = await dispatch(
      changePassword(dataFromDynamicForm),
    ).unwrap();
    setLoading(false);

    if (error) return;

    handleClose();
    iToast.success({
      title: t('SUCCESS_TITLE'),
      msg: t('CHANGE_PASSWORD_SUCCESS'),
    });
  };

  return (
    <div className="mt-6">
      <FormProvider {...form}>
        <FormData
          methods={form}
          fields={fields}
          onSubmit={onSubmit}
          actions={
            <div className="flex justify-end gap-8 pt-8">
              <Button
                fullWidth={false}
                variant={ButtonVariants.secondary}
                size={ButtonSizes.lg}
                onClick={handleClose}
              >
                {t('COMMON.TEXT.CANCEL')}
              </Button>
              <Button
                fullWidth={false}
                size={ButtonSizes.lg}
                loading={loading}
                disabled={loading}
              >
                {t('PORTAL.PASSWORD.CHANGE_PASSWORD')}
              </Button>
            </div>
          }
        />
      </FormProvider>
    </div>
  );
}
