import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { EVENTS, logEvent } from '@greyhole/ui-tracking';
import { SSO_PATH } from '@core/okit/build/const/routes';
import { spacesConfig } from './const';
import styles from './styles.module.scss';
import { useRedirectPath } from '../../hooks/useRedirectPath';
var OpsTooltip = withStyles(function () { return ({
    tooltip: {
        backgroundColor: 'white',
        color: '#000',
        fontSize: '14px',
        border: '1px solid #e0e0e0',
        padding: '8px 16px',
        filter: 'drop-shadow(0px -4px 16px rgba(3, 19, 82, 0.16))',
    },
    arrow: {
        '&::before': {
            backgroundColor: '#fff',
            border: '1px solid #e0e0e0',
        },
    },
}); })(Tooltip);
var SpaceList = function (_a) {
    var _b;
    var moduleIdsList = _a.moduleIdsList;
    var t = useTranslation().t;
    var getRedirectPath = useRedirectPath().getRedirectPath;
    var domain = window.location.origin;
    var matchDomain = function (url) {
        return domain.includes(url);
    };
    var getLink = function (url) {
        return "" + url + SSO_PATH + "?" + getRedirectPath();
    };
    var listSpaces = React.useMemo(function () {
        if (!(moduleIdsList === null || moduleIdsList === void 0 ? void 0 : moduleIdsList.length))
            return [];
        return spacesConfig.filter(function (s) { return moduleIdsList.includes(s === null || s === void 0 ? void 0 : s.id); });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleIdsList]);
    var handleSpaceClick = function (event, space) {
        logEvent(EVENTS.SIDEBAR_SPACE_CLICK, { space: t(space.title) });
    };
    var spaces = listSpaces.map(function (space) {
        var _a;
        return (React.createElement("li", { key: space.id, className: cn((_a = {}, _a[styles.active] = matchDomain(space.url), _a)) },
            React.createElement(OpsTooltip, { title: t(space.title) || '-', placement: "right", arrow: true },
                React.createElement("a", { href: getLink(space.url), onClick: function (event) {
                        handleSpaceClick(event, space);
                    } }, space.iconSelected && matchDomain(space.url) ? (React.createElement(space.iconSelected, null)) : (React.createElement(space.icon, null))))));
    });
    return (React.createElement("div", { className: cn(styles.spaces, (_b = {},
            _b[styles.hideSpaceList] = listSpaces.length === 1,
            _b)) },
        React.createElement("ul", null, spaces)));
};
export default SpaceList;
