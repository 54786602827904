var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import Icon from '@core/uikit/build/src/components/Icon';
import { Export } from '@core/icons';
import Button, { ButtonVariants, } from '@core/uikit/build/src/components/Button';
var ExportCsv = function (_a) {
    var csvData = _a.csvData, _b = _a.filename, filename = _b === void 0 ? 'data-export' : _b, canExport = _a.canExport, loading = _a.loading, disabled = _a.disabled;
    var t = useTranslation().t;
    var csvRef = useRef();
    var handleClick = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var isExport, _a;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = canExport;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, canExport()];
                case 1:
                    _a = (_e.sent());
                    _e.label = 2;
                case 2:
                    isExport = _a;
                    if (!canExport || isExport) {
                        (_d = (_c = (_b = csvRef === null || csvRef === void 0 ? void 0 : csvRef.current) === null || _b === void 0 ? void 0 : _b.link) === null || _c === void 0 ? void 0 : _c.click) === null || _d === void 0 ? void 0 : _d.call(_c);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: handleClick, loading: loading, disabled: loading || disabled, variant: ButtonVariants.secondary, style: { padding: 8 } },
            React.createElement(Icon, { width: 24, height: 24, component: Export })),
        React.createElement(CSVLink, { data: csvData, filename: filename, ref: csvRef })));
};
export default ExportCsv;
