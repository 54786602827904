var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
function Banker(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M9 4a3 3 0 100 6 3 3 0 000-6zM4 7a5 5 0 1110 0A5 5 0 014 7zM7 16a3 3 0 00-3 3v2a1 1 0 11-2 0v-2a5 5 0 015-5h4a5 5 0 015 5v2a1 1 0 11-2 0v-2a3 3 0 00-3-3H7zM16.732 3.732A2.5 2.5 0 0118.5 3H21a1 1 0 110 2h-2.5a.5.5 0 000 1h1a2.5 2.5 0 010 5H17a1 1 0 110-2h2.5a.5.5 0 000-1h-1a2.5 2.5 0 01-1.768-4.268z", clipRule: "evenodd" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M19 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm0 7a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1z", clipRule: "evenodd" })));
}
export default Banker;
