import { ChangePasswordRequest } from '@greyhole/myid/myid_pb';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { myIDClient } from '@core/myservice/build/client';

export const getUserInfoThunk = createAsyncThunk(
  'auth/getUserInfo',
  async () => {
    return await myIDClient.getMe();
  },
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (payload: ChangePasswordRequest.AsObject) => {
    return await myIDClient.changePassword(payload);
  },
);

export const getAuthThunk = createAsyncThunk('auth/getAuthThunk', async () => {
  return await myIDClient.getMe();
});
