var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import MenuItem from './Item';
import styles from './styles.module.scss';
var MenuInSidebar = function (_a) {
    var routes = _a.routes;
    return (React.createElement("ul", { className: styles.menu }, routes.map(function (route, idx) {
        return React.createElement(MenuItem, __assign({ key: route.path || idx }, route));
    })));
};
export default MenuInSidebar;
