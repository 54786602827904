import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logEvent, setUserId, setUserTenantId } from './firebase';
import { EVENTS } from '.';
import { TENANT_GAMELOOT } from './const';
const pageExcludes = (process.env.REACT_APP_PAGE_TRACKING_EXCLUDES || '').split(',');
export function PageViewLogger({ spaceName, userID }) {
    usePageViewLogger(spaceName, userID);
    return null;
}
function usePageViewLogger(spaceName, userID) {
    const location = useLocation();
    useEffect(() => {
        if (userID) {
            setUserId(userID);
        }
        setUserTenantId(TENANT_GAMELOOT);
    }, [userID]);
    useEffect(() => {
        // Log event for a route change should occur after the page title changed
        setTimeout(() => {
            if (pageExcludes.includes(document.title)) {
                return;
            }
            logEvent(EVENTS.PAGE_VIEW_EVENT, {
                page_path: location.pathname,
                space: spaceName,
            });
        }, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
}
