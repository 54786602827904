var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DailyplanetClient } from '@dceu/dailyplanet/Dailyplanet_key_indexesServiceClientPb';
import { ListLoginsRequest, ListSalesRequest, ListWalletEventsRequest, GetUserStatsRequest, ListTopicsRequest, ListSessionsRequest, ListAcquisitionRequest, ListPlayerInsightsRequest, GetLatestTimeRequest, } from '@dceu/dailyplanet/dailyplanet_key_indexes_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import gRPCClientAbstract from '../abstract/gRPCClient';
var rpcDailyPlanetClient = /** @class */ (function (_super) {
    __extends(rpcDailyPlanetClient, _super);
    function rpcDailyPlanetClient(config) {
        var _this = this;
        config.serviceName = 'DAILY_PLANET';
        _this = _super.call(this, DailyplanetClient, config) || this;
        return _this;
    }
    rpcDailyPlanetClient.prototype.listSales = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListSalesRequest();
                        if (p.month) {
                            ts = new Timestamp();
                            ts.setSeconds(p.month.seconds + 15 * 24 * 3600); // add 15 days
                            ts.setNanos(p.month.nanos);
                            req.setMonth(ts);
                            req.setTimeZone(p.timeZone);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listSales', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcDailyPlanetClient.prototype.listLogins = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, fromTimeStamp, toTimeStamp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListLoginsRequest();
                        req.setCursor(p.cursor);
                        req.setLimit(p.limit);
                        req.setUserId(p.userId);
                        if (p.from && p.to) {
                            fromTimeStamp = new Timestamp();
                            fromTimeStamp.setSeconds(p.from.seconds);
                            fromTimeStamp.setNanos(p.from.nanos);
                            toTimeStamp = new Timestamp();
                            toTimeStamp.setSeconds(p.to.seconds);
                            toTimeStamp.setNanos(p.to.nanos);
                            req.setFrom(fromTimeStamp);
                            req.setTo(toTimeStamp);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listLogins', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcDailyPlanetClient.prototype.listWalletEvents = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, fromTimeStamp, toTimeStamp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListWalletEventsRequest();
                        req.setUserId(p.userId);
                        req.setTopicsList(p.topicsList);
                        req.setLabelsList(p.labelsList);
                        req.setCursor(p.cursor);
                        req.setLimit(p.limit);
                        if (p.from && p.to) {
                            fromTimeStamp = new Timestamp();
                            fromTimeStamp.setSeconds(p.from.seconds);
                            fromTimeStamp.setNanos(p.from.nanos);
                            toTimeStamp = new Timestamp();
                            toTimeStamp.setSeconds(p.to.seconds);
                            toTimeStamp.setNanos(p.to.nanos);
                            req.setFrom(fromTimeStamp);
                            req.setTo(toTimeStamp);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listWalletEvents', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcDailyPlanetClient.prototype.getUserStats = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetUserStatsRequest();
                        req.setUserId(p.userId);
                        return [4 /*yield*/, this.gRPCClientRequest('getUserStats', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcDailyPlanetClient.prototype.listTopics = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListTopicsRequest();
                        return [4 /*yield*/, this.gRPCClientRequest('listTopics', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcDailyPlanetClient.prototype.listSessions = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts, toTimeStamp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListSessionsRequest();
                        req.setLimit(p.limit);
                        req.setCursor(p.cursor);
                        req.setUserId(p.userId);
                        req.setTopicsList(p.topicsList);
                        if (p.from) {
                            ts = new Timestamp();
                            ts.setSeconds(p.from.seconds);
                            ts.setNanos(p.from.nanos);
                            req.setFrom(ts);
                        }
                        if (p.to) {
                            toTimeStamp = new Timestamp();
                            toTimeStamp.setSeconds(p.to.seconds);
                            toTimeStamp.setNanos(p.to.nanos);
                            req.setTo(toTimeStamp);
                        }
                        return [4 /*yield*/, this.gRPCClientRequest('listSessions', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcDailyPlanetClient.prototype.listAcquisition = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListAcquisitionRequest();
                        if (p.month) {
                            ts = new Timestamp();
                            ts.setSeconds(p.month.seconds + 15 * 24 * 3600); // add 15 days
                            ts.setNanos(p.month.nanos);
                            req.setMonth(ts);
                        }
                        req.setTimeZone(p.timeZone);
                        return [4 /*yield*/, this.gRPCClientRequest('listAcquisition', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcDailyPlanetClient.prototype.listPlayerInsights = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req, ts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new ListPlayerInsightsRequest();
                        if (p.month) {
                            ts = new Timestamp();
                            ts.setSeconds(p.month.seconds + 15 * 24 * 3600); // add 15 days
                            ts.setNanos(p.month.nanos);
                            req.setMonth(ts);
                        }
                        req.setTimeZone(p.timeZone);
                        return [4 /*yield*/, this.gRPCClientRequest('listPlayerInsights', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    rpcDailyPlanetClient.prototype.getLatestTime = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new GetLatestTimeRequest();
                        req.setDatasource(p.datasource);
                        return [4 /*yield*/, this.gRPCClientRequest('getLatestTime', req)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return rpcDailyPlanetClient;
}(gRPCClientAbstract));
export default rpcDailyPlanetClient;
