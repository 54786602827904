import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Divider } from '@material-ui/core';
import { Bar, LiveOpsLogo } from '@core/icons';
import LanguageSelector from '@core/uikit/build/src/components/LanguageSelector';
import SpaceList from '@core/oui/build/src/components/SpaceList';
import { spacesConfig } from '@core/oui/build/src/components/SpaceList/const';
import {
  MenuInSidebar,
  SearchBox,
  TimezoneSelector,
} from '@core/oui/build/src/components';

import { OpsRouteObject } from '@core/oui/build/src/components/OpsRoutes';

import { OpsTooltip } from './components/OpsTooltip';
import useAuth from 'hooks/useAuth';
import Profile from './Profile';
import styles from './styles.module.scss';

interface IMainLayoutProps {
  routes: OpsRouteObject[];
}

const DividerHeader = () => (
  <Divider orientation="vertical" className={styles.divider} />
);

const MainLayout: React.FunctionComponent<IMainLayoutProps> = ({
  children,
  routes,
}) => {
  const { t } = useTranslation();
  const [collapse, setCollapseMenu] = React.useState<boolean>(true);
  const { moduleIdsList } = useAuth();

  const collapseMenu = () => {
    setCollapseMenu(!collapse);
  };

  const space = spacesConfig.find((space) =>
    space.url.includes(window.location.origin),
  );

  const isNoSpaces = React.useMemo(() => {
    return moduleIdsList?.length === 1;
  }, [moduleIdsList?.length]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.logoBox}>
          <div className={styles.collapse} onClick={collapseMenu}>
            <Bar />
          </div>
          <div className={styles.logo}>
            <LiveOpsLogo />
          </div>
        </div>
        <div className={styles.other}>
          <SearchBox />
          <DividerHeader />
          <TimezoneSelector />
          <DividerHeader />
          <LanguageSelector />
          <DividerHeader />
          <Profile />
        </div>
      </div>

      <div className={styles.body}>
        <SpaceList moduleIdsList={moduleIdsList} />
        <div
          className={cn(
            styles.sidebar,
            { [styles.sidebarShow]: collapse },
            { [styles.noSpaces]: isNoSpaces },
          )}
        >
          <OpsTooltip title={t(space?.title)} placement="right" arrow>
            <div className={styles.spaceName}>
              <span>{t(space?.title || '-')}</span>
            </div>
          </OpsTooltip>
          <div className={styles.menu}>
            <MenuInSidebar routes={routes} />
          </div>
        </div>
        <div
          className={cn(styles.content, {
            [styles.contentShow]: collapse,
            [styles.contentShowFullWidth]:
              !collapse && moduleIdsList?.length === 1,
          })}
        >
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
