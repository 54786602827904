var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AngleRight } from '@core/icons';
import Typography, { TypoTypes, TypoVariants, TypoWeights, } from '../../components/Typography';
import Icon from '../../components/Icon';
var useStyle = makeStyles({
    main: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fill: '#556785 !important',
        margin: '0 16px',
    },
});
var BreadcrumbElement = function (_a) {
    var name = _a.name, to = _a.to, active = _a.active;
    return (_jsx(Link, __assign({ to: to }, { children: active ? (_jsx(Typography, __assign({ variant: TypoVariants.body1, weight: TypoWeights.bold, type: TypoTypes.placeholder }, { children: name }), void 0)) : (_jsx(Typography, __assign({ variant: TypoVariants.body1, weight: TypoWeights.light, type: TypoTypes.placeholder }, { children: name }), void 0)) }), void 0));
};
var Breadcrumbs = function (_a) {
    var breadcrumbs = _a.breadcrumbs;
    var classes = useStyle();
    return (_jsx("div", __assign({ className: classes.main }, { children: breadcrumbs.map(function (item, index) { return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbElement, __assign({}, item), index), !item.active && (_jsx(Icon, { className: classes.icon, component: AngleRight }, void 0))] }, void 0)); }) }), void 0));
};
export default Breadcrumbs;
