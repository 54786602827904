var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var SvgComponent = function (props) { return (React.createElement("svg", __assign({ width: 24, height: 24, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fill: "#E31D1C", d: "M2 5h20v14H2z" }),
    React.createElement("path", { d: "m5 6.682.588 1.19 1.314.192-.95.927.224 1.309L5 9.682l-1.176.618.225-1.31-.951-.926 1.314-.191L5 6.682ZM7.627 5.487l.744.54-.284.876h-.92l-.285-.875.745-.541ZM10.007 7.02l.144.91-.82.418-.65-.651.417-.82.909.144ZM7.627 11.75l-.745-.541.285-.875h.92l.284.875-.744.541ZM10.007 10.216l-.909.144-.418-.82.651-.651.82.418-.144.909Z", fill: "#FFD018" }))); };
export default SvgComponent;
